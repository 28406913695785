import {
  PracticeUserSearchResults,
  ProviderListItem,
  usePracticeSearchUsersQuery
} from "@/store/services/practice";
import SetStaffScheduleRenderer, { StaffScheduleFormValues } from "./render";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { METRIC_LABELS, METRICS, STATUS_KEYS } from "@/globals/constants";
import { setModalIsOpen } from "../../modalSlice";
import {
  EventType,
  EventUpdateBody,
  useEventCreateMutation,
  useEventDeleteMutation,
  useEventUpdateMutation
} from "@/store/services/event";
import {
  apptSlots,
  convertLocalDatetimeToUtcInt,
  convertUtcIntToLocalDatetime
} from "@/components/scheduling/calendars/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCustomPracticeLocations } from "@/globals/helpers/customHooks";
import { RootState } from "@/store/store";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

export interface SetStaffScheduleModalProps {
  defaultValues: Partial<StaffScheduleFormValues>;
  eventId?: string;
  isSchedule?: boolean;
}

export default function SetStaffSchedule({
  defaultValues,
  eventId,
  isSchedule
}: SetStaffScheduleModalProps) {
  const dispatch = useDispatch();
  const form = useForm({ defaultValues });

  /* Redux */
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const locations = useCustomPracticeLocations();
  const { data: users } = usePracticeSearchUsersQuery(
    {
      practiceId: sessionInfo?.practice_id as number
    },
    {
      skip: sessionInfo?.practice_id === undefined
    }
  );

  const [createCalendarEvent] = useEventCreateMutation();
  const [updateCalendarEvent] = useEventUpdateMutation();
  const [deleteCalendarEvent] = useEventDeleteMutation();

  /* Local State */
  const locationOptions = (locations || [])
    .filter(l => typeof l === "object")
    .map(location => ({
      value: location,
      label: location.name || ""
    }));
  const fromCalEventTypes: { value: EventType; label: string }[] = [
    {
      value: "internal",
      label: METRIC_LABELS["internal"]
    },
    {
      value: "out_of_office",
      label: METRIC_LABELS["out_of_office"]
    }
  ];
  const fromProviderScheduleEventTypes: { value: EventType; label: string }[] =
    [
      {
        value: METRICS.ON_CALL_FIRST as EventType,
        label: METRIC_LABELS[METRICS.ON_CALL_FIRST]
      },
      {
        value: METRICS.ON_CALL_SECOND as EventType,
        label: METRIC_LABELS[METRICS.ON_CALL_SECOND]
      },
      {
        value: METRICS.IN_CLINIC as EventType,
        label: METRIC_LABELS[METRICS.IN_CLINIC]
      },
      {
        value: METRICS.ON_CALL_IN_CLINIC_FIRST as EventType,
        label: METRIC_LABELS[METRICS.ON_CALL_IN_CLINIC_FIRST]
      },
      {
        value: METRICS.ON_CALL_IN_CLINIC_SECOND as EventType,
        label: METRIC_LABELS[METRICS.ON_CALL_IN_CLINIC_SECOND]
      },
      {
        value: METRICS.SCHEDULE as EventType,
        label: METRIC_LABELS[METRICS.SCHEDULE]
      }
    ];
  const now = parseInt(dayjs().format("YYYYMMDDhhmmss"));
  const startOptions = apptSlots(
    convertUtcIntToLocalDatetime(defaultValues?.shift?.starts || now).format(
      "MM/DD/YYYY"
    )
  ).map(slot => ({
    value: convertLocalDatetimeToUtcInt(dayjs(slot)) as number,
    label: dayjs(slot).format("h:mm a")
  }));
  const endOptions = apptSlots(
    convertUtcIntToLocalDatetime(defaultValues?.shift?.ends || now).format(
      "MM/DD/YYYY"
    )
  ).map(slot => ({
    value: convertLocalDatetimeToUtcInt(dayjs(slot)) as number,
    label: dayjs(slot).format("h:mm a")
  }));

  const eventType = form.watch("select_event_type");

  /* Effects */
  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  // Event handlers
  const onSubmit = (data: StaffScheduleFormValues) => {
    let start = data.shift.starts || now;
    let end = data.shift.ends || now;
    let start_at = convertUtcIntToLocalDatetime(start).toISOString();
    let end_at = convertUtcIntToLocalDatetime(end).toISOString();

    if (data) {
      const event: EventUpdateBody = {
        location_id: data.select_location.location_id,
        title: isSchedule ? "Schedule" : data.event_title,
        start_at,
        end_at,
        all_day: data.all_day,
        event_type: data.select_event_type?.toLowerCase() as
          | EventType
          | undefined // update & create endpoint expect lowercase string
      };
      if (data.select_participants) {
        event.participants = data.select_participants.map(
          (p: ProviderListItem) => p.user_id
        );
      }

      if (eventId) {
        updateCalendarEvent({ eventId, eventUpdateBody: event })
          .unwrap()
          .then(() => {
            dispatch(
              addAlertToToastTrough({
                message: "Successly updated schedule!",
                type: STATUS_KEYS.SUCCESS
              })
            );
            dispatch(setModalIsOpen(false));
          })

          .catch(() =>
            dispatch(
              addAlertToToastTrough({
                message: "Error!",
                type: STATUS_KEYS.ERROR
              })
            )
          );
      } else {
        // @ts-ignore
        // TODO @mia: update when reference updated
        createCalendarEvent({ eventCreateBody: event })
          .unwrap()
          .then(() => {
            dispatch(
              addAlertToToastTrough({
                message: "Successfully updated schedule!",
                type: STATUS_KEYS.SUCCESS
              })
            );
            dispatch(setModalIsOpen(false));
          })

          .catch(() =>
            dispatch(
              addAlertToToastTrough({
                message: "Error!",
                type: STATUS_KEYS.ERROR
              })
            )
          );
      }
    }
  };

  const handleDelete = (eventId: string) => {
    deleteCalendarEvent({ eventId })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Successfully deleted event",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })

      .catch(() =>
        dispatch(
          addAlertToToastTrough({
            message: "Error!",
            type: STATUS_KEYS.ERROR
          })
        )
      );
  };

  return (
    <SetStaffScheduleRenderer
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      isSchedule={!!isSchedule}
      fromCalEventTypes={fromCalEventTypes}
      fromProviderScheduleEventTypes={fromProviderScheduleEventTypes}
      locationOptions={locationOptions}
      startOptions={startOptions}
      endOptions={endOptions}
      users={users as PracticeUserSearchResults}
      eventId={eventId}
      handleDelete={handleDelete}
      allDayToggleVisible={eventType => {
        return (
          !!isSchedule &&
          [
            METRICS.ON_CALL_FIRST,
            METRICS.ON_CALL_SECOND,
            METRICS.IN_OFFICE,
            METRICS.ON_CALL_IN_CLINIC_FIRST,
            METRICS.ON_CALL_IN_CLINIC_SECOND,
            METRICS.IN_CLINIC
          ].includes(eventType.toLowerCase())
        );
      }}
    />
  );
}
