import * as generated from "./generated/task";

const task = generated.task.enhanceEndpoints({
  endpoints: {
    taskComplete: {
      invalidatesTags: ["Tasks", "Patient-Files", "Practice-Files"]
    },
    taskMarkIncomplete: {
      invalidatesTags: ["Tasks", "Patient-Files", "Practice-Files"]
    },
    taskCreate: {
      invalidatesTags: ["Tasks"]
    },
    taskUpdate: {
      invalidatesTags: ["Tasks"]
    },
    taskDelete: {
      invalidatesTags: ["Tasks"]
    },
    taskInfo: {
      providesTags: ["Tasks"]
    },
    taskList: {
      providesTags: ["Tasks"]
    },
    taskGeneratePdf: {
      invalidatesTags: ["Tasks"]
    }
  }
});

export * from "./generated/task";
export default task;
export const {
  useTaskCreateMutation,
  useTaskUpdateMutation,
  useTaskInfoQuery,
  useLazyTaskInfoQuery,
  useTaskDeleteMutation,
  useTaskCompleteMutation,
  useTaskListQuery,
  useLazyTaskListQuery,
  useTaskGeneratePdfMutation
} = task;
