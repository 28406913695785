/* ViewBody Name */
/* External Imports */
import { ReactNode, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

/* Local Imports */

// components
import Annotation from "@/components/annotations";
import AddAnnotation from "@/components/annotations/addAnnotation";
import LayoutLeft from "../../../public/svgs/layout-left.svg";
import Button from "../button";
import NotificationIcon from "../notificationIcon";

// constants
import { STYLES, STATUS_KEYS } from "@/globals/constants";
// store
import {
  FileId,
  useFileDetailsQuery,
  FileAnnotation,
  useFileAnnotationDeleteMutation,
  AnnotationId,
  useFileAnnotationCreateMutation,
  useFileAnnotationUpdateMutation
} from "@/store/services/file";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";
import { RootState } from "@/store/store";

// styles
import styles from "./styles.module.scss";

/* ViewBody Typescript Interface */
interface ViewBodyProps {
  children: ReactNode;
  fileId?: FileId;
}

export default function ViewBody({ children, fileId }: ViewBodyProps) {
  /* Redux */
  const dispatch = useDispatch();

  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { data: fileDetails } = useFileDetailsQuery(
    { fileId: fileId as FileId },
    { skip: !fileId }
  );

  const [deleteAnnotation, { isLoading: isDeleting }] =
    useFileAnnotationDeleteMutation();

  const [createAnnotation, { isLoading: isCreating }] =
    useFileAnnotationCreateMutation();
  const [updateAnnotation, { isLoading: isUpdating }] =
    useFileAnnotationUpdateMutation();
  const [addAnnotationOpen, setAddAnnotationOpen] = useState(false);

  /* Local State */
  const [showAnnotations, setShowAnnotations] = useState(false);
  const [openAnnotationIds, setOpenAnnotationIds] = useState<
    (AnnotationId | null)[]
  >([]);

  /* Effects */

  /* Event Handlers */

  function renderAddButton() {
    return (
      <button
        className={styles.addAnnotation}
        onClick={() => setAddAnnotationOpen(true)}
      >
        +
      </button>
    );
  }

  const handleClose = () => {
    setAddAnnotationOpen(false);
  };

  const handleDeleteFileAnnotation = (
    fileId: FileId,
    annotationId: AnnotationId
  ) => {
    deleteAnnotation({ fileId, annotationId })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Annotation Deleted",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(err => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to delete annotation",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleCreateFileAnnotation = (fileId: FileId, annotation: string) => {
    createAnnotation({
      fileId,
      fileAnnotationCreateRequest: { file_id: fileId, annotation }
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Annotation Created",
            type: STATUS_KEYS.SUCCESS
          })
        );
        setAddAnnotationOpen(false);
      })
      .catch(err => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to create annotation",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleUpdateFileAnnotation = (
    fileId: FileId,
    annotationId: AnnotationId,
    annotation: string
  ) => {
    // if the annotation id is not already present in the open annotation ids, add it
    if (!openAnnotationIds.includes(annotationId)) {
      setOpenAnnotationIds([...openAnnotationIds, annotationId]);
    }

    updateAnnotation({
      fileId,
      annotationId,
      fileAnnotationUpdateRequest: {
        annotation_id: annotationId,
        annotation
      }
    })
      .unwrap()
      .then(() => {
        // remove the annotation id from the open annotation ids
        setOpenAnnotationIds(
          openAnnotationIds.filter(id => id !== annotationId)
        );
        dispatch(
          addAlertToToastTrough({
            message: "Annotation Updated",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(err => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to update annotation",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  return (
    <div className={clsx(styles.ViewBody)}>
      <div className={styles.menu}>
        {fileId && !sessionInfo?.is_patient && (
          <div className={styles.icon}>
            <Button
              style={STYLES.ICON}
              onClick={() => setShowAnnotations(!showAnnotations)}
              dataCy="toggle-annotations-button"
            >
              <LayoutLeft
                stroke={showAnnotations ? styles.primary700 : styles.gray500}
                height={20}
                width={20}
              />
              <div className="light">
                {showAnnotations
                  ? "Close Annotation Drawer"
                  : "Open Annotation Drawer"}
              </div>
              {fileDetails?.annotations ? <NotificationIcon /> : ""}
            </Button>
          </div>
        )}
      </div>
      {/* The PDF */}
      <div className={styles.content}>
        <div className={styles.contentViewer}>{children} </div>
        {/* The annotations menu */}
        {showAnnotations && (
          <div
            className={styles.annotationWrapper}
            data-cy="file-annotations-wrapper"
          >
            {/* either display already existing annotations, or empty state prompting user to add some */}

            <>
              <div className={styles.overflow}>
                {fileDetails?.annotations?.map(
                  (fileAnnotation: FileAnnotation) => (
                    <Annotation
                      key={fileAnnotation.annotation_id}
                      annotation={fileAnnotation.annotation}
                      author={fileAnnotation.author}
                      created={fileAnnotation.created}
                      updated={fileAnnotation.updated}
                      annotationId={fileAnnotation.annotation_id}
                      isEditing={openAnnotationIds.includes(
                        fileAnnotation.annotation_id
                      )}
                      onAnnotationUpdate={(annotationId, annotation) =>
                        handleUpdateFileAnnotation(
                          fileAnnotation.file_id,
                          annotationId as AnnotationId, // because file & lab order annotation ids are different types
                          annotation
                        )
                      }
                      onAnnotationCreate={(annotation: string) =>
                        handleCreateFileAnnotation(
                          fileAnnotation.file_id,
                          annotation
                        )
                      }
                      onDelete={annotationId =>
                        handleDeleteFileAnnotation(
                          fileAnnotation.file_id,
                          annotationId as AnnotationId // because file & lab order annotation ids are different types
                        )
                      }
                      isSubmitting={isCreating || isUpdating}
                    />
                  )
                )}
              </div>

              <div className={styles.emptyState}>
                <div className={styles.addWrapper}>
                  {renderAddButton()}

                  {addAnnotationOpen && fileId && (
                    <AddAnnotation
                      onCancel={handleClose}
                      onUpdate={(annotationId, annotation) =>
                        handleUpdateFileAnnotation(
                          fileId,
                          annotationId as AnnotationId, // because file & lab order annotation ids are different types
                          annotation
                        )
                      }
                      onCreate={annotation =>
                        handleCreateFileAnnotation(fileId, annotation)
                      }
                      isEditing={false}
                      isSubmitting={isCreating || isUpdating}
                    />
                  )}
                </div>
                <div className="tMd Med dark">Add Comments</div>
                {/* // TODO: move into copy constants */}
                <p className="xLight">Add annotations to the document</p>
              </div>
            </>
          </div>
        )}
      </div>
    </div>
  );
}
