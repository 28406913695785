import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import clsx from "clsx";

// components
import Button from "@/components/button";
import Input from "@/components/input";
import Icon from "../icons";

// store
import {
  Address,
  PatientUpsertRequest,
  Statecode,
  Sex,
  usePatientUpsertMutation,
  PatientType,
  PatientInfo,
  usePregnancyUpsertMutation
} from "@/store/services/patient";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";
import { setModalIsOpen } from "../modal/modalSlice";
import {
  useCreateEddMutation,
  CreateEddApiArg
} from "@/store/services/pregnancy";
import { usePracticeSearchUsersQuery } from "@/store/services/practice";
import { RootState } from "@/store/store";
import { useGetFeatureFlagsQuery } from "@/store/services/system";

// constants
import {
  METRIC_LABELS,
  STATUS_KEYS,
  STYLES,
  METRICS,
  STATE_CODES
} from "@/globals/constants";
import { nameAndDOB } from "@/globals/helpers/formatters";

// styles
import styles from "./styles.module.scss";

// utils
import { calculateEDD } from "@/globals/helpers";
import {
  useCustomPatientTypes,
  useCustomPatientSexOptions
} from "@/globals/helpers/customHooks";
import ControlledCombobox from "../input/controlledCombobox";

interface CreatePatientFormFields extends FieldValues {
  first_name: string;
  last_name: string;
  dob: string;
  email: string;
  mobile: string;
  addr_line_1: string;
  addr_line_2: string;
  addr_line_3: string;
  city: string;
  state: string;
  zip: string;
  sex: string;
  pronouns: string;
  LMP?: string;
  patient_type: string;
  parent?: PatientInfo;
}

export default function CreatePatient() {
  /* Redux */
  const dispatch = useDispatch();
  const CustomPatientTypes = useCustomPatientTypes();
  const CustomPatientSexOptions = useCustomPatientSexOptions();

  const [upsertPregnancy] = usePregnancyUpsertMutation();

  const form = useForm<CreatePatientFormFields>();
  const patientType = form.watch("patient_type");
  const parent = form.watch("parent");
  const onSubmit: SubmitHandler<CreatePatientFormFields> = data => {
    const address: Partial<Address> = {
      line1: data.addr_line_1,
      line2: data.addr_line_2,
      line3: data.addr_line_3,
      city: data.city,
      state: data.state as Statecode,
      zip: data.zip
    };

    const pronouns = data.pronouns;
    const dobAsInt = parseInt((data.dob as string).split("-").join(""));

    const parentIds = parent ? [parent.user_id] : undefined;
    const req: PatientUpsertRequest = {
      ...data,
      dob: dobAsInt,
      address,
      sex: data.sex as Sex,
      practice_data: { type: data.patient_type as PatientType },
      parent_ids: parentIds
    };

    if (featureFlags?.pronouns_enabled && pronouns.length > 0) {
      req.pronouns = pronouns;
    }
    upsertPatient({ patientUpsertRequest: req })
      .unwrap()
      .then(patient => {
        if (patient.practice_data.type === METRICS.PROSPECTIVE && data.LMP) {
          // Create the pregnancy
          upsertPregnancy({
            patientId: patient.user_id,
            pregnancyUpsertRequest: {}
          })
            .unwrap()
            .then(pregId => {
              // Create/update EDD
              const EDD = calculateEDD(data.LMP as string);

              const updateReq = {
                pregnancyId: pregId,
                estimatedDueDate: {
                  method: "LMP",
                  date_of_method: data.LMP,
                  estimated_due_date: EDD.format("YYYY-MM-DD")
                }
              } as CreateEddApiArg;

              createEdd(updateReq);
            });
        }
        // if patient is OB & LMP field is populated
        // update patient's pregnancy EDD
        if (
          patient.practice_data.type === METRICS.OB &&
          patient.pregnancy &&
          data.LMP
        ) {
          // Create/update EDD
          const EDD = calculateEDD(data.LMP);

          const updateReq = {
            pregnancyId: patient.pregnancy.pregnancy_id as number,
            estimatedDueDate: {
              method: "LMP",
              date_of_method: data.LMP,
              estimated_due_date: EDD.format("YYYY-MM-DD")
            }
          } as CreateEddApiArg;

          createEdd(updateReq);
        }

        dispatch(setModalIsOpen(false));
        dispatch(
          addAlertToToastTrough({
            message: "patient created",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(err => {
        dispatch(
          addAlertToToastTrough({
            message: err.data.message,
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { data: featureFlags } = useGetFeatureFlagsQuery();

  const { data: users } = usePracticeSearchUsersQuery(
    {
      practiceId: sessionInfo?.practice_id as number
    },
    {
      skip: sessionInfo?.practice_id === undefined
    }
  );

  const [createEdd] = useCreateEddMutation();
  const [upsertPatient] = usePatientUpsertMutation();

  useEffect(() => {
    if (parent) {
      const addrLine1 = form.getValues("addr_line_1");
      const addrLine2 = form.getValues("addr_line_2");
      const addrLine3 = form.getValues("addr_line_3");
      const city = form.getValues("city");
      const state = form.getValues("state");
      const zip = form.getValues("zip");

      // Only set values if the fields are empty
      if (!addrLine1 && !addrLine2 && !addrLine3 && !city && !state && !zip) {
        form.setValue("addr_line_1", parent.address?.line1 || "");
        form.setValue("addr_line_2", parent.address?.line2 || "");
        form.setValue("addr_line_3", parent.address?.line3 || "");
        form.setValue("city", parent.address?.city || "");
        form.setValue("state", parent.address?.state || "");
        form.setValue("zip", parent.address?.zip || "");
      }
    }
  }, [parent, form]);

  return (
    <form
      className={clsx(styles.Create, styles.CreateUserForm)}
      action="post"
      onSubmit={form.handleSubmit(onSubmit)}
      data-cy="create-patient-form"
    >
      <div className="note">
        <Icon svg="lightbulb" width={12} />
        Patient email and phone number must be unique
      </div>
      <div className={styles.gridCol3}>
        <div data-cy="first-name">
          <Input<CreatePatientFormFields>
            label="First Name"
            name="first_name"
            id="first_name"
            type="text"
            register={form.register}
            required
          />
        </div>
        <div data-cy="last-name">
          <Input<CreatePatientFormFields>
            label="Last Name"
            name="last_name"
            id="last_name"
            type="text"
            register={form.register}
            required
          />
        </div>
        <div data-cy="dob">
          <Input<CreatePatientFormFields>
            type="date"
            label="Date of Birth"
            name="dob"
            id="dob"
            register={form.register}
            required
          />
        </div>
      </div>
      <div className={styles.gridCol2}>
        {form.getValues("patient_type") !== METRICS.INFANT && (
          <>
            <div data-cy="email">
              <Input<CreatePatientFormFields>
                label="Email"
                name="email"
                id="email"
                type="email"
                required={!parent}
                register={form.register}
                fullWidth
              />
            </div>
            <div data-cy="phone">
              <Input<CreatePatientFormFields>
                label="Phone"
                name="mobile"
                id="mobile"
                type="tel"
                required={!parent}
                register={form.register}
                fullWidth
              />
            </div>
          </>
        )}
        <div data-cy="sex">
          <ControlledCombobox<CreatePatientFormFields, any>
            name="sex"
            label="Sex*"
            options={CustomPatientSexOptions}
            control={form.control}
            labelAcc={v => METRIC_LABELS[v]}
            required
            fullWidth
            isHorizontalLayout={false}
            errors={form.formState.errors}
          />
        </div>
        {featureFlags?.pronouns_enabled && (
          <div data-cy="pronouns">
            <Input
              label="Pronouns"
              name="pronouns"
              id="pronouns"
              type="text"
              fullWidth
              register={form.register}
            />
          </div>
        )}

        <div data-cy="patient-type">
          <ControlledCombobox
            fullWidth
            name="patient_type"
            label="Patient Type*"
            options={CustomPatientTypes}
            labelAcc={item => METRIC_LABELS[item] || item}
            control={form.control}
            required
            isHorizontalLayout={false}
            errors={form.formState.errors}
          />
        </div>
        {patientType === METRICS.OB || patientType === METRICS.PROSPECTIVE ? (
          <div data-cy="lmp">
            <Input
              label="LMP"
              name="LMP"
              id="LMP"
              type="date"
              fullWidth
              register={form.register}
            />
          </div>
        ) : patientType === METRICS.INFANT ? (
          <div data-cy="parent" id="patient-parent">
            <ControlledCombobox<CreatePatientFormFields, any>
              name="parent"
              label="Select Parent*"
              fullWidth
              labelAcc={patient => (patient ? nameAndDOB(patient) : patient)}
              options={([...(users?.patients || [])] || []).sort((a, b) =>
                a.last_name.localeCompare(b.last_name)
              )}
              control={form.control}
              required
              isHorizontalLayout={false}
              errors={form.formState.errors}
            />
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className={styles.gridCol1} data-cy="addr-line-1">
        <Input
          label="Address Line 1"
          name="addr_line_1"
          id="addr-line1"
          type="text"
          register={form.register}
          fullWidth
        />
      </div>
      <div className={styles.gridCol2}>
        <Input
          label="Address Line 2"
          name="addr_line_2"
          id="addr-line2"
          type="text"
          register={form.register}
          fullWidth
        />
        <Input
          label="Address Line 3"
          name="addr_line_3"
          id="addr-line3"
          type="text"
          fullWidth
        />
      </div>
      <div className={styles.gridCol1}>
        <Input
          label="City"
          name="city"
          id="addr-city"
          type="text"
          fullWidth
          register={form.register}
        />
      </div>
      <div className={styles.gridCol2}>
        <div id="patient-state" data-cy="patient-state">
          <ControlledCombobox
            fullWidth
            name="state"
            label="State"
            options={STATE_CODES}
            control={form.control}
            isHorizontalLayout={false}
            errors={form.formState.errors}
          />
        </div>
        <Input
          label="Zip"
          name="zip"
          id="addr-zip"
          type="text"
          fullWidth
          register={form.register}
        />
      </div>
      <div className={styles.buttonWrapper} data-cy="save">
        <Button style={STYLES.FULL_WIDTH} type={"submit"}>
          Save
        </Button>
      </div>
    </form>
  );
}
