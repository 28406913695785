/* Internal Message Modal Template */

// External
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { useForm, Controller, FieldValues } from "react-hook-form";
import clsx from "clsx";
import dayjs from "dayjs";

// components
import Tag from "@/components/tag";
import TextArea from "@/components/textArea";
import Search from "@/components/input/search";
import Input from "@/components/input";
import Button from "@/components/button";

// constants, helpers, globals
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { setModalIsOpen } from "../modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { FORMAT } from "@/globals/helpers/formatters";

// store
import {
  MessageContact,
  useMessageListContactsQuery,
  useMessageSendMutation
} from "@/store/services/message";
import { RootState } from "@/store/store";
import { UserId } from "@/store/services/patient";

// styles
import styles from "../styles.module.scss";

export interface NewMessageModalProps {
  recipients?: Array<number>;
  subject?: string;
  content?: string;
  onSuccess?: () => void;
  // The user id of the user's inbox if we're on the facepage and not the inbox page
  userInboxId?: UserId;
}

export default function NewMessageModal({
  recipients = [],
  subject = "",
  content = "",
  userInboxId
}: NewMessageModalProps) {
  // React
  const dispatch = useDispatch();
  const router = useRouter();

  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { control, handleSubmit, register, reset } = useForm({
    defaultValues: {
      message_subject: subject,
      message_content: content
    } as FieldValues
  });

  // State
  const [recipientList, setRecipientList] = useState<MessageContact[]>([]);

  // API Hooks
  const { data } = useMessageListContactsQuery();
  const [sendMessage, { isLoading: messageSendLoading }] =
    useMessageSendMutation();

  // Prepopulate recipients with optional prop. Go through passed user_ids and map
  // them to our data structure
  useEffect(() => {
    recipients.forEach(recipient => {
      // If they're already in the list don't add them again
      const inList = recipientList.find(user => user.user_id == recipient);
      if (inList || !data) {
        return;
      }
      const item = data.find(user => user.user_id == recipient);
      if (!item) {
        return;
      }
      const name = FORMAT.name(item);
      if (item.is_patient) {
        const hasPatient = recipientList.find(
          recipient => recipient.is_patient
        );

        if (hasPatient) {
          alert("You can't send messages to multiple patients");
          return;
        }
      }
      setRecipientList([...recipientList, item]);
    });
  }, [recipients, data]);

  // Event handlers
  // Handle recipient change - map data to our data structure
  const onRecipientSelect = (item: any) => {
    const name = FORMAT.name(item);
    if (item.is_patient) {
      const hasPatient = recipientList.find(recipient => recipient.is_patient);

      if (hasPatient) {
        alert("You can't send messages to multiple patients");
        return;
      }
    }

    setRecipientList(recipientList => [...recipientList, item]);
  };

  // Delete recipient when X is clicked
  const removeRecipient = (userId: number) => {
    setRecipientList(recipientList.filter(item => item.user_id != userId));
  };

  // Submit button handler passed to the TipTap menu in the TextArea component
  // This takes the TipTap editor as an argument (provided by the TipTap menu)
  const onSubmit = (data: any) => {
    const recipients = recipientList.map(recipient => recipient.user_id);
    const body = data.message_content;
    const subject = data.message_subject;

    if (recipients.length <= 0) {
      dispatch(
        addAlertToToastTrough({
          message: "Please include at least one recipient.",
          type: STATUS_KEYS.ERROR
        })
      );
      return;
    }

    if (subject === "") {
      dispatch(
        addAlertToToastTrough({
          message: "Message subject can't be empty.",
          type: STATUS_KEYS.ERROR
        })
      );
      return;
    }

    if (body === "") {
      dispatch(
        addAlertToToastTrough({
          message: "Message body can't be empty.",
          type: STATUS_KEYS.ERROR
        })
      );
      return;
    }

    sendMessage({
      messageSendRequest: {
        recipients,
        body,
        subject
      }
    })
      .unwrap()
      .then(data => {
        // Message success
        // Clears the content from the TipTap editor
        reset();
        if (
          !userInboxId ||
          recipientList.findIndex(item => item.user_id === userInboxId) != -1
        ) {
          // If we're on the inbox page or the user is in the recipient list, we
          // push the mid to the query params
          let query: Record<string, any> = { mid: data };
          if (userInboxId) {
            query = { mid: data, patientId: userInboxId };
          }
          router.push({
            query
          });
        }
        setRecipientList([]);
        dispatch(setModalIsOpen(false));
      })
      .catch(err => {
        // On failure, don't delete any of the user's content so they don't have to type
        // it all again
        console.error(err);
        dispatch(
          addAlertToToastTrough({
            message: "Something went wrong trying to send that message.",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const formatUsersName = (user: MessageContact) => {
    let name = `${user.first_name} ${user.last_name}`;
    // If the user is a patient, we don't need to add any extra info
    if (sessionInfo?.is_patient) {
      return name;
    }
    // If the user is a staff member, we add their role and dob if they have one
    name +=
      user.dob > 0
        ? ` - ${dayjs(`${user.dob}`, "YYYYMMDD").format("MM/DD/YYYY")}`
        : "";
    name += user.is_patient ? ": Patient" : ": Staff";
    return name;
  };

  return (
    <div className={clsx(styles.InternalMessage)}>
      <div className={styles.recipientsSection}>
        <div className={styles.recipientsListing}>
          <p className="dark tMd">Recipients</p>
          <div className={styles.recipientsTagList}>
            {recipientList &&
              recipientList.map(item => (
                <Tag
                  key={item.user_id}
                  type={STATUS_KEYS.RECIPIENT}
                  label={formatUsersName(item)}
                  onRemove={() => removeRecipient(item.user_id)}
                />
              ))}
          </div>
        </div>
        <Search
          placeholder="Search contacts"
          name="recipients"
          options={(data || []).filter(
            item => recipientList.indexOf(item) == -1
          )}
          id="recipients"
          label="recipients"
          hiddenLabel
          labelAcc={item => formatUsersName(item)}
          onSelect={onRecipientSelect}
          isClearOnSelect
          fullWidth
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <p className="dark tMd">Subject</p>
        <Input
          type="text"
          label="message subject"
          hiddenLabel
          placeholder="Subject"
          //   onChange={onSubjectChange}
          name="message_subject"
          id="message_subject"
          //   value={subjectContent}
          fullWidth
          marginBottom
          register={register}
        />
        <p className="dark tMd">Message</p>

        <Controller
          render={({ field }) => (
            <TextArea
              label={"Reply"}
              name={"message_content"}
              id={"message_content"}
              hiddenLabel={true}
              onChange={field.onChange}
              content={field.value}
              placeholder={"Type your message"}
              rows={10}
              // We simply just dispatch the form submit so that all of the
              // validation can be handled by react-form-hook
              onSubmit={() => {
                formRef.current &&
                  formRef.current.dispatchEvent(
                    new Event("submit", {
                      cancelable: true,
                      bubbles: true
                    })
                  );
              }}
              submitOnEnter
            />
          )}
          name="message_content"
          defaultValue={""}
          control={control}
        />
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            style={STYLES.PRIMARY}
            nativeButtonProps={{ disabled: messageSendLoading }}
          >
            Send
          </Button>
        </div>
      </form>
    </div>
  );
}
