// External
import { useEffect, useMemo, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";

// Components
import Checkbox from "@/components/input/checkbox";

// Styles
import styles from "@/components/forms/_labor/styles.module.scss";
import {
  ListId,
  ListOption,
  ListOptionId,
  RecordGenerationFormValues,
  ResourceIdKey
} from "./helpers/recordGenerationHooks";

interface AdditionalDataCheckListProps {
  form: UseFormReturn<RecordGenerationFormValues>;
  listId: ListId; // ID of the list catergory
  listLabel: string; // Label for the list category
  listOptions: ListOption[]; // List of the checkboxes for this category
  additionalListOptions?: ListOption[]; // Additional options below the list for modifying the list
}

export default function AdditionalDataCheckList({
  form,
  listId,
  listLabel,
  listOptions,
  additionalListOptions
}: AdditionalDataCheckListProps) {
  const { register, setValue } = form;

  const allInputsId = useMemo(() => {
    return `all_${listId}` as keyof RecordGenerationFormValues;
  }, [listId]);

  const allInputsLabel = useMemo(() => {
    return `All ${listLabel}`;
  }, [listLabel]);

  const setAllInputs = (value: boolean) => {
    listOptions.forEach(option => {
      const optionId: ListOptionId = option.id as ResourceIdKey;
      setValue(optionId, value);
    });
  };

  return (
    <div className={styles.additionalChecks}>
      <div className={styles.additionalFiles}>
        <div className={styles.fileListChecks}>
          <p>{listLabel}</p>
          {listOptions.length > 0 ? (
            <>
              {additionalListOptions && (
                <div className={styles.additionalSubCheckList}>
                  {additionalListOptions?.map(option => (
                    <Checkbox
                      label={option.label}
                      name={option.id}
                      id={option.id}
                      register={register}
                      key={option.id}
                    />
                  ))}
                </div>
              )}
              <Checkbox
                label={allInputsLabel}
                id={allInputsId}
                name={allInputsId}
                register={register}
                rules={{
                  onChange: (e: any) => {
                    const val: boolean = e.target.checked;
                    setAllInputs(val);
                  }
                }}
              />

              <div className={styles.subCheckList}>
                {listOptions.map(option => (
                  <Checkbox
                    label={option.label}
                    register={register}
                    id={option.id}
                    name={option.id}
                    key={option.id}
                    rules={{
                      onChange: (e: any) => {
                        const val = e.target.checked;
                        if (!val) {
                          setValue(allInputsId, false);
                        }
                      }
                    }}
                  />
                ))}
              </div>
            </>
          ) : (
            <p>Patient doesn't have any {listLabel.toLowerCase()}.</p>
          )}
        </div>
      </div>
    </div>
  );
}
