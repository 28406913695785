// third-party
import clsx from "clsx";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

// components
import Button from "@/components/button";
import Input from "@/components/input";
import ControlledCombobox from "@/components/input/controlledCombobox";

// store
import {
  PatientInfo,
  PatientUpsertRequest,
  UserInsurance,
  usePatientGetInfoQuery,
  usePatientUpsertMutation
} from "@/store/services/patient";
import {
  PayorListItem,
  usePayorGetInfoQuery,
  usePayorListQuery
} from "@/store/services/payor";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { Insurance, Statecode } from "@/store/services/user";

// constants
import styles from "../styles.module.scss";
import { STYLES, STATUS_KEYS, STATE_CODES } from "@/globals/constants";
import { convertUtcIntToLocalDatetime } from "../../scheduling/calendars/utils";
import { useEffect, useMemo } from "react";
import { setModalContent, setModalIsOpen } from "../../modal/modalSlice";
import { MODAL_TYPES } from "../../modal/dispatcher";
import { RootState } from "@/store/store";
import { setRightPaneContent } from "../../drawer/drawerSlice";
import Checkbox from "@/components/input/checkbox";
import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";

interface InsuranceFormProps {
  patientId: number;
  type: "PRIMARY" | "SECONDARY" | "TERTIARY";
  onUpdate?: () => void;
  // Whether or not it's viewed from the patient task in the sidebar
  isTaskView?: boolean;
}

let oldRelToSubscriber = "";

export default function InsuranceForm({
  patientId,
  type,
  onUpdate,
  isTaskView = false
}: InsuranceFormProps) {
  const dispatch = useDispatch();
  const newPayorId = useSelector((state: RootState) => state.insurance.payorId);

  const [upsertPatient] = usePatientUpsertMutation();
  const { data: payors } = usePayorListQuery();
  const { data: patient } = usePatientGetInfoQuery(
    {
      patientId
    },
    {
      skip: !patientId
    }
  );

  const getDefaultValues = (defaultPatient: PatientInfo) => {
    let defaultValues: InsuranceWithSelfPay | undefined = {};
    switch (type) {
      case "PRIMARY":
        defaultValues = {
          ...defaultPatient.insurance?.primary,
          self_pay: defaultPatient.insurance?.self_pay,
          payor_data: payors?.find(
            p => p.hg_id === defaultPatient.insurance?.primary?.hg_org_id
          )
        };
        break;
      case "SECONDARY":
        defaultValues = {
          ...defaultPatient.insurance?.secondary,
          payor_data: payors?.find(
            p => p.hg_id === defaultPatient.insurance?.secondary?.hg_org_id
          )
        };
        break;
      case "TERTIARY":
        defaultValues = {
          ...defaultPatient.insurance?.tertiary,
          payor_data: payors?.find(
            p => p.hg_id === defaultPatient.insurance?.tertiary?.hg_org_id
          )
        };
        break;
      default:
        throw Error("invalid insurance type");
    }
    if (defaultValues?.subscriber_dob && defaultValues?.subscriber_dob > 0) {
      // @ts-ignore
      defaultValues.subscriber_dob = defaultValues?.subscriber_dob
        ? dayjs(`${defaultValues?.subscriber_dob}`, "YYYYMMDD").format(
            "YYYY-MM-DD"
          )
        : "";
    }
    return defaultValues;
  };

  const form = useForm<InsuranceWithSelfPay>();
  const { watch, setValue } = form;

  const relToSubscriber = watch("relationship_to_subscriber");
  const currentPayor = watch("payor");

  const { data: payorInfo } = usePayorGetInfoQuery(
    {
      // @ts-ignore payor not typed correctly
      payorId: currentPayor?.hg_id as string
    },
    {
      // @ts-ignore payor not typed correctly
      skip: !currentPayor?.hg_id
    }
  );

  type PayorOption = {
    label: string;
    value: PayorListItem;
  };

  const payorOptions: PayorOption[] = useMemo(() => {
    return (payors || [])?.map(payor => ({
      label: payor.name + " - " + payor.address,
      value: payor
    }));
  }, [payors]);

  type RelationshipOption = {
    label: string;
    value: string;
  };

  const relationshipOptions: RelationshipOption[] = [
    {
      label: "Self",
      value: "SELF"
    },
    {
      label: "Spouse",
      value: "SPOUSE"
    },
    {
      label: "Guardian",
      value: "GUARDIAN"
    },
    {
      label: "Other",
      value: "OTHER"
    }
  ];

  const setSubscriberData = (subscriber: PatientInfo | null) => {
    setValue("subscriber_phone", subscriber?.phone || "");
    setValue("subscriber_first_name", subscriber?.first_name || "");
    setValue("subscriber_last_name", subscriber?.last_name || "");
    setValue(
      "subscriber_dob",
      // @ts-ignore
      subscriber?.dob
        ? dayjs(`${subscriber.dob}`, "YYYYMMDD").format("YYYY-MM-DD")
        : ""
    );
    setValue("subscriber_ssn", subscriber?.ssn || "");
    setValue("subscriber_address.line1", subscriber?.address?.line1 || "");
    setValue("subscriber_address.line2", subscriber?.address?.line2 || "");
    setValue("subscriber_address.line3", subscriber?.address?.line3 || "");
    setValue("subscriber_address.city", subscriber?.address?.city || "");
    setValue(
      "subscriber_address.state",
      (subscriber?.address?.state || "") as Statecode
    );
    setValue("subscriber_address.zip", subscriber?.address?.zip || "");
    form.handleSubmit(onSubmit)();
  };

  // This sets the payor drop down when the patient and payors list are changed
  useEffect(() => {
    if (!patient) {
      return;
    }
    form.reset(getDefaultValues(patient));

    if (payors) {
      let currentInsurance;
      switch (type) {
        case "PRIMARY":
          currentInsurance = patient.insurance?.primary;
          break;
        case "SECONDARY":
          currentInsurance = patient.insurance?.secondary;
          break;
        case "TERTIARY":
          currentInsurance = patient.insurance?.tertiary;
          break;
        default:
          currentInsurance = null;
      }
      if (!currentInsurance) {
        return;
      }
      const payor = payors.find(
        payor => payor.hg_id === currentInsurance.hg_org_id
      );
      // @ts-ignore again the payor type is all messed up
      setValue("payor", payor);
      // @ts-ignore
      if (payor) setValue("payor_phone", payor.phoneNumber);
    }
  }, [patient, payors]);

  // This sets all the extra stuff in the form if we have it.
  useEffect(() => {
    if (!patient) {
      // No point if we don't have patient data
      return;
    }

    if (relToSubscriber == "SELF") {
      setSubscriberData(patient);
    } else if (oldRelToSubscriber == "SELF") {
      // If the current value doesn't equal self but the old value did equal self
      // This stops the data from clearing if you change it from "Guardian" to "Other" or something
      setSubscriberData(null);
    }
    // Set the value to our current value so when this runs next, it's the old value
    oldRelToSubscriber = relToSubscriber || "";
  }, [relToSubscriber]);

  // If the user has created a new payor via the modal, this will set that in the form
  useEffect(() => {
    if (!newPayorId) {
      return;
    }

    const newPayor = payors?.find(payor => payor.hg_id == newPayorId);

    // @ts-ignore payor value isn't typed correctly
    setValue("payor_data", newPayor);
  }, [payors, newPayorId]);

  // This will attempt to grab the phone number for the payor when it changes
  useEffect(() => {
    if (payorInfo) {
      // @ts-ignore payorInfo doesn't contain the phone number?
      setValue("payor_phone", payorInfo.phoneNumber || "");
    }
  }, [payorInfo]);

  const onAddNewPayor = () => {
    if (isTaskView) {
      dispatch(
        setRightPaneContent({
          type: MODAL_TYPES.ADD_NEW_PAYOR,
          props: { title: "Add new payor", isDrawer: true }
        })
      );
    } else {
      dispatch(
        setModalContent({
          type: MODAL_TYPES.ADD_NEW_PAYOR,
          props: { title: "Add new payor" }
        })
      );
      dispatch(setModalIsOpen(true));
    }
  };
  interface InsuranceWithSelfPay extends Insurance {
    self_pay?: boolean;
    payor_data?: PayorListItem;
  }

  const onSubmit = (data: InsuranceWithSelfPay) => {
    if (!patient) {
      return;
    }

    const payor = data.payor_data;

    if (data.subscriber_dob && data.subscriber_dob + "" != "") {
      data.subscriber_dob = parseInt(
        dayjs(data.subscriber_dob as unknown as string).format("YYYYMMDD")
      );
    } else {
      data.subscriber_dob = undefined;
    }

    const patientUpsertRequest: PatientUpsertRequest = {
      user_id: patient.user_id,
      insurance: patient.insurance as UserInsurance
    };

    // We only want self-pay on Primary insurance and we add that manually,
    // this prevents it from being accidentally added on the other insurances
    const sanitizedData = { ...data };
    delete sanitizedData.self_pay;

    switch (type) {
      case "PRIMARY":
        patientUpsertRequest.insurance = {
          ...patientUpsertRequest.insurance,
          self_pay: data.self_pay,
          primary: {
            ...sanitizedData,
            payor: payor?.name,
            hg_org_id: payor?.hg_id
          }
        };

        break;
      case "SECONDARY":
        patientUpsertRequest.insurance = {
          ...patientUpsertRequest.insurance,
          secondary: {
            ...sanitizedData,
            payor: payor?.name,
            hg_org_id: payor?.hg_id
          }
        };
        break;
      case "TERTIARY":
        patientUpsertRequest.insurance = {
          ...patientUpsertRequest.insurance,
          tertiary: {
            ...sanitizedData,
            payor: payor?.name,
            hg_org_id: payor?.hg_id
          }
        };
        break;
      default:
        throw Error("invalid insurance type");
    }

    try {
      upsertPatient({ patientUpsertRequest });
    } catch (err) {
      console.error(err);
      dispatch(
        addAlertToToastTrough({
          type: STATUS_KEYS.ERROR,
          message: "Failed to update insurance"
        })
      );
    }
  };

  return (
    <form
      className={clsx(styles.InsuranceForm)}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className={styles.wrapper}>
        {type === "PRIMARY" && (
          <div style={{ gridColumn: "span 2" }}>
            <Checkbox
              id="self_pay"
              label="Patient is Self Pay"
              name="self_pay"
              register={form.register}
              rules={{
                onChange: () => {
                  form.handleSubmit(onSubmit)();
                }
              }}
            />
          </div>
        )}
        <div className={styles.payorWrapper}>
          <Controller
            control={form.control}
            name="payor_data"
            rules={{
              onBlur: () => {
                form.handleSubmit(onSubmit)();
              }
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <label className="standardLabel">Insurance company</label>
                <Select
                  options={payorOptions}
                  theme={SelectDefaultTheme}
                  styles={SelectDefaultStyles}
                  onChange={(newValue: any) => {
                    onChange(newValue?.value);
                    form.handleSubmit(onSubmit)();
                  }}
                  value={payorOptions.find(
                    option => option.value.hg_id === value?.hg_id
                  )}
                  isClearable
                />
              </>
            )}
          />
          <p>- or -</p>
          <Button type="button" style={STYLES.PRIMARY} onClick={onAddNewPayor}>
            Add new payor
          </Button>
        </div>
        <Input
          id="payor_phone"
          type="tel"
          label="Insurance co. Phone"
          name="payor_phone"
          register={form.register}
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          id="plan_type"
          type="text"
          label="Plan Type"
          name="plan_type"
          register={form.register}
          placeholder={"PPO, HMO, etc."}
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          id="policy_or_group_number"
          type="text"
          label="Policy/Group Number"
          name="policy_or_group_number"
          register={form.register}
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          id="subscriber_id"
          type="text"
          label="Subscriber/Member ID"
          name="subscriber_id"
          register={form.register}
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Controller
          name="relationship_to_subscriber"
          control={form.control}
          render={({ field }) => (
            <>
              <label className="standardLabel">
                Relationship to Subscriber
                <Select
                  {...field}
                  options={relationshipOptions}
                  theme={SelectDefaultTheme}
                  styles={SelectDefaultStyles}
                  onChange={(newValue: any) => {
                    field.onChange(newValue?.value);
                    // The autosave will trigger on the other field with self selected
                    // because it populates other fields
                    if (newValue?.value !== "SELF") {
                      form.handleSubmit(onSubmit)();
                    }
                  }}
                  value={relationshipOptions.find(
                    option => option.value === field.value
                  )}
                  isClearable
                />
              </label>
            </>
          )}
        />

        <Input
          label="Subscriber Phone"
          id="subscriber_phone"
          name="subscriber_phone"
          type="tel"
          register={form.register}
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          id="subscriber_first_name"
          type="text"
          label="Subscriber First Name"
          name="subscriber_first_name"
          register={form.register}
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          id="subscriber_last_name"
          type="text"
          label="Subscriber Last Name"
          name="subscriber_last_name"
          register={form.register}
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          id="subscriber_dob"
          type="date"
          label="Subscriber Date of Birth"
          name="subscriber_dob"
          register={form.register}
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          id="subscriber_ssn"
          type="text"
          label="Subscriber SSN"
          name="subscriber_ssn"
          register={form.register}
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          id="subscriber_address.line1"
          type="text"
          label="Subscriber Address Line 1"
          name="subscriber_address.line1"
          register={form.register}
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          id="subscriber_address.line2"
          type="text"
          label="Subscriber Address Line 2"
          name="subscriber_address.line2"
          register={form.register}
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          id="subscriber_address.line3"
          type="text"
          label="Subscriber Address Line 3"
          name="subscriber_address.line3"
          register={form.register}
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          id="subscriber_address.city"
          type="text"
          label="Subscriber City"
          name="subscriber_address.city"
          register={form.register}
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <ControlledCombobox<InsuranceWithSelfPay, Statecode>
          label="Subscriber State"
          name="subscriber_address.state"
          errors={form.formState.errors}
          control={form.control}
          options={STATE_CODES}
          isHorizontalLayout={false}
        />

        <Input
          id="subscriber_address.zip"
          type="text"
          label="Subscriber Zip Code"
          name="subscriber_address.zip"
          register={form.register}
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          type="text"
          register={form.register}
          id="guarantor_first_name"
          name="guarantor_first_name"
          label="Guarantor First Name"
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          type="text"
          register={form.register}
          id="guarantor_last_name"
          name="guarantor_last_name"
          label="Guarantor Last Name"
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          type="text"
          register={form.register}
          id="guarantor_addr_line_1"
          name="guarantor_addr_line_1"
          label="Guarantor Address Line 1"
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          type="text"
          register={form.register}
          id="guarantor_addr_line_2"
          name="guarantor_addr_line_2"
          label="Guarantor Address Line 2"
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          type="text"
          register={form.register}
          id="guarantor_addr_line_3"
          name="guarantor_addr_line_3"
          label="Guarantor Address Line 3"
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <Input
          type="text"
          register={form.register}
          id="guarantor_city"
          name="guarantor_city"
          label="Guarantor City"
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />

        <ControlledCombobox<InsuranceWithSelfPay, Statecode>
          label="Guarantor State"
          name="guarantor_state"
          errors={form.formState.errors}
          control={form.control}
          options={STATE_CODES}
          isHorizontalLayout={false}
        />

        <Input
          type="text"
          register={form.register}
          id="guarantor_zip"
          name="guarantor_zip"
          label="Guarantor Zip Code"
          rules={{
            onBlur: () => {
              form.handleSubmit(onSubmit)();
            }
          }}
        />
      </div>
      <Button
        style={STYLES.FULL_WIDTH}
        type="button"
        onClick={_ => {
          onUpdate && onUpdate();
        }}
      >
        Update
      </Button>
    </form>
  );
}
