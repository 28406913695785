/* External */
import { useDispatch, useSelector } from "react-redux";
import {
  Fragment,
  useState,
  FormEvent,
  useMemo,
  ReactNode,
  useEffect
} from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import { Inter } from "next/font/google";
import { AnimatePresence, motion } from "framer-motion";
import { ascending, descending } from "d3";
import Skeleton from "react-loading-skeleton";

/* Local */

// components
import Input from "@/components/input";
import Head, { ASC, sortDirection } from "@/components/datagrid/sortHead";
import Icon from "@/components/icons";
import Button from "@/components/button";
import FixedMenu from "@/components/menus/fixedMenu";
import EmptyState from "@/components/cards/emptyState";

// store
import { addId, removeId, setIds } from "./datagridSlice";

// constants
import {
  GRID_METRICS,
  METRICS,
  METRIC_LABELS,
  INPUT_TYPES,
  STYLES,
  STATUS_KEYS
} from "@/globals/constants";

// helpers
import { GetColSpan, HandleFormat } from "@/components/datagrid/helpers";
import { useCustomOBDatagridColumns } from "@/globals/helpers/customHooks";

// styles
import styles from "./styles.module.scss";
import { RootState } from "@/store/store";

const font = Inter({
  subsets: ["latin"]
});

// TODO: type metricKey stronger than string once metrics finalized
export type metrics = {
  [metricKey: string]: string | number | Date | Object;
};

export type DataTag = {
  label: string;
  type: keyof typeof STATUS_KEYS;
};

export type GridDataType = {
  id: number | string;
  metrics: metrics;
  tags?: DataTag[];
  tagMetric?: string;
}[];

export interface DatagridProps {
  gridType: string; // key to retrieve grid headers
  selectableRows?: boolean; // emptyRow
  style?: string;
  allowsInput?: boolean;
  onSubmit?: (formData: { [key: string]: any }) => void; // when datagrid is a form
  // table data
  data?: GridDataType;
  defaultSortMetric?: string;
  onRowClick?: (id: number | string) => void;
  onCheckboxClick?: (id: number) => void;
  menuItems?: string[];
  onMenuClick?: (menuItem: string, rowId: number | string) => void;
  isLoading?: boolean;
  defaultSortDirection?: sortDirection;
  isEmpty?: boolean;
  unsorted?: boolean; // for rendering lab results; order is fixed by the backend
  useOverride?: boolean;
  hasUnfixedLayout?: boolean;
  noHeaders?: boolean; // for rendering table data without headers
  onSortChange?: (sortMetric: string, sortDirection: sortDirection) => void;
  customEmptyMessage?: string;
  hasTopAlignedRows?: boolean;
  selectAllEnabled?: boolean;
}

export default function Datagrid({
  gridType,
  data = [],
  selectableRows = false,
  style = "primary",
  allowsInput = false,
  defaultSortMetric,
  onSubmit,
  onRowClick,
  onCheckboxClick,
  onMenuClick,
  menuItems,
  isLoading,
  defaultSortDirection = ASC,
  isEmpty,
  unsorted = false,
  useOverride = false,
  hasUnfixedLayout = false,
  noHeaders = false,
  onSortChange,
  customEmptyMessage = "No data at the moment",
  hasTopAlignedRows = false,
  selectAllEnabled = false
}: DatagridProps) {
  const router = useRouter();
  /* Redux */
  const dispatch = useDispatch();
  const customOBDatagridColumns = useCustomOBDatagridColumns();

  const selectedRowIds = useSelector(
    (state: RootState) => state.dataGrid.selectedRowIds
  );
  const [selectAll, setSelectAll] = useState(false);

  const headers: string[] =
    gridType === METRICS.OB ? customOBDatagridColumns : GRID_METRICS[gridType];

  // React
  const [openRowIds, setOpenRowIds] = useState<number[]>([]);
  const [currentSortMetric, setCurrentSortMetric] = useState<
    [string, sortDirection]
  >([defaultSortMetric || headers?.[0], defaultSortDirection]);
  const [showMenu, setShowMenu] = useState<null | number | string>(null);

  // Event Handlers

  // Toggles extra row drawer visibility
  const handleToggleRowOpen = (id: number) => {
    let newOpenRowIds;
    // if row is already open, close
    if (openRowIds.includes(id)) {
      newOpenRowIds = openRowIds.filter(openRowId => openRowId !== id);
    } else {
      // otherwise open it
      newOpenRowIds = [...openRowIds, id];
    }
    setOpenRowIds(newOpenRowIds);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.currentTarget;
    const formData = new FormData(form);
    const formObject = Object.fromEntries(formData.entries());
    // bubble event up to parent
    onSubmit && onSubmit(formObject);
  };

  const handleSort = ([metric, sortDirection]: [string, sortDirection]) => {
    if (unsorted) return;

    // TODO: handle edge cases, will metric ever not be a string?
    const sortFunc = sortDirection === ASC ? ascending : descending;
    data.sort((a, b) =>
      sortFunc(a?.metrics?.[metric] as string, b?.metrics?.[metric] as string)
    );
  };

  useMemo(() => handleSort(currentSortMetric), [currentSortMetric, data]);

  useEffect(() => {
    const allIds = data.map(item => item.id);
    if (selectAll) {
      dispatch(setIds(allIds));
    } else {
      // If all are selected then uncheck them all
      if (!data.find(item => !selectedRowIds.includes(item.id))) {
        dispatch(setIds([]));
      }
    }
  }, [selectAll]);

  useEffect(() => {
    if (!!data.find(item => !selectedRowIds.includes(item.id))) {
      setSelectAll(false);
    }
  }, [selectedRowIds]);

  const spring = {
    type: "spring",
    damping: 25,
    stiffness: 120
  };

  return isEmpty ? (
    <EmptyState customMessage={customEmptyMessage} />
  ) : (
    <form onSubmit={handleSubmit}>
      <table
        className={clsx(styles.Datagrid, font.className, styles[style], {
          [styles.unfixed]: hasUnfixedLayout,
          [styles.topAligned]: hasTopAlignedRows
        })}
        data-cy={`datagrid-${gridType}`}
      >
        {/* table head */}
        {!noHeaders && (
          <Head
            headers={headers}
            hasEmpty={selectableRows}
            onSort={([metric, direction]) => {
              setCurrentSortMetric([metric, direction]);
              if (onSortChange) onSortChange(metric, direction);
            }}
            defaultSortHeader={defaultSortMetric}
            hasEmptyEnd={menuItems && menuItems.length > 0}
            selectAllElement={
              <>
                {selectableRows && selectAllEnabled && (
                  <td
                    onClick={e => {
                      if (onCheckboxClick) e.stopPropagation();
                    }}
                    style={{ paddingRight: "0px" }}
                  >
                    <Input
                      hiddenLabel
                      label="select row"
                      name={`select-all`}
                      type="checkbox"
                      id={`select-all`}
                      // If we can find an item that's not selected, this ISN'T checked
                      checked={selectAll}
                      // checkbox selects / deselects row
                      onChange={e => {
                        setSelectAll(!selectAll);
                      }}
                    />
                  </td>
                )}
              </>
            }
          />
        )}
        {/* table body */}
        <tbody>
          {/* show input row */}
          {allowsInput && (
            <tr>
              {headers &&
                headers.map(metric => (
                  <td key={`${metric}-input`} className={styles.inputCell}>
                    {/* handle formatting exceptions */}
                    {metric !== METRICS.NOTE ? (
                      <Input
                        label={METRIC_LABELS[metric] || metric}
                        name={`${metric}`}
                        id={`${metric}-input`}
                        required={metric === METRICS.DATE}
                        type={INPUT_TYPES[metric] || "text"}
                        placeholder=" "
                        hiddenLabel
                      />
                    ) : (
                      <button type="submit" className={styles.save}>
                        <Icon svg={"save"} />
                      </button>
                    )}
                  </td>
                ))}
            </tr>
          )}
          {isLoading && (
            <tr>
              {headers &&
                headers.map(metric => (
                  <td key={`${metric}-input`} className={styles.inputCell}>
                    <Skeleton />
                  </td>
                ))}
            </tr>
          )}
          {/* populate table with existing data */}
          {data &&
            data.map(({ id, metrics, tags, tagMetric }) => (
              <Fragment key={id}>
                <AnimatePresence>
                  <motion.tr
                    layout
                    transition={spring}
                    key={`data-${id}`}
                    onClick={() => onRowClick && onRowClick(id as number)}
                    className={clsx({ [styles.clickable]: onRowClick })}
                    data-cy={`datagrid-row-${id}`}
                  >
                    {/* if row should contain a checkbox */}
                    {selectableRows && (
                      <td
                        onClick={e => {
                          if (onCheckboxClick) e.stopPropagation();
                        }}
                        style={{ paddingRight: "0px" }}
                      >
                        <Input
                          hiddenLabel
                          label="select row"
                          name={`select-row-${id}`}
                          type="checkbox"
                          id={`select-row-${id}`}
                          checked={selectedRowIds.includes(id)}
                          // checkbox selects / deselects row
                          onChange={() => {
                            dispatch(
                              selectedRowIds.includes(id)
                                ? removeId(id)
                                : addId(id)
                            );
                            if (onCheckboxClick) onCheckboxClick(id as number);
                          }}
                        />
                        <p>{!!selectedRowIds.includes(id.toString())}</p>
                      </td>
                    )}
                    {/* metric data - align data with correct header */}
                    {headers &&
                      headers.map(metric => {
                        if (metric === tagMetric) {
                          return (
                            <td key={`${metric}-${id}`}>
                              {
                                HandleFormat(
                                  metrics,
                                  metric,
                                  id as number,
                                  handleToggleRowOpen,
                                  openRowIds,
                                  router,
                                  tags,
                                  useOverride
                                ) as ReactNode
                              }
                            </td>
                          );
                        }
                        return (
                          <td
                            key={`${metric}-${id}`}
                            colSpan={GetColSpan(metric)}
                          >
                            {/* handle formatting exceptions */}
                            {
                              HandleFormat(
                                metrics,
                                metric,
                                id as number,
                                handleToggleRowOpen,
                                openRowIds,
                                router,
                                tags,
                                useOverride
                              ) as ReactNode
                            }
                          </td>
                        );
                      })}
                    {menuItems && menuItems.length > 0 && (
                      <td>
                        <Button
                          style={STYLES.ICON}
                          onClick={() => {
                            showMenu === id
                              ? setShowMenu(null)
                              : setShowMenu(id as number);
                          }}
                        >
                          <Icon svg="dotMenu" />
                        </Button>
                        {showMenu === id && (
                          <FixedMenu
                            onClick={section => {
                              setShowMenu(null);
                              onMenuClick && onMenuClick(section, id);
                            }}
                            noIcons
                            fitContent
                            menuItems={menuItems}
                          />
                        )}
                      </td>
                    )}
                  </motion.tr>
                </AnimatePresence>
                {/* display associated notes if user has toggled row open, spans entire row */}
                {openRowIds.includes(id as number) && (
                  <tr>
                    <td colSpan={headers.length} className={styles.drawer}>
                      <p className="xLight">
                        {metrics[METRICS.NOTE] as string}
                      </p>
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
        </tbody>
      </table>
    </form>
  );
}
