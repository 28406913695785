import Datagrid from "@/components/datagrid";
import BasicAccordion from "@/components/accordions/basic";

import {
  EncounterInfo,
  LabOrderInfo,
  LabTestInfo
} from "@/store/services/encounter";

import {
  APP_KEYS,
  METRICS,
  METRIC_LABELS,
  STATUS_KEYS,
} from "@/globals/constants";


import styles from "./styles.module.scss";


interface TestResultsHeaderProps {
  status:
    | "registered"
    | "sent"
    | "preliminary"
    | "final"
    | "corrected"
    | "unsent";
  order: LabOrderInfo;
  test: LabTestInfo;
  encounter?: EncounterInfo;
}

export interface TestResultsProps {
  resultID: number;
  order: LabOrderInfo;
  test: LabTestInfo;
}
export function TestResults({ resultID, order, test }: TestResultsProps) {
  // Filter out observations that are listed as DNR (do not report)
  const data = (test.observations || [])
    .filter(obs => obs.value !== "DNR")
    .map((obs, i) => {
      const unit = obs.unit ? ` ${obs.unit}` : "";
      let value: string;
      if (obs.value) {
        value = obs.value + unit;
      } else {
        value = "NULL";
      }

      return {
        id: ((resultID + i) * (resultID + i + 1)) / 2 + i, // Cantor pairing function: https://en.wikipedia.org/wiki/Pairing_function
        metrics: {
          [METRICS.LAB_TEST]: obs.display,
          [METRICS.DATE_UPDATED]: obs.issued,
          [METRICS.ORDER_STATUS]:
            obs.interpretation || (obs.status?.toUpperCase() as string),
          [METRICS.LAB_NOTE]: obs.note as string,
          [METRICS.LAB_VALUE]: value,
          [METRICS.LAB_REFERENCE]: (obs.reference_range?.text || "N/A") + unit,
          [METRICS.ORDERS_PERFORMING_SITE]: obs.performing_site as Object
        }
      };
    });

  const statuses = (test.observations || []).map(obs => obs.status);
  let testStatus: TestResultsHeaderProps["status"] =
    order.status !== "NEW" ? "sent" : "unsent";
  if (statuses.includes("registered")) {
    testStatus = "registered";
  }
  if (statuses.includes("preliminary")) {
    testStatus = "preliminary";
  }
  if (statuses.includes("corrected")) {
    testStatus = "corrected";
  }
  if (
    statuses.length > 0 &&
    statuses.every(status => status === "final" || status === "cancelled")
  ) {
    testStatus = "final";
  }

  return (
    <div className={styles.LabResults}>
      <BasicAccordion
        open={true}
        title={test.display}
        smallTitle
        tag={{
          label: METRIC_LABELS[testStatus] || testStatus,
          type:
            {
              sent: STATUS_KEYS.INFO,
              registered: STATUS_KEYS.INFO_GREY,
              unsent: STATUS_KEYS.INFO_GREY,
              preliminary: STATUS_KEYS.INFO,
              final: STATUS_KEYS.SUCCESS,
              corrected: STATUS_KEYS.WARNING
            }[testStatus] || STATUS_KEYS.INFO_GREY
        }}
      >
        <div className={styles.gridWrapper}>
          {data && data.length > 0 ? (
            <Datagrid
              gridType={APP_KEYS.PATIENT_LAB_RESULTS}
              data={data}
              unsorted
              hasUnfixedLayout
              hasTopAlignedRows
            />
          ) : (
            <div style={{ marginTop: 10 }}>
              <div className="emptyState">No results yet</div>
            </div>
          )}
        </div>
      </BasicAccordion>
    </div>
  );
}
