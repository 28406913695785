// third-party
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { sanitize } from "dompurify";

// components
import Icon from "@/components/icons";
import Input from "@/components/input";
import Button from "../button";
import ContentRenderer from "../textArea/contentRenderer";
import BasicAccordion from "../accordions/basic";

// icon
import Plus from "../../../public/svgs/plus.svg";

// store
import { PatientInfo, Pregnancy } from "@/store/services/patient";
import { setModalContent, setModalIsOpen } from "../modal/modalSlice";

// styles
import styles from "./styles.module.scss";

// constants
import { METRIC_LABELS, STYLES } from "@/globals/constants";
import { MODAL_TYPES } from "../modal/dispatcher";

// utils
import { convertUtcIntToLocalDate } from "@/components/scheduling/calendars/utils";
import { getWeightObjectFromGrams } from "../input/weightInput";
import DashboardCard from "../cards/dashboardCard";

const HEADERS = [
  "Delivery Date",
  "Gestational Age",
  "Labor Length",
  "Outcome",
  "Living",
  "Weight",
  "Sex",
  "Name",
  "Delivery Location",
  "Notes",
  ""
];

export interface PastPregnanciesProps {
  patient: PatientInfo;
  isOpen?: boolean;
}

export default function PastPregnancies({
  patient,
  isOpen = true
}: PastPregnanciesProps) {
  const isMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const dispatch = useDispatch();

  const [noteVisible, setNoteVisible] = useState(false);

  const rows = (patient.medical_history.pregnancies || []).filter(
    preg => preg.state != "DELETED"
  );

  const handleEditPregnancy = (preg: Pregnancy) => {
    dispatch(setModalIsOpen(true));
    dispatch(
      setModalContent({
        type: MODAL_TYPES.PAST_PREGNANCY,
        props: { patient, preg, title: "Edit past pregnancy" }
      })
    );
  };

  function ReadOnlyPregnancyCell({
    preg,
    field
  }: {
    preg: Pregnancy;
    field: string;
  }) {
    switch (field) {
      case "Delivery Date":
        return (
          <p>
            {preg.delivery_date
              ? convertUtcIntToLocalDate(preg.delivery_date).format(
                  "MM/DD/YYYY"
                )
              : "-"}
          </p>
        );
      case "Gestational Age":
        return (
          <div className={styles.fieldWrapper}>
            {preg.gestational_age_at_delivery ? (
              <>
                <p>{Math.floor(preg.gestational_age_at_delivery / 7)}</p>
                <span>wks.</span>
                <p>{preg.gestational_age_at_delivery % 7}</p>
                <span>days</span>
              </>
            ) : (
              "-"
            )}
          </div>
        );
      case "Labor Length":
        return (
          <div className={styles.fieldWrapper}>
            {preg.length_of_labor_minutes ? (
              <>
                <p>{Math.floor((preg.length_of_labor_minutes || 0) / 60)}</p>
                <span>hrs.</span>
                <p>{(preg.length_of_labor_minutes || 0) % 60}</p>
                <span>mins.</span>
              </>
            ) : (
              "-"
            )}
          </div>
        );
      case "Outcome":
        return <p>{preg.outcome || "-"}</p>;
      case "Living":
        return (
          <Input
            label=""
            hiddenLabel
            name={`preg-${preg.pregnancy_id}-living`}
            id={`preg-${preg.pregnancy_id}-living`}
            checked={preg.living}
            type="checkbox"
            disabled
          />
        );
      case "Weight":
        return (
          <div className={styles.fieldWrapper}>
            {preg.weight ? (
              <p>{`${getWeightObjectFromGrams(preg.weight).pounds} lbs ${
                getWeightObjectFromGrams(preg.weight).ounces
              } oz`}</p>
            ) : (
              <p>-</p>
            )}
          </div>
        );
      case "Sex":
        return <p>{preg.sex ? METRIC_LABELS[preg.sex] : "-"}</p>;
      case "Name":
        return <p>{preg.name || "-"}</p>;
      case "Delivery Location":
        return <p>{preg.birthplace || "-"} </p>;
      case "Notes":
        return (
          <div className={styles.fieldWrapper}>
            <ContentRenderer
              content={preg.comment ? sanitize(preg.comment) : "-"}
            />
          </div>
        );
      case "":
        return (
          <div className={styles.fieldWrapper}>
            <button type="button" onClick={() => handleEditPregnancy(preg)}>
              <Icon svg="edit" />
            </button>
          </div>
        );
      default:
        return "-";
    }
  }

  function ReadonlyPregnancyRow({ preg }: { preg: Pregnancy }) {
    return (
      <>
        <tr key={`preg-${preg.pregnancy_id}`}>
          <td>
            <p>
              {preg.delivery_date
                ? convertUtcIntToLocalDate(preg.delivery_date).format(
                    "MM/DD/YYYY"
                  )
                : "-"}
            </p>
          </td>
          <td>
            <div className={styles.fieldWrapper}>
              {preg.gestational_age_at_delivery ? (
                <>
                  <p>{Math.floor(preg.gestational_age_at_delivery / 7)}</p>
                  <span>wks.</span>
                  <p>{preg.gestational_age_at_delivery % 7}</p>
                  <span>days</span>
                </>
              ) : (
                "-"
              )}
            </div>
          </td>
          <td>
            <div className={styles.fieldWrapper}>
              {preg.length_of_labor_minutes ? (
                <>
                  <p>{Math.floor((preg.length_of_labor_minutes || 0) / 60)}</p>
                  <span>hrs.</span>
                  <p>{(preg.length_of_labor_minutes || 0) % 60}</p>
                  <span>mins.</span>
                </>
              ) : (
                "-"
              )}
            </div>
          </td>
          <td>
            <p>{preg.outcome || "-"}</p>
          </td>
          <td>
            <Input
              label=""
              hiddenLabel
              name={`preg-${preg.pregnancy_id}-living`}
              id={`preg-${preg.pregnancy_id}-living`}
              checked={preg.living}
              type="checkbox"
              disabled
            />
          </td>
          <td>
            <div className={styles.fieldWrapper}>
              {preg.weight ? (
                <p>{`${getWeightObjectFromGrams(preg.weight).pounds} lbs ${
                  getWeightObjectFromGrams(preg.weight).ounces
                } oz`}</p>
              ) : (
                <p>-</p>
              )}
            </div>
          </td>
          <td>
            <p>{preg.sex ? METRIC_LABELS[preg.sex] : "-"}</p>
          </td>
          <td>
            <p>{preg.name || "-"}</p>
          </td>
          <td>
            <p>{preg.birthplace || "-"} </p>
          </td>
          <td>
            <button type="button" onClick={() => setNoteVisible(!noteVisible)}>
              <Icon
                svg={noteVisible ? "chevron_up_blue" : "chevron_down_blue"}
                width={10}
              />
            </button>
          </td>
          <td>
            <div className={styles.fieldWrapper}>
              <button type="button" onClick={() => handleEditPregnancy(preg)}>
                <Icon svg="edit" />
              </button>
            </div>
          </td>
        </tr>
        {noteVisible && (
          <tr>
            <td colSpan={11} className={styles.drawer}>
              <ContentRenderer content={preg.comment || "-"} />
            </td>
          </tr>
        )}
      </>
    );
  }

  const handleAddPregnancy = () => {
    dispatch(setModalIsOpen(true));
    dispatch(
      setModalContent({
        type: MODAL_TYPES.PAST_PREGNANCY,
        props: { patient, title: "Add past pregnancy" }
      })
    );
  };

  return (
    <DashboardCard
      title="Past Pregnancies"
      customRightElement={
        <Button style={STYLES.SECONDARY} onClick={handleAddPregnancy}>
          <Plus stroke={styles.gray400} width={12} height={12} />
          Add Pregnancy
        </Button>
      }
    >
      {!rows.length ? (
        <div className="emptyState">No past pregnancies</div>
      ) : isMobile ? (
        <div className={styles.mobileContainer}>
          {rows.map(preg => (
            <div
              className={styles.mobilePastPregnancies}
              key={preg.pregnancy_id}
            >
              {HEADERS.map(header => (
                <>
                  <div key={header} className={styles.cell}>
                    {header}
                  </div>
                  <div className={styles.cell}>
                    {ReadOnlyPregnancyCell({ preg, field: header })}
                  </div>
                </>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.PastPregnancies}>
          <table>
            <thead>
              <tr>
                {/* map over headers */}
                {HEADERS.map(header => (
                  <th key={header}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map(preg => (
                <ReadonlyPregnancyRow preg={preg} key={preg.pregnancy_id} />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </DashboardCard>
  );
}
