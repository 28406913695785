import clsx from "clsx";
import styles from "./styles.module.scss";

interface KeyValueItemProps {
  keyLabel: string;
  children?: JSX.Element | JSX.Element[];
  // If the data is especially long, this will probably make the styles look better
  longData?: boolean;
  // If you add the longData prop but you want text left styles (generally for longer paragraphs)
  textLeft?: boolean;
}

export default function KeyValueItem({ keyLabel, children, longData = false, textLeft = false }: KeyValueItemProps) {
  return (
    <div className={clsx(styles.KeyValueItem, {[styles.longData]: longData, [styles.textLeft]: textLeft})}>
      <p className={styles.gridKey}>{keyLabel}</p>
      <div className={styles.gridValue}>{children}</div>
    </div>
  );
}
