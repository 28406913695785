/* Confirmation Modal Template */

// External
import { useEffect, useMemo, useState } from "react";
import { Inter } from "next/font/google";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";
import Select from "react-select";

// components
import Button from "@/components/button";
import Input from "@/components/input";
import ControlledTextArea from "@/components/textArea/controlledTextArea";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { FORMAT, nameAndDOB } from "@/globals/helpers/formatters";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// store
import { setModalIsOpen } from "../../modalSlice";
import {
  CreatePatientRecallApiArg,
  PatientInfo,
  useCreatePatientRecallMutation
} from "@/store/services/patient";
import {
  PatientListItem,
  usePatientGetListQuery
} from "@/store/services/practice";

// styles
import styles from "../../styles.module.scss";
import {
  SelectDefaultTheme,
  SelectDefaultStyles
} from "@/styles/themes/selectDefaultTheme";

export interface PatientRecallProps {
  patient?: PatientInfo;
}

export default function PatientRecall({ patient }: PatientRecallProps) {
  const dispatch = useDispatch();

  const [query, setQuery] = useState<string | undefined>(undefined);

  const patientListResponse = usePatientGetListQuery({
    query,
    pagesz: 1000
  });

  const [createPatientRecall, createPatientRecallResponse] =
    useCreatePatientRecallMutation();

  type PatientOption = {
    value: PatientListItem;
    label: string;
  };

  const patientOptions: PatientOption[] = useMemo(() => {
    return (
      patientListResponse.data?.patients?.map(p => ({
        value: p,
        label: nameAndDOB(p)
      })) || []
    );
  }, [patientListResponse]);

  const form = useForm<{
    patient: PatientInfo | null;
    date: string;
    reason: string;
  }>({
    defaultValues: {
      patient: patient,
      date: dayjs().format("YYYY-MM-DD"),
      reason: ""
    }
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = form;

  /**
   * The effects for the response of creating the patient recall
   * Success: Toast and close modal
   * Failure: Toast but keep modal open
   */
  useEffect(() => {
    if (createPatientRecallResponse.isSuccess) {
      dispatch(setModalIsOpen(false));
      dispatch(
        addAlertToToastTrough({
          type: STATUS_KEYS.SUCCESS,
          message: "Successfully created patient recall"
        })
      );
    }

    if (createPatientRecallResponse.isError) {
      dispatch(
        addAlertToToastTrough({
          type: STATUS_KEYS.ERROR,
          message: "Something went wrong creating the patient recall"
        })
      );
    }
  }, [
    createPatientRecallResponse.isSuccess,
    createPatientRecallResponse.isError
  ]);

  const onSubmit = (data: any) => {
    const createRecallPayload: CreatePatientRecallApiArg = {
      patientRecallCreate: {
        patient_id: data.patient.user_id,
        due_date: data.date,
        reason: data.reason
      }
    };

    createPatientRecall(createRecallPayload);
  };

  return (
    <div className={styles.PatientRecall} data-cy="patient-recall-modal">
      <form onSubmit={handleSubmit(onSubmit)} className={styles.recallForm}>
        <Controller
          name="patient"
          control={control}
          rules={{ required: "Patient is required" }}
          render={({ field }) => (
            <Select
              {...field}
              options={patientOptions}
              isLoading={patientListResponse.isLoading}
              onInputChange={newValue => setQuery(newValue)}
              styles={SelectDefaultStyles}
              theme={SelectDefaultTheme}
              placeholder="Select a patient"
              value={patientOptions.find(
                p => p.value.user_id === field.value?.user_id
              )}
              onChange={(v: any) => {
                if (v) field.onChange(v?.value as PatientOption["value"]);
              }}
              id="patient-select"
            />
          )}
        />
        {errors.patient && <p className="error">{errors.patient.message}</p>}
        <Input
          type="date"
          id="date"
          name="date"
          label="Recall Date"
          register={register}
          isHorizontalLayout={false}
          fullWidth
          required
        />
        <ControlledTextArea
          label="Reason"
          id="reason"
          name="reason"
          form={form}
        />
        <div className={styles.buttonContainer}>
          <Button
            style={STYLES.SECONDARY}
            onClick={() => dispatch(setModalIsOpen(false))}
          >
            Cancel
          </Button>
          <Button
            style={STYLES.PRIMARY}
            type="submit"
            loading={createPatientRecallResponse.isLoading}
            dataCy="submit-recall"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
