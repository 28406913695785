import Input from "@/components/input";
import { Controller, UseFormReturn } from "react-hook-form";
import Select from "react-select";

import styles from "../../../styles.module.scss";
import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";
import { UserEmergencyContact } from "@/store/services/patient";

interface FormElementProps {
  form: UseFormReturn;
  submitOnBlur?: (data: UserEmergencyContact) => void;
}

export default function FormElement({ form, submitOnBlur }: FormElementProps) {
  const phoneTypeOptions = [
    {
      label: "Home",
      value: "Home"
    },
    {
      label: "Work",
      value: "Work"
    },
    {
      label: "Mobile",
      value: "Mobile"
    }
  ];

  return (
    <div className={styles.formFields}>
      <Input
        type="text"
        name="name"
        id="name"
        label="Name"
        register={form.register}
        rules={{
          onBlur: () => {
            if (submitOnBlur) {
              form.handleSubmit(submitOnBlur)();
            }
          }
        }}
      />
      <Input
        type="text"
        name="email"
        id="email"
        label="Email"
        register={form.register}
        rules={{
          onBlur: () => {
            if (submitOnBlur) {
              form.handleSubmit(submitOnBlur)();
            }
          }
        }}
      />
      <Input
        type="text"
        name="phone"
        id="phone"
        label="Phone"
        register={form.register}
        rules={{
          onBlur: () => {
            if (submitOnBlur) {
              form.handleSubmit(submitOnBlur)();
            }
          }
        }}
      />
      <Controller
        control={form.control}
        name="phone_type"
        render={({ field: { onChange, value } }) => (
          <div className={styles.flexOne}>
            <label className="standardLabel">Phone type</label>
            <Select
              options={phoneTypeOptions}
              onChange={(newValue: any) => {
                onChange(newValue?.value);
                if (submitOnBlur) {
                  form.handleSubmit(submitOnBlur)();
                }
              }}
              value={phoneTypeOptions.find(type => type.value === value)}
              styles={SelectDefaultStyles}
              theme={SelectDefaultTheme}
            />
          </div>
        )}
      />
      <Input
        type="text"
        name="relationship"
        id="relationship"
        label="Relationship"
        register={form.register}
        rules={{
          onBlur: () => {
            if (submitOnBlur) {
              form.handleSubmit(submitOnBlur)();
            }
          }
        }}
      />
    </div>
  );
}
