/* PatientHeader Name */
/* External Imports */
import clsx from "clsx";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { useRouter } from "next/router";

/* Local Imports */

// components
import AvatarPlaceholder from "@/components/avatarPlaceholder";
import Icon from "@/components/icons";
import Button from "@/components/button";

// constants
import { Action } from "@/globals/constants/types";
import { DRAWER_TYPES } from "@/components/drawer/dispatcher";
import {
  PATIENT_VIEWS,
  METRICS,
  STATUS_KEYS,
  PAGES,
  METRIC_LABELS
} from "@/globals/constants";
import { parseParioDate } from "@/utils/api/time";

// store
import { setLeftPaneOpen, setLeftPaneContent } from "../drawer/drawerSlice";
import { usePatientGetFormQuery, PatientInfo } from "@/store/services/patient";
import { RootState } from "@/store/store";
import { useLazyLocationGetInfoQuery } from "@/store/services/location";

// utils
import {
  calculateAge,
  calculateAgeInMonths,
  getGestationalAge,
  getGsPs,
  getPostPartumDuration
} from "@/globals/helpers";
import { TIME_FORMAT } from "@/globals/helpers/formatters";

// styles
import styles from "./styles.module.scss";
import Tag from "../tag";

/* PatientHeader Typescript Interface */
interface PatientHeaderProps {
  patient: PatientInfo;
  // profilePictureSrc?: string; // source for profile picture
  actions?: Action[];
}

export default function PatientHeader({
  patient,
  actions = []
}: PatientHeaderProps) {
  /* Redux */
  const dispatch = useDispatch();
  const router = useRouter();
  const leftPaneOpen = useSelector(
    (state: RootState) => state.drawer.leftPaneOpen
  );

  /* Local State */
  const dob = parseParioDate(patient.dob) || new Date();
  const pregnancyHistory = useMemo(() => getGsPs(patient), [patient]);

  /* Event Handlers */
  const handleAvatarClick = () => {
    router.push(`${PAGES.PATIENTS}/${patient.user_id}`);
  };

  const handleCollapse = () => {
    dispatch(setLeftPaneOpen(true));
    dispatch(
      setLeftPaneContent({
        type: DRAWER_TYPES.FACEPAGE,
        props: {
          patient,
          view: PATIENT_VIEWS.DASH
        }
      })
    );
  };

  return (
    <div
      className={clsx(styles.PatientHeader, {
        [styles.collapsed]: leftPaneOpen
      })}
      data-cy="patient-header"
    >
      {/* patient data */}
      <div>
        <button onClick={handleCollapse}>
          <Icon svg="back" width={12} height={8} flipped />
        </button>

        {/* profile picture */}
        <div onClick={handleAvatarClick} className={styles.profilePicture}>
          <AvatarPlaceholder
            character={`${
              patient.first_name
                ? patient.first_name[0]
                : "" + patient.last_name[0]
            }`}
            userId={patient.user_id}
          />
        </div>
        <div className={styles.name}>
          {patient.first_name} {patient.last_name}
        </div>
        <p>
          DOB: {TIME_FORMAT.date(dob)}{" "}
          {`(Age ${
            patient.practice_data.type === METRICS.INFANT
              ? calculateAgeInMonths(dob)
              : calculateAge(dob)
          })`}
        </p>
        {patient.practice_data.type !== METRICS.INFANT &&
          patient.pregnancy &&
          patient.sex !== METRICS.MALE && (
            <p className="xLight">
              <span>G{pregnancyHistory.gravida}</span>
              <span>
                {" "}
                P{pregnancyHistory.termBirths}
                {pregnancyHistory.pretermBirths}
                {pregnancyHistory.abortionsMiscarriages}
                {pregnancyHistory.livingChildren}
              </span>
            </p>
          )}
        {patient?.pregnancy?.labor && !patient?.pregnancy.delivery_date && (
          <Tag label={"Labor in progress"} type={STATUS_KEYS.WARNING} />
        )}
        {patient.pregnancy?.edd?.estimated_due_date &&
        !patient.pregnancy.delivery_date ? (
          <>
            <Tag
              label={
                "EDD | " +
                dayjs(patient.pregnancy.edd?.estimated_due_date).format(
                  "MM/DD/YYYY"
                )
              }
              type={STATUS_KEYS.INFO_GREY}
            />
            <Tag
              label={
                "GA |" +
                " " +
                getGestationalAge(
                  parseInt(
                    dayjs(patient.pregnancy.edd?.estimated_due_date).format(
                      "YYYYMMDD"
                    )
                  )
                )
              }
              type={STATUS_KEYS.INFO}
            />
          </>
        ) : null}
        {patient.pregnancy?.outcome ? (
          <Tag
            label={patient.pregnancy.outcome.replace(/\([^)]*\)/g, "")}
            type={STATUS_KEYS.INFO}
          />
        ) : null}
        {patient.pregnancy?.delivery_date ? (
          <Tag
            label={
              "Postpartum |" +
              " " +
              getPostPartumDuration(patient.pregnancy.delivery_date)
            }
            type={STATUS_KEYS.INFO}
          />
        ) : null}
        {patient.pregnancy &&
          ![METRICS.INFANT, METRICS.POSTPARTUM].includes(
            patient.practice_data.type
          ) && (
            <Tag
              type={
                patient.pregnancy?.gbs_status === undefined ||
                patient.pregnancy?.gbs_status === METRICS.DECLINED
                  ? STATUS_KEYS.WARNING
                  : patient.pregnancy?.gbs_status === METRICS.POSITIVE
                    ? STATUS_KEYS.ERROR
                    : STATUS_KEYS.SUCCESS
              }
              label={
                "GBS Status | " +
                (patient.pregnancy?.gbs_status === undefined
                  ? "--"
                  : METRIC_LABELS[patient.pregnancy?.gbs_status])
              }
            />
          )}
        <Tag
          label={
            "Blood Type | " +
            (patient.medical_history.blood_type
              ? patient.medical_history.blood_type
              : "Unknown")
          }
          type={
            patient.medical_history.blood_type
              ? STATUS_KEYS.INFO
              : STATUS_KEYS.ERROR
          }
        />
      </div>
      {/* user actions */}
      <div>
        {actions.map(({ label, style, action }) => (
          <Button style={style} onClick={action} key={label}>
            {label}
          </Button>
        ))}
      </div>
    </div>
  );
}
