import { useEffect, useRef, useState } from "react";

import styles from "./styles.module.scss";
import { msToTime } from "@/globals/helpers/formatters";

interface TranscriptRendererProps {
  transcriptData?: Record<string, any>;
}

export default function TranscriptRenderer({
  transcriptData
}: TranscriptRendererProps) {
  const renderer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!transcriptData) {
      return;
    }

    const renderer = document.getElementById(
      "transcript_renderer"
    ) as HTMLElement;

    if (renderer?.scrollHeight <= renderer?.clientHeight) {
      return;
    }
    const lastItem = transcriptData[transcriptData.length - 1];
    const topPos = document.getElementById(`${lastItem.id}`)?.offsetTop;
    if (topPos) {
      renderer.scrollTop = topPos as number;
    }
  }, [transcriptData]);

  return (
    <div className={styles.transcriptRenderer} id="transcript_renderer">
      {transcriptData &&
        transcriptData.map((item: Record<string, any>) => {
          if (!item.object || item.object != "transcript_item") {
            return;
          }
          return (
            <p key={item.id} id={item.id} className={styles.transcriptEntry}>
              <span>{`[${msToTime(item.start_offset_ms)} to ${msToTime(
                item.end_offset_ms
              )}]:`}</span>
              <span className="ph-no-capture">{`${item.text}`}</span>
            </p>
          );
        })}
    </div>
  );
}
