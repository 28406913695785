/* Confirmation Modal Template */

// External
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

// components
import Button from "@/components/button";
import ComboboxSelect from "@/components/input/combobox";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { FORMAT, nameAndDOB } from "@/globals/helpers/formatters";
// store
import { setModalIsOpen } from "../modalSlice";
import { usePatientGetListQuery } from "@/store/services/practice";
import { RootState } from "@/store/store";
import { PatientInfo } from "@/store/services/patient";
import { useFileBatchUpdateMutation, FileId } from "@/store/services/file";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// styles
import styles from "../styles.module.scss";
import { setIds } from "@/components/datagrid/datagridSlice";
import { bulkRemoveSelectedFileIds } from "@/components/fileExplorer/fileExplorerSlice";

export interface ConfirmMoveFileModalProps {
  fileIds: FileId[];
}

export default function ConfirmMoveFile({
  fileIds
}: ConfirmMoveFileModalProps) {
  const dispatch = useDispatch();
  /* Redux */
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const [query, setQuery] = useState<string | undefined>(undefined);
  const { data: listing, isSuccess } = usePatientGetListQuery({ query }, { skip: !sessionInfo?.practice_id });
  const [batchUpdateFiles] = useFileBatchUpdateMutation();

  /* Local State */
  const [selectedPatient, setSelectedPatient] = useState<PatientInfo>();
  // Event handlers

  const handleCancel = () => {
    // close modal
    dispatch(setModalIsOpen(false));
  };

  const handleConfirm = () => {
    // update file batch
    if (selectedPatient && fileIds && fileIds.length > 0) {
      const patientId = selectedPatient.user_id;
      batchUpdateFiles({
        fileBatchUpdateRequest: {
          file_ids: fileIds,
          patient_id: patientId
        }
      })
        .unwrap()
        .then(() => {
          dispatch(
            addAlertToToastTrough({
              message:
                "Successfully moved file to " + FORMAT.name(selectedPatient),
              type: STATUS_KEYS.SUCCESS
            })
          );
          dispatch(setModalIsOpen(false));
          dispatch(setIds([]));
          dispatch(bulkRemoveSelectedFileIds(fileIds));
        })
        .catch(() =>
          dispatch(
            addAlertToToastTrough({
              message:
                "Oops! Could not move file to " + FORMAT.name(selectedPatient),
              type: STATUS_KEYS.ERROR
            })
          )
        );
    }
  };

  return (
    <div className={clsx(styles.ConfirmFileMove)}>
      <ComboboxSelect
        label="Move files to"
        onSearch={setQuery}
        placeholder="Select a patient to move files to"
        onChange={patient => setSelectedPatient(patient)}
        labelAcc={patient => (patient ? nameAndDOB(patient) : "")}
        options={listing?.patients || []}
        fullWidth
      />
      <div className={styles.buttons}>
        <Button style={STYLES.SECONDARY_FULL} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          style={STYLES.FULL_WIDTH}
          onClick={handleConfirm}
          nativeButtonProps={{
            disabled: !selectedPatient || !fileIds || fileIds.length === 0
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}
