// External
import { useForm } from "react-hook-form";

// Store
import { UserEmergencyContact } from "@/store/services/patient";

// Components
import ListingControls from "./components/listingControls";
import FormElement from "./components/formElement";

// Styles
import styles from "../../styles.module.scss";

interface ContactFormProps {
  onSubmit: (data: UserEmergencyContact) => void;
  onCancel: () => void;
}

export default function NewContactForm({
  onSubmit,
  onCancel
}: ContactFormProps) {
  const form = useForm<UserEmergencyContact>();

  const onOurSubmit = (data: UserEmergencyContact) => {
    onSubmit(data);
    form.reset();
  };

  return (
    <div className={styles.contactForm}>
      <form onSubmit={form.handleSubmit(onOurSubmit)}>
        <FormElement form={form} />
        <ListingControls buttonType="SUBMIT" onDelete={() => onCancel()} />
      </form>
    </div>
  );
}
