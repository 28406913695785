/* Alert Name */
/* External Imports */
import { useEffect, useState } from "react";
import clsx from "clsx";

/* Local Imports */

// components
import Icon from "../icons";

// constants
import { STATUS_TYPES, STYLES } from "@/globals/constants";

// store

// styles
import styles from "./styles.module.scss";
import Button from "../button";

/* Alert Typescript Interface */
interface AlertProps {
  message: string;
  type: keyof typeof STATUS_TYPES;
  isHidden?: boolean;
  confirmButtonText?: string;
  onConfirm?: () => void;
}

export default function Alert({
  message,
  type,
  isHidden = false,
  confirmButtonText,
  onConfirm
}: AlertProps) {
  /* Redux */

  /* Local State */
  const [hide, setHide] = useState<boolean>(isHidden);

  /* Effects */

  useEffect(() => {
    setHide(isHidden);
  }, [isHidden]);
  /* Event Handlers */

  return (
    <div className={clsx(styles.Alert, styles[type], { [styles.hide]: hide })}>
      <div className={styles.message}>
        <Icon svg="warn_triangle" width={18} />
        {message}

        {confirmButtonText && (
          <Button style={STYLES.SECONDARY} onClick={() => onConfirm && onConfirm()}>
            {confirmButtonText}
          </Button>
        )}
      </div>
      <button onClick={() => setHide(!hide)}>
        <Icon svg="close_warn" width={10} />
      </button>
    </div>
  );
}
