import { FORMAT } from "@/globals/helpers/formatters";
import { Cookie } from "@/store/services/user";
import posthog from "posthog-js";

export class AudioRecorderLogger {
  private sessionInfo: Cookie;
  private userId: number;
  private userMetaData: Record<string, any>;
  private encounterId: Number;

  constructor(sessionInfo: Cookie, encounterId: number) {
    this.sessionInfo = sessionInfo;
    this.userId = sessionInfo.user_id;
    this.encounterId = encounterId;
    this.userMetaData = {
      name: FORMAT.name(this.sessionInfo),
      userId: this.userId,
      email: this.sessionInfo.email,
      practice: this.sessionInfo.practice_id,
      encounterId: this.encounterId,
    };
  }

  //////////////////////////////////
  // Microphone logging
  public async logInitialMicrophoneData() {
    // Request permissions, if we already have permissions, this won't reprompt it
    await navigator.mediaDevices.getUserMedia({ audio: true });

    // get all audio devices
    const devices = await navigator.mediaDevices.enumerateDevices();
    // filter for audio inputs (ie microphones)
    const audioDevices = devices.filter(device => device.kind === "audioinput");
    const audioDeviceNames = audioDevices.map(device => device.label)

    // Send posthog event with number of devices.
    posthog.capture("user_mic_data", {
      deviceNumber: audioDevices.length,
      deviceNames: audioDeviceNames,
      ...this.userMetaData
    });

    // Send separate event for user with no mic
    if (audioDevices.length === 0) {
      posthog.capture("user_no_mic", {
        ...this.userMetaData
      });
    }
  }

  public logMicrophoneFailure(errorMessage: string = "") {
    posthog.capture("user_mic_failure", {
      errorMessage,
      ...this.userMetaData
    });
  }

  public logMicrophoneSuccess() {
    posthog.capture("user_mic_success", {
      ...this.userMetaData
    });
  }

  public logMicrophoneTooQuiet() {
    posthog.capture("user_mic_too_quiet", {
      ...this.userMetaData
    })
  }

  public logMicrophoneSendingValidAudio() {
    posthog.capture("user_mic_sending_valid_audio", {
      ...this.userMetaData
    })
  }

  //////////////////////////////////
  // WebSocket logging
  public logWebSocketConnectionStarted(connectionAttemptNumber: number = 1) {
    posthog.capture("user_websocket_connection_started", {
      connectionAttemptNumber,
      ...this.userMetaData
    })
  }

  public logWebSocketConnectionSuccess() {
    posthog.capture("user_websocket_connection_success", {
      ...this.userMetaData
    });
  }

  public logWebSocketConnectionFailure() {
    posthog.capture("user_websocket_connection_failed", {
      ...this.userMetaData
    })
  }

  public logWebSocketDisconnected() {
    posthog.capture("user_websocket_disconnected", {
      ...this.userMetaData
    })
  }

  public logWebSocketError(error: Error) {
    posthog.capture("user_websocket_error", {
      error,
      ...this.userMetaData
    })
  }

  //////////////////////////////////
  // Audio Connection logging
  public logCleanupMessage(message: string) {
    posthog.capture("user_cleanup_audio_recording", {
      message,
      ...this.userMetaData
    })
  }

  public logAudioConnectionError(error: any) {
    posthog.capture("user_audio_connection_error", {
      error,
      ...this.userMetaData
    })
  }

  public logEvent(message: string) {
    posthog.capture("user_audio_recording_event", {
      message,
      ...this.userMetaData
    })
  }
}
