/* FacePage Name */
/* External Imports */
import dayjs from "dayjs";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import clsx from "clsx";
import libphonenumber from "google-libphonenumber";
import Select from "react-select";
import Link from "next/link";

/* Local Imports */

// components
import Icon from "@/components/icons";
import Button from "@/components/button";
import Tag from "../tag";
import AvatarPlaceholder from "../avatarPlaceholder";
import { HealthHistory } from "@/components/healthHistory";

// icons
import Edit from "../../../public/svgs/edit_simple.svg";
import Check from "../../../public/svgs/check.svg";
import Arrow from "../../../public/svgs/arrow_link.svg";

// helpers
import {
  useCustomPatientTypes,
  useIncompleteEncountersList
} from "@/globals/helpers/customHooks";

// constants
import {
  PAGES,
  STYLES,
  METRIC_LABELS,
  STATUS_KEYS,
  METRICS,
  BLOOD_TYPES
} from "@/globals/constants";

// store
import { RootState } from "@/store/store";
import {
  AntibodyScreenDat,
  BloodType,
  Met1,
  Met2,
  PatientInfo,
  PatientType,
  usePatientUpsertMutation,
  usePregnancyInfoQuery
} from "@/store/services/patient";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";
import {
  GbsStatus,
  usePregnancyUpsertMutation
} from "@/store/services/pregnancy";
import { useGetFeatureFlagsQuery } from "@/store/services/system";
import { usePracticeInfoQuery } from "@/store/services/practice";

// styles
import styles from "./styles.module.scss";
import { SelectDefaultTheme } from "@/styles/themes/selectDefaultTheme";

// utils
import { parseParioDate } from "@/utils/api/time";
import { formatNumberWithDashes } from "@/utils/strings";
import {
  getGsPs,
  getGestationalAge,
  calculateAge,
  getPostPartumDuration,
  calculateAgeInMonths
} from "@/globals/helpers";
import { FORMAT } from "@/globals/helpers/formatters";
import { setModalContent, setModalIsOpen } from "../modal/modalSlice";
import { MODAL_TYPES } from "../modal/dispatcher";

/* FacePage Typescript Interface */
export interface FacePageProps {
  view: string;
  patient: PatientInfo;
}

export default function FacePage({ view, patient }: FacePageProps) {
  const router = useRouter();
  const dispatch = useDispatch();
  const customPatientTypes = useCustomPatientTypes();

  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { data: pregnancyInfo } = usePregnancyInfoQuery(
    {
      pregnancyId: patient.pregnancy?.pregnancy_id as number
    },
    {
      skip: !patient.pregnancy?.pregnancy_id
    }
  );
  const { data: featureFlags } = useGetFeatureFlagsQuery();
  const { data: practiceInfo } = usePracticeInfoQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo?.practice_id }
  );

  const patientTypes = (customPatientTypes as PatientType[]).map(type => ({
    label: METRIC_LABELS[type] || type,
    value: type
  }));

  const [editingPatientMedicalState, setEditingPatientMedicalState] =
    useState(false);

  const [updatePatient] = usePatientUpsertMutation();
  const [upsertPregnancy] = usePregnancyUpsertMutation();

  const incompleteEncounters = useIncompleteEncountersList("practice");
  const activeLabor = useMemo(() => {
    return incompleteEncounters?.find(
      encounter =>
        encounter.encounter_type === "LABOR" &&
        encounter.patient.user_id === patient.user_id
    );
  }, [incompleteEncounters]);

  const pregnancyHistory = useMemo(
    () => getGsPs(patient),
    [patient, patient.pregnancy, patient.medical_history]
  );

  const birthplaceOptions = useMemo(() => {
    if (practiceInfo?.desired_birthplaces) {
      return practiceInfo.desired_birthplaces.map(place => ({
        value: place,
        label: place
      }));
    }
    return [];
  }, [practiceInfo]);

  const GBSOptions = [
    {
      value: METRICS.POSITIVE as GbsStatus,
      label: METRIC_LABELS[METRICS.POSITIVE]
    },
    {
      value: METRICS.NEGATIVE as GbsStatus,
      label: METRIC_LABELS[METRICS.NEGATIVE]
    },
    {
      value: METRICS.DECLINED as GbsStatus,
      label: METRIC_LABELS[METRICS.DECLINED]
    }
  ];

  const bloodTypeOptions = BLOOD_TYPES.map(type => ({
    value: type,
    label: type
  }));

  const metabolicScreenOptions = (
    ["NORMAL", "ABNORMAL", "DECLINED"] as const
  ).map(v => ({
    value: v,
    label: v ? METRIC_LABELS[v] : "-"
  }));

  const DATScreenOptions = (["NEGATIVE", "POSITIVE", "DECLINED"] as const).map(
    v => ({
      value: v,
      label: v ? METRIC_LABELS[v] : "-"
    })
  );

  const handleUpdatePatientStatus = async (item: PatientType) => {
    if (!item) {
      return;
    }

    if (item !== status) {
      try {
        // TODO(josh): This should cause the label to change without refreshing, but
        // the status update doesn't display until the user refreshes the page. To
        // get around that, I'm using a piece of state (status) to optimistically
        // disply the status change.
        await updatePatient({
          patientUpsertRequest: {
            user_id: patient.user_id,
            practice_data: {
              type: item
            }
          }
        }).unwrap();
        setStatus(item);
        dispatch(
          addAlertToToastTrough({
            message: "updated patient status",
            type: STATUS_KEYS.SUCCESS
          })
        );
      } catch (e) {
        dispatch(
          addAlertToToastTrough({
            message: "failed to update patient status",
            type: STATUS_KEYS.ERROR
          })
        );
      }
    }
  };

  const handleUpdatePatientBloodType = async (item: BloodType) => {
    if (!item) {
      return;
    }

    if (item !== patient.medical_history.blood_type) {
      try {
        await updatePatient({
          patientUpsertRequest: {
            user_id: patient.user_id,
            medical_history: {
              ...patient.medical_history,
              blood_type: item
            }
          }
        }).unwrap();
        dispatch(
          addAlertToToastTrough({
            message: "updated patient blood type",
            type: STATUS_KEYS.SUCCESS
          })
        );
      } catch (e) {
        dispatch(
          addAlertToToastTrough({
            message: "failed to update blood type",
            type: STATUS_KEYS.ERROR
          })
        );
      }
    }
  };

  // handle update patient antibody screen DAT
  const handleUpdatePatientAntibodyScreenDAT = async (
    item: "POSITIVE" | "NEGATIVE" | "DECLINED" | undefined | ""
  ) => {
    if (!item) {
      item = "";
    }
    if (item !== patient.medical_history.antibody_screen_DAT) {
      try {
        await updatePatient({
          patientUpsertRequest: {
            user_id: patient.user_id,
            medical_history: {
              ...patient.medical_history,
              antibody_screen_DAT: item as AntibodyScreenDat
            }
          }
        }).unwrap();
        dispatch(
          addAlertToToastTrough({
            message: "updated patient antibody screen DAT",
            type: STATUS_KEYS.SUCCESS
          })
        );
      } catch (e) {
        dispatch(
          addAlertToToastTrough({
            message: "failed to update antibody screen DAT",
            type: STATUS_KEYS.ERROR
          })
        );
      }
    }
  };

  // handle update patient metabolic screen 1
  const handleUpdatePatientMetabolicScreen1 = async (
    item: "NORMAL" | "ABNORMAL" | "DECLINED" | "" | undefined
  ) => {
    if (!item) {
      item = "";
    }
    if (item !== patient.medical_history.met_1) {
      try {
        await updatePatient({
          patientUpsertRequest: {
            user_id: patient.user_id,
            medical_history: {
              ...patient.medical_history,
              met_1: item as Met1
            }
          }
        }).unwrap();
        dispatch(
          addAlertToToastTrough({
            message: "updated patient metabolic screen 1",
            type: STATUS_KEYS.SUCCESS
          })
        );
      } catch (e) {
        dispatch(
          addAlertToToastTrough({
            message: "failed to update metabolic screen 1",
            type: STATUS_KEYS.ERROR
          })
        );
      }
    }
  };

  // handle update patient metabolic screen 2
  const handleUpdatePatientMetabolicScreen2 = async (
    item: "NORMAL" | "ABNORMAL" | "DECLINED" | "" | undefined
  ) => {
    if (!item) {
      item = "";
    }

    if (item !== patient.medical_history.met_2) {
      try {
        await updatePatient({
          patientUpsertRequest: {
            user_id: patient.user_id,
            medical_history: {
              ...patient.medical_history,
              met_2: item as Met2
            }
          }
        }).unwrap();
        dispatch(
          addAlertToToastTrough({
            message: "updated patient metabolic screen 2",
            type: STATUS_KEYS.SUCCESS
          })
        );
      } catch (e) {
        dispatch(
          addAlertToToastTrough({
            message: "failed to update metabolic screen 2",
            type: STATUS_KEYS.ERROR
          })
        );
      }
    }
  };

  const handleUpdateDesiredBirthplace = async (place: string) => {
    if (!place) {
      return;
    }

    if (place !== patient.pregnancy?.desired_birthplace) {
      try {
        await upsertPregnancy({
          patientId: patient.user_id,
          pregnancyUpsertRequest: {
            pregnancy_id: patient.pregnancy?.pregnancy_id as number,
            desired_birthplace: place
          }
        }).unwrap();
        dispatch(
          addAlertToToastTrough({
            message: `updated ${METRIC_LABELS[METRICS.DESIRED_BIRTH_LOC]}`,
            type: STATUS_KEYS.SUCCESS
          })
        );
      } catch (e) {
        dispatch(
          addAlertToToastTrough({
            message: `failed to update ${
              METRIC_LABELS[METRICS.DESIRED_BIRTH_LOC]
            }`,
            type: STATUS_KEYS.ERROR
          })
        );
      }
    }
  };

  const handleUpdatePatientGBSStatus = async (
    GBSStatus: {
      label: string;
      value: GbsStatus;
    } | null
  ) => {
    let value = "";
    if (GBSStatus?.value) {
      value = GBSStatus.value;
    }
    if (value !== patient.pregnancy?.gbs_status) {
      try {
        await upsertPregnancy({
          patientId: patient.user_id,
          pregnancyUpsertRequest: {
            pregnancy_id: patient.pregnancy?.pregnancy_id as number,
            gbs_status: value as GbsStatus // allow empty string which will set the value to null in the DB
          }
        }).unwrap();
        dispatch(
          addAlertToToastTrough({
            message: "updated GBS status",
            type: STATUS_KEYS.SUCCESS
          })
        );
      } catch (e) {
        dispatch(
          addAlertToToastTrough({
            message: "failed to update GBS status",
            type: STATUS_KEYS.ERROR
          })
        );
      }
    }
  };

  const [status, setStatus] = useState<string>("");

  useEffect(() => {
    setStatus(patient.practice_data.type);
  }, [patient.practice_data.type]);

  /**
   * EDD String is the Estimated Due Date string formatted as MM/DD/YYYY
   */
  const eddString = useMemo(() => {
    if (
      !!pregnancyInfo?.edd?.estimated_due_date &&
      patient.practice_data.type !== METRICS.POSTPARTUM
    ) {
      return dayjs(pregnancyInfo.edd?.estimated_due_date).format("MM/DD/YYYY");
    } else {
      return "";
    }
  }, [pregnancyInfo]);

  const handleOpenSecretNote = () => {
    dispatch(setModalIsOpen(true));
    dispatch(
      setModalContent({
        type: MODAL_TYPES.SECRET_NOTE,
        props: { patient, title: "Private Note" }
      })
    );
  };

  let phoneStr = "--";
  try {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const phone = phoneUtil.parse(patient.phone, "US");
    phoneStr = phoneUtil.formatInOriginalFormat(phone, "US");
  } catch (e) {
    console.error(e);
  }

  return patient ? (
    <div className={clsx(styles.FacePage)} data-cy="patient-sidebar">
      <div className={styles.faceCard}>
        <div className={styles.header}>
          <AvatarPlaceholder
            isLarge
            userId={patient.user_id}
            character={
              patient.first_name
                ? patient?.first_name?.[0]
                : "" + patient?.last_name?.[0]
            }
          />
          <div className={styles.headerData}>
            <div className={styles.nameNote}>
              <div>
                <h4>{FORMAT.name(patient)}</h4>
                {featureFlags?.pronouns_enabled && patient.pronouns && (
                  <h5>({patient.pronouns})</h5>
                )}
              </div>

              <button
                type="button"
                onClick={handleOpenSecretNote}
                className={styles.privateNote}
              >
                <Icon
                  svg={
                    patient.secret_note &&
                    !/^<p><\/p>$/i.test(patient.secret_note) // this takes care of the case when the user has deleted all the content but the p tag is still there
                      ? "secret_note"
                      : "sticker-square"
                  }
                  width={20}
                  height={20}
                />
              </button>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="t5 xLight flex center">
                ID: # {formatNumberWithDashes(patient.user_id, 3, 9)}
                {patient.signup_completed && (
                  <div title="Signup completed" className="flex center">
                    <Icon svg="badge_check" width={14} height={14} />
                  </div>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className={styles.statusGrid}>
          {patient.practice_data.type !== METRICS.INFANT &&
            patient.sex !== METRICS.MALE && (
              <>
                <p data-cy="gravida-para">Gravida & Para</p>

                <p>
                  <span>G{pregnancyHistory.gravida}</span>
                  <span>
                    {" "}
                    P{pregnancyHistory.termBirths}
                    {pregnancyHistory.pretermBirths}
                    {pregnancyHistory.abortionsMiscarriages}
                    {pregnancyHistory.livingChildren}
                  </span>
                </p>
              </>
            )}
          <p>Date of Birth</p>
          <p>
            {dayjs(parseParioDate(patient.dob)).format("MM/DD/YYYY")}
            <p className="t5">{`(Age ${
              patient.practice_data.type === METRICS.INFANT
                ? calculateAgeInMonths(
                    parseParioDate(patient.dob) || new Date()
                  )
                : calculateAge(parseParioDate(patient.dob) || new Date())
            })`}</p>
          </p>
          <p>Phone</p>
          <p>{phoneStr}</p>
          <p>Email</p>
          <p>{patient.email || "--"}</p>
          {patient.pregnancy &&
            (patient.pregnancy.delivery_date ? (
              <>
                <p>
                  {patient?.pregnancy?.outcome?.replace(/\([^)]*\)/g, "") ||
                    "Pregnancy completed"}
                </p>
                <p>
                  {dayjs(
                    parseParioDate(patient.pregnancy.delivery_date as number)
                  ).format("MM/DD/YYYY")}
                  <p className="xLight t5">
                    (
                    {getPostPartumDuration(
                      patient.pregnancy.delivery_date as number
                    )}
                    )
                  </p>
                </p>
              </>
            ) : (
              eddString &&
              eddString.length > 0 && (
                <>
                  <span
                    className={styles.hasTooltip}
                    title="EDD comes from the dating table. To access, click the dating button on the gestational timeline."
                  >
                    <p className={clsx(styles.eddLabel, "xLight")}>
                      EDD <sup>?</sup>
                    </p>
                  </span>
                  <p>
                    {eddString}
                    <p className="xLight t5">
                      (
                      {getGestationalAge(
                        parseInt(
                          dayjs(eddString, "MM/DD/YYYY").format("YYYYMMDD")
                        )
                      )}
                      )
                    </p>
                  </p>
                </>
              )
            ))}
          <p>Insurance</p>
          <p>
            {patient.insurance?.self_pay
              ? "Self Pay"
              : patient.insurance?.primary?.payor || "--"}
          </p>
        </div>
        {/* link to parent chart */}
        {patient.practice_data.type === METRICS.INFANT &&
          patient.parent_ids &&
          patient.parent_ids.length > 0 && (
            <Button
              style={STYLES.SECONDARY_FULL}
              onClick={() =>
                router.push(
                  `${PAGES.PATIENTS}/${patient.parent_ids?.[0] as number}`
                )
              }
            >
              View Associated Chart{" "}
              <Icon svg="chevron_right" width={10} height={12} />
            </Button>
          )}

        {/* Link to infant chart from parent */}
        {patient.practice_data.type !== METRICS.INFANT &&
          patient.children?.map(child => (
            <Button
              key={`child-${child.user_id}`}
              style={STYLES.SECONDARY_FULL_TEXT_WRAP}
              onClick={() => router.push(`${PAGES.PATIENTS}/${child.user_id}`)}
            >
              <p>
                View {FORMAT.name(child)} Chart{" "}
                <Icon svg="chevron_right" width={10} height={12} />
              </p>
            </Button>
          ))}
      </div>
      <div className={styles.medicalStateCard} data-cy="medical-state-card">
        <div className={styles.statusGrid}>
          {editingPatientMedicalState ? (
            <>
              <p className="t5 xLight">Patient Type</p>
              <div data-cy="patient-type">
                <Select
                  theme={SelectDefaultTheme}
                  options={patientTypes}
                  onChange={v => {
                    if (!v || !v.value) return;

                    handleUpdatePatientStatus(v.value);
                  }}
                  value={patientTypes.find(v => v.value === status)}
                  placeholder="Select status"
                  aria-label="Patient Type"
                />
              </div>
              <p className="t5 xLight">Blood type</p>
              <Select
                theme={SelectDefaultTheme}
                options={bloodTypeOptions}
                onChange={v => {
                  if (!v || !v.value) return;

                  handleUpdatePatientBloodType(v.value);
                }}
                value={bloodTypeOptions.find(
                  ({ value }) => value === patient.medical_history.blood_type
                )}
                placeholder="Select..."
                aria-label="Blood Type"
                id="blood-type-select"
              />
              {![METRICS.INFANT, METRICS.POSTPARTUM].includes(
                patient.practice_data.type
              ) &&
                patient.pregnancy && (
                  <>
                    <p className="t5 xLight">GBS Status</p>
                    <Select
                      aria-label="GBS Status"
                      theme={SelectDefaultTheme}
                      options={GBSOptions}
                      onChange={v => {
                        handleUpdatePatientGBSStatus(v);
                      }}
                      value={GBSOptions.find(
                        ({ value }) => value === patient.pregnancy?.gbs_status
                      )}
                      placeholder="Select..."
                      id="gbs-select"
                      isClearable
                    />

                    {practiceInfo?.desired_birthplaces &&
                      practiceInfo.desired_birthplaces.length > 0 && (
                        <>
                          <p className="t5 xLight">
                            {METRIC_LABELS[METRICS.DESIRED_BIRTH_LOC]}
                          </p>
                          <Select
                            theme={SelectDefaultTheme}
                            options={birthplaceOptions}
                            onChange={v =>
                              handleUpdateDesiredBirthplace(v?.value as string)
                            }
                            value={birthplaceOptions.find(
                              ({ value }) =>
                                value === patient.pregnancy?.desired_birthplace
                            )}
                            placeholder="Select..."
                            aria-label="Desired Birth Location"
                          />
                        </>
                      )}
                  </>
                )}
              {patient.practice_data.type === METRICS.INFANT && (
                // Infant specific medical state
                // Antibody Screen DAT
                <>
                  <p className="t5 xLight">DAT</p>
                  <Select
                    isClearable
                    theme={SelectDefaultTheme}
                    options={DATScreenOptions}
                    onChange={v =>
                      handleUpdatePatientAntibodyScreenDAT(v?.value)
                    }
                    value={DATScreenOptions.find(
                      ({ value }) =>
                        value === patient.medical_history.antibody_screen_DAT
                    )}
                    placeholder="Select..."
                    aria-label="DAT"
                    id="dat-select"
                  />
                  {/* Metabolic Screen 1 */}
                  <p className="t5 xLight">Metabolic Screen 1</p>
                  <Select
                    isClearable
                    theme={SelectDefaultTheme}
                    options={metabolicScreenOptions}
                    onChange={v =>
                      handleUpdatePatientMetabolicScreen1(v?.value)
                    }
                    value={metabolicScreenOptions.find(
                      ({ value }) => value === patient.medical_history.met_1
                    )}
                    placeholder="Select..."
                    aria-label="Metabolic Screen 1"
                  />
                  {/* Metabolic Screen 2 */}
                  <p className="t5 xLight">Metabolic Screen 2</p>
                  <Select
                    isClearable
                    theme={SelectDefaultTheme}
                    options={metabolicScreenOptions}
                    onChange={v =>
                      handleUpdatePatientMetabolicScreen2(v?.value)
                    }
                    value={metabolicScreenOptions.find(
                      ({ value }) => value === patient.medical_history.met_2
                    )}
                    placeholder="Select..."
                    aria-label="Metabolic Screen 2"
                  />
                </>
              )}
            </>
          ) : (
            <>
              <p className="t5 xLight">Patient Type</p>

              <div data-cy="patient-type">
                <Tag
                  type={status ? STATUS_KEYS.INFO : STATUS_KEYS.WARNING}
                  label={METRIC_LABELS[status] || status || "--"}
                />
              </div>
              <p className="t5 xLight">Blood type</p>
              <Tag
                type={
                  patient.medical_history.blood_type
                    ? STATUS_KEYS.INFO
                    : STATUS_KEYS.WARNING
                }
                label={patient.medical_history.blood_type || "--"}
              />
              {![METRICS.INFANT].includes(patient.practice_data.type) &&
                patient.pregnancy && (
                  <>
                    <p className="t5 xLight">GBS Status</p>
                    <Tag
                      type={
                        patient.pregnancy?.gbs_status === undefined ||
                        patient.pregnancy?.gbs_status === METRICS.DECLINED
                          ? STATUS_KEYS.WARNING
                          : patient.pregnancy?.gbs_status === METRICS.POSITIVE
                            ? STATUS_KEYS.ERROR
                            : STATUS_KEYS.SUCCESS
                      }
                      label={
                        patient.pregnancy?.gbs_status === undefined
                          ? "--"
                          : METRIC_LABELS[patient.pregnancy?.gbs_status]
                      }
                    />
                    {practiceInfo?.desired_birthplaces &&
                      practiceInfo.desired_birthplaces.length > 0 && (
                        <>
                          <p className="t5 xLight">
                            {METRIC_LABELS[METRICS.DESIRED_BIRTH_LOC]}
                          </p>
                          <Tag
                            type={
                              patient.pregnancy?.desired_birthplace
                                ? STATUS_KEYS.INFO
                                : STATUS_KEYS.WARNING
                            }
                            label={
                              patient.pregnancy?.desired_birthplace || "--"
                            }
                          />
                        </>
                      )}
                  </>
                )}
              {patient.practice_data.type === METRICS.INFANT && (
                // Infant specific medical state
                // Antibody Screen DAT
                <>
                  <p className="t5 xLight">DAT</p>
                  <Tag
                    type={
                      patient.medical_history.antibody_screen_DAT ===
                      METRICS.POSITIVE
                        ? STATUS_KEYS.WARNING
                        : STATUS_KEYS.INFO
                    }
                    label={
                      patient.medical_history.antibody_screen_DAT
                        ? METRIC_LABELS[
                            patient.medical_history.antibody_screen_DAT
                          ]
                        : "--"
                    }
                  />
                  {/* Metabolic Screen 1 */}
                  <p className="t5 xLight">Metabolic Screen 1</p>
                  <Tag
                    type={
                      patient.medical_history.met_1 === METRICS.ABNORMAL
                        ? STATUS_KEYS.WARNING
                        : STATUS_KEYS.INFO
                    }
                    label={
                      patient.medical_history.met_1
                        ? METRIC_LABELS[patient.medical_history.met_1]
                        : "--"
                    }
                  />
                  {/* Metabolic Screen 2 */}
                  <p className="t5 xLight">Metabolic Screen 2</p>
                  <Tag
                    type={
                      patient.medical_history.met_2 === METRICS.ABNORMAL
                        ? STATUS_KEYS.WARNING
                        : STATUS_KEYS.INFO
                    }
                    label={
                      patient.medical_history.met_2
                        ? METRIC_LABELS[patient.medical_history.met_2]
                        : "--"
                    }
                  />
                </>
              )}
            </>
          )}
        </div>

        {
          <button
            type="button"
            data-cy="patient-edit-medical-state"
            onClick={() =>
              setEditingPatientMedicalState(!editingPatientMedicalState)
            }
          >
            {editingPatientMedicalState ? (
              <Check stroke={styles.gray400} width={16} height={16} />
            ) : (
              <Edit stroke={styles.gray400} width={16} height={16} />
            )}
          </button>
        }
      </div>
      {activeLabor && (
        <div className={styles.activeLabor}>
          <div className={clsx(styles.banner, styles.warningBanner)}>
            <div className={styles.iconWrapper}>
              <Icon svg="warn_triangle" width={18} />
            </div>
            <div className={styles.info}>
              <p className="t4">
                <Link href={`${PAGES.ENCOUNTERS}/${activeLabor.encounter_id}`}>
                  {activeLabor.patient.first_name}'s labor is currently in
                  progress <Arrow stroke="rgb(181, 71, 8)" />
                </Link>
              </p>
            </div>
          </div>
        </div>
      )}
      {!router.pathname.includes(PAGES.PATIENTS) && (
        <div className={styles.buttonWrapper}>
          <Button
            style={STYLES.SECONDARY_FULL}
            onClick={() =>
              router.push(
                `${PAGES.PATIENTS}/${encodeURIComponent(patient.user_id)}`
              )
            }
          >
            <Icon svg="eye" />
            View Facepage
          </Button>
        </div>
      )}
      <HealthHistory patientId={patient.user_id} />
    </div>
  ) : null;
}
