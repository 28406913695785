/* TestForm Name */
/* External Imports */
import clsx from "clsx";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

/* Local Imports */

// components
import GeneratedInput from "@/components/input/generated";
import GeneratedBoolField from "./bool";
import ControlledCombobox from "@/components/input/controlledCombobox";
import ControlledMultiselect from "@/components/input/controlledMultiselect";
import TextArea from "@/components/textArea";
import Button from "@/components/button";
import BasicAccordion from "@/components/accordions/basic";
import InputTable from "./table";
import PastPregnancies from "@/components/pastPregnancies";

// constants
import { FIELD_TYPES } from "@/globals/constants/formSchema";
import { STYLES } from "@/globals/constants";

// store
import { FormInfo, FormFieldType } from "@/store/services/form";
import {
  FormSubmission,
  usePatientGetInfoQuery,
  UserId
} from "@/store/services/patient";
import { FormFieldOptions } from "@/store/services/form";

// styles
import styles from "./styles.module.scss";

// utils
import { isValidDate } from "@/utils/time";
import { convertUtcIntToLocalDate } from "@/components/scheduling/calendars/utils";

/* TestForm Typescript Interface */
export interface DynamicFormProps {
  form: FormInfo;
  submission?: FormSubmission;
  onCancel?: () => void;
  onSubmit?: (data: any) => void; // TODO: generate data type from schema
  isReadOnly?: boolean;
  patientId?: UserId;
}

export default function DynamicForm({
  onCancel,
  onSubmit = () => {},
  form,
  submission,
  isReadOnly = false,
  patientId
}: DynamicFormProps) {
  // Generate validation schema from Zod object generated by items
  // TODO: probably will need to generate schema separate from component type mapping
  // perhaps there is tooling for Zod to map from some schema
  //const schema: ZodType = z.object(buildZodObject(allFields));
  /* Incorporate useForm() from https://react-hook-form.com/
    to register our items for validation,
    handle validation on submit, track form state & errors */

  // construct defaults for each field from provided form submission

  const getDefaultValues = () => {
    const defaultValues: Record<string, any> = {};
    for (const [k, v] of Object.entries(submission?.data || {})) {
      defaultValues[k] = v;
    }
    const fields = Object.values(form.sections).flatMap(s => s.fields);
    const fieldTypes: Record<string, FormFieldType> = {};
    for (const field of fields) {
      fieldTypes[field.name as string] = field.type;

      // We only want to convert dates if they've been entered
      if (
        defaultValues[field.name as string] &&
        (field.type == "DATE" ||
          isValidDate(defaultValues[field.name as string]))
      ) {
        defaultValues[field.name as string] = convertUtcIntToLocalDate(
          defaultValues[field.name as string] as number
        ).format("YYYY-MM-DD");
      }
    }
    return defaultValues;
  };

  useEffect(() => {
    const defaultValues = getDefaultValues();
    reset(defaultValues);
  }, [submission]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset
  } = useForm();

  /* Redux */
  const { data: patient } = usePatientGetInfoQuery(
    {
      patientId: patientId as number
    },
    { skip: !patientId }
  );

  /* Local State */

  /* Effects */

  /* Event Handlers */
  const _onSubmit = (data: any) => {
    // Normalize date fields to pTime
    const fields = Object.values(form.sections).flatMap(s => s.fields);
    fields.forEach(field => {
      if (!field.name || field.type !== "DATE") {
        return;
      }

      data[field.name] = dayjs(`${data[field.name]}`).format("YYYYMMDD");
    });

    onSubmit(data);
  };

  return (
    <div className={clsx(styles.GeneratedForm)}>
      <form onSubmit={handleSubmit(_onSubmit)}>
        {form.sections.map((section, i) => (
          <>
            {section.id === "ob-history" && patient && (
              <PastPregnancies patient={patient} />
            )}
            {section.id !== "ob-history" && (
              <BasicAccordion
                title={section.label}
                style={STYLES.SECONDARY}
                key={section.id + i}
                open
              >
                <fieldset>
                  <legend>{section.label}</legend>
                  {section.fields.map(field => {
                    switch (field.type) {
                      case FIELD_TYPES.TEXTAREA:
                        return (
                          <TextArea
                            key={field.name}
                            label={field.label}
                            name={field.name as string}
                            id={field.name as string}
                            setValue={setValue}
                            content={getDefaultValues()[field.name as string]}
                            placeholder={field.placeholder}
                            disabled={isReadOnly}
                          />
                        );
                      case FIELD_TYPES.BOOL:
                        return (
                          <GeneratedBoolField
                            key={field.name}
                            label={field.label as string}
                            name={field.name as string}
                            control={control}
                            disabled={isReadOnly}
                          />
                        );
                      case FIELD_TYPES.SINGLE_DROPDOWN:
                        return (
                          <ControlledCombobox
                            key={field.name}
                            label={field.label as string}
                            errors={errors}
                            name={field.name as string}
                            control={control}
                            options={field.options as FormFieldOptions}
                            disabled={isReadOnly}
                          />
                        );
                      case FIELD_TYPES.MULTI_DROPDOWN:
                        return (
                          <ControlledMultiselect
                            key={field.name}
                            label={field.label as string}
                            errors={errors}
                            name={field.name as string}
                            control={control}
                            options={field.options as FormFieldOptions}
                          />
                        );
                      case FIELD_TYPES.TABLE:
                        return (
                          <InputTable
                            key={field.name}
                            control={control}
                            defaultValues={getDefaultValues()}
                            fieldName={field.name || ""}
                            subfields={field.subfields || []}
                            errors={errors}
                          />
                        );
                      default:
                        return (
                          <GeneratedInput
                            key={field.name}
                            type={field.type}
                            label={field.label as string}
                            name={field.name as string}
                            register={register}
                            errors={errors}
                            defaultValue={
                              getDefaultValues()[field.name as string]
                            }
                            disabled={isReadOnly}
                          />
                        );
                    }
                  })}
                </fieldset>
              </BasicAccordion>
            )}
          </>
        ))}

        <div className={styles.buttons}>
          <Button
            style={STYLES.SECONDARY_FULL}
            onClick={onCancel}
            nativeButtonProps={{ disabled: isReadOnly }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            style={STYLES.FULL_WIDTH}
            nativeButtonProps={{ disabled: isReadOnly }}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
