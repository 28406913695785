import Skeleton from "react-loading-skeleton";

import Button from "@/components/button";
import { PregnancyId } from "@/store/services/pregnancy";

import { STYLES } from "@/globals/constants";
import styles from "./styles.module.scss";

export interface AreYouPrenatalChartingDisplayProps {
  type: "PRENATAL" | "POSTPARTUM";
  activePregnancyId?: PregnancyId;
  onCreatePregnancy: () => Promise<void>;
  onLinkPregnancy: (pregnancyId: PregnancyId) => Promise<void>;
  onClose: () => void;
  submitting: boolean;
  loading: boolean;
}

export default function AreYouPrenatalChartingDisplay({
  type,
  activePregnancyId,
  onCreatePregnancy,
  onLinkPregnancy,
  onClose,
  submitting,
  loading
}: AreYouPrenatalChartingDisplayProps) {
  return (
    <div className={styles.Content}>
      {loading ? (
        <Skeleton count={8} width={500} containerTestId="skeleton" />
      ) : (
        <>
          <div className={styles.prompt}>
            <p>
              This is a {type.toLowerCase()} encounter but it's not linked to a
              pregnancy.
            </p>
            <p>What would you like to do?</p>
          </div>

          <div className={styles.buttons}>
            <Button
              style={STYLES.TERTIARY_CLR}
              onClick={onClose}
              nativeButtonProps={{ disabled: submitting }}
            >
              Cancel
            </Button>

            {!activePregnancyId && (
              <Button
                onClick={onCreatePregnancy}
                loading={submitting}
                nativeButtonProps={{ disabled: submitting }}
              >
                Create a new pregnancy
              </Button>
            )}
            {activePregnancyId && (
              <Button
                onClick={() => onLinkPregnancy(activePregnancyId)}
                loading={submitting}
                nativeButtonProps={{ disabled: submitting }}
              >
                Link to the active pregnancy
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
