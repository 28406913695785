import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Button from "@/components/button";
import Tag from "@/components/tag";

import Skeleton from "react-loading-skeleton";
import FaxContact from "@/components/input/faxContact";

import { STATUS_KEYS, STYLES } from "@/globals/constants";

import { setModalIsOpen } from "../modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { useFaxSendMutation } from "@/store/services/fax";
import { FileListItem } from "@/store/services/patient";
import { setIds } from "@/components/datagrid/datagridSlice";
import { bulkRemoveSelectedFileIds } from "@/components/fileExplorer/fileExplorerSlice";

import styles from "../styles.module.scss";

export interface FaxFilesProps {
  patientID?: number;
  files: FileListItem[];
}

export default function FaxFiles({ patientID, files }: FaxFilesProps) {
  const dispatch = useDispatch();

  const [sendFax, { error, isError, isSuccess, isLoading }] =
    useFaxSendMutation();

  const [recipient, setRecipient] = useState<Record<string, string> | null>(
    null
  );

  useEffect(() => {
    if (isError) {
      console.error("failed to send fax", error);
      dispatch(
        addAlertToToastTrough({
          message: "Failed to fax files!",
          type: STATUS_KEYS.ERROR
        })
      );
    }

    if (isSuccess) {
      console.debug("successfully sent fax");

      dispatch(
        addAlertToToastTrough({
          message: "Successfully faxed files.",
          type: STATUS_KEYS.SUCCESS
        })
      );
    }
  }, [isError, isSuccess]);

  if (isLoading) {
    return (
      <div className={clsx([styles.FaxFiles, styles.loading])}>
        Faxing files...
        <Skeleton count={10} />
      </div>
    );
  }

  const handleCancel = () => {
    dispatch(setModalIsOpen(false));
  };

  const handleSendFax = () => {
    if (!recipient) {
      return;
    }
    sendFax({
      faxSendRequest: {
        recipients: [recipient.fax as string],
        files: files.map(file => file.file_id)
      }
    })
      .unwrap()
      .then(() => {
        dispatch(setIds([]));
        dispatch(bulkRemoveSelectedFileIds(files.map(file => file.file_id)));
        dispatch(setModalIsOpen(false));
      })
      .catch(err => console.error("failed to send fax", err));
  };

  return (
    <div className={styles.FaxFiles}>
      <div className={styles.files}>
        <ul>
          {files.map(file => (
            <li key={file.file_id}>
              <Tag
                label={file.name}
                type={STATUS_KEYS.INFO}
                customIconSvgPath="check"
              />
            </li>
          ))}
        </ul>
      </div>

      <FaxContact onChange={r => setRecipient(r)} />

      <div className={styles.buttons}>
        <Button style={STYLES.SECONDARY} onClick={handleCancel}>
          Cancel
        </Button>
        <Button style={STYLES.PRIMARY} onClick={handleSendFax}>
          Send
        </Button>
      </div>
    </div>
  );
}
