/* PatientCheckout */
/* External Imports */
import { Fragment, useState, useMemo, useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { sanitize } from "dompurify";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import { debounce } from "lodash";

/* Local Imports */

// components
import Button from "@/components/button";
import Input from "@/components/input";
import Tag from "@/components/tag";
import BasicAccordion from "@/components/accordions/basic";
import Datagrid from "@/components/datagrid";
import ContentRenderer from "@/components/textArea/contentRenderer";
import ComboboxSelect from "../input/combobox";

// constants
import {
  STATUS_KEYS,
  STYLES,
  METRICS,
  PAYMENT_METHODS,
  METRIC_LABELS,
  PAGES
} from "@/globals/constants";
import { FORMAT } from "@/globals/helpers/formatters";

// store
import {
  PatientInfo,
  usePatientGetBalanceQuery,
  usePatientGetTransactionsQuery,
  usePatientCreateTransactionMutation,
  TransactionType,
  PaymentMethod,
  AppointmentInfo,
  AppointmentStatus
} from "@/store/services/patient";
import {
  useLazyCodingSearchIcd10Query,
  useLazyCodingSearchCptQuery,
  useCodingIcd10LookupMutation,
  useCodingCptLookupMutation,
  CptCodeDetails,
  useLazyCodingSearchCptModifierQuery,
  useCodingCptModifierLookupMutation
} from "@/store/services/coding";
import { useAppointmentUpsertMutation } from "@/store/services/scheduling";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";
import {
  Coding,
  EncounterCreateUpdatePayload,
  EncounterId,
  EncounterType,
  useEncounterInfoQuery
} from "@/store/services/encounter";
import { CptId } from "@/store/services/coding";
import { useChargemasterGetEntriesMutation } from "@/store/services/chargemaster";

import { useEncounterUpdateMutation } from "@/store/services/encounter";

// styles
import styles from "./styles.module.scss";
import Icon from "../icons";

/* PatientCheckout Typescript Interface */
interface PatientCheckoutProps {
  patient: PatientInfo | null;
  encounterId?: EncounterId;
  appointmentData: Partial<AppointmentInfo>;
  isOpen?: boolean;
}

export default function PatientCheckout({
  patient,
  encounterId,
  appointmentData,
  isOpen = false
}: PatientCheckoutProps) {
  /* Redux */
  const router = useRouter();
  const dispatch = useDispatch();
  const { data: transactions, isLoading: transactionsLoading } =
    usePatientGetTransactionsQuery(
      { patientId: patient?.user_id as number, encounter: encounterId },
      { skip: !patient }
    );

  const { data: patientBalance } = usePatientGetBalanceQuery(
    { patientId: patient?.user_id as number, encounter: encounterId },
    { skip: !patient }
  );

  const { data: encounter } = useEncounterInfoQuery(
    { encounterId: encounterId as number },
    { skip: !encounterId }
  );

  const [lookupChargemaster, { data: chargemasterEntries }] =
    useChargemasterGetEntriesMutation();

  const [lookupCptCodes, { data: cptDetails = {} }] =
    useCodingCptLookupMutation();
  const [lookupIcd10Codes, { data: icd10Details = {} }] =
    useCodingIcd10LookupMutation();
  const [lookupCptModifiers, { data: cptModifierDetails = {} }] =
    useCodingCptModifierLookupMutation();
  const [searchICD10, { data: icd10SearchResults }] =
    useLazyCodingSearchIcd10Query();
  const [searchCPT, { data: cptSearchResults }] = useLazyCodingSearchCptQuery();
  const [searchCPTModifiers, { data: modiferSearchResults }] =
    useLazyCodingSearchCptModifierQuery();
  const [updateEncounter, updateEncounterRequest] =
    useEncounterUpdateMutation();

  const [createTransaction] = usePatientCreateTransactionMutation();
  const [upsertAppointment, { isLoading: isUpsertingAppointment }] =
    useAppointmentUpsertMutation();

  /* Use Form */
  const { control, handleSubmit, getValues } = useForm();

  /* Local State */
  const [totalListPrice, setTotalListPrice] = useState(0);
  const [totalSalesPrice, setTotalSalesPrice] = useState(0);
  const [checkoutClicked, setCheckoutClicked] = useState(false);

  /* Memoized Values */

  const checkoutComplete = useMemo(() => {
    return appointmentData.status === METRICS.CHECKED_OUT || checkoutClicked;
  }, [appointmentData.status, checkoutClicked]);

  const transactionData = useMemo(() => {
    return transactions?.map(transaction => ({
      id: transaction.transaction_id,
      metrics: {
        [METRICS.CHARGE]:
          transaction.type === "SERVICE_CHARGES" ? (
            <span className="errorText med">
              -$
              {/* if there has been an adjustment to the amount, display the most recent one */}
              {transaction.adjustments && transaction.adjustments.length > 0
                ? transaction.adjustments[0].amount
                : transaction.amount}
            </span>
          ) : (
            <span className="success med">
              +$
              {transaction.adjustments && transaction.adjustments.length > 0
                ? transaction.adjustments[0].amount
                : transaction.amount}
            </span>
          ),
        [METRICS.PAYMENT_METHOD]: transaction.payment_method,
        [METRICS.AUTHOR]: FORMAT.name(transaction.created_by),
        [METRICS.DATE]: transaction.created_at,
        [METRICS.DATE_OF_SERVICE]: transaction.date_of_service,
        [METRICS.TRANSACTION_NOTE]: (
          <ContentRenderer
            content={sanitize(transaction.description || "")}
            classes="t5 xLight"
          />
        ),
        [METRICS.CPT_CODE]:
          transaction.cpt_id &&
          cptDetails[transaction.cpt_id as number]?.cpt_code ? (
            <Tag
              label={cptDetails[transaction.cpt_id as number]?.cpt_code}
              type={STATUS_KEYS.INFO_GREY}
            />
          ) : (
            "-"
          )
      }
    }));
  }, [transactions, cptDetails]);

  const encounterCodings = useMemo(() => {
    return encounter?.codings;
  }, [encounter?.codings]);

  /* Effects */

  useEffect(() => {
    if (encounter?.codings) {
      const icd10Ids = encounter.codings
        .map(coding => coding.icd10_ids)
        .flat()
        .filter((id): id is number => id !== undefined);
      const cptIds = encounter.codings
        .map(coding => coding.cpt_id)
        .filter((id): id is number => id !== undefined);
      const cptModifiers = encounter.codings.flatMap(
        coding => coding.modifier_ids || []
      );
      lookupIcd10Codes({ body: icd10Ids });
      lookupCptCodes({ body: cptIds });
      lookupCptModifiers({ body: cptModifiers });
    }
  }, [encounter?.codings]);

  // fetch cpt details
  useEffect(() => {
    if (encounterCodings) {
      const cptIds = encounterCodings.map(coding => coding.cpt_id);
      lookupCptCodes({ body: cptIds as CptId[] });
      if (cptDetails && cptIds.length > 0) {
        const cptCodes = cptIds
          .map(id => {
            if (!id || !cptDetails[id]) {
              return; // Explicitly return undefined
            } else {
              return cptDetails[id]?.cpt_code;
            }
          })
          .filter((code): code is string => !!code); // Type guard to ensure code is string

        if (cptCodes && cptCodes.length > 0) {
          lookupChargemaster({
            chargemasterLookupPayload: {
              cpt_codes: cptCodes,
              payor: "cash" // TEMP: hardcoded payor
            }
          });
        }
      }
    }
  }, [encounterCodings]);

  // check when cptDetails changes
  useEffect(() => {
    if (encounterCodings) {
      const cptIds = encounterCodings.map(coding => coding.cpt_id);
      if (cptDetails && cptIds.length > 0) {
        const cptCodes = cptIds
          .map(id => {
            if (!id || !cptDetails[id]) {
              return; // Explicitly return undefined
            } else {
              return cptDetails[id]?.cpt_code;
            }
          })
          .filter((code): code is string => !!code); // Type guard to ensure code is string

        if (cptCodes && cptCodes.length > 0) {
          lookupChargemaster({
            chargemasterLookupPayload: {
              cpt_codes: cptCodes,
              payor: "cash" // TEMP: hardcoded payor
            }
          });
        }
      }
    }
  }, [encounterCodings, cptDetails]);

  // Function to calculate total price for a given key
  const calculateTotalPrice = (
    values: Record<string, any>,
    key: string
  ): number => {
    const prices = Object.keys(values)
      .filter(k => k.includes(key))
      .map(k => +values[k]);
    return prices.reduce((acc, curr) => acc + curr, 0);
  };

  // Debounced update functions
  const debounceUpdateTotalListPrice = debounce(() => {
    const values = getValues();
    const totalListPrice = calculateTotalPrice(values, "listPrice");
    setTotalListPrice(totalListPrice);
  }, 300);

  const debounceUpdateTotalSalesPrice = debounce(() => {
    const values = getValues();
    const totalSalesPrice = calculateTotalPrice(values, "salesPrice");
    setTotalSalesPrice(totalSalesPrice);
  }, 300);

  // useEffect for updating total list price
  useEffect(() => {
    const values = getValues();
    const totalListPrice = calculateTotalPrice(values, "listPrice");
    setTotalListPrice(totalListPrice);
    const totalSalesPrice = calculateTotalPrice(values, "salesPrice");
    setTotalSalesPrice(totalSalesPrice);
  }, [getValues()]);

  /* Event Handlers */

  const handleChargeAll = () => {
    const values = getValues();
    const date = dayjs().format("YYYY-MM-DD");
    encounterCodings?.forEach(coding => {
      const listPrice = values[`cpt-${coding.cpt_id}-listPrice`];
      const salesPrice = values[`cpt-${coding.cpt_id}-salesPrice`];
      const transaction: {
        date: string;
        amount: string;
        adjustment?: string;
        paymentType: TransactionType;
        cptId?: CptId;
        method?: PaymentMethod;
        note?: string;
      } = {
        date,
        amount: listPrice,
        paymentType: "SERVICE_CHARGES",
        cptId: coding.cpt_id
      };

      // if difference between list price and sales price is not zero, add an adjustment transaction
      if (Math.abs(+listPrice - +salesPrice) !== 0) {
        transaction.adjustment = `${salesPrice}`;
      }
      handleAddTransaction(transaction);
    });
  };

  const handlePayment = () => {
    const values = getValues();
    const amount = values.paymentAmount;
    if (!amount || amount === "0" || amount === "0.00") return;
    const date = values.date_of_service || dayjs().format("YYYY-MM-DD");
    const method = values.payment_type;
    const note = values.payment_note;
    handleAddTransaction({
      date,
      amount,
      paymentType: "PATIENT_PAYMENT",
      method,
      note
    });
  };

  const handleAddTransaction = ({
    date,
    amount,
    adjustment,
    paymentType,
    cptId,
    method,
    note
  }: {
    date: string;
    amount: string;
    adjustment?: string;
    paymentType: TransactionType;
    cptId?: CptId;
    method?: PaymentMethod;
    note?: string;
  }) => {
    if (!patient) return;
    createTransaction({
      patientId: patient?.user_id,
      patientTransactionCreatePayload: {
        date_of_service: date,
        amount,
        type: paymentType,
        encounter_id: encounterId,
        cpt_id: cptId,
        payment_method: method,
        description: note
      }
    })
      .unwrap()
      .then(txId => {
        if (adjustment) {
          createTransaction({
            patientId: patient?.user_id,
            patientTransactionCreatePayload: {
              date_of_service: date,
              amount: adjustment,
              type: "ADJUSTMENT" as TransactionType,
              encounter_id: encounterId,
              cpt_id: cptId,
              payment_method: method,
              description: note,
              modifies_transaction_id: txId
            }
          });
        }
        dispatch(
          addAlertToToastTrough({
            message: "Transaction Added",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Transaction Failed",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleCompleteCheckout = () => {
    handleChargeAll();
    handlePayment();

    upsertAppointment({
      appointmentUpsertRequest: {
        id: appointmentData.appointment_id,
        appointment_type_id: appointmentData?.appointment_type?.appointment_type_id as string,
        patient_id: appointmentData?.patient?.user_id as number,
        provider_id: appointmentData?.provider?.user_id as number,
        starts: appointmentData?.starts as number,
        ends: appointmentData.ends as number,
        status: METRICS.CHECKED_OUT as AppointmentStatus
      }
    })
      .unwrap()
      .then(() => {
        setCheckoutClicked(true);
        dispatch(
          addAlertToToastTrough({
            message: "Appointment succesfully updated",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Oops! Appointment could not be updated",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleViewSuperbill = () => {
    // open new tab with superbill
    const url =
      router.asPath.replace(
        `${router.pathname}`,
        `${PAGES.ENCOUNTERS}/${appointmentData.encounter_id}${PAGES.SUPERBILL}`
      ) +
      `?pId=${appointmentData.patient?.user_id}&eId=${appointmentData.encounter_id}`;

    window.open(url, "_blank");
  };
  const handleAddCpt = (cptId: number) => {
    if (!encounter) return;
    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType
    };

    let codings = encounter.codings || [];
    // add the new coding object if it doesn't exist
    let coding = codings.find(coding => coding.cpt_id === cptId);
    if (!coding) {
      coding = {
        cpt_id: cptId
      };
      codings = [...codings, coding];
    }

    encounterCreateUpdatePayload.codings = codings;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {})
      .catch(() => {
        console.error("Error adding cpt");
      });
  };

  const handleAddICD10 = (cptId: number, icd10Id: number) => {
    if (!encounter) return;

    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType
    };

    let codings = encounter.codings || [];
    // add the new coding object if it doesn't exist
    let coding = codings.find(coding => coding.cpt_id === cptId);
    if (!coding) {
      coding = {
        cpt_id: cptId,
        icd10_ids: [icd10Id]
      };
      codings.push(coding);
    } else {
      // if the coding object exists but does not have any icd10codes, create the array and add the icd10code
      if (!coding.icd10_ids) {
        coding = {
          ...coding,
          icd10_ids: [icd10Id]
        };
        // replace the old coding object with the new one
        codings = codings.map(c => (c.cpt_id === cptId ? coding : c) as Coding);
      } else {
        // if the coding object exists and has icd10codes, add the new icd10code if it isn't already in the array
        if (!coding.icd10_ids.includes(icd10Id)) {
          coding = { ...coding, icd10_ids: [...coding.icd10_ids, icd10Id] };
          // replace the old coding object with the new one
          codings = codings.map(
            c => (c.cpt_id === cptId ? coding : c) as Coding
          );
        }
      }
    }

    encounterCreateUpdatePayload.codings = codings;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {})
      .catch(() => {
        console.error("Error adding icd10");
      });
  };

  const handleRemoveICD10 = (cptId: number, icd10Id: number) => {
    if (!encounter) return;

    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType
    };

    let codings = encounter.codings || [];
    // add the new coding object if it doesn't exist
    let coding = codings.find(coding => coding.cpt_id === cptId);
    if (coding && coding.icd10_ids) {
      coding = {
        ...coding,
        icd10_ids: coding.icd10_ids.filter(id => id !== icd10Id)
      };
      // replace the old coding object with the new one
      codings = codings.map(c => (c.cpt_id === cptId ? coding : c) as Coding);
    }

    encounterCreateUpdatePayload.codings = codings;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {})
      .catch(() => {
        console.error("Error removing icd10");
      });
  };

  // remove mofier handler
  const handleRemoveModifier = (cptId: number, modifier: number) => {
    if (!encounter) return;

    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType
    };

    let codings = encounter.codings || [];
    // add the new coding object if it doesn't exist
    let coding = codings.find(coding => coding.cpt_id === cptId);
    if (coding && coding.modifier_ids) {
      coding = {
        ...coding,
        modifier_ids: coding.modifier_ids.filter(mod => mod !== modifier)
      };
      // replace the old coding object with the new one
      codings = codings.map(c => (c.cpt_id === cptId ? coding : c) as Coding);
    }

    encounterCreateUpdatePayload.codings = codings;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {})
      .catch(() => {
        console.error("Error removing  modifier");
      });
  };

  const handleDeleteCpt = (cptId: number) => {
    if (!encounter) return;

    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType
    };

    let codings = encounter.codings || [];
    // remove the coding object if it exists
    codings = codings.filter(coding => coding.cpt_id !== cptId);

    encounterCreateUpdatePayload.codings = codings;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {})
      .catch(() => {
        console.error("Error adding modifier");
      });
  };

  const handleAddModifier = (cptId: number, modifier: number) => {
    if (!encounter) return;

    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType
    };

    let codings = encounter.codings || [];
    // add the new coding object if it doesn't exist
    let coding = codings.find(coding => coding.cpt_id === cptId);
    if (!coding) {
      coding = {
        cpt_id: cptId,
        modifier_ids: [modifier]
      };
      codings = [...codings, coding];
    } else {
      // if the coding object exists but does not have any modifiers, create the array and add the modifier
      if (!coding.modifier_ids) {
        coding = {
          ...coding,
          modifier_ids: [modifier]
        };
        // replace the old coding object with the new one
        codings = codings.map(c => (c.cpt_id === cptId ? coding : c) as Coding);
      } else {
        // if the coding object exists and has modifiers, add the new modifier if it isn't already in the array
        if (!coding.modifier_ids.includes(modifier)) {
          coding = {
            ...coding,
            modifier_ids: [...coding.modifier_ids, modifier]
          };
          // replace the old coding object with the new one
          codings = codings.map(
            c => (c.cpt_id === cptId ? coding : c) as Coding
          );
        }
      }
    }

    encounterCreateUpdatePayload.codings = codings;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {})
      .catch(() => {
        console.error("Error adding modifier");
      });
  };

  return (
    <div className={clsx(styles.PatientCheckout)}>
      {/* Patient Checkout */}
      <BasicAccordion
        title="Patient Checkout"
        style={STYLES.TERTIARY}
        open={isOpen}
      >
        {(appointmentData.status === METRICS.CHECKED_OUT ||
          checkoutClicked) && (
          <div className={styles.completedAlert}>
            <Icon svg="check_success_outline" width={20} height={20} />
            Checkout Complete
          </div>
        )}
        <div className={styles.checkoutGrid}>
          {!checkoutComplete && (
            <>
              <div className={clsx(styles.span2, styles.sectionTitle)}>
                Service Charges
              </div>
              <div className={styles.header}>
                {[
                  "CPT Code",
                  "ICD10 Codes",
                  "Modifiers",
                  "List Price",
                  "Sales Price",
                  ""
                ].map((title, index) => (
                  <div key={index} className={styles.headers}>
                    {title}
                  </div>
                ))}
              </div>

              {/* select CPT code first */}
              {encounterCodings?.map(coding => (
                <Fragment key={coding.cpt_id}>
                  <div className={styles.cptRow}>
                    <Tag
                      label={cptDetails?.[coding.cpt_id as number]?.cpt_code}
                      type={STATUS_KEYS.INFO_GREY}
                    />
                    <p className="t4 ">
                      {cptDetails?.[coding.cpt_id as number]?.description}
                    </p>
                  </div>
                  <div className={styles.chargeRow}>
                    <div>
                      <ComboboxSelect
                        label="ICD10 Codes"
                        placeholder={checkoutComplete ? "" : "Search ICD10s"}
                        name={`${coding.cpt_id}-icd10-code-dropdown`}
                        options={icd10SearchResults || []}
                        hiddenLabel
                        labelAcc={item =>
                          `${item?.name} - ${item?.description}`
                        }
                        onChange={icd10 => {
                          if (icd10) {
                            handleAddICD10(
                              coding.cpt_id as number,
                              icd10.icd_id
                            );
                          }
                        }}
                        clearOnSelect
                        onSearch={term => {
                          if (term.length >= 3) searchICD10({ term });
                        }}
                        isDisabled={
                          appointmentData.status === METRICS.CHECKED_OUT ||
                          checkoutClicked
                        }
                      />
                      <div className={styles.pillWrapper}>
                        {coding.icd10_ids?.map(icd10 => (
                          <Tag
                            key={icd10}
                            label={icd10Details?.[icd10]?.name}
                            type={STATUS_KEYS.INFO_GREY}
                            onRemove={
                              appointmentData.status === METRICS.CHECKED_OUT ||
                              checkoutClicked
                                ? undefined
                                : () =>
                                    handleRemoveICD10(
                                      coding.cpt_id as number,
                                      icd10
                                    )
                            }
                            dataCyRemove={`icd10-${icd10}-remove`}
                          />
                        ))}
                      </div>
                    </div>
                    <div>
                      <ComboboxSelect
                        label="Modifiers"
                        placeholder={checkoutComplete ? "" : "Search modifiers"}
                        name={`${coding.cpt_id}-modifier-code-dropdown`}
                        options={modiferSearchResults || []}
                        labelAcc={item =>
                          `${item?.modifier_code} - ${item?.modifier_description}`
                        }
                        hiddenLabel
                        onChange={mod => {
                          if (mod)
                            handleAddModifier(
                              coding.cpt_id as number,
                              mod.modifier_id
                            );
                        }}
                        clearOnSelect
                        isDisabled={
                          appointmentData.status === METRICS.CHECKED_OUT ||
                          checkoutClicked
                        }
                        onSearch={term => {
                          if (term.length >= 2) searchCPTModifiers({ term });
                        }}
                      />
                      <div className={styles.pillWrapper}>
                        {coding.modifier_ids?.map((modifier, index) => (
                          <Tag
                            key={index}
                            label={
                              cptModifierDetails?.[modifier]?.modifier_code
                            }
                            type={STATUS_KEYS.INFO_GREY}
                            onRemove={
                              appointmentData.status === METRICS.CHECKED_OUT ||
                              checkoutClicked
                                ? undefined
                                : () =>
                                    handleRemoveModifier(
                                      coding.cpt_id as number,
                                      modifier
                                    )
                            }
                            dataCyRemove={`modifier-${modifier}-remove`}
                          />
                        ))}
                      </div>
                    </div>

                    <div>
                      <Controller
                        name={`cpt-${coding.cpt_id}-listPrice`}
                        control={control}
                        defaultValue={
                          chargemasterEntries?.find(
                            cptCode =>
                              cptDetails?.[coding.cpt_id as number]
                                ?.cpt_code === cptCode.cpt_code
                          )?.amount || 0
                        }
                        render={({ field }) => (
                          <Input
                            {...field}
                            id={`cpt-${coding.cpt_id as number}-listPrice`}
                            name={`cpt-${coding.cpt_id as number}-listPrice`}
                            type="number"
                            label="List Price"
                            hiddenLabel
                            disabled={
                              appointmentData.status === METRICS.CHECKED_OUT ||
                              checkoutClicked
                            }
                            fullWidth
                            onChange={v => {
                              field.onChange(v);

                              debounceUpdateTotalListPrice();
                            }}
                          />
                        )}
                      />
                      <p className="t5">
                        Base price: $
                        {chargemasterEntries?.find(
                          cptCode =>
                            cptDetails?.[coding.cpt_id as number]?.cpt_code ===
                            cptCode.cpt_code
                        )?.amount || 0}
                      </p>
                    </div>
                    <Controller
                      name={`cpt-${coding.cpt_id as number}-salesPrice`}
                      control={control}
                      defaultValue={
                        chargemasterEntries?.find(
                          cptCode =>
                            cptDetails?.[coding.cpt_id as number]?.cpt_code ===
                            cptCode.cpt_code
                        )?.amount || 0
                      }
                      render={({ field }) => (
                        <Input
                          {...field}
                          id={`cpt-${coding.cpt_id as number}-salesPrice`}
                          type="number"
                          label="Sales Price"
                          disabled={
                            appointmentData.status === METRICS.CHECKED_OUT ||
                            checkoutClicked
                          }
                          fullWidth
                          hiddenLabel
                          onChange={v => {
                            field.onChange(v);

                            debounceUpdateTotalSalesPrice();
                          }}
                        />
                      )}
                    />
                    <Button
                      style={STYLES.SECONDARY}
                      onClick={() => {
                        handleDeleteCpt(coding.cpt_id as number);
                      }}
                      nativeButtonProps={{ disabled: checkoutComplete }}
                    >
                      <Icon svg="trash_grey" />
                    </Button>
                  </div>
                </Fragment>
              ))}
              <div className={clsx(styles.addCptRow)}>
                {
                  <ComboboxSelect
                    label="Add new CPT code"
                    placeholder="Search CPTs"
                    name="cpt-code-dropdown"
                    options={cptSearchResults || []}
                    labelAcc={(item: CptCodeDetails) =>
                      `${item.cpt_code} - ${item.description}`
                    }
                    onChange={(cpt: CptCodeDetails) => {
                      if (cpt) {
                        handleAddCpt(cpt.cpt_id);
                      }
                    }}
                    clearOnSelect
                    onSearch={term => {
                      searchCPT({ term });
                    }}
                    debounce
                  />
                }
              </div>
              <div></div>

              <div className={styles.cptRow}>
                <div className={styles.total}>Total</div>
                <div />
              </div>
              {/* total charge amount */}
              {/* calculated from the sum of all charge amounts */}
              <div className={styles.chargeRow}>
                <div />
                <div />
                <div className={styles.total}>
                  <span className="tMd">${totalListPrice}</span>
                  <p className="t5">Total List Price</p>
                </div>

                <div className={styles.total}>
                  <span className="tMd">${totalSalesPrice}</span>
                  <p className="t5">Total Sales Price</p>
                </div>
                {/* /* charge all button */}
                <div />
                <div />
              </div>
            </>
          )}
          <div className={clsx(styles.span2, styles.sectionTitle)}>
            {checkoutComplete ? "Processed Transactions" : "queued Charges"}
          </div>
        </div>
        {checkoutComplete ? (
          <div className={styles.transactions}>
            <Datagrid
              // @ts-ignore
              data={transactionData}
              gridType={METRICS.PAYMENTS}
              style={STYLES.SECONDARY}
              isLoading={transactionsLoading}
              isEmpty={!transactionData || !transactionData.length}
              customEmptyMessage="No transactions posted for this charge"
            />
          </div>
        ) : (
          <>
            <div className={clsx(styles.headers, styles.queuedTransactions)}>
              <div className="t5 tMd">CPT Code</div>
              <div />
              <div />
              <div className="t5 tMd">List Price</div>
              <div className="t5 tMd">Sales Price</div>
              <div />
            </div>
            <div className={styles.queuedTransactions}>
              {/* map over CPT codes and display them along with list price and sales price */}
              {encounter?.codings?.map(coding => (
                <Fragment key={coding.cpt_id}>
                  <Tag
                    label={cptDetails?.[coding.cpt_id as number]?.cpt_code}
                    type={STATUS_KEYS.INFO_GREY}
                  />
                  <div />
                  <div />
                  <div>
                    ${getValues()[`cpt-${coding.cpt_id}-listPrice`] || 0}
                  </div>
                  <div>
                    ${getValues()[`cpt-${coding.cpt_id}-salesPrice`] || 0}
                  </div>
                  <div />
                </Fragment>
              ))}
              <>
                <div />
                <div />
                <div />
                <div className={styles.total}>
                  <span className="tMd">${totalListPrice}</span>
                  <p className="t5">Total List Price</p>
                </div>
                <div className={styles.total}>
                  <span className="tMd">${totalSalesPrice}</span>
                  <p className="t5">Total Sales Price</p>
                </div>
                <div />
              </>
            </div>
          </>
        )}

        {checkoutComplete && (
          <div className={styles.patientBalance}>
            <div>Outstanding Balance</div>
            {+(patientBalance || 0) < 0 ? `$0.00` : `$${patientBalance}`}{" "}
          </div>
        )}
        {!checkoutComplete && (
          <div className={styles.checkoutGrid}>
            <div className={clsx(styles.span2, styles.sectionTitle)}>
              Patient Payment
            </div>
            <div className={clsx(styles.paymentRow, styles.span2)}>
              <Controller
                name="date_of_service"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="date"
                    id="date_of_service"
                    name="date_of_service"
                    label="Date of Service"
                    fullWidth
                    required
                    value={dayjs().format("YYYY-MM-DD")}
                    disabled={checkoutComplete}
                  />
                )}
              />
              <Controller
                name={`paymentAmount`}
                control={control}
                defaultValue={totalSalesPrice}
                render={({ field }) => (
                  <Input
                    {...field}
                    id={`paymentAmount`}
                    type="number"
                    label="Payment Amount"
                    disabled={checkoutComplete}
                  />
                )}
              />
              {/* payment type */}
              <Controller
                name="payment_type"
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <ComboboxSelect
                    {...field}
                    options={PAYMENT_METHODS}
                    label="Payment Type"
                    labelAcc={item => METRIC_LABELS[item]}
                    fullWidth
                    initialValue={PAYMENT_METHODS[0]}
                    isDisabled={checkoutComplete}
                  />
                )}
              />
              <Controller
                name="payment_note"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="string"
                    name="payment_note"
                    id="payment_note"
                    label="Note"
                    disabled={checkoutComplete}
                    fullWidth
                  />
                )}
              />
            </div>
          </div>
        )}
        <div className="grid2 padded">
          <Button
            style={checkoutComplete ? STYLES.SUCCESS : STYLES.FULL_WIDTH}
            onClick={handleCompleteCheckout}
            nativeButtonProps={{
              disabled:
                appointmentData.status === METRICS.CHECKED_OUT ||
                checkoutClicked
            }}
          >
            {checkoutComplete ? "Checkout Complete" : "Complete Checkout"}
          </Button>
          <Button
            style={STYLES.SECONDARY_FULL}
            onClick={handleViewSuperbill}
            nativeButtonProps={{
              disabled: !encounter?.codings || encounter?.codings?.length < 1
            }} // no bill if no codings
          >
            <Icon svg="file_highlighted" width={20} height={20} />
            View Superbill
          </Button>
        </div>
      </BasicAccordion>
    </div>
  );
}
