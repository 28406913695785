import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    encounterCreate: build.mutation<
      EncounterCreateApiResponse,
      EncounterCreateApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.patientId}/${queryArg.providerId}/create`,
        method: "POST",
        body: queryArg.encounterCreateUpdatePayload
      })
    }),
    encounterCreateFromAppointment: build.mutation<
      EncounterCreateFromAppointmentApiResponse,
      EncounterCreateFromAppointmentApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.appointmentId}/create`,
        method: "POST"
      })
    }),
    encounterList: build.query<EncounterListApiResponse, EncounterListApiArg>({
      query: queryArg => ({
        url: `/encounter/${queryArg.id}/${queryArg.scope}/list`,
        params: {
          start: queryArg.start,
          end: queryArg.end,
          page: queryArg.page,
          pagesz: queryArg.pagesz,
          superbill_status: queryArg.superbillStatus,
          encounter_status: queryArg.encounterStatus,
          encounter_type: queryArg.encounterType
        }
      })
    }),
    encounterUpdate: build.mutation<
      EncounterUpdateApiResponse,
      EncounterUpdateApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/update`,
        method: "POST",
        body: queryArg.encounterCreateUpdatePayload
      })
    }),
    encounterListVersions: build.query<
      EncounterListVersionsApiResponse,
      EncounterListVersionsApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/versions`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    encounterInfo: build.query<EncounterInfoApiResponse, EncounterInfoApiArg>({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}`,
        params: { version: queryArg.version }
      })
    }),
    encounterSubmit: build.mutation<
      EncounterSubmitApiResponse,
      EncounterSubmitApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/${queryArg.version}/submit`,
        method: "POST"
      })
    }),
    encounterDelete: build.mutation<
      EncounterDeleteApiResponse,
      EncounterDeleteApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/delete`,
        method: "DELETE"
      })
    }),
    encounterExport: build.query<
      EncounterExportApiResponse,
      EncounterExportApiArg
    >({
      query: queryArg => ({ url: `/encounter/${queryArg.encounterId}/export` })
    }),
    encounterLaborInfo: build.query<
      EncounterLaborInfoApiResponse,
      EncounterLaborInfoApiArg
    >({
      query: queryArg => ({ url: `/encounter/${queryArg.encounterId}/labor` })
    }),
    encounterLaborSheet: build.query<
      EncounterLaborSheetApiResponse,
      EncounterLaborSheetApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/sheet`
      })
    }),
    encounterLaborUpsert: build.mutation<
      EncounterLaborUpsertApiResponse,
      EncounterLaborUpsertApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/upsert`,
        method: "POST",
        body: queryArg.laborModel
      })
    }),
    encounterLaborEventUpsert: build.mutation<
      EncounterLaborEventUpsertApiResponse,
      EncounterLaborEventUpsertApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/${queryArg.stage}/${queryArg.laborEvent}/upsert`,
        method: "PUT",
        body: queryArg.laborEventUpdate
      })
    }),
    encounterLaborEventDelete: build.mutation<
      EncounterLaborEventDeleteApiResponse,
      EncounterLaborEventDeleteApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/${queryArg.stage}/${queryArg.laborEvent}/delete`,
        method: "DELETE"
      })
    }),
    encounterLaborRecurringEventUpdate: build.mutation<
      EncounterLaborRecurringEventUpdateApiResponse,
      EncounterLaborRecurringEventUpdateApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/${queryArg.stage}/${queryArg.scope}/${queryArg.laborEventId}`,
        method: "PUT",
        body: queryArg.laborEventUpdate
      })
    }),
    encounterLaborRecurringEventCreate: build.mutation<
      EncounterLaborRecurringEventCreateApiResponse,
      EncounterLaborRecurringEventCreateApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/${queryArg.stage}/${queryArg.scope}/create`,
        method: "PUT",
        body: queryArg.laborEventCreate
      })
    }),
    encounterLaborRecurringEventDelete: build.mutation<
      EncounterLaborRecurringEventDeleteApiResponse,
      EncounterLaborRecurringEventDeleteApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/${queryArg.stage}/${queryArg.scope}/${queryArg.laborEventId}/delete`,
        method: "DELETE"
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as encounter };
export type EncounterCreateApiResponse =
  /** status 200 Sent in response to a successful encounter create API call.
   */ EncounterId;
export type EncounterCreateApiArg = {
  patientId: UserId;
  providerId: UserId;
  /** Payload for creating/updating an encounter.
   */
  encounterCreateUpdatePayload: EncounterCreateUpdatePayload;
};
export type EncounterCreateFromAppointmentApiResponse =
  /** status 200 Sent in response to a successful encounter create API call.
   */ EncounterId;
export type EncounterCreateFromAppointmentApiArg = {
  /** System-assigned ID for an appointment.
   */
  appointmentId: AppointmentId;
};
export type EncounterListApiResponse =
  /** status 200 Abbreviated list of patients suitable for dropdown list population.
   */ EncounterInfo[];
export type EncounterListApiArg = {
  id: Id;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  /** Start date for searching for a range of dates, including this date.
   */
  start?: Timestamp;
  /** End date for searching for a range of dates, including this date.
   */
  end?: Timestamp;
  page?: Page;
  pagesz?: PageSize;
  /** The status of the superbill.
   */
  superbillStatus?: SuperbillStatus;
  encounterStatus?: EncounterStatus;
  encounterType?: EncounterType;
};
export type EncounterUpdateApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type EncounterUpdateApiArg = {
  encounterId: EncounterId;
  /** Payload for creating/updating an encounter.
   */
  encounterCreateUpdatePayload: EncounterCreateUpdatePayload;
};
export type EncounterListVersionsApiResponse =
  /** status 200 List of all changes to an encounter (version history).
   */ EncounterVersions;
export type EncounterListVersionsApiArg = {
  encounterId: EncounterId;
  page?: Page;
  pagesz?: PageSize;
};
export type EncounterInfoApiResponse =
  /** status 200 Full details of a specific encounter version / event.
   */ EncounterInfo;
export type EncounterInfoApiArg = {
  encounterId: EncounterId;
  version?: Version;
};
export type EncounterSubmitApiResponse =
  /** status 200 Response to submitting an encounter.
   */ EncounterInfo;
export type EncounterSubmitApiArg = {
  encounterId: EncounterId;
  version: Version;
};
export type EncounterDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type EncounterDeleteApiArg = {
  encounterId: EncounterId;
};
export type EncounterExportApiResponse =
  /** status 200 The encounter export PDF file.
   */ EncounterExport200Response;
export type EncounterExportApiArg = {
  encounterId: EncounterId;
};
export type EncounterLaborInfoApiResponse =
  /** status 200 Provides the current state of a pregnancy's labor.
   */ LaborModel;
export type EncounterLaborInfoApiArg = {
  encounterId: EncounterId;
};
export type EncounterLaborSheetApiResponse =
  /** status 200 Provides the current state of a pregnancy's labor sheet.
   */ LaborSheet;
export type EncounterLaborSheetApiArg = {
  encounterId: EncounterId;
};
export type EncounterLaborUpsertApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type EncounterLaborUpsertApiArg = {
  encounterId: EncounterId;
  /** Payload for creating/updating a labor.
   */
  laborModel: LaborModel;
};
export type EncounterLaborEventUpsertApiResponse =
  /** status 200 Provides the current state of the upserted event.
   */ LaborEventUpsertResponse;
export type EncounterLaborEventUpsertApiArg = {
  encounterId: EncounterId;
  stage: string;
  laborEvent: string;
  /** Payload for updating a labor.
   */
  laborEventUpdate: LaborEventUpdate;
};
export type EncounterLaborEventDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type EncounterLaborEventDeleteApiArg = {
  encounterId: EncounterId;
  stage: string;
  laborEvent: string;
};
export type EncounterLaborRecurringEventUpdateApiResponse =
  /** status 200 Provides the current state of the upserted event.
   */ LaborEventUpsertResponse;
export type EncounterLaborRecurringEventUpdateApiArg = {
  encounterId: EncounterId;
  stage: string;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  laborEventId: string;
  /** Payload for updating a labor.
   */
  laborEventUpdate: LaborEventUpdate;
};
export type EncounterLaborRecurringEventCreateApiResponse =
  /** status 200 Provides the current state of the upserted event.
   */ LaborEventUpsertResponse;
export type EncounterLaborRecurringEventCreateApiArg = {
  encounterId: EncounterId;
  stage: string;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  /** Payload for creating a labor.
   */
  laborEventCreate: LaborEventCreate;
};
export type EncounterLaborRecurringEventDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type EncounterLaborRecurringEventDeleteApiArg = {
  encounterId: EncounterId;
  stage: string;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  laborEventId: string;
};
export type EncounterId = number;
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type UserId = number;
export type EncounterType =
  | "WELL_WOMAN"
  | "PRENATAL"
  | "LABOR"
  | "POSTPARTUM"
  | "INFANT"
  | "PHONE";
export type HgLabTestId = string;
export type DiagnosisInfo = {
  cpt: number[];
  lab_tests?: HgLabTestId[];
  prescriptions?: number[];
};
export type EncounterDiagnoses = {
  [key: string]: DiagnosisInfo;
};
export type CptModifierId = number;
export type BillRecipients =
  | "PATIENT"
  | "PRIMARY_INSURANCE"
  | "SECONDARY_INSURANCE"
  | "TERTIARY_INSURANCE";
export type Coding = {
  cpt_id?: number;
  is_billable?: boolean;
  modifier_ids?: CptModifierId[];
  icd10_ids?: number[];
  units?: number;
  place_of_service?: string;
  type_of_service?: string;
  bill_recipient?: BillRecipients;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  fee?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  charge?: string;
  comment?: string;
};
export type Codings = Coding[];
export type EncounterStatus = "IN_PROGRESS" | "SUBMITTED" | "DELETED";
export type LocationId = number;
export type AltLocation = string;
export type EncounterLocation = LocationId | AltLocation;
export type VitalsInfo = {
  /** Height of a person in CM. This is always stored as rounded-up centimeters although the visual presentation may
    be in feet and inches, or centimeters with a fractional part, but this is how it is stored.
     */
  height?: number;
  /** Weight of a non-infant. This is stored as kilograms.
   */
  weight?: number;
  /** Temperature in Fahrenheit.
   */
  temperature?: number;
  blood_pressure?: string;
  heart_rate?: string;
};
export type InfantGrowthMeasurements = {
  date?: string;
  /** The weight of an infant, in grams. This differs from the mothers weight which is in kilograms. The actual
    visual display may be converted to ounces, or kilograms with a fractional part, but this is how it is stored.
     */
  weight_grams?: number;
  standing_height_cm?: number;
  recumbent_length_cm?: number;
  head_circumference_cm?: number;
  respiratory_rate?: number;
  blood_pressure?: string;
  /** Temperature in Fahrenheit.
   */
  temperature?: number;
  pulse?: number;
};
export type EncounterMaternityNeighborhoodMetadata = {
  episode_id?: string;
  encounter_id?: number;
};
export type EncounterCreateUpdatePayload = {
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id: number;
  encounter_type: EncounterType;
  /** A ulid for an appointment type. */
  appointment_type_id?: string;
  note?: string;
  diagnoses?: EncounterDiagnoses;
  codings?: Codings;
  status?: EncounterStatus;
  type_specific?: object;
  related?: EncounterId[];
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  location?: EncounterLocation;
  vitals?: VitalsInfo;
  infant_growth_measurements?: InfantGrowthMeasurements;
  mn_metadata?: EncounterMaternityNeighborhoodMetadata;
  is_billable?: boolean;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
};
export type AppointmentId = number;
export type ParentIds = UserId[];
export type Sex = "MALE" | "FEMALE" | "UNKNOWN";
export type Statecode =
  | "AK"
  | "AL"
  | "AR"
  | "AZ"
  | "CA"
  | "CO"
  | "CT"
  | "DC"
  | "DE"
  | "FL"
  | "GA"
  | "HI"
  | "IA"
  | "ID"
  | "IL"
  | "IN"
  | "KS"
  | "KY"
  | "LA"
  | "MA"
  | "MD"
  | "ME"
  | "MI"
  | "MN"
  | "MO"
  | "MS"
  | "MT"
  | "NC"
  | "ND"
  | "NE"
  | "NH"
  | "NJ"
  | "NM"
  | "NV"
  | "NY"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "PR"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VA"
  | "VI"
  | "VT"
  | "WA"
  | "WI"
  | "WV"
  | "WY";
export type Address = {
  /** Address line 1. Mandatory. This can include the street number.
   */
  line1?: string;
  /** Optional line 2 of 3 for an address.
   */
  line2?: string;
  /** Optional line 3 of 3 for an address.
   */
  line3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  /** This is the optional, human-readable representation of the address. This is used
    when communicating addresses that come from sources that don't parse their addresses
    down into its constituent parts, like Health Gorilla.
     */
  display?: string;
};
export type ProblemStatus = "ACTIVE" | "ARCHIVED" | "DELETED";
export type ProblemType = "NOTE" | "DIAGNOSIS";
export type ProblemInfo = {
  /** System-assigned identifier for a problem.
   */
  id: number;
  type: ProblemType;
  status: ProblemStatus;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  date_of_onset?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  created_by: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  archived?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  archived_by?: number;
};
export type Icd10CodeDetails = {
  /** The system-generated ID for the ICD-10 code. We always use this ID and not the ICD10 code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  icd_id: number;
  /** The actual ICD-10 code as provided by the WHO (no, not the rock band).
   */
  name: string;
  /** The human-readable description of the code.
   */
  description: string;
};
export type ProblemNote = {
  /** Optional note limited to 4K in length.
   */
  content: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  at: number;
};
export type ProblemDiagnosisDetails = {
  diagnosis: Icd10CodeDetails;
  note?: ProblemNote;
};
export type ProblemDiagnosisInfo = ProblemInfo & ProblemDiagnosisDetails;
export type PatientProblemDiagnosis = {
  /** The actual ICD-10 code as provided by the WHO (no, not the rock band).
   */
  name: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  lastUpdated: number;
  status: ProblemStatus;
  title: string;
  instances: ProblemDiagnosisInfo[];
};
export type PatientProblemDiagnoses = PatientProblemDiagnosis[];
export type ProblemNoteDetails = {
  title: string;
  note: ProblemNote;
};
export type ProblemNoteInfo = ProblemInfo & ProblemNoteDetails;
export type PatientProblemNotes = ProblemNoteInfo[];
export type PatientProblems = {
  diagnoses: PatientProblemDiagnoses;
  notes: PatientProblemNotes;
};
export type RelationshipToSubscriber = "SELF" | "SPOUSE" | "GUARDIAN" | "OTHER";
export type Insurance = {
  insurance_id?: string;
  insurance_id_type?: 1 | 2 | 3 | 4 | 5 | 6;
  payor?: string;
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  hg_org_id?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  payor_phone?: string;
  payor_address?: Address;
  plan_type?: string;
  policy_or_group_number?: string;
  subscriber_id?: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  subscriber_first_name?: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  subscriber_last_name?: string;
  /** A Pario date in YYYYMMDD format.
   */
  subscriber_dob?: number;
  /** Standard US social security number in XXX-XX-XXXX format.
   */
  subscriber_ssn?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  subscriber_phone?: string;
  subscriber_address?: Address;
  relationship_to_subscriber?: RelationshipToSubscriber;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  guarantor_first_name?: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  guarantor_last_name?: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  guarantor_addr_line_1?: string;
  /** Optional line 2 of 3 for an address.
   */
  guarantor_addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  guarantor_addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  guarantor_city?: string;
  guarantor_state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  guarantor_zip?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  guarantor_phone?: string;
};
export type UserInsurance = {
  self_pay?: boolean;
  primary?: Insurance;
  secondary?: Insurance;
  tertiary?: Insurance;
};
export type EddMethod = "LMP" | "ULTRASOUND" | "DATE_OF_CONCEPTION" | "UNKNOWN";
export type EstimatedDueDate = {
  method: EddMethod;
  estimated_due_date?: string;
  date_of_service?: string;
  date_of_method?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id?: number;
  estimate_id?: string;
  comment?: string;
  lmp_cycle_length_days?: number;
  file_id?: number;
};
export type Lmp = {
  last_menstrual_period?: string;
  estimate_id?: string;
};
export type PregnancyState =
  | "GESTATING"
  | "DELIVERED"
  | "MISCARRIED"
  | "STILLBORN"
  | "ECTOPIC"
  | "TERMINATED"
  | "POSTPARTUM"
  | "UNSPECIFIED"
  | "DELETED";
export type PrenatalFlowSheetEntry = {
  weeks?: string;
  days?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  encounter_start?: number;
  weight?: number;
  blood_pressure?: string;
  temperature?: number;
  fundus?: string;
  fundal_height?: string;
  fetal_heart_tones?: string;
  fetal_position?: string;
  fetal_activity?: string;
  edema?: string;
  pulse?: number;
  note?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
};
export type PrenatalFlowSheet = PrenatalFlowSheetEntry[];
export type GbsStatus = "POSITIVE" | "NEGATIVE" | "DECLINED";
export type LabOrderId = string;
export type LabOrders = LabOrderId[];
export type UserIdList = UserId[];
export type LaborEvent = {
  event_id: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  authors?: UserIdList;
  forms?: object;
};
export type LaborStage = {
  events: {
    [key: string]: LaborEvent;
  };
  progress_updates: LaborEvent[];
  medications: LaborEvent[];
  infant_progress_updates?: LaborEvent[];
  infant_medications?: LaborEvent[];
  recurring_events?: LaborEvent[];
  notes: LaborEvent[];
};
export type LaborModel = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  transfer?: object;
  stages: {
    [key: string]: LaborStage;
  };
  note?: string;
};
export type PregnancyOutcome =
  | "UNKNOWN"
  | "TAB (therapeautic abortion)"
  | "EAB (elective abortion)"
  | "SAB (miscarriage)"
  | "SBVD (stillbirth vaginal delivery)"
  | "SBCS (stillbirth cesarean delivery)"
  | "NSVD (vaginal delivery)"
  | "NSVB (vaginal birth)"
  | "VAVD (vacuum vaginal delivery)"
  | "FAVD (forceps vaginal delivery)"
  | "CS (cesarean)"
  | "VBAC (vaginal birth after previous cesarean)"
  | "NSVD (vaginal delivery) - Water"
  | "VBAC (vaginal birth after previous cesarean) - Water"
  | "EP (ectopic pregnancy)";
export type MaternityNeighborhoodPregnancyMetadata = {
  episode_of_care_id?: string;
  past_pregnancy_id?: string;
};
export type ClientCarePregnancyMetadata = {
  pregnancy_id?: string;
};
export type PregnancyMetadata = {
  maternity_neighborhood?: MaternityNeighborhoodPregnancyMetadata;
  client_care?: ClientCarePregnancyMetadata;
};
export type Pregnancy = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  edd?: EstimatedDueDate;
  lmp?: Lmp;
  state: PregnancyState;
  flow_sheet: PrenatalFlowSheet;
  /** Where the patient would like to have labor.
   */
  desired_birthplace?: string;
  gbs_status?: GbsStatus;
  lab_orders?: LabOrders;
  labor?: LaborModel;
  /** A Pario date in YYYYMMDD format.
   */
  delivery_date?: number;
  /** The gestational age of the baby at the time of delivery, measured
    in days.
     */
  gestational_age_at_delivery?: number;
  /** The length of labor in minutes.
   */
  length_of_labor_minutes?: number;
  /** The weight of an infant, in grams. This differs from the mothers weight which is in kilograms. The actual
    visual display may be converted to ounces, or kilograms with a fractional part, but this is how it is stored.
     */
  weight?: number;
  sex?: Sex;
  name?: string;
  living?: boolean;
  outcome?: PregnancyOutcome;
  /** Where the pregnancy was concluded.
   */
  birthplace?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  infant_id?: number;
  comment?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  pregnancy_completion_time?: number;
  metadata: PregnancyMetadata;
};
export type PatientHgMetadata = {
  /** A URL identifying a FHIR-R4 Resource in Health Gorilla's systems.
   */
  resource_url?: string;
};
export type PatientLocations = LocationId[];
export type MeridianMetadata = {
  meridian_id: string;
};
export type PatientMetadata = {
  health_gorilla?: PatientHgMetadata;
  locations: PatientLocations;
  meridian?: MeridianMetadata;
};
export type PatientType =
  | "PROSPECTIVE"
  | "OB"
  | "GYN"
  | "INACTIVE"
  | "INFANT"
  | "PEDIATRIC"
  | "POSTPARTUM"
  | "LACTATION"
  | "PRIMARY_CARE";
export type PracticeData = {
  type: PatientType;
};
export type BloodType =
  | "A+"
  | "A-"
  | "B+"
  | "B-"
  | "AB+"
  | "AB-"
  | "O+"
  | "O-"
  | "UNKNOWN";
export type Diabetes = "TYPE_1" | "TYPE_2" | "GESTATIONAL" | "NONE";
export type HivStatus = "POSITIVE" | "NEGATIVE" | "UNKNOWN";
export type AllergySeverity = "MILD" | "MODERATE" | "SEVERE" | "FATAL";
export type Allergy = {
  icd10_code_details?: Icd10CodeDetails;
  name?: string;
  reaction?: string;
  severity?: AllergySeverity;
  notes?: string;
  date_of_onset?: string;
  allergy_id?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
};
export type Allergies = Allergy[];
export type Immunization = {
  name: string;
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt: number;
  /** A Pario date in YYYYMMDD format.
   */
  date?: number;
  notes?: string;
};
export type Immunizations = Immunization[];
export type MedicationStatus =
  | "ACTIVE"
  | "INACTIVE"
  | "NONCOMPLIANT"
  | "DELETED";
export type Medication = {
  name: string;
  dosage?: string;
  comment?: string;
  status: MedicationStatus;
  /** A Pario date in YYYYMMDD format.
   */
  start?: number;
  /** A Pario date in YYYYMMDD format.
   */
  end?: number;
  lexicomp_product_id?: number;
  lexicomp_drug_id?: string;
  lexicomp_synonym_id?: number;
  lexicomp_synonym_type?: number;
  dosespot_id?: number;
  days_supply?: number;
  is_prescription?: boolean;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
};
export type Medications = {
  [key: string]: Medication;
};
export type Pregnancies = Pregnancy[];
export type AntibodyScreenDat = "NEGATIVE" | "POSITIVE" | "DECLINED";
export type Met1 = "NORMAL" | "ABNORMAL" | "DECLINED";
export type Met2 = "NORMAL" | "ABNORMAL" | "DECLINED";
export type UserMedicalHistory = {
  blood_type?: BloodType;
  /** Height of a person in CM. This is always stored as rounded-up centimeters although the visual presentation may
    be in feet and inches, or centimeters with a fractional part, but this is how it is stored.
     */
  height?: number;
  /** Weight of a non-infant. This is stored as kilograms.
   */
  weight?: number;
  diabetic?: Diabetes;
  hiv_status?: HivStatus;
  allergies?: Allergies;
  immunizations?: Immunizations;
  medications?: Medications;
  pregnancies?: Pregnancies;
  no_known_drug_allergies?: boolean;
  no_known_medications?: boolean;
  no_known_problems?: boolean;
  antibody_screen_DAT?: AntibodyScreenDat;
  met_1?: Met1;
  met_2?: Met2;
};
export type Bmi = string;
export type PatientMedicalHistory = UserMedicalHistory & {
  bmi: Bmi;
};
export type RoleId = number;
export type UserPracticeRoles = RoleId[];
export type LocationIds = LocationId[];
export type UserMetaPractice = {
  roles: UserPracticeRoles;
  locations: LocationIds;
  /** A yes/no or true/false value.
   */
  is_ma: boolean;
  /** A yes/no or true/false value.
   */
  is_provider: boolean;
  /** A yes/no or true/false value.
   */
  is_admin: boolean;
  /** A yes/no or true/false value.
   */
  is_superadmin: boolean;
  /** A yes/no or true/false value.
   */
  is_patient: boolean;
  /** A yes/no or true/false value.
   */
  is_biller: boolean;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_email?: number;
};
export type UserMetaPractices = {
  [key: string]: UserMetaPractice;
};
export type UserMetaLast = {
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** A role which can be assigned to a user at the practice. The role ID is defined at the practice level but
    a given user can have a different role at each location. This is used to determine what the user can do at the
    location level. For example, a user may be a patient at one location and a front desk person at another. Each
    user in a practice has a practice role too, which is used to determine what they can do at the practice level.
    So a user will have a practice role and a location role (or multiple location roles).
     */
  role_id: number;
};
export type UserDosespotMeta = {
  patient_id?: number;
  proxy_clinician_id?: number;
  prescribing_clinician_id?: number;
};
export type UserMetadata = {
  practices: UserMetaPractices;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
  /** Drug Enforcement Administration (DEA) number.
   */
  dea_id?: string;
  last?: UserMetaLast;
  dosespot: UserDosespotMeta;
};
export type UserListItem = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  metadata: UserMetadata;
};
export type UserEmergencyContact = {
  name?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  phone_type?: "Home" | "Work" | "Mobile";
  relationship?: string;
};
export type UserEmergencyContacts = UserEmergencyContact[];
export type PatientInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  parent_ids?: ParentIds;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  /** This is how the person identifies themselves and not necessarily medically accurate. See `sex`.
   */
  pronouns?: string;
  sex: Sex;
  address?: Address;
  problems?: PatientProblems;
  location_data?: object;
  form_data?: object;
  insurance?: UserInsurance;
  pregnancy?: Pregnancy;
  metadata?: PatientMetadata;
  practice_data: PracticeData;
  medical_history: PatientMedicalHistory;
  primary_pharmacy_id?: string;
  note?: string;
  secret_note?: string;
  children: UserListItem[];
  emergency_contacts?: UserEmergencyContacts;
  /** Standard US social security number in XXX-XX-XXXX format.
   */
  ssn?: string;
  signup_completed: boolean;
};
export type ProviderHgMetadata = {
  /** A URL identifying a FHIR-R4 Resource in Health Gorilla's systems.
   */
  resource_url?: string;
};
export type ProviderLocations = LocationId[];
export type ProviderMeridianMetadata = {
  meridian_id: string;
};
export type ProviderMetadata = {
  health_gorilla?: ProviderHgMetadata;
  locations?: ProviderLocations;
  meridian?: ProviderMeridianMetadata;
};
export type ProviderInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  sex?: Sex;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email: string;
  address: Address;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  preferences?: object;
  metadata?: ProviderMetadata;
};
export type AppointmentTypeModel = {
  /** A ulid for an appointment type. */
  appointment_type_id: string;
  /** Each practice and location can define their own list of standard appointment types, along with default
    durations, personnel and notes etc. This is the name of such an appointment type. It must be all lower case
    and have no spaces or special characters other than a hyphen. This will be unique within a practice
    and a location, each of which has their own lists, with the location list overriding any practice type
    that has the same name.
     */
  appointment_type: string;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  default_duration: number;
  encounter_type: EncounterType;
  /** A color hex code that will be used to represent this appointment type in the calendar. This is optional
    and if not provided, a default color will be used.
     */
  appointment_hex?: string;
  file_bundle_id?: string;
  cpt_bundle_id?: string;
};
export type LabOrderPatientInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
};
export type LabOrderUrgency = "ROUTINE" | "URGENT" | "ASAP" | "STAT";
export type LabOrderBillRecipient =
  | "CLIENT"
  | "PATIENT"
  | "GUARANTOR"
  | "THIRD_PARTY";
export type LabObservationStatus =
  | "registered"
  | "preliminary"
  | "final"
  | "amended"
  | "cancelled"
  | "corrected";
export type ObservationSource = "LAB" | "MANUAL";
export type ValueQuantity = {
  value: string;
  unit?: string;
};
export type ReferenceRange = {
  low?: ValueQuantity;
  high?: ValueQuantity;
  text?: string;
};
export type PerformingSite = {
  name?: string;
  address?: Address;
  contact?: string;
};
export type LabObservationInfo = {
  /** System-assigned unique ID of an observation stored in the Pario system.
    Not assigned to observations stored in Health Gorilla.
     */
  observation_id: string;
  /** An ID for a lab test.
   */
  test_id: string;
  /** Health Gorilla ID for an observation from lab results.
   */
  hg_id: string;
  status: LabObservationStatus;
  /** Unique file ID to reference a file stored in S3.
   */
  file_id?: number;
  source: ObservationSource;
  display: string;
  code: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  issued: string;
  value?: string;
  unit?: string;
  reference_range?: ReferenceRange;
  interpretation?: string;
  note?: string;
  performing_site?: PerformingSite;
};
export type LabOrderStatus =
  | "NEW"
  | "SENT"
  | "IN_PROGRESS"
  | "ERROR"
  | "FULFILLED"
  | "CORRECTED"
  | "REVIEWED";
export type AoeAnswer = {
  aoe_id: string;
  response: string;
};
export type LabTestInfo = {
  /** An ID for a lab test.
   */
  test_id: string;
  /** An ID for a lab order.
   */
  order_id?: string;
  /** Code used by the lab to identify a test.
   */
  code: string;
  /** Code used by the lab to identify a test.
   */
  display: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note_to_lab?: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note_from_lab?: string;
  observations: LabObservationInfo[];
  status?: LabOrderStatus;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated: string;
  aoe_answers?: AoeAnswer[];
};
export type LabOrderMetadata = {
  hg_request_group_url?: string;
  hg_request_result_url?: string;
};
export type LabOrderInfo = {
  /** An ID for a lab order.
   */
  order_id: string;
  patient: LabOrderPatientInfo;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id: number;
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  lab_id: string;
  /** Health Gorilla ID for Location FHIR/R4 resource
   */
  location_id?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  /** A human-readable label for the order.
   */
  label: string;
  /** A note on the order to be sent along to the servicing lab.
   */
  note?: string;
  /** A note sent back from the lab to be displayed at the order level.
   */
  result_note?: string;
  urgency: LabOrderUrgency;
  bill_recipient: LabOrderBillRecipient;
  specimen_collected?: string;
  fasting: boolean;
  tests: LabTestInfo[];
  icd10_codes?: Icd10CodeDetails[];
  status: LabOrderStatus;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  sent?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  fulfilled?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  reviewed?: string;
  has_abn: boolean;
  metadata: LabOrderMetadata;
};
export type UserInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  sex: Sex;
  /** This is how the person identifies themselves and not necessarily medically accurate. See `sex`.
   */
  pronouns?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  mobile?: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  addr_line_1?: string;
  /** Optional line 2 of 3 for an address.
   */
  addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  metadata: UserMetadata;
  medical_history: UserMedicalHistory;
};
export type LocationType =
  | "MIDWIFE"
  | "BIRTHING_CENTER"
  | "MIDWIFERY_CLINIC"
  | "OBGYN_CLINIC"
  | "HOSPITAL"
  | "INTERNAL";
export type PhraseModel = {
  /** A unique phrase defined by a practice as one of its "dot-phrases". This must be alphanumeric, lower case, start with
    an alphabetic character, and between 1 and 16 characters in length. It must be unique across a practice.
     */
  phrase: string;
  /** Description of a dot-phrase, used in selection dropdown.
   */
  description?: string;
  /** Expansion for a dot-phrase.
   */
  text: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  last_editor: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_edited: number;
};
export type PhrasePreferences = {
  [key: string]: PhraseModel;
};
export type LabOrgInfo = {
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  id: string;
  name: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax: string;
};
export type LabLocationInfo = {
  /** Health Gorilla ID for Location FHIR/R4 resource
   */
  location_id: string;
  name: string;
  address: Address;
  lab?: LabOrgInfo;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax: string;
};
export type LabTests = LabTestInfo[];
export type LabTestBundle = {
  /** System-assigned ID for a bundle. Formatted as a UUID.
   */
  bundle_uuid?: string;
  /** User-defined name for a bundle.
   */
  name: string;
  tests: LabTests;
};
export type LocationBookmarkedLab = {
  locations: {
    [key: string]: LabLocationInfo;
  };
  bundles: {
    [key: string]: LabTestBundle;
  };
};
export type LocationBookmarkedLabs = {
  [key: string]: LocationBookmarkedLab;
};
export type LocationGroupListItem = {
  /** System-assigned unique ID for a group of users.
   */
  group_id: number;
  /** The name of a location group. This is a collection of users (most likely staff but patients are allowed too)
    within the location. For example, all front desk staff.
     */
  name: string;
  /** System level and defined flags. Very little should ever see let alone be able to set these.
   */
  flags: number;
  members: UserIdList;
};
export type LocationGroups = {
  [key: string]: LocationGroupListItem;
};
export type LocationPreferences = {
  phrases: PhrasePreferences;
  labs?: LocationBookmarkedLabs;
  prenatal_test_sequence?: object;
  groups?: LocationGroups;
};
export type LocationDosespotMeta = {
  clinic_id?: number;
  clinic_key?: string;
};
export type LocationMetadata = {
  dosespot?: LocationDosespotMeta;
};
export type LocationInfo = {
  /** System-assigned unique location identifier.
   */
  location_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** User ID of the user who owns the practice or location. An owner has extra priviliges. Every practice
    or location has one and only one owner, and by definition this user can see, modify or create anything
    within that practice / location. However, just because a user is an owner of one practice does not
    infer any rights or priviliges too other practices. Likewise a location owner has no extra powers
    outside of their location.
     */
  owner_id?: number;
  /** The name of the location. There is no requirement that this be unique as locations are referenced
    throughout the system by their ID, not their name.
     */
  name: string;
  location_type: LocationType;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  contact?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  contact_email?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  public_email?: string;
  address: Address;
  fields?: object;
  preferences: LocationPreferences;
  metadata: LocationMetadata;
  /** Tax identification number.
   */
  tin?: string;
  /** Clinical Laboratory Improvement Amendments ID. CLIA is a federal regulation that applies to labs in the
    United States.
     */
  clia?: string;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
  /** A timezone in the form of a string. This is a standard timezone string, such as 'America/New_York'.
   */
  timezone?: string;
};
export type AppointmentLocation = LocationInfo | AltLocation;
export type AppointmentStatus =
  | "SCHEDULED"
  | "CONFIRMED"
  | "CANCELED"
  | "NO_SHOW"
  | "CHECKED_IN"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "CHECKED_OUT"
  | "RESCHEDULED";
export type AppointmentAttachment = {
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  display: string;
};
export type AppointmentAttachments = AppointmentAttachment[];
export type TaskType =
  | "FORM"
  | "FILE"
  | "UPLOAD"
  | "GENERIC"
  | "DEMOGRAPHICS"
  | "DOCUMENT_REVIEW";
export type AppointmentTask = {
  display: string;
  /** System-assigned ID of a patient task.
   */
  task_id?: number;
  task_type: TaskType;
  /** Generic system ID, for cases where a single API call can use an ID for multiple entities. Some other mechanism,
    usually a query or path parameter, will instruct the system as to the type of ID.
     */
  resource_id: number;
};
export type AppointmentTasks = AppointmentTask[];
export type AppointmentMetadata = {
  meridian?: MeridianMetadata;
};
export type AppointmentInfo = {
  /** System-assigned unique ID of an appointment. Used as a table linkage. Every appointment the system
    creates is given a unique ID that is used by other types to refer to that specific appointment
    (for example, all messages sent by the system to a user regarding a specific appointment).
     */
  appointment_id: number;
  appointment_type: AppointmentTypeModel;
  patient: PatientInfo;
  provider: ProviderInfo;
  appointment_location?: AppointmentLocation;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  starts: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  ends: number;
  status: AppointmentStatus;
  chief_complaint?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  referring_provider_id?: number;
  referring_external_provider?: string;
  message?: string;
  attachments?: AppointmentAttachments;
  tasks?: AppointmentTasks;
  flagged: boolean;
  metadata?: AppointmentMetadata;
  incomplete_tasks_count: number;
};
export type SuperbillStatus =
  | "IN_PROGRESS"
  | "SUBMITTED"
  | "DENIED"
  | "APPROVED"
  | "REQUIRES_CHANGES";
export type SuperbillCodes = {
  [key: string]: string;
};
export type SuperbillDetails = {
  cpt_codes: SuperbillCodes;
  icd10_codes: SuperbillCodes;
};
export type SuperbillInfo = {
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id: number;
  /** The amount owed by the patient in cents.
   */
  patient_balance: number;
  status: SuperbillStatus;
  details?: SuperbillDetails;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  submitted?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  submitted_by?: number;
};
export type EncounterMetadata = {
  related?: EncounterId[];
  maternity_neighborhood?: EncounterMaternityNeighborhoodMetadata;
};
export type InfantGrowthDerivedMeasurements = {
  /** Body Mass Index. This is a computed value based on the patient's height and weight.
   */
  bmi?: string;
  /** A percentage value. Imagine the percent sign is there.
   */
  who_weight_for_length_percentile?: number;
  /** A percentage value. Imagine the percent sign is there.
   */
  who_head_circumference_for_age_percentile?: number;
  /** A percentage value. Imagine the percent sign is there.
   */
  who_length_for_age_percentile?: number;
  /** A percentage value. Imagine the percent sign is there.
   */
  who_weight_for_age_percentile?: number;
  /** A percentage value. Imagine the percent sign is there.
   */
  percent_change_weight_since_birth?: number;
};
export type InfantGrowth = InfantGrowthMeasurements &
  InfantGrowthDerivedMeasurements;
export type EncounterInfo = {
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  /** System-assigned version of a resource. Many system types can change over time. Where they are referenced
    in othe types, the version number of the resource is also stored. This allows us to identify a resource as it
    existed at a specific point in time. For example, a patient info form may undergo many changes, and the data
    used and stored at any given time will be a specific version number of that form. Thus in the future if we
    need to query (perhaps for legal reasons) what exactly was the information a provider was using when they
    provided service, we can reference the patient form as it existed at that version.
     */
  version: number;
  patient: PatientInfo;
  provider: ProviderInfo;
  encounter_type?: EncounterType;
  appointment_type?: AppointmentTypeModel;
  status: EncounterStatus;
  note?: string;
  diagnoses?: EncounterDiagnoses;
  codings?: Codings;
  /** System-assigned version of a resource. Many system types can change over time. Where they are referenced
    in othe types, the version number of the resource is also stored. This allows us to identify a resource as it
    existed at a specific point in time. For example, a patient info form may undergo many changes, and the data
    used and stored at any given time will be a specific version number of that form. Thus in the future if we
    need to query (perhaps for legal reasons) what exactly was the information a provider was using when they
    provided service, we can reference the patient form as it existed at that version.
     */
  health_history_version: number;
  orders?: LabOrderInfo[];
  type_specific?: object;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  submitted?: number;
  submitted_by?: UserInfo;
  appointment?: AppointmentInfo;
  superbill?: SuperbillInfo;
  metadata: EncounterMetadata;
  location: EncounterLocation;
  vitals?: VitalsInfo;
  infant_growth?: InfantGrowth;
  is_billable: boolean;
  claim_id?: string;
};
export type Id = number;
export type IdType =
  | "form"
  | "group"
  | "message"
  | "practice"
  | "patient"
  | "medical_assistant"
  | "administrative"
  | "biller"
  | "location"
  | "pregnancy"
  | "provider"
  | "template"
  | "user"
  | "medication"
  | "progress_update"
  | "note"
  | "infant_medication"
  | "infant_progress_update"
  | "recurring_event";
export type Timestamp = number;
export type Page = number;
export type PageSize = number;
export type ErrorId = number;
export type EncounterVersionsItem = {
  num?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  when?: number;
  status?: EncounterStatus;
};
export type EncounterVersions = EncounterVersionsItem[];
export type Version = number;
export type EncounterExport200Response = {
  content: string;
};
export type LaborSheetEventType =
  | "EVENT"
  | "PROGRESS_UPDATE"
  | "MEDICATION"
  | "NOTES"
  | "RECURRING_EVENT"
  | "INFANT_PROGRESS_UPDATE"
  | "INFANT_MEDICATION";
export type LaborEventAuthor = {
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name?: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name?: string;
};
export type LaborEventInfo = {
  event_id: string;
  stage: string;
  type: LaborSheetEventType;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  authors?: LaborEventAuthor[];
  forms?: object;
};
export type LaborSheetEvents = LaborEventInfo[];
export type LaborSheet = {
  [key: string]: LaborSheetEvents;
};
export type LaborEventUpsertResponse = {
  stage: string;
  labor_event_id?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
};
export type LaborEventUpdate = {
  event_id: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  forms?: object;
};
export type LaborEventCreate = {
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  forms?: object;
};
export const {
  useEncounterCreateMutation,
  useEncounterCreateFromAppointmentMutation,
  useEncounterListQuery,
  useLazyEncounterListQuery,
  useEncounterUpdateMutation,
  useEncounterListVersionsQuery,
  useLazyEncounterListVersionsQuery,
  useEncounterInfoQuery,
  useLazyEncounterInfoQuery,
  useEncounterSubmitMutation,
  useEncounterDeleteMutation,
  useEncounterExportQuery,
  useLazyEncounterExportQuery,
  useEncounterLaborInfoQuery,
  useLazyEncounterLaborInfoQuery,
  useEncounterLaborSheetQuery,
  useLazyEncounterLaborSheetQuery,
  useEncounterLaborUpsertMutation,
  useEncounterLaborEventUpsertMutation,
  useEncounterLaborEventDeleteMutation,
  useEncounterLaborRecurringEventUpdateMutation,
  useEncounterLaborRecurringEventCreateMutation,
  useEncounterLaborRecurringEventDeleteMutation
} = injectedRtkApi;
