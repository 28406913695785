// store
import {
  UserInsurance,
  usePatientGetInfoQuery
} from "@/store/services/patient";

// components
import InsuranceReadOnly from "./insuranceReadOnly";
import InsuranceForm from "./insuranceForm";
import EmptyState from "@/components/cards/emptyState";
import { FORMAT } from "@/globals/helpers/formatters";

interface InsuranceformProps {
  patientId: number;
  type: "PRIMARY" | "SECONDARY" | "TERTIARY";
  isReadOnly?: boolean;
  onUpdate?: () => void;
}

export default function InsuranceInformation({
  patientId,
  type,
  isReadOnly = false,
  onUpdate
}: InsuranceformProps) {
  const { data: patient } = usePatientGetInfoQuery(
    {
      patientId
    },
    {
      skip: !patientId
    }
  );

  const customEmptyString = FORMAT.capitalize(type) + " insurance not recorded.";

  if (isReadOnly) {
    if (
      patient &&
      patient.insurance &&
      patient.insurance[type.toLowerCase() as keyof UserInsurance]
    ) {
      return <InsuranceReadOnly patientId={patientId} type={type} />;
    }
    return <EmptyState customMessage={customEmptyString} />;
  }
  return (
    <InsuranceForm
      patientId={patientId}
      type={type}
      onUpdate={() => onUpdate && onUpdate()}
    />
  );
}
