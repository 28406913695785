import { PhraseListItem } from "@/store/services/generated/phrase";
import { useDispatch, useSelector } from "react-redux";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { MODAL_TYPES } from "@/components/modal/dispatcher";
import { RootState } from "@/store/store";
import { usePhraseDeleteMutation } from "@/store/services/phrase";

import Edit from "../../../public/svgs/edit_simple.svg";
import Trash from "../../../public/svgs/delete.svg";

import styles from "./styles.module.scss";

export interface PhraseOperationProps {
  phrase: PhraseListItem;
}

export default function PhraseOperations({ phrase }: PhraseOperationProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const [deletePhrase] = usePhraseDeleteMutation();

  const onPhraseEdit = (phrase: PhraseListItem) => {
    dispatch(
      setModalContent({
        type: MODAL_TYPES.DOT_PHRASE_FORM,
        props: { phrase, title: "Edit dot phrase" },
      })
    );
    dispatch(setModalIsOpen(true));
  };

  const onPhraseDelete = (phraseName: string) => {
    deletePhrase({
      id: sessionInfo?.practice_id as number,
      scope: "practice",
      phrase: phraseName,
    });
  };

  return (
    <>
      <a
        className={styles.phraseButton}
        onClick={() => onPhraseDelete(phrase.name)}
      >
        <Trash width={20} height={20} />
      </a>
      <a className={styles.phraseButton} onClick={() => onPhraseEdit(phrase)}>
        <Edit width={22} height={22} />
      </a>
    </>
  );
}
