/* OptionsMenu Name */
/* External Imports */
import clsx from "clsx";
import { useState } from "react";
/* Local Imports */

// components

// constants

// store

// styles
import styles from "./styles.module.scss";

/* OptionsMenu Typescript Interface */
interface OptionsMenuProps {
  options: { id: string; label: string, style?: string }[];
  selectedOption: string | null; // Prop for the currently selected option
  onClick: (s: string) => void;
  disabled?: boolean;
  onBlur?: () => void;
}

export default function OptionsMenu({
  options,
  selectedOption,
  onClick,
  disabled = false,
  onBlur = () => {},
}: OptionsMenuProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.OptionsMenu)}>
      {options &&
        options.map(({ id, label, style = "" }) => (
          <button
            type="button"
            key={id}
            onClick={() => {
              onClick(id);
            }}
            className={clsx({ [styles.selected]: id === selectedOption }, [styles[style]])}
            disabled={disabled}
            data-cy={id === selectedOption ? "selected-option" : "option"}
            role="option"
            onBlur={onBlur}
          >
            {label}
          </button>
        ))}
    </div>
  );
}
