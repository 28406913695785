/* ProblemList */
/* External Imports */
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import Fuse from "fuse.js";

/* Local Imports */

// components
import Button from "@/components/button";
import TabMenu from "@/components/tabbed";
import Problem from "./problem";
import ComboboxSelect from "@/components/input/combobox";
import CreateProblem from "./createProblem";
import NoKnownToggle from "../allergyList/noKnownToggle";

// constants
import {
  STYLES,
  STATUS_KEYS,
  METRIC_LABELS,
  METRICS,
  APP_KEYS
} from "@/globals/constants";
// store
import {
  ProblemStatus,
  ProblemType,
  ProblemUpsertApiArg,
  ProblemUpsertRequest,
  useProblemUpsertMutation
} from "@/store/services/problem";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";
import {
  PatientProblems,
  usePatientArchiveDiagnosisMutation
} from "@/store/services/patient";

// styles
import styles from "./styles.module.scss";
import { setModalIsOpen } from "../modal/modalSlice";

/* ProblemList Typescript Interface */
interface ProblemListProps {
  isExpanded?: boolean;
  problems?: PatientProblems;
  patientId: number;
}

export default function ProblemList({
  isExpanded = false,
  problems = { diagnoses: [], notes: [] },
  patientId
}: ProblemListProps) {
  /* Redux */

  const dispatch = useDispatch();
  const [upsertProblem, upsertProblemResponse] = useProblemUpsertMutation();
  const [archiveDiagnosis] = usePatientArchiveDiagnosisMutation();
  /* Local State */

  const [isAddProblemMode, setIsAddProblemMode] = useState<boolean>(false);

  // problems to display when user filters
  const [filteredProblems, setFilteredProblems] =
    useState<PatientProblems>(problems);

  /* Form Validation & Management using react-hook-form */

  // react-hook-form hook configuration
  const form = useForm();
  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    reset
  } = form;

  /* Effects */

  // TODO: should we be resetting filter term on archive / update?
  useEffect(() => {
    setFilteredProblems(problems);
  }, [problems]);

  /* Event Handlers */

  // Update problem status
  const handleUpdateProblemStatus = async (
    type: ProblemType,
    status: ProblemStatus,
    id?: number,
    icd_id?: number
  ) => {
    try {
      // update problem
      await upsertProblem({
        patientId,
        problemUpsertRequest: { id, type, status, icd_id }
      }).unwrap();

      // user alert
      launchToast(
        `Problem ${METRIC_LABELS[status]} successfully.`,
        STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
      );
    } catch (error) {
      launchToast(
        `An error occurred. Problem was not ${METRIC_LABELS[status]}.`,
        STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
      );
    }
  };

  const handleStatusChange = (
    problemName: string,
    problemId: string | number,
    status: ProblemStatus
  ) => {
    switch (
      status // Added a colon after 'switch status'
    ) {
      case METRICS.DELETED:
        handleUpdateProblemStatus(
          "DIAGNOSIS",
          status as ProblemStatus,
          problemId as number
        );
      case METRICS.ACTIVE:
        // TODO: handle reactivating diagnoses
        // launchToast(
        //   "Reactivating diagnoses problems currently unsupported",
        //   // @ts-ignore
        //   STATUS_KEYS.ERROR
        // );
        break;
      case METRICS.ARCHIVED:
        handleArchiveDiagnosis(problemName);
        break;
      default:
        break;
    }
  };

  // Archive problem
  const handleArchiveDiagnosis = async (diagnosisName?: string) => {
    if (diagnosisName) {
      try {
        await archiveDiagnosis({
          patientId,
          icd10Code: diagnosisName
        }).unwrap();

        // user alert
        launchToast(
          `Problem archived successfully.`,
          STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        );
      } catch (error) {
        launchToast(
          `An error occurred. Probem was not archived.`,
          STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        );
      }
    } else {
      launchToast(
        `An error occurred. Probem was not archived.`,
        STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
      );
    }
  };

  // Update problem note
  const handleUpdateProblemNote = async (id: number, type: ProblemType) => {
    const formInfo = getValues();
    const note = formInfo[`problem-${id}-note`];
    if (note) {
      try {
        await upsertProblem({
          patientId,
          problemUpsertRequest: { id, type, note }
        }).unwrap();
        // user alert
        launchToast(
          "Problem note updated successfully.",
          STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        );
        // clear edit mode
        // setEditProblemInstance(null);
      } catch (error) {
        launchToast(
          "An error occurred while updating problem note.",
          STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        );
      }
    }
  };

  // handle add problem button
  const onSubmit = (data: any) => {
    const handleProblemUpsert = async () => {
      try {
        let problem: Partial<ProblemUpsertRequest> = {
          note: data.problem_new_note,
          type: "NOTE" as ProblemType,
          title: data.new_problem_title,
          date_of_onset: data.new_date_of_onset || undefined
        };
        if (data.select_icd10) {
          problem.icd_id = data.select_icd10.icd_id;
          problem.type = METRICS.DIAGNOSIS as ProblemType;
          problem.title = data.select_icd10.description;
        }
        await upsertProblem({
          patientId,
          // @ts-ignore
          problemUpsertRequest: problem
        }).unwrap();
        setIsAddProblemMode(false);
        // user alert
        launchToast(
          `Problem added successfully.`,
          STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        );
      } catch (error) {
        launchToast(
          `An error occurred while adding problem, please try again later.`,
          STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        );
      }
    };
    handleProblemUpsert();
    reset();
  };

  const launchToast = (message: string, type: keyof typeof STATUS_KEYS) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };

  const handleCreateProblemSubmit = (data: ProblemUpsertRequest) => {
    upsertProblem({
      patientId,
      problemUpsertRequest: data
    }).unwrap().then(res => {
      launchToast("Problem created successfully", STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS);
      dispatch(setModalIsOpen(false));
    }).catch(err => {
      console.error(err);
      launchToast("Something went wrong creating problem", STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS);
    });
  } 


  const handleFilterProblems = (term: string) => {
    // filter problems using fuzzy search if user inputs more than 3 characters
    if (problems && term?.length > 2) {
      // search through note type problems
      const filteredNotesResults =
        problems.notes && problems.notes.length > 0
          ? notesFuse.search(term)
          : [];
      const currentFilteredNotes = filteredNotesResults.map(({ item }) => item);
      // search through diagnoses
      const filteredDiagnosesResults =
        problems.diagnoses && problems.diagnoses.length > 0
          ? diagnosesFuse.search(term)
          : [];
      const currentFilteredDiagnoses = filteredDiagnosesResults.map(
        ({ item }) => item
      );
      const currentFilteredProblems = {
        notes: currentFilteredNotes,
        diagnoses: currentFilteredDiagnoses
      };
      setFilteredProblems(currentFilteredProblems);
    } else {
      // reset to all problems when user clears input
      setFilteredProblems(problems);
    }
  };
  /* Fuse Search Configuration */
  const notesFuse = new Fuse(
    // @ts-ignore
    problems && problems?.notes?.length > 0 && problems.notes,
    {
      keys: ["note.content", "title"]
    }
  );

  const diagnosesFuse = new Fuse(problems && problems?.diagnoses, {
    keys: [
      "title",
      "name",
      "instances.diagnosis.icd_id",
      "instances.diagnosis.description"
    ]
  });

  return (
    <div
      className={clsx(styles.ProblemList, { [styles.isExpanded]: isExpanded })}
    >
      {isAddProblemMode ? (
        <CreateProblem
          onCancel={() => setIsAddProblemMode(false)}
          onSubmit={handleCreateProblemSubmit}
        />
      ) : (
        <div className={styles.content}>
          {isExpanded ? (
            <div className={styles.filterWrapper}>
              <Controller
                name="search_problems"
                control={control}
                render={({ field: { onChange } }) => (
                  <ComboboxSelect
                    label="Search problems"
                    placeholder="Search problems"
                    hiddenLabel
                    options={[
                      ...filteredProblems.notes,
                      ...filteredProblems.diagnoses
                    ]}
                    labelAcc={problem => `${problem?.note?.content}`}
                    onChange={v => onChange(v)}
                    onSearch={handleFilterProblems}
                    noMenu
                    fullWidth
                  />
                )}
                rules={{ required: false }}
              />
            </div>
          ) : undefined}
          <NoKnownToggle
            patientId={patientId}
            toggleField="no_known_problems"
          />
          <TabMenu
            tabs={[
              {
                id: METRICS.ACTIVE,
                label: "Active"
              },
              { id: METRICS.ARCHIVED, label: "Prior Diagnoses" },
              { id: APP_KEYS.ALL, label: "All" }
            ]}
            style={STYLES.SECONDARY}
            noPadding
            onChange={() => setFilteredProblems(problems)} // clear filter on tab change
            initialSelectedTab={METRICS.ACTIVE}
          >
            {tab => (
              <div className={styles.tabbedContent}>
                {/* unless all tab selected, filter problems by tab */}
                {(tab === APP_KEYS.ALL
                  ? filteredProblems.notes
                  : filteredProblems.notes?.filter(
                      ({ status }) => status === tab
                    )
                )?.map(problem => (
                  // accordion for each problem
                  <Problem
                    key={problem.id}
                    patientId={patientId}
                    id={problem.id}
                    title={problem.title}
                    status={problem.status}
                    form={form}
                    isExpanded={isExpanded}
                    tagLabel="Note"
                    onUpdateStatus={(problemId, status) =>
                      handleUpdateProblemStatus(
                        "NOTE",
                        status as ProblemStatus,
                        problemId as number
                      )
                    }
                    onUpdateNote={problemId =>
                      handleUpdateProblemNote(problemId, "NOTE")
                    }
                    mostRecentTimestamp={problem.created}
                    instances={[
                      {
                        timestamp: problem.note.at,
                        problemId: problem.id,
                        noteContent: problem.note.content,
                        date_of_onset: problem.date_of_onset
                      }
                    ]}
                  />
                ))}
                {(tab === APP_KEYS.ALL
                  ? filteredProblems.diagnoses
                  : filteredProblems.diagnoses.filter(
                      ({ status }) => status === tab
                    )
                )?.map((problem, i) => (
                  // accordion for each problem
                  <Problem
                    key={problem.name}
                    patientId={patientId}
                    id={problem.name}
                    status={problem.status}
                    title={problem.title}
                    form={form}
                    isExpanded={isExpanded}
                    // tagLabel={problem.details?.diagnosis?.name}
                    tagLabel={problem.name}
                    onUpdateStatus={(problemId, status) =>
                      handleStatusChange(
                        problem.name,
                        problemId,
                        status as ProblemStatus
                      )
                    }
                    onUpdateNote={instanceId =>
                      handleUpdateProblemNote(instanceId, "DIAGNOSIS")
                    }
                    mostRecentTimestamp={problem.lastUpdated}
                    instances={problem.instances.map(instance => ({
                      timestamp: instance.created,
                      problemId: instance.id,
                      noteContent: instance.note?.content,
                      encounterId: instance.encounter_id,
                      date_of_onset: instance.date_of_onset
                    }))}
                  />
                ))}
              </div>
            )}
          </TabMenu>
          {isExpanded && (
            <div className={styles.buttons}>
              <Button
                style={STYLES.PRIMARY}
                onClick={() => setIsAddProblemMode(true)}
              >
                Add New Problem
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
