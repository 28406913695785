/* Confirmation Modal Template */

// External
import { useEffect, useMemo, useState } from "react";
import { Inter } from "next/font/google";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";

// components
import Button from "@/components/button";
import ControlledCombobox from "@/components/input/controlledCombobox";
import Input from "@/components/input";
import ControlledTextArea from "@/components/textArea/controlledTextArea";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { FORMAT } from "@/globals/helpers/formatters";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// store
import { setModalIsOpen } from "../modalSlice";
import {
  CreatePatientRecallApiArg,
  PatientInfo,
  UpdatePatientRecallApiArg,
  useCreatePatientRecallMutation,
  useDeletePatientRecallMutation,
  useGetPatientRecallQuery,
  useUpdatePatientRecallMutation
} from "@/store/services/patient";
import {
  PatientListItem,
  usePatientGetListQuery
} from "@/store/services/practice";

// styles
import styles from "../styles.module.scss";
import { setRightPaneOpen } from "@/components/drawer/drawerSlice";

export interface PatientRecallProps {
  patient?: PatientInfo;
  recallId: string;
}

export default function PatientRecallEdit({
  patient,
  recallId
}: PatientRecallProps) {
  const dispatch = useDispatch();
  // State to see if we're deleting a recall so that we don't attempt to GET it
  // again until the delete is resolve so we don't run into issues getting a
  // deleted recall
  const [deleting, setDeleting] = useState<boolean>(false);

  const patientListResponse = usePatientGetListQuery({
    pagesz: 1000
  });

  const {
    data: recallInfo,
    isSuccess: isRecallSuccess,
    isLoading: isRecallLoading
  } = useGetPatientRecallQuery(
    {
      recallId: recallId
    },
    {
      skip: !recallId || deleting
    }
  );
  const [updatePatientRecall, updatePatientRecallResponse] =
    useUpdatePatientRecallMutation();
  const [deletePatientRecall, deletePatientRecallResponse] =
    useDeletePatientRecallMutation();

  const componentState: "LOADING" | "READY" | "ERROR" = useMemo(() => {
    if (
      patientListResponse.isLoading ||
      updatePatientRecallResponse.isLoading ||
      isRecallLoading
    ) {
      return "LOADING";
    }

    if (patientListResponse.isSuccess && isRecallSuccess) {
      return "READY";
    }

    if (deletePatientRecallResponse.isError) {
      return "ERROR";
    }

    return "LOADING";
  }, [patientListResponse, updatePatientRecallResponse, isRecallSuccess]);

  const form = useForm({
    defaultValues: {
      patient: (patientListResponse.data?.patients || [])?.find(
        (patient: PatientListItem) => patient.user_id == recallInfo?.patient_id
      ),
      date: recallInfo?.due_date,
      reason: recallInfo?.reason
    }
  });

  useEffect(() => {
    reset({
      patient: (patientListResponse.data?.patients || [])?.find(
        (patient: PatientListItem) => patient.user_id == recallInfo?.patient_id
      ),
      date: recallInfo?.due_date,
      reason: recallInfo?.reason
    });
  }, [recallInfo, patientListResponse]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = form;

  /**
   * The effects for the response of creating the patient recall
   * Success: Toast and close modal
   * Failure: Toast but keep modal open
   */
  useEffect(() => {
    if (updatePatientRecallResponse.isSuccess) {
      dispatch(setRightPaneOpen(false));
      dispatch(
        addAlertToToastTrough({
          type: STATUS_KEYS.SUCCESS,
          message: "Successfully updated patient recall"
        })
      );
    }

    if (updatePatientRecallResponse.isError) {
      dispatch(
        addAlertToToastTrough({
          type: STATUS_KEYS.ERROR,
          message: "Something went wrong updating the patient recall"
        })
      );
    }
  }, [updatePatientRecallResponse]);

  useEffect(() => {
    if (deletePatientRecallResponse.isSuccess) {
      dispatch(setRightPaneOpen(false));
      dispatch(
        addAlertToToastTrough({
          type: STATUS_KEYS.SUCCESS,
          message: "Successfully completed patient recall"
        })
      );
    }
  }, [deletePatientRecallResponse]);

  const markAsDone = () => {
    setDeleting(true);
    deletePatientRecall({
      recallId: recallId
    })
      .unwrap()
      .then(() => {
        dispatch(setRightPaneOpen(false));
        setDeleting(false);
      })
      .catch(() => {
        setDeleting(false);
        dispatch(
          addAlertToToastTrough({
            type: STATUS_KEYS.ERROR,
            message: "Something went wrong completing the patient recall"
          })
        );
      });
  };

  const saveAndClose = (data: any) => {
    const updateRecallPayload: UpdatePatientRecallApiArg = {
      recallId: recallId,
      patientRecallUpdate: {
        due_date: data.date,
        reason: data.reason
      }
    };

    updatePatientRecall(updateRecallPayload);
  };

  return (
    <div className={styles.PatientRecall} data-cy="patient-recall-edit-modal">
      {componentState == "LOADING" && (
        <>
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
        </>
      )}
      {(componentState == "READY" || componentState == "ERROR") && (
        <form
          onSubmit={handleSubmit(saveAndClose)}
          className={styles.recallForm}
        >
          <ControlledCombobox
            label="Patient"
            options={patientListResponse.data?.patients || []}
            name="patient"
            control={control}
            labelAcc={patient => FORMAT.name(patient)}
            required
            errors={errors}
            isHorizontalLayout={false}
            fullWidth
            disabled
            // @ts-ignore this prop should probably be typed "any"
            valueKey={form.getValues("patient")}
          />
          <Input
            type="date"
            id="date"
            name="date"
            label="Recall date"
            register={register}
            isHorizontalLayout={false}
            fullWidth
            required
          />
          <ControlledTextArea
            label="Reason"
            id="reason"
            name="reason"
            form={form}
          />
          <div className={styles.buttonContainer}>
            <Button style={STYLES.SECONDARY} type="submit">
              Save and close
            </Button>
            <Button style={STYLES.PRIMARY} type="button" onClick={markAsDone}>
              Mark as done
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}
