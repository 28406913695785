/* Create Patient Modal Template */

// External
import clsx from "clsx";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { z, ZodType } from "zod";
import libphonenumber from "google-libphonenumber";
import Select from "react-select";
import { useMediaQuery } from "react-responsive";
import { IMask, IMaskInput } from "react-imask";

// styles
import styles from "./styles.module.scss";
import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";

// components
import Button from "@/components/button";
import GeneratedInput from "@/components/input/generated";
import KeyValueGrid from "@/components/birthSummary/keyValueGrid";
import KeyValueItem from "@/components/birthSummary/keyValueItem";

// constants
import { STYLES, METRIC_LABELS, STATUS_KEYS } from "@/globals/constants";
import { FIELD_TYPES, buildZodObject } from "@/globals/constants/formSchema";
import { useCustomPatientDemographics } from "@/globals/helpers/customHooks";

// store
import { RootState } from "@/store/store";
import {
  usePatientUpsertMutation,
  PatientUpsertApiArg,
  PatientInfo
} from "@/store/services/patient";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { FORMAT } from "@/globals/helpers/formatters";
import { useEffect } from "react";

export interface PatientFormProps {
  patient?: Partial<PatientInfo>;
  isReadOnly?: boolean;
  onUpdate?: () => void;
}

export default function PatientGeneralInfoForm({
  patient,
  isReadOnly,
  onUpdate
}: PatientFormProps) {
  const dispatch = useDispatch();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const customPatientDemographicsFields = useCustomPatientDemographics();
  // create or update a patient
  const [upsertPatient] = usePatientUpsertMutation();

  // validation schema definition
  const schema: ZodType = z.object(
    buildZodObject(customPatientDemographicsFields)
  );
  type FormSchemaType = z.infer<typeof schema>;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset
  } = useForm<FormSchemaType>({
    //resolver: zodResolver(schema),
    defaultValues: patient && getInitialValues(patient)
  });

  /* Local State */

  /* Event handlers */

  useEffect(() => {
    if (patient) {
      reset(getInitialValues(patient));
    }
  }, [patient?.user_id]);

  // on "Update" button click
  const onSubmit = (data: any) => {
    const {
      dob: dob,
      email: email,
      first_name: first_name,
      last_name: last_name,
      phone: mobile,
      addr_line_1: line1,
      addr_line_2: line2,
      addr_line_3: line3,
      city: city,
      state: state,
      zip: zip,
      sex: sex,
      pronouns: pronouns,
      ssn
    } = data;

    const dobAsInt = parseInt(dayjs(dob).format("YYYYMMDD"));

    // dispatch to backend
    // UPSERT PATIENT
    const updatePatient = async () => {
      const sanitizedSsn = ssn.replaceAll("-", "");

      const req: PatientUpsertApiArg = {
        patientUpsertRequest: {
          user_id: patient?.user_id,
          location_ids: sessionInfo?.locations || [],
          sex,
          pronouns,
          ssn: sanitizedSsn,
          dob: dobAsInt,
          first_name,
          last_name,
          address: {
            line1,
            line2,
            line3,
            city,
            state,
            zip
          }
        }
      };
      if (email) {
        req.patientUpsertRequest.email = email;
      }
      if (mobile && mobile.length > 3) {
        req.patientUpsertRequest.mobile = mobile;
      }

      upsertPatient(req)
        .unwrap()
        .catch(err => {
          dispatch(
            addAlertToToastTrough({
              message: "Something went wrong updating the information",
              type: STATUS_KEYS.ERROR
            })
          );
          console.error(err);
        });
    };

    updatePatient();
  };

  if (!isReadOnly) {
    return (
      <form
        className={clsx(styles.PatientGeneralInfo)}
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Patient information section
        {/* set open to default if patient is for new patient */}
        {customPatientDemographicsFields?.map(
          ({ fieldType, fieldID: field, options, label }) => {
            let inputType = "text";
            switch (fieldType) {
              case FIELD_TYPES.DATE:
              case FIELD_TYPES.DATE_REQ:
                inputType = "date";
                break;
              case FIELD_TYPES.SINGLE_DROPDOWN:
                inputType = "select";
                break;
              case FIELD_TYPES.PHONE:
                return (
                  <div data-cy={"field-" + field} key={field}>
                    <Controller
                      control={control}
                      name={field}
                      render={({ field: inputField }) => (
                        <label className="horizontalLabel">
                          Phone Number
                          <IMaskInput
                            data-cy="input-phone-number"
                            type="text"
                            placeholder="(***)-***-****"
                            className="standardInput"
                            mask={IMask.createMask({ mask: "(000)-000-0000" })}
                            onAccept={value =>
                              inputField.onChange({
                                target: { name: inputField.name, value }
                              })
                            }
                            {...inputField}
                          />
                        </label>
                      )}
                      rules={{
                        onBlur: () => {
                          handleSubmit(onSubmit)();
                        }
                      }}
                    />
                  </div>
                );
                break;
              default:
                inputType = "text";
                break;
            }
            if (field === "ssn") {
              return (
                <div data-cy={"field-" + field} key={field}>
                  <Controller
                    control={control}
                    name={field}
                    render={({ field: inputField }) => (
                      <label className="horizontalLabel">
                        SSN
                        <IMaskInput
                          data-cy="input-ssn"
                          type="text"
                          placeholder="***-**-****"
                          className="standardInput"
                          mask={IMask.createMask({ mask: "000-00-0000" })}
                          onAccept={value =>
                            inputField.onChange({
                              target: { name: inputField.name, value }
                            })
                          }
                          {...inputField}
                        />
                      </label>
                    )}
                    rules={{
                      onBlur: () => {
                        handleSubmit(onSubmit)();
                      }
                    }}
                  />
                </div>
              );
            }
            return (
              <div data-cy={"field-" + field} key={field}>
                {fieldType !== FIELD_TYPES.SINGLE_DROPDOWN ? (
                  <GeneratedInput
                    key={field}
                    type={inputType}
                    label={label}
                    name={field}
                    id={field}
                    register={register}
                    errors={errors}
                    fullWidth={false}
                    rules={{
                      onBlur: () => {
                        handleSubmit(onSubmit)();
                      }
                    }}
                  />
                ) : (
                  <Controller
                    control={control}
                    name={field}
                    key={field}
                    rules={{
                      onBlur: () => {
                        handleSubmit(onSubmit)();
                      }
                    }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <label className="horizontalLabel">
                          {label}
                          <Select
                            options={options}
                            theme={SelectDefaultTheme}
                            styles={SelectDefaultStyles}
                            onChange={(v: any) => {
                              onChange(v.value);
                              handleSubmit(onSubmit)();
                            }}
                            value={options?.find(
                              item =>
                                item.value.toLowerCase() === value.toLowerCase()
                            )}
                            aria-label={label}
                          />
                        </label>
                      </>
                    )}
                  />
                )}
              </div>
            );
          }
        )}
        <Button
          style={STYLES.FULL_WIDTH}
          type="button"
          onClick={_ => onUpdate && onUpdate()}
        >
          Update
        </Button>
      </form>
    );
  } else {
    return (
      <KeyValueGrid twoColumn matchInputHeight>
        {customPatientDemographicsFields?.map(
          ({ fieldType, fieldID: field, label }) => {
            if (
              fieldType == FIELD_TYPES.DATE ||
              fieldType == FIELD_TYPES.DATE_REQ
            ) {
              if (!patient) {
                return <p key={field}></p>;
              }

              let formattedVal = "-";
              const val = getInitialValues(patient)[field as keyof {}] as
                | number
                | undefined;
              // We need to convert the YYYYMMDD integer to MM/DD/YYYY
              if (val) {
                formattedVal = dayjs(val).format("MM/DD/YYYY");
              }

              return (
                <KeyValueItem keyLabel={label} key={field}>
                  {patient ? (
                    <p data-cy={"field-" + field}>{formattedVal}</p>
                  ) : (
                    <p></p>
                  )}
                </KeyValueItem>
              );
            } else if (field.indexOf("addr") != -1) {
              if (field == "addr_line_1") {
                return (
                  <div style={{ gridColumn: "span 2" }} key={field}>
                    <KeyValueItem keyLabel="Address" longData>
                      <p>
                        {patient && patient.address
                          ? FORMAT.address(patient.address)
                          : "-"}
                      </p>
                    </KeyValueItem>
                  </div>
                );
              }
            } else if (field == "city" || field == "state" || field == "zip") {
              return;
            } else if (field == "ssn") {
              return patient ? (
                // Re-add the slashes we remove to submit to the backend
                <KeyValueItem keyLabel={label} key={field}>
                  <p>
                    {FORMAT.ssn(getInitialValues(patient)[field as keyof {}])}
                  </p>
                </KeyValueItem>
              ) : (
                <p></p>
              );
            } else {
              return (
                <KeyValueItem keyLabel={label} key={field}>
                  {patient ? (
                    <p data-cy={"field-" + field}>
                      {METRIC_LABELS[
                        getInitialValues(patient)[field as keyof {}]
                      ] || getInitialValues(patient)[field as keyof {}]}
                    </p>
                  ) : (
                    <p></p>
                  )}
                </KeyValueItem>
              );
            }
          }
        )}
      </KeyValueGrid>
    );
  }
}

function getInitialValues(patient: Partial<PatientInfo>) {
  let phoneStr = "";
  try {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    if (patient.phone && patient.phone.length >= 7) {
      const phone = phoneUtil.parse(patient.phone, "US");
      phoneStr = phoneUtil.formatInOriginalFormat(phone, "US");
    }
  } catch (e) {
    console.error("PHONE: ", patient.phone, e);
  }

  const defaults: Record<string, any> = {
    first_name: patient?.first_name ? patient?.first_name : "",
    last_name: patient?.last_name ? patient?.last_name : "",
    dob: patient?.dob
      ? dayjs(patient?.dob.toString(), "YYYYMMDD", true).format("YYYY-MM-DD")
      : "",
    addr_line_1: patient?.address?.line1 || "",
    addr_line_2: patient?.address?.line2 || "",
    addr_line_3: patient?.address?.line3 || "",
    city: patient?.address?.city || "",
    state: patient?.address?.state || "",
    zip: patient?.address?.zip || "",
    sex: patient?.sex || "",
    pronouns: patient?.pronouns || "",
    phone: phoneStr,
    email: patient?.email || "",
    ssn: patient.ssn || ""
  };

  return defaults;
}
