/* Note Section */
/* External Imports */
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { z, ZodType } from "zod";
import { sanitize } from "isomorphic-dompurify";
import dayjs from "dayjs";
import lodash from "lodash";

/* Local Imports */

// components
import Button from "@/components/button";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import ContentRenderer from "@/components/textArea/contentRenderer";
import TranscriptRenderer from "@/components/audioRecorder/transcriptRenderer";
import AudioRecorder from "@/components/audioRecorder";
import TabMenu from "@/components/tabbed";

// constants
import { FIELD_TYPES, buildZodObject } from "@/globals/constants/formSchema";
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import {
  EncounterInfo,
  EncounterType,
  useEncounterUpdateMutation
} from "@/store/services/encounter";
import { useGetFeatureFlagsQuery } from "@/store/services/system";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setTabId } from "@/components/tabbed/tabSlice";
import { RootState } from "@/store/store";
import { NablaNote } from "@/store/services/ai";

// styles
import styles from "./styles.module.scss";

/* Component Typescript Interface */
interface NoteProps {
  encounter: EncounterInfo;
  isEditable?: boolean;
  isPetrified?: boolean;
}

export default function Note({
  encounter,
  isEditable = true,
  isPetrified = false
}: NoteProps) {
  const router = useRouter();
  // Local State
  const dispatch = useDispatch();
  const tabId = useSelector((state: RootState) => state.tabMenu.tabId);

  // track whether form has been saved
  const [isEditMode, setIsEditMode] = useState<boolean>(
    isEditable && !encounter.note
  );
  // the transcript data from the audio recorder
  // this data comes from the webhook in the backend
  const [transcriptData, setTranscriptData] = useState<
    Array<Record<string, any>>
  >([]);
  // Autosave text notification
  const [autosaveNotification, setAutosaveNotification] = useState<string>("");
  const [isAutosaveError, setIsAutosaveError] = useState<boolean>(false);

  /* Redux */
  const [updateEncounter, updateEncounterRequest] =
    useEncounterUpdateMutation();

  const { data } = useGetFeatureFlagsQuery();

  /* React Use Form Configuration */
  const schema: ZodType = z.object(
    buildZodObject([{ fieldID: "SO_notes", fieldType: FIELD_TYPES.TEXT }])
  );

  const form = useForm<{ note: string }>({
    resolver: zodResolver(schema),
    defaultValues: {
      note: encounter.note
    }
  });

  const textAreaWatch = form.watch("note");

  useEffect(() => {
    const autosaveDebounce = setTimeout(() => {
      updateEncounter({
        encounterId: encounter.encounter_id,
        encounterCreateUpdatePayload: {
          patient_id: encounter.patient.user_id,
          provider_id: encounter.provider.user_id,
          encounter_type: encounter.encounter_type as EncounterType,
          note: textAreaWatch
        }
      })
        .unwrap()
        .then(_ => {
          setAutosaveNotification(
            "Saved at " + dayjs(Date.now()).format("h:mm a")
          );
          setIsAutosaveError(false);
        })
        .catch(err => {
          setAutosaveNotification("Autosaving unavailable right now");
          if (!isAutosaveError) {
            dispatch(
              addAlertToToastTrough({
                message: "Failed to update encounter note",
                type: STATUS_KEYS.ERROR
              })
            );
            setIsAutosaveError(true);
          }
        });
    }, 500);

    return () => clearTimeout(autosaveDebounce);
  }, [textAreaWatch]);

  useEffect(() => {
    setIsEditMode(isEditable);
  }, [isEditable]);

  // Update default values when the encounter prop changes
  useEffect(() => {
    form.reset({ note: encounter.note });
  }, [encounter.encounter_id]);

  const handleNoteGeneration = (
    noteContent: Array<string>,
    rawNoteData: NablaNote
  ) => {
    const contentString = noteContent.reduce((acc, curr) => {
      acc += curr;
      return acc;
    }, "");

    const oldNote = form.getValues("note");

    form.setValue("note", oldNote + "\n" + contentString);
    dispatch(setTabId("note"));
  };

  const handleTranscriptChange = (
    newTranscriptData: Array<Record<string, any>>
  ) => {
    setTranscriptData(newTranscriptData);
  };

  return (
    <div className={clsx(styles.Note)} data-cy="encounter-note">
      {data?.nabla_enabled && !isPetrified ? (
        <TabMenu
          tabs={[
            { id: "note", label: "Note" },
            { id: "transcript", label: "Transcript" }
          ]}
          style={STYLES.SECONDARY}
          initialSelectedTab="transcript"
          customElement={
            <AudioRecorder
              onNoteGeneration={handleNoteGeneration}
              onTranscriptChange={handleTranscriptChange}
              recordingIsDisabled={!isEditable}
            />
          }
        >
          {tab => {
            if (tab == "note") {
              return (
                <div className={styles.formType}>
                  {isEditMode ? (
                    <>
                      <ControlledTextArea
                        label=""
                        placeholder="Start typing or enter dot phrases to generate templates"
                        id="note"
                        name="note"
                        required={false}
                        setValue={form.setValue}
                        content={encounter.note || ""}
                        submitOnEnter
                        form={form}
                        rows={14}
                      />
                      <p
                        className={clsx(
                          { [styles.autosaveError]: isAutosaveError },
                          styles.autosaveNotification
                        )}
                      >
                        {autosaveNotification}
                      </p>
                    </>
                  ) : (
                    <ContentRenderer content={sanitize(encounter.note || "")} />
                  )}
                </div>
              );
            } else {
              return (
                <div id="note">
                  <TranscriptRenderer transcriptData={transcriptData} />
                </div>
              );
            }
          }}
        </TabMenu>
      ) : (
        <div className={styles.formType}>
          {isEditMode ? (
            <>
              <ControlledTextArea
                label=""
                placeholder="Start typing or enter dot phrases to generate templates"
                id="note"
                name="note"
                required={false}
                setValue={form.setValue}
                content={encounter.note || ""}
                form={form}
              />
              <p
                className={clsx(
                  { [styles.autosaveError]: isAutosaveError },
                  styles.autosaveNotification
                )}
              >
                {autosaveNotification}
              </p>
            </>
          ) : (
            <ContentRenderer content={sanitize(encounter.note || "")} />
          )}
        </div>
      )}
    </div>
  );
}
