import Confirmation from "./templates/confirmation";
import AppointmentModal from "./templates/appointment";
import ProblemList from "./templates/problemList";
import NewMessageModal from "./templates/newMessage";
import MessageConfirmation from "./templates/messageConfirmation";
import DeleteFilesConfirmation from "./templates/deleteFilesConfirmation";
import FaxFiles from "./templates/faxFiles";
import RxModal from "./templates/rx";
import CreatePatient from "@/components/forms/createUser";
import MedicationForm from "./templates/medication";
import AllergyList from "./templates/allergyList";
import ImmunizationList from "./templates/immunizationList";
import BirthSummary from "../birthSummary";
import GenericFormModal from "./templates/genericForm";
import ViewFile from "./templates/viewFile";
import ViewFax from "./templates/viewFax";
import ShareRecordsModal from "./templates/shareRecords";
import LabOrderingTemplate from "./templates/labOrdering";
import ConfirmMoveFile from "./templates/confirmFileRecipient";
import SetStaffSchedule from "./templates/setStaffSchedule";
import EditLaborProgressUpdate from "./templates/editLaborProgressUpdate";
import EditLaborInfantProgressUpdate from "./templates/editLaborInfantProgressUpdate";
import EditLaborMedication from "./templates/editLaborMedication";
import EditLaborRecurringEvent from "./templates/editLaborRecurringEvent";
import ReleaseFilesConfirmation from "./templates/releaseFilesConfirmation";
import HideFilesConfirmation from "./templates/hideFilesConfirmation";
import SecretNoteModal from "./templates/secretNote";
import CopyFaxes from "./templates/copyFaxes";
import ForwardFaxes from "./templates/forwardFaxes";
import AddTransaction from "./templates/addTransaction";
import TransactionDetails from "./templates/transactionDetails";
import PastPregnancy from "./templates/pastPregnancy";
import LoadingSpinner from "./templates/loadingSpinner";
import MarkPregnancyDelivered from "./templates/markPregnancyDelivered";
import CreateInfant from "./templates/createInfantChart";
import PregnancyDating from "./templates/pregnancyDating";
import NewLmpEstimate from "./templates/newLmpEstimate";
import NewUltrasoundEstimate from "./templates/newUltrasoundEstimate";
import CopyFiles from "./templates/copyFiles";
import UploadTaskModal from "./templates/uploadTask";
import GenericTaskModal from "./templates/genericTask";
import NewUnknownEstimate from "./templates/newUnknownEstimate";
import AddNewPayorModal from "./templates/addNewPayor";
import DemographicsTaskModal from "./templates/demographicsTaskModal";
import PatientRecall from "./templates/patientRecall/index";
import ConfirmDeleteEncounter from "./templates/confirmDeleteEncounter";
import EditLaborMilestoneForm from "./templates/editLaborMilestoneForm";
import { EditHealthHistoryEntry } from "./templates/editHealthHistoryEntry";
import MedicationListModal from "./templates/medicationList";
import EditEncounterDetails from "./templates/editEncounterDetails";
import NewDateOfConceptionEstimate from "./templates/newDateOfConceptionEstimate";
import EditLaborSoapNote from "./templates/editLaborSoapNote";
import DotPhraseForm from "./templates/dotPhraseForm";
import CreateTaskFlow from "./templates/createTaskFlow";
import SendFilesForReview from "./templates/sendFileForReview";
import SendFaxForReview from "./templates/sendFaxForReview";
import PreviewPDF from "./templates/shareRecords/previewPdf";
import SendRecordsForm from "./templates/shareRecords/sendRecordsForm";
import AreYouPrenatalCharting from "./templates/areYouPrenatalCharting";
import StaffUpsert from "./templates/staffUpsert";
import AppointmentTypeUpsert from "./templates/appointmentTypeUpsert";
import TaskBundleUpsert from "./templates/taskBundleUpsert";

const MODAL_TYPES = {
  CONFIRM: "confirmation",
  PROBLEMS: "problems",
  APPOINTMENT: "appointment",
  TEST_SELECTION: "test_selection",
  ARE_YOU_PRENATAL_CHARTING: "are_you_prenatal_charting",
  SEND_ORDERS: "send_orders",
  NEW_MESSAGE: "send_internal_message",
  MESSAGE_CONFIRMATION: "message_sent_confirmation",
  DELETE_FILES_CONFIRMATION: "delete_files_confirmation",
  RELEASE_FILES_CONFIRMATION: "release_files_confirmation",
  HIDE_FILES_CONFIRMATION: "hide_files_confirmation",
  FAX_FILES: "fax_files",
  RX: "prescriptions",
  NEW_PATIENT: "new_patient",
  DOT_PHRASE_FORM: "dot_phrase_form",
  ADD_MEDICATION: "add_medication",
  MEDICATION_LIST: "medication_list",
  ALLERGIES: "allergies",
  IMMUNIZATIONS: "immunizations",
  EDIT_ENCOUNTER_DETAILS: "edit_encounter_details",
  BIRTH_SUMMARY: "birth_summary",
  GENERIC_FORM: "generic_form",
  VIEW_FILE: "view_file",
  VIEW_FAX: "view_fax",
  SHARE_RECORDS: "share_records",
  CONFIRM_FILE_MOVE: "confirm_file_move",
  STAFF_SCHEDULE: "staff_schedule",
  EDIT_LABOR_PROGRESS_UPDATE: "edit_labor_progress_update",
  EDIT_LABOR_INFANT_PROGRESS_UPDATE: "edit_labor_infant_progress_update",
  EDIT_LABOR_MEDICATION: "edit_labor_medication",
  EDIT_LABOR_RECURRING_EVENT: "edit_labor_recurring_event",
  EDIT_LABOR_MILESTONE_EVENT: "edit_labor_milestone_event",
  EDIT_LABOR_SOAP_NOTE: "edit_labor_soap_note",
  SECRET_NOTE: "secret_note",
  COPY_FAXES: "copy_faxes",
  FORWARD_FAXES: "forward_faxes",
  ADD_TRANSACTION: "add_transaction",
  TRANSACTION_DETAILS: "transaction_details",
  VIEW_INTERACTIVE_FILE: "view_interactive_file",
  PAST_PREGNANCY: "past_pregnancy",
  LOADING_SPINNER: "loading_spinner",
  DELIVER_PREGNANCY: "deliver_pregnancy",
  CREATE_INFANT: "create_infant",
  DATING: "dating",
  NEW_LMP_ESTIMATE: "new_lmp_estimate",
  NEW_ULTRASOUND_ESTIMATE: "new_ultrasound_estimate",
  NEW_DOC_ESTIMATE: "new_doc_estimate",
  NEW_UNKNOWN_ESTIMATE: "new_unknown_estimate",
  COPY_FILES: "copy_files",
  SEND_FOR_REVIEW: "send_for_review",
  CREATE_TASK_FLOW: "create_task_flow",
  PREVIEW_PDF: "preview_pdf",
  UPLOAD_TASK: "upload_task",
  GENERIC_TASK: "generic_task",
  DEMOGRAPHICS_TASK: "demographics_task",
  ADD_NEW_PAYOR: "add_new_payor",
  STAFF_UPSERT: "staff_upsert",
  APPOINTMENT_TYPE_UPSERT: "appointment_type_upsert",
  TASK_BUNDLE_UPSERT: "task_bundle_upsert",
  PATIENT_RECALL: "patient_recall",
  PATIENT_RECALL_EDIT: "patient_recall_edit",
  CONFIRM_DELETE_ENCOUNTER: "confirm_delete_encounter",
  EDIT_HEALTH_HISTORY_ENTRY: "edit_health_history_entry",
  SEND_FAX_FOR_REVIEW: "send_fax_for_review",
  SEND_RECORDS_FORM: "send_records_form",
  DOCUMENT_REVIEW: "document_review"
};

const MODAL_DISPATCHER = {
  [MODAL_TYPES.ARE_YOU_PRENATAL_CHARTING]: (props: any) => (
    <AreYouPrenatalCharting {...props} />
  ),

  [MODAL_TYPES.PROBLEMS]: (props: any) => <ProblemList {...props} />,
  [MODAL_TYPES.ALLERGIES]: (props: any) => <AllergyList {...props} />,
  [MODAL_TYPES.IMMUNIZATIONS]: (props: any) => <ImmunizationList {...props} />,

  [MODAL_TYPES.CONFIRM]: (props: any) => <Confirmation {...props} />,
  [MODAL_TYPES.APPOINTMENT]: (props: any) => <AppointmentModal {...props} />,
  [MODAL_TYPES.TEST_SELECTION]: (props: any) => (
    <LabOrderingTemplate {...props} />
  ),
  [MODAL_TYPES.NEW_MESSAGE]: (props: any) => <NewMessageModal {...props} />,
  [MODAL_TYPES.MESSAGE_CONFIRMATION]: (props: any) => (
    <MessageConfirmation {...props} />
  ),
  [MODAL_TYPES.DELETE_FILES_CONFIRMATION]: (props: any) => (
    <DeleteFilesConfirmation {...props} />
  ),
  [MODAL_TYPES.RELEASE_FILES_CONFIRMATION]: (props: any) => (
    <ReleaseFilesConfirmation {...props} />
  ),
  [MODAL_TYPES.HIDE_FILES_CONFIRMATION]: (props: any) => (
    <HideFilesConfirmation {...props} />
  ),
  [MODAL_TYPES.FAX_FILES]: (props: any) => <FaxFiles {...props} />,
  [MODAL_TYPES.RX]: (props: any) => <RxModal {...props} />,
  [MODAL_TYPES.NEW_PATIENT]: (props: any) => <CreatePatient />,
  [MODAL_TYPES.DOT_PHRASE_FORM]: (props: any) => <DotPhraseForm {...props} />,
  [MODAL_TYPES.ADD_MEDICATION]: (props: any) => <MedicationForm {...props} />,
  [MODAL_TYPES.MEDICATION_LIST]: (props: any) => (
    <MedicationListModal {...props} />
  ),
  [MODAL_TYPES.EDIT_ENCOUNTER_DETAILS]: (props: any) => (
    <EditEncounterDetails {...props} />
  ),
  [MODAL_TYPES.BIRTH_SUMMARY]: (props: any) => <BirthSummary {...props} />,
  [MODAL_TYPES.GENERIC_FORM]: (props: any) => <GenericFormModal {...props} />,
  [MODAL_TYPES.VIEW_FILE]: (props: any) => <ViewFile {...props} />,
  [MODAL_TYPES.VIEW_FAX]: (props: any) => <ViewFax {...props} />,
  [MODAL_TYPES.SHARE_RECORDS]: (props: any) => <ShareRecordsModal {...props} />,
  [MODAL_TYPES.SEND_RECORDS_FORM]: (props: any) => (
    <SendRecordsForm {...props} />
  ),
  [MODAL_TYPES.CONFIRM_FILE_MOVE]: (props: any) => (
    <ConfirmMoveFile {...props} />
  ),
  [MODAL_TYPES.STAFF_SCHEDULE]: (props: any) => <SetStaffSchedule {...props} />,
  [MODAL_TYPES.STAFF_UPSERT]: (props: any) => <StaffUpsert {...props} />,
  [MODAL_TYPES.APPOINTMENT_TYPE_UPSERT]: (props: any) => (
    <AppointmentTypeUpsert {...props} />
  ),
  [MODAL_TYPES.TASK_BUNDLE_UPSERT]: (props: any) => (
    <TaskBundleUpsert {...props} />
  ),
  [MODAL_TYPES.EDIT_LABOR_PROGRESS_UPDATE]: (props: any) => (
    <EditLaborProgressUpdate {...props} />
  ),
  [MODAL_TYPES.EDIT_LABOR_INFANT_PROGRESS_UPDATE]: (props: any) => (
    <EditLaborInfantProgressUpdate {...props} />
  ),
  [MODAL_TYPES.EDIT_LABOR_MEDICATION]: (props: any) => (
    <EditLaborMedication {...props} />
  ),
  [MODAL_TYPES.EDIT_LABOR_RECURRING_EVENT]: (props: any) => (
    <EditLaborRecurringEvent {...props} />
  ),
  [MODAL_TYPES.EDIT_LABOR_MILESTONE_EVENT]: (props: any) => (
    <EditLaborMilestoneForm {...props} />
  ),
  [MODAL_TYPES.EDIT_LABOR_SOAP_NOTE]: (props: any) => (
    <EditLaborSoapNote {...props} />
  ),
  [MODAL_TYPES.SECRET_NOTE]: (props: any) => <SecretNoteModal {...props} />,
  [MODAL_TYPES.COPY_FAXES]: (props: any) => <CopyFaxes {...props} />,
  [MODAL_TYPES.FORWARD_FAXES]: (props: any) => <ForwardFaxes {...props} />,
  [MODAL_TYPES.ADD_TRANSACTION]: (props: any) => <AddTransaction {...props} />,
  [MODAL_TYPES.TRANSACTION_DETAILS]: (props: any) => (
    <TransactionDetails {...props} />
  ),
  [MODAL_TYPES.FORWARD_FAXES]: (props: any) => <ForwardFaxes {...props} />,
  [MODAL_TYPES.PAST_PREGNANCY]: (props: any) => <PastPregnancy {...props} />,
  [MODAL_TYPES.LOADING_SPINNER]: (props: any) => <LoadingSpinner {...props} />,
  [MODAL_TYPES.DELIVER_PREGNANCY]: (props: any) => (
    <MarkPregnancyDelivered {...props} />
  ),
  [MODAL_TYPES.CREATE_INFANT]: (props: any) => <CreateInfant {...props} />,
  [MODAL_TYPES.DATING]: (props: any) => <PregnancyDating {...props} />,
  [MODAL_TYPES.NEW_LMP_ESTIMATE]: (props: any) => <NewLmpEstimate {...props} />,
  [MODAL_TYPES.NEW_ULTRASOUND_ESTIMATE]: (props: any) => (
    <NewUltrasoundEstimate {...props} />
  ),
  [MODAL_TYPES.NEW_DOC_ESTIMATE]: (props: any) => (
    <NewDateOfConceptionEstimate {...props} />
  ),
  [MODAL_TYPES.NEW_UNKNOWN_ESTIMATE]: (props: any) => (
    <NewUnknownEstimate {...props} />
  ),
  [MODAL_TYPES.COPY_FILES]: (props: any) => <CopyFiles {...props} />,
  [MODAL_TYPES.CREATE_TASK_FLOW]: (props: any) => <CreateTaskFlow {...props} />,
  [MODAL_TYPES.PREVIEW_PDF]: (props: any) => <PreviewPDF {...props} />,
  [MODAL_TYPES.UPLOAD_TASK]: (props: any) => <UploadTaskModal {...props} />,
  [MODAL_TYPES.GENERIC_TASK]: (props: any) => <GenericTaskModal {...props} />,
  [MODAL_TYPES.DEMOGRAPHICS_TASK]: (props: any) => (
    <DemographicsTaskModal {...props} />
  ),
  [MODAL_TYPES.ADD_NEW_PAYOR]: (props: any) => <AddNewPayorModal {...props} />,
  [MODAL_TYPES.PATIENT_RECALL]: (props: any) => <PatientRecall {...props} />,
  [MODAL_TYPES.CONFIRM_DELETE_ENCOUNTER]: (props: any) => (
    <ConfirmDeleteEncounter {...props} />
  ),
  [MODAL_TYPES.EDIT_HEALTH_HISTORY_ENTRY]: (props: any) => (
    <EditHealthHistoryEntry {...props} />
  ),
  [MODAL_TYPES.SEND_FOR_REVIEW]: (props: any) => (
    <SendFilesForReview {...props} />
  ),
  [MODAL_TYPES.SEND_FAX_FOR_REVIEW]: (props: any) => (
    <SendFaxForReview {...props} />
  )
};

export { MODAL_DISPATCHER, MODAL_TYPES };
