import { StylesConfig, Theme } from "react-select";

export function SelectDefaultTheme(theme: Theme): Theme {
  return {
    ...theme,
    borderRadius: 8,
    colors: {
      ...theme.colors,
      primary: "rgba(72, 110, 131, 1)",
      primary75: "#5b8094",
      primary50: "#7398ab",
      primary25: "#8dadbe"
    }
  } as Theme;
}

export const SelectDefaultStyles: StylesConfig = {
  container: styles => ({
    ...styles,
    width: "100%"
  }),
  control: styles => ({
    ...styles,
    fontSize: "14px",
    minHeight: "36px",
    height: "36px",
  }),
  option: styles => ({
    ...styles,
    fontSize: "14px"
  })
};
