// External
import { useDispatch } from "react-redux";

// Components
import KeyValueGrid from "@/components/birthSummary/keyValueGrid";
import KeyValueItem from "@/components/birthSummary/keyValueItem";
import ListingControls from "../components/listingControls";

// Store
import {
  PatientInfo,
  usePatientUpsertMutation,
  UserEmergencyContact
} from "@/store/services/patient";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { STATUS_TYPES } from "@/globals/constants";

// Styles
import styles from "../../../styles.module.scss";

interface ContactReadOnlyProps {
  contact: UserEmergencyContact;
  contactIndex: number;
  patientInfo: PatientInfo;
  onEdit: () => void;
}

export default function ContactReadOnly({
  contact,
  onEdit,
  contactIndex,
  patientInfo
}: ContactReadOnlyProps) {
  const dispatch = useDispatch();

  const [updatePatient] = usePatientUpsertMutation();

  const handleDelete = () => {
    const allContacts = [...(patientInfo.emergency_contacts || [])];
    if (!allContacts || allContacts.length < contactIndex) {
      throw new Error("Trying to update contact that doesn't exist");
    }

    allContacts.splice(contactIndex, 1);

    updatePatient({
      patientUpsertRequest: {
        ...patientInfo,
        emergency_contacts: allContacts
      }
    }).unwrap().catch((err) => {
      dispatch(addAlertToToastTrough({
        message: "Something went wrong deleting the contact",
        type: STATUS_TYPES.ERROR,
      }));
      console.error(err);
    });
  };

  return (
    <div className={styles.item}>
      <div className={styles.formFields}>
        <KeyValueGrid matchInputHeight horizontal>
          <KeyValueItem keyLabel="Name">
            <p>{contact.name || "-"}</p>
          </KeyValueItem>
          <KeyValueItem keyLabel="Email">
            <p>{contact.email || "-"}</p>
          </KeyValueItem>
          <KeyValueItem keyLabel="Phone">
            <p>
              {contact.phone_type ? contact.phone_type + ": " : ""}
              {contact.phone || "-"}
            </p>
          </KeyValueItem>
          <KeyValueItem keyLabel="Relationship">
            <p>{contact.relationship}</p>
          </KeyValueItem>
        </KeyValueGrid>
      </div>
      <ListingControls
        buttonType="EDIT"
        onButtonPress={() => onEdit()}
        onDelete={() => handleDelete()}
      />
    </div>
  );
}
