import {
  EncounterInfo,
  useEncounterListQuery,
  usePatientGetInfoQuery,
  usePatientGetLabOrdersQuery,
  UserId
} from "@/store/services/patient";
import { useFileListQuery } from "@/store/services/file";
import { useMemo } from "react";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import {
  ListOption,
  useEncounterDataList,
  useFileDataList,
  useImagingDataList,
  useLabDataList,
  usePatientDataList
} from "../helpers/recordGenerationHooks";
import AdditionalDataCheckList from "../additionalDataCheckList";
interface StandardGenerationFormProps {
  patientId: UserId;
  form: any;
}

export default function StandardGenerationForm({
  patientId,
  form
}: StandardGenerationFormProps) {
  const additionalDataOptions = usePatientDataList("STANDARD");
  const fileListOptions = useFileDataList(patientId);
  const labListOptions = useLabDataList(patientId);
  const imagingListOptions = useImagingDataList(patientId);
  const encounterListOptions = useEncounterDataList(patientId);

  const encounterAdditionalListOptions: ListOption[] = [
    {
      id: "include_codes",
      label: "Include Codes"
    }
  ];

  return (
    <div className={styles.formContainer}>
      <AdditionalDataCheckList
        form={form}
        listId="patient_data"
        listLabel="Patient Data"
        listOptions={additionalDataOptions}
      />
      <AdditionalDataCheckList
        form={form}
        listId="encounters"
        listLabel="Encounters"
        listOptions={encounterListOptions}
        additionalListOptions={encounterAdditionalListOptions}
      />
      <AdditionalDataCheckList
        form={form}
        listId="labs"
        listLabel="Labs"
        listOptions={labListOptions}
      />
      <AdditionalDataCheckList
        form={form}
        listId="imaging"
        listLabel="Images"
        listOptions={imagingListOptions}
      />
      <AdditionalDataCheckList
        form={form}
        listId="files"
        listLabel="Files"
        listOptions={fileListOptions}
      />
    </div>
  );
}
