// External
import { useEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";

// API
import { FileListApiArg, useFileListQuery } from "@/store/services/file";
import {
  EncounterListApiArg,
  LaborTransferPdfParams,
  PatientPdfSection,
  useEncounterListQuery,
  usePatientGetInfoQuery,
  UserId
} from "@/store/services/patient";
import { FormType, getEncounterLabel } from "./utilities";

/*============
* Form Types
==============*/
// This is the string references of our "lists" which are our list sections that have either hard coded or
// dynamic lists of checkboxes of what to include in the PDF generation.
export type ListId =
  | "encounters"
  | "labs"
  | "files"
  | "imaging"
  | "patient_data";

// The key for dynamic resources selected from the lists (i.e. encounters_[ENCOUNTER_ID])
export type ResourceIdKey = `${ListId}_${number}`;

/**
 * This type handles all of the dynamic data types.
 * ResourceIdKey => encounters_12, labs_1, etc.
 * PatientPdfSection (comes from OpenAPI spec) => demographics, problem_list, etc.
 * `all_${SectionId} => all_demographics, all_encounters, etc
 */
type RecordGenerationFormGeneric = Record<
  ResourceIdKey | PatientPdfSection | `all_${ListId}`,
  boolean
>;

interface StandardContactForm {
  recipient_name?: string;
  recipient_fax?: string;
  sender_name?: string;
  sender_phone?: string;
  record_note?: string;
}

// The sections shared between all the different form types
export interface RecordGenerationFormValues
  extends RecordGenerationFormGeneric,
    StandardContactForm,
    LaborTransferPdfParams {
  include_newborn_exams: boolean;
  include_codes: boolean;
}

// Custom typeguard for PatientPdfSection
export function isPatientPdfSection(
  section: string
): section is PatientPdfSection {
  const sections: PatientPdfSection[] = [
    "demographics",
    "insurance",
    "encounter_list",
    "problem_list",
    "medication_list",
    "allergy_list",
    "health_history",
    "prenatal_flowsheet",
    "pregnancy_edd",
    "past_pregnancies",
    "pediatric_record",
    "labor_transfer",
    "mother_demographics",
    "infant_growth_sheet"
  ];
  return sections.includes(section as PatientPdfSection);
}

/*=============
 * Hook types
 * Types for the hooks and helper functions
 *==============*/
// The SectionListOptionId has to map to a key of our form so when building our lists dynamically, the
// two match up
export type ListOptionId = keyof RecordGenerationFormValues;

// The type for the list options. This is the type that represents a checkbox in our form. The label
// is just for display, but the ID maps to the type of our form so that when building a dynamic list
// of checkboxes, we'll always have strong typing for our form.
export type ListOption = {
  label: string;
  id: ListOptionId;
};

/* ===========
 * React Hooks
 * Custom hooks specifically for the PDF generation functionality
 */

/**
 * This is a hook that returns the correct ListOption array for the options for specific patient types.
 * @param patientType The type of patient you need the form for
 * @returns An array of ListOption with the options for that particular form type
 */
const usePatientDataList = (patientType: "STANDARD" | "INFANT") => {
  if (patientType === "STANDARD") {
    const options: ListOption[] = [
      {
        id: "demographics",
        label: "Demographics"
      },
      {
        id: "insurance",
        label: "Insurance"
      },
      {
        id: "problem_list",
        label: "Problem List"
      },
      {
        id: "medication_list",
        label: "Current Medications"
      },
      {
        id: "allergy_list",
        label: "Allergies"
      },
      {
        id: "health_history",
        label: "Health History"
      },
      {
        id: "past_pregnancies",
        label: "Past Pregnancies",
      },
      {
        id: "prenatal_flowsheet",
        label: "Prenatal flow sheet"
      }
    ];
    return options;
  } else {
    // TODO: INFANT OPTIONS
    const options: ListOption[] = [
      {
        id: "demographics",
        label: "Demographics"
      },
      {
        id: "insurance",
        label: "Insurance",
      },
      {
        id: "problem_list",
        label: "Problem List"
      },
      {
        id: "medication_list",
        label: "Current Medications"
      },
      {
        id: "allergy_list",
        label: "Allergies"
      },
      {
        id: "health_history",
        label: "Health History"
      },
      {
        id: "mother_demographics",
        label: "Mother's Demographics"
      },
      {
        id: "infant_growth_sheet",
        label: "Infant Growth Sheet"
      }
    ];
    return options;
  }
};

/**
 * This hook returns a listing in the form of ListOption[] for the patient's encounters
 * @param patientId The patient you need the listing for
 * @returns ListOption[] of formatted encounter data
 */
const useEncounterDataList = (patientId: UserId) => {
  const encounterListArg: EncounterListApiArg = {
    scope: "patient",
    id: patientId,
    pagesz: 100
  };

  const { data: encounters } = useEncounterListQuery(encounterListArg);

  const encounterListOptions: ListOption[] = useMemo(() => {
    if (!encounters) return [];

    return encounters.map(
      encounter =>
        ({
          id: `encounters_${encounter.encounter_id}`,
          label: getEncounterLabel(encounter)
        }) as ListOption
    );
  }, [encounters, patientId]);

  return encounterListOptions;
};

/**
 * This hook returns the ListOption[] of formatted lab data for a patient
 * @param patientId The patient you need the listing for
 * @returns ListOption[] of formatted lab data
 */
const useLabDataList = (patientId: UserId) => {
  const fileListArg: FileListApiArg = {
    scope: "patient",
    id: patientId,
    tagName: "labs",
    pagesz: 1000
  };

  const { data: labData } = useFileListQuery(fileListArg, {
    skip: !patientId
  });

  const labListOptions: ListOption[] = useMemo(() => {
    if (!labData) return [];

    return labData.map(
      lab =>
        ({
          id: `labs_${lab.file_id}`,
          label: lab.name
        }) as ListOption
    );
  }, [labData, patientId]);

  return labListOptions;
};

/**
 * This hook returns the ListOption[] for the file data for a patient, excluding the labs and imaging files
 * @param patientId The patient id you need the list for
 * @returns Formatted ListOption[] of file data
 */
const useFileDataList = (patientId: UserId) => {
  const fileListArg: FileListApiArg = {
    scope: "patient",
    id: patientId,
    pagesz: 1000,
    tagNameExclude: ["labs", "imaging"]
  };
  const { data: fileData } = useFileListQuery(fileListArg, {
    skip: !patientId
  });

  const fileListOptions: ListOption[] = useMemo(() => {
    if (!fileData) return [];

    return fileData.map(
      file =>
        ({
          id: `files_${file.file_id}`,
          label: file.name
        }) as ListOption
    );
  }, [fileData, patientId]);

  return fileListOptions;
};

/**
 * This hook returns the formatted ListOption[] of the imaging data for the patient
 * @param patientId The id of the patient you need the listing for
 * @returns The formatted ListOption[] of the imaging data for the patient
 */
const useImagingDataList = (patientId: UserId) => {
  const fileListArg: FileListApiArg = {
    scope: "patient",
    id: patientId,
    tagName: "imaging",
    pagesz: 1000
  };
  const { data: imageFiles } = useFileListQuery(fileListArg, {
    skip: !patientId
  });

  const imagesListOptions: ListOption[] = useMemo(() => {
    if (!imageFiles) return [];

    return imageFiles.map(
      file =>
        ({
          id: `imaging_${file.file_id}`,
          label: file.name
        }) as ListOption
    );
  }, [imageFiles, patientId]);

  return imagesListOptions;
};

/**
 * This hook returns whether or not we're sending the PDF generation form. This is determined
 * by whether or not the user has entered data for the recipient's name or fax number.
 * @param form The UseForm hook return of the form in question
 * @returns A boolean of whether or not we're sending the PDF as a fax or just printing it.
 */
const useIsSendingFax = (form: UseFormReturn<RecordGenerationFormValues>) => {
  const [val, setVal] = useState<boolean>(false);
  const nameWatch = form.watch("recipient_name");
  const faxWatch = form.watch("recipient_fax");
  const noteWatch = form.watch("record_note");
  useEffect(() => {
    const hasRecipient: boolean = !!(nameWatch && nameWatch != "");
    const hasFax: boolean = !!(faxWatch && faxWatch != "");
    // If they filled out the note, they probably want to send a fax
    const hasNote: boolean = !!(noteWatch && noteWatch != "");
    // If one of the watches has content we set val to true
    setVal(hasRecipient || hasFax || hasNote);
  }, [nameWatch, faxWatch, noteWatch]);
  return val;
};

const useIsContactError = (form: UseFormReturn<RecordGenerationFormValues>) => {
  const [val, setVal] = useState<boolean>(false);
  const nameWatch = form.watch("recipient_name");
  const faxWatch = form.watch("recipient_fax");

  useEffect(() => {
    const hasRecipient: boolean = !!(nameWatch && nameWatch != "");
    const hasFax: boolean = !!(faxWatch && faxWatch != "");
    // If one of the watches is falsy or empty this is true
    setVal(!hasRecipient || !hasFax);
  }, [nameWatch, faxWatch]);
  return val;
};

const useIsDataOptionsError = (form: UseFormReturn<RecordGenerationFormValues>) => {
  const [isError, setIsError] = useState<boolean>(true);
  const watchAll = form.watch();
  useEffect(() => {
    Object.values(watchAll).forEach((value: any) => {
      if (value === true) {
        setIsError(false);
        return;
      }
    })
  }, [watchAll]);
  return isError;
}

const useFormType = (patientId: number) => {
  const { data: patientInfo } = usePatientGetInfoQuery(
    { patientId },
    { skip: !patientId }
  );

  const formType: FormType = useMemo(() => {
    if (!patientInfo) return "LOADING";

    if (patientInfo.practice_data.type == "INFANT") {
      return "INFANT";
    }

    return "STANDARD";
  }, [patientInfo]);

  return formType;
};

export {
  usePatientDataList,
  useEncounterDataList,
  useLabDataList,
  useFileDataList,
  useImagingDataList,
  useIsSendingFax,
  useIsContactError,
  useIsDataOptionsError,
  useFormType
};
