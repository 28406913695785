const getUserMediaStream = async (): Promise<MediaStream> => {
  let stream: MediaStream;
  try {
    stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: "default",
        sampleRate: 16000,
        sampleSize: 16,
        channelCount: 1
      },
    });

    // Verify we got a valid stream with at least one audio track
    if (!stream || stream.getAudioTracks().length === 0) {
      throw new Error("No audio track available in media stream");
    }
  } catch (error: any) {
    if (error instanceof DOMException) {
      switch (error.name) {
        case "NotAllowedError":
          // User denied permission or permission was previously denied
          // We have another helper to check permissions so this realistic should never happen
          throw new Error("Microphone access denied");
        case "NotFoundError":
          // The user doesn't have an available microphone
          throw new Error("No microphone detected on your device");
        case "NotReadableError":
          // There's a microphone but it's unavailable for one reason or another
          throw new Error("Your microphone is busy or unavailable");
        default:
          throw new Error("Microphone access error: " + error.message);
      }
    }
    throw error;
  }

  // We can safely confirm this stream is ready and available
  return stream;
};

//********** DEBUG HELPERs */
// Convert the raw PCM data to wav so you can listen back to it
function pcmToWav(
  samples: Float32Array,
  opts = { sampleRate: 16000, channels: 1 }
) {
  const buffer = new ArrayBuffer(44 + samples.length * 2);
  const view = new DataView(buffer);

  // Write WAV header
  writeString(view, 0, "RIFF");
  view.setUint32(4, 32 + samples.length * 2, true);
  writeString(view, 8, "WAVE");
  writeString(view, 12, "fmt ");
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true);
  view.setUint16(22, opts.channels, true);
  view.setUint32(24, opts.sampleRate, true);
  view.setUint32(28, opts.sampleRate * 2, true);
  view.setUint16(32, 2, true);
  view.setUint16(34, 16, true);
  writeString(view, 36, "data");
  view.setUint32(40, samples.length * 2, true);

  // Write PCM samples
  floatTo16BitPCM(view, 44, samples);

  return buffer;
}

function writeString(view: DataView, offset: number, string: string) {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}

function floatTo16BitPCM(view: DataView, offset: number, input: Float32Array) {
  for (let i = 0; i < input.length; i++, offset += 2) {
    const s = Math.max(-1, Math.min(1, input[i]));
    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
}

export { getUserMediaStream, pcmToWav };
