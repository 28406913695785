import * as generated from "./generated/lab";

const lab = generated.lab.enhanceEndpoints({
  addTagTypes: ["Encounter"],
  endpoints: {
    labSendOrder: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Encounter", "LabOrder"];
      }
    },
    labToggleOrderReviewed: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Encounter", "LabOrder"];
      }
    },
    labOrderUpdate: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Encounter", "LabOrder"];
      }
    },
    labOrderCreate: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Encounter", "LabOrder"];
      }
    },
    labDeleteOrder: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Encounter", "LabOrder"];
      }
    },
    labOrderAnnotationCreate: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["OrderAnnotations"];
      }
    },
    labOrderAnnotationDelete: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["OrderAnnotations"];
      }
    },
    labOrderAnnotationUpdate: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["OrderAnnotations"];
      }
    },
    labOrderAnnotationList: {
      providesTags: (_resp, _err, _req) => {
        return ["OrderAnnotations"];
      }
    }
  }
});

export * from "./generated/lab";
export default lab;
export const {
  useSetClaimNonBillableMutation,
  useLabListQuery,
  useLazyLabListQuery,
  useListAcogTestsQuery,
  useLazyListAcogTestsQuery,
  useLabGetInfoQuery,
  useLazyLabGetInfoQuery,
  useLabOrderCreateMutation,
  useLabOrderUpdateMutation,
  useLabDeleteOrderMutation,
  useLabListTestsQuery,
  useLazyLabListTestsQuery,
  useLabGetAoeQuestionsQuery,
  useLazyLabGetAoeQuestionsQuery,
  useLabSearchLocationsMutation,
  useLabBookmarkLocationMutation,
  useLabListBookmarkedLocationsQuery,
  useLazyLabListBookmarkedLocationsQuery,
  useLabGetLocationInfoQuery,
  useLazyLabGetLocationInfoQuery,
  useLabDeleteBookmarkedLocationMutation,
  useLabListTestBundlesQuery,
  useLazyLabListTestBundlesQuery,
  useLabCreateTestBundleMutation,
  useLabUpdateTestBundleMutation,
  useLabDeleteTestBundleMutation,
  useLabSendOrderMutation,
  useLabGetOrderResultsPdfQuery,
  useLazyLabGetOrderResultsPdfQuery,
  useLabGetOrderRequisitionPdfQuery,
  useLazyLabGetOrderRequisitionPdfQuery,
  useLabGetOrderObservationsQuery,
  useLazyLabGetOrderObservationsQuery,
  useLabToggleOrderReviewedMutation,
  useLabGetOrderAbnQuery,
  useLazyLabGetOrderAbnQuery,
  useLabGetOrderLetterLabelsQuery,
  useLazyLabGetOrderLetterLabelsQuery,
  useLabGetOrderDymoLabelsQuery,
  useLazyLabGetOrderDymoLabelsQuery,
  useLabOrderAnnotationCreateMutation,
  useLabOrderAnnotationUpdateMutation,
  useLabOrderAnnotationDeleteMutation,
  useLabOrderAnnotationListQuery,
  useLazyLabOrderAnnotationListQuery
} = lab;
