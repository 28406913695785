// external
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

// components
import FlowSheet from "@/components/flowSheet/FlowSheet";
import { GridDataType } from "@/components/datagrid";
import {
  getWeightObjectFromGrams,
  Weight
} from "@/components/input/weightInput";

// store
import {
  EncounterInfo,
  EncounterType,
  useEncounterUpdateMutation
} from "@/store/services/encounter";
import { usePatientGetInfantGrowthSheetQuery } from "@/store/services/patient";

// constants
import { GRID_METRICS, METRICS, APP_KEYS, PAGES } from "@/globals/constants";

// styles
import styles from "@/components/datagrid/styles.module.scss";

export interface FetalGrowthFlowSheetProps {
  encounter?: EncounterInfo;
  patientId?: number;
  isReadOnly?: boolean;
}
export default function FetalGrowthFlowSheet({
  encounter,
  patientId,
  isReadOnly
}: FetalGrowthFlowSheetProps) {
  const router = useRouter();
  const [updateEncounter] = useEncounterUpdateMutation();
  const { data: infantGrowthSheet, isLoading } =
    usePatientGetInfantGrowthSheetQuery({
      patientId: patientId || (encounter?.patient.user_id as number)
    });

  const [data, setData] = useState<GridDataType>([]);

  const headers: string[] = GRID_METRICS[APP_KEYS.FETAL_GROWTH];

  useEffect(() => {
    if (!infantGrowthSheet) {
      return;
    }
    setData(
      infantGrowthSheet.map((entry, i) => {
        const weight: Weight = getWeightObjectFromGrams(
          entry.weight_grams || 0
        );
        let percentChange = "-";
        if (entry.percent_change_weight_since_birth) {
          const changeFloat =
            Math.round(entry.percent_change_weight_since_birth * 10) / 10;
          if (changeFloat || changeFloat === 0) {
            percentChange = `${changeFloat}%`;
          }
        }
        const metrics = {
          [METRICS.DATE_OF_ENTRY]: entry.date,
          [METRICS.WEIGHT_GRAMS]: weight.grams,
          [METRICS.BIRTH_WEIGHT]: weight,
          [METRICS.PERCENT_CHANGE_WEIGHT_SINCE_BIRTH]: percentChange,
          [METRICS.STANDING_HEIGHT_CM]: entry.standing_height_cm,
          [METRICS.RECUMBENT_LENGTH_CM]: entry.recumbent_length_cm,
          [METRICS.HEAD_CIRCUMFERENCE_CM]: entry.head_circumference_cm,
          [METRICS.RESPIRATORY_RATE]: entry.respiratory_rate,
          [METRICS.TEMP]: entry.temperature,
          [METRICS.PULSE]: entry.pulse,
          encounter_id: entry.encounter_id,
          note: entry.encounter_note
        };
        const dataEntry = { id: i, metrics };
        return dataEntry;
      }) as GridDataType
    );
  }, [infantGrowthSheet]);

  // Event handlers
  const submit = (formData: { [key: string]: any }) => {
    if (isReadOnly || !encounter) return;
    const newEntry = {
      [METRICS.DATE]: dayjs(formData[METRICS.DATE_OF_ENTRY]).format(
        "YYYY-MM-DD"
      ), // backend expects "YYYY-MM-DD" date format
      [METRICS.WEIGHT_GRAMS]: formData[METRICS.BIRTH_WEIGHT]?.grams,

      [METRICS.HEAD_CIRCUMFERENCE_CM]: parseInt(
        formData[METRICS.HEAD_CIRCUMFERENCE_CM]
      ),
      [METRICS.RECUMBENT_LENGTH_CM]: parseInt(
        formData[METRICS.RECUMBENT_LENGTH_CM]
      ),
      [METRICS.RESPIRATORY_RATE]: parseInt(formData[METRICS.RESPIRATORY_RATE]),
      [METRICS.TEMP]: parseInt(formData[METRICS.TEMP]),
      [METRICS.PULSE]: parseInt(formData[METRICS.PULSE])
    };

    // update encounter
    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload: {
        patient_id: encounter.patient.user_id,
        provider_id: encounter.provider.user_id,
        encounter_type: encounter.encounter_type as EncounterType,
        infant_growth_measurements: newEntry
      }
    });
  };

  return (
    <div className={styles.FetalGrowthFlowSheet}>
      <FlowSheet
        title="Infant Growth Sheet"
        isReadOnly={isReadOnly}
        isLoading={isLoading}
        headers={headers}
        submitOnBlur
        data={data}
        encounterId={encounter?.encounter_id}
        onRowSubmit={submit}
        onRowNavigate={encounterId => {
          router.push(`${PAGES.ENCOUNTERS}/${encounterId}`);
        }}
        navigateButtonTitle="View associated encounter"
      />
    </div>
  );
}
