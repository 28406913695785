/* MedicalCoding Section */
/* External Imports */
import clsx from "clsx";
import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";

/* Local Imports */

// components
import Tag from "@/components/tag";
import Button from "@/components/button";
import Icon from "@/components/icons";
import ComboboxSelect from "@/components/input/combobox";
import Toggle from "@/components/input/toggle";
import OptionsMenu from "@/components/optionsMenu";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import {
  Coding,
  EncounterCreateUpdatePayload,
  EncounterInfo,
  EncounterType
} from "@/store/services/encounter";
import {
  useLazyCodingSearchIcd10Query,
  useLazyCodingSearchCptQuery,
  useCodingIcd10LookupMutation,
  useCodingCptLookupMutation,
  CptCodeDetails,
  useLazyCodingSearchCptModifierQuery,
  useCodingCptModifierLookupMutation
} from "@/store/services/coding";
import { useEncounterUpdateMutation } from "@/store/services/encounter";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// styles
import styles from "./styles.module.scss";

/* MedicalCoding Typescript Interface */
interface MedicalCodingProps {
  encounter: EncounterInfo;
  isReadOnly?: boolean;
}

const HEADERS = ["CPT code", "ICD 10 codes", "Modifiers", "Billable", ""];

export default function MedicalCoding({
  encounter,
  isReadOnly = false
}: MedicalCodingProps) {
  /* Local State */
  const [lookupIcd10Codes, { data: icd10Details = {} }] =
    useCodingIcd10LookupMutation();
  const [lookupCptCodes, { data: cptDetails = {} }] =
    useCodingCptLookupMutation();
  const [lookupCptModifiers, { data: cptModifierDetails = {} }] =
    useCodingCptModifierLookupMutation();

  useEffect(() => {
    if (encounter.codings) {
      const icd10Ids = encounter.codings
        .map(coding => coding.icd10_ids)
        .flat()
        .filter((id): id is number => id !== undefined);
      const cptIds = encounter.codings
        .map(coding => coding.cpt_id)
        .filter((id): id is number => id !== undefined);
      const cptModifiers = encounter.codings.flatMap(
        coding => coding.modifier_ids || []
      );
      lookupIcd10Codes({ body: icd10Ids });
      lookupCptCodes({ body: cptIds });
      lookupCptModifiers({ body: cptModifiers });
    }
  }, [encounter.codings]);

  /* Redux */
  const dispatch = useDispatch();
  const [searchICD10, { data: icd10SearchResults }] =
    useLazyCodingSearchIcd10Query();
  const [searchCPT, { data: cptSearchResults }] = useLazyCodingSearchCptQuery();
  const [searchCPTModifiers, { data: modiferSearchResults }] =
    useLazyCodingSearchCptModifierQuery();
  const [updateEncounter, _] = useEncounterUpdateMutation();

  /* Event Handlers */
  const handleAddCpt = (cptId: number) => {
    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType
    };

    let codings = encounter.codings || [];
    // add the new coding object if it doesn't exist
    let coding = codings.find(coding => coding.cpt_id === cptId);
    if (!coding) {
      coding = {
        cpt_id: cptId
      };
      codings = [...codings, coding];
    }

    encounterCreateUpdatePayload.codings = codings;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  };

  const handleAddICD10 = (cptId: number, icd10Id: number) => {
    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType
    };

    let codings = encounter.codings || [];
    // add the new coding object if it doesn't exist
    let coding = codings.find(coding => coding.cpt_id === cptId);
    if (!coding) {
      coding = {
        cpt_id: cptId,
        icd10_ids: [icd10Id]
      };
      codings.push(coding);
    } else {
      // if the coding object exists but does not have any icd10codes, create the array and add the icd10code
      if (!coding.icd10_ids) {
        coding = {
          ...coding,
          icd10_ids: [icd10Id]
        };
        // replace the old coding object with the new one
        codings = codings.map(c => (c.cpt_id === cptId ? coding : c) as Coding);
      } else {
        // if the coding object exists and has icd10codes, add the new icd10code if it isn't already in the array
        if (!coding.icd10_ids.includes(icd10Id)) {
          coding = { ...coding, icd10_ids: [...coding.icd10_ids, icd10Id] };
          // replace the old coding object with the new one
          codings = codings.map(
            c => (c.cpt_id === cptId ? coding : c) as Coding
          );
        }
      }
    }

    encounterCreateUpdatePayload.codings = codings;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  };

  const handleAddICD10ToAllCPTs = (icd10Ids: number[]) => {
    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType
    };

    const codings = [...(encounter.codings || [])];
    codings.forEach((coding, i) => {
      const newCoding = { ...coding };
      const newIds = icd10Ids.filter(
        icd10Id => !coding.icd10_ids?.includes(icd10Id)
      );
      newCoding.icd10_ids = [...(coding.icd10_ids || []), ...newIds];
      codings[i] = newCoding;
    });

    encounterCreateUpdatePayload.codings = codings;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  };

  const handleRemoveICD10 = (cptId: number, icd10Id: number) => {
    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType
    };

    let codings = encounter.codings || [];
    // add the new coding object if it doesn't exist
    let coding = codings.find(coding => coding.cpt_id === cptId);
    if (coding && coding.icd10_ids) {
      coding = {
        ...coding,
        icd10_ids: coding.icd10_ids.filter(id => id !== icd10Id)
      };
      // replace the old coding object with the new one
      codings = codings.map(c => (c.cpt_id === cptId ? coding : c) as Coding);
    }

    encounterCreateUpdatePayload.codings = codings;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  };

  // remove mofier handler
  const handleRemoveModifier = (cptId: number, modifier: number) => {
    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType
    };

    let codings = encounter.codings || [];
    // add the new coding object if it doesn't exist
    let coding = codings.find(coding => coding.cpt_id === cptId);
    if (coding && coding.modifier_ids) {
      coding = {
        ...coding,
        modifier_ids: coding.modifier_ids.filter(mod => mod !== modifier)
      };
      // replace the old coding object with the new one
      codings = codings.map(c => (c.cpt_id === cptId ? coding : c) as Coding);
    }

    encounterCreateUpdatePayload.codings = codings;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  };

  const handleBillableChange = (cptId: number, isBillable: boolean) => {
    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType
    };

    let codings = encounter.codings || [];
    // add the new coding object if it doesn't exist
    let coding = codings.find(coding => coding.cpt_id === cptId);
    if (coding) {
      coding = { ...coding, is_billable: isBillable };
      // replace the old coding object with the new one
      codings = codings.map(c => (c.cpt_id === cptId ? coding : c) as Coding);
    }

    encounterCreateUpdatePayload.codings = codings;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  };

  /* Effects */

  /* Event Handlers */

  const onSuccess = () => {
    dispatch(
      addAlertToToastTrough({
        type: STATUS_KEYS.SUCCESS,
        message: "Encounter updated successfully"
      })
    );
  };

  const onError = () => {
    dispatch(
      addAlertToToastTrough({
        type: STATUS_KEYS.ERROR,
        message: "Failed to update encounter"
      })
    );
  };

  const handleSetEncounterBillable = (option: string) => {
    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType,
      is_billable: option === "billable"
    };

    // iterate through all codings, and set coding billable to false if
    // option is "non_billable"
    // if the encounter is set to "billable", do not change the individual codings
    if (
      option === "non_billable" &&
      encounter.codings &&
      encounter.codings.length > 0
    ) {
      const newCodings = encounter.codings.map(coding => ({
        ...coding,
        is_billable: false
      }));
      encounterCreateUpdatePayload.codings = newCodings;
    }

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  };

  const handleDeleteCpt = (cptId: number) => {
    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType
    };

    let codings = encounter.codings || [];
    // remove the coding object if it exists
    codings = codings.filter(coding => coding.cpt_id !== cptId);

    encounterCreateUpdatePayload.codings = codings;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  };

  const handleAddModifier = (cptId: number, modifier: number) => {
    const encounterCreateUpdatePayload: EncounterCreateUpdatePayload = {
      patient_id: encounter.patient.user_id,
      provider_id: encounter.provider.user_id,
      encounter_type: encounter.encounter_type as EncounterType
    };

    let codings = encounter.codings || [];
    // add the new coding object if it doesn't exist
    let coding = codings.find(coding => coding.cpt_id === cptId);
    if (!coding) {
      coding = {
        cpt_id: cptId,
        modifier_ids: [modifier]
      };
      codings = [...codings, coding];
    } else {
      // if the coding object exists but does not have any modifiers, create the array and add the modifier
      if (!coding.modifier_ids) {
        coding = {
          ...coding,
          modifier_ids: [modifier]
        };
        // replace the old coding object with the new one
        codings = codings.map(c => (c.cpt_id === cptId ? coding : c) as Coding);
      } else {
        // if the coding object exists and has modifiers, add the new modifier if it isn't already in the array
        if (!coding.modifier_ids.includes(modifier)) {
          coding = {
            ...coding,
            modifier_ids: [...coding.modifier_ids, modifier]
          };
          // replace the old coding object with the new one
          codings = codings.map(
            c => (c.cpt_id === cptId ? coding : c) as Coding
          );
        }
      }
    }

    encounterCreateUpdatePayload.codings = codings;

    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload
    })
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      });
  };

  return (
    <div
      className={clsx(styles.MedicalCodingGrid, {
        [styles.isReadOnly]: isReadOnly
      })}
      data-cy="medical-coding"
    >
      <div className={styles.encounterBillable} data-cy="encounter-billable">
        Encounter is:
        <OptionsMenu
          options={[
            { id: "billable", label: "Billable" },
            { id: "non_billable", label: "Non-Billable" }
          ]}
          selectedOption={encounter.is_billable ? "billable" : "non_billable"}
          onClick={handleSetEncounterBillable}
          disabled={isReadOnly}
        />
      </div>
      {/* first row contains headers */}
      {/* in read only mode, the last header is removed because the delete button is not shown */}
      {(isReadOnly ? [...HEADERS.slice(0, -1)] : HEADERS).map(header => (
        <div key={header} className={styles.header}>
          {header}
        </div>
      ))}

      {/* write a row for each diagnosis */}
      {encounter.codings?.map(coding => (
        <Fragment key={coding.cpt_id}>
          {/* CPT codes */}
          <div
            // dynamic data cy for each cpt code
            data-cy={`cpt-code-${coding.cpt_id}`}
            className={clsx(styles.cell, styles.code)}
            title={cptDetails[coding.cpt_id as number]?.description}
          >
            <Tag
              label={cptDetails[coding.cpt_id as number]?.cpt_code}
              type={STATUS_KEYS.INFO_GREY}
            />
            <p>{cptDetails[coding.cpt_id as number]?.description}</p>
          </div>
          <div className={clsx(styles.cell)}>
            {!isReadOnly && (
              <div className={styles.icd10Input}>
                <ComboboxSelect
                  label="Search for ICD10 code"
                  placeholder="Search ICD10s"
                  name={`${coding.cpt_id}-icd10-code-dropdown`}
                  hiddenLabel
                  options={icd10SearchResults || []}
                  labelAcc={item => `${item?.name} - ${item?.description}`}
                  onChange={icd10 => {
                    if (icd10) {
                      handleAddICD10(coding.cpt_id as number, icd10.icd_id);
                    }
                  }}
                  clearOnSelect
                  isDisabled={isReadOnly}
                  onSearch={term => {
                    if (term.length >= 3) searchICD10({ term });
                  }}
                />

                {coding.icd10_ids && (
                  <Button
                    onClick={() => {
                      handleAddICD10ToAllCPTs(coding.icd10_ids || []);
                    }}
                  >
                    Apply to all CPTs
                  </Button>
                )}
              </div>
            )}
            <div className={styles.icd10grid}>
              {coding.icd10_ids?.map(icd10 => (
                <Fragment key={icd10}>
                  <Tag
                    label={icd10Details?.[icd10]?.name}
                    type={STATUS_KEYS.INFO_GREY}
                    onRemove={
                      isReadOnly
                        ? undefined
                        : () =>
                            handleRemoveICD10(coding.cpt_id as number, icd10)
                    }
                    dataCyRemove={`icd10-${icd10}-remove`}
                    interactivityIsDisabled={isReadOnly}
                  />
                  {icd10Details && icd10Details[icd10] && (
                    <div
                      className={styles.icd10description}
                      title={icd10Details[icd10]?.description}
                      // dynamic data cy for each icd10 code
                      data-cy={`icd10-code-${icd10}`}
                    >
                      {icd10Details?.[icd10].description}
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
          <div className={styles.cell}>
            {!isReadOnly && (
              <ComboboxSelect
                label="Search for modifiers"
                placeholder="Search modifiers"
                name={`${coding.cpt_id}-modifier-code-dropdown`}
                hiddenLabel
                options={modiferSearchResults || []}
                labelAcc={item =>
                  `${item?.modifier_code} - ${item?.modifier_name}`
                }
                onChange={mod => {
                  if (mod)
                    handleAddModifier(coding.cpt_id as number, mod.modifier_id);
                }}
                clearOnSelect
                isDisabled={isReadOnly}
                onSearch={term => {
                  if (term) searchCPTModifiers({ term });
                }}
              />
            )}
            <div className={styles.icd10grid}>
              {coding.modifier_ids?.map((modifier, index) => (
                <Fragment key={modifier}>
                  <Tag
                    key={index}
                    label={cptModifierDetails?.[modifier]?.modifier_code}
                    type={STATUS_KEYS.INFO_GREY}
                    onRemove={
                      isReadOnly
                        ? undefined
                        : () =>
                            handleRemoveModifier(
                              coding.cpt_id as number,
                              modifier
                            )
                    }
                    dataCyRemove={`modifier-${modifier}-remove`}
                    interactivityIsDisabled={isReadOnly}
                  />
                  <div
                    className={styles.icd10description}
                    title={cptModifierDetails?.[modifier]?.modifier_name}
                    // dynamic data cy for each modifier code
                    data-cy={`modifier-code-${modifier}`}
                  >
                    {cptModifierDetails?.[modifier]?.modifier_name}
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
          {/* billable */}
          <div className={styles.cell}>
            <Toggle
              onChange={checked => {
                handleBillableChange(coding.cpt_id as number, checked);
              }}
              initialChecked={coding.is_billable === false ? false : true}
              labelIsRight
              label={coding.is_billable === false ? "No" : "Yes"}
              isDisabled={isReadOnly}
            />
          </div>
          {/* delete button */}
          {!isReadOnly && (
            <div
              className={styles.cell}
              data-cy={`cpt-${coding.cpt_id}-delete`}
            >
              <Button
                style={STYLES.ICON}
                onClick={() => {
                  handleDeleteCpt(coding.cpt_id as number);
                }}
              >
                <Icon svg="trash_grey" />
              </Button>
            </div>
          )}
        </Fragment>
      ))}

      {/* Empty row for addition of codes */}
      {!isReadOnly && (
        <>
          {/* select CPT code first */}
          <div className={clsx(styles.cell, styles.first)}>
            {!isReadOnly && (
              <ComboboxSelect
                label="Search for CPT code"
                placeholder="Search CPTs"
                name="cpt-code-dropdown"
                hiddenLabel
                options={cptSearchResults || []}
                labelAcc={(item: CptCodeDetails) =>
                  `${item.cpt_code} - ${item.description}`
                }
                onChange={(cpt: CptCodeDetails) => {
                  if (cpt) {
                    handleAddCpt(cpt.cpt_id);
                  }
                }}
                clearOnSelect
                onSearch={term => {
                  if (term) searchCPT({ term });
                }}
                debounce
              />
            )}
          </div>
          <div className={styles.cell}>
            <ComboboxSelect
              label="Search for ICD10 code"
              placeholder="Search ICD10s"
              isDisabled
              hiddenLabel
              options={icd10SearchResults || []}
              labelAcc={item => `${item?.name} - ${item?.description}`}
              clearOnSelect
              onSearch={term => {
                if (term.length >= 3) searchICD10({ term });
              }}
              debounce
            />
          </div>
          <div className={styles.cell}>
            <ComboboxSelect
              label="Search for modifiers"
              placeholder="Search modifiers"
              name="cpt-modifiers-dropdown"
              hiddenLabel
              options={modiferSearchResults || []}
              labelAcc={item => `${item?.name} - ${item?.modifier_name}`}
              clearOnSelect
              isDisabled
            />
          </div>
          {/* billable */}
          <div className={styles.cell}>
            <Toggle
              onChange={checked => {
                // handleBillableChange(coding.cpt_id as number, checked);
              }}
              isDisabled
            />
          </div>
          {/* delete button */}
          <div className={styles.cell}>
            <Button
              style={STYLES.ICON}
              onClick={() => {
                // handleDeleteCpt(coding.cpt_id as number);
              }}
              nativeButtonProps={{ disabled: true }}
            >
              <Icon svg="trash_grey" />
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
