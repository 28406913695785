import {
  PhraseListItem,
  PhraseUpsertRequest,
  usePhraseTextQuery,
  usePhraseUpsertMutation
} from "@/store/services/generated/phrase";
import DotPhraseEdit from "./dotPhraseEdit";
import DotPhraseCreation from "./dotPhraseCreation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { setModalIsOpen } from "../../modalSlice";
import Skeleton from "react-loading-skeleton";
import { useEffect } from "react";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { STATUS_KEYS } from "@/globals/constants";

interface DotPhraseFormProps {
  phrase?: PhraseListItem;
}

export default function DotPhraseForm({ phrase }: DotPhraseFormProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const [triggerDotPhraseMutation] = usePhraseUpsertMutation();

  const dispatch = useDispatch();

  const { data: phraseContent, isError } = usePhraseTextQuery(
    {
      id: sessionInfo?.practice_id as number,
      scope: "practice",
      phrase: phrase?.name as string
    },
    {
      skip: !sessionInfo?.practice_id || !phrase
    }
  );

  const handleSubmit = (serializedData: PhraseUpsertRequest) => {
    triggerDotPhraseMutation({
      id: sessionInfo?.practice_id as number,
      scope: "practice",
      phraseUpsertRequest: serializedData
    }).then(() => {
      dispatch(setModalIsOpen(false));
      dispatch(
        addAlertToToastTrough({
          message: "Successfully saved dot phrase",
          type: STATUS_KEYS.SUCCESS
        })
      );
    });
  };

  useEffect(() => {
    if (isError) {
      dispatch(
        addAlertToToastTrough({
          message: "Error loading the dot phrase, please try again later",
          type: STATUS_KEYS.ERROR
        })
      );
    }
  }, [isError]);

  return phrase ? (
    phraseContent ? (
      <DotPhraseEdit
        phrase={phrase}
        phraseContent={phraseContent}
        onSubmit={handleSubmit}
      />
    ) : (
      <>
        <Skeleton height={40} width="100%" />
        <Skeleton height={40} width="100%" />
        <Skeleton height={40} width="100%" />
        <Skeleton height={40} width="100%" />
        <Skeleton height={40} width="100%" />
      </>
    )
  ) : (
    <DotPhraseCreation onSubmit={handleSubmit} />
  );
}
