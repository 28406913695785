// External
import { useDispatch } from "react-redux";
import { ChangeEvent, useState } from "react";

// TipTap
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";

// Api
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { setModalIsOpen } from "../../modalSlice";
import { PhraseUpsertRequest } from "@/store/services/generated/phrase";

// Components
import Button from "@/components/button";
import Input from "@/components/input";
import MenuBar from "@/components/textArea/menuBar";

// Styles
import styles from "../../styles.module.scss";

interface DotPhraseCreationProps {
  onSubmit: (apiArg: PhraseUpsertRequest) => void;
}

export default function DotPhraseCreation({
  onSubmit
}: DotPhraseCreationProps) {
  const dispatch = useDispatch();

  const [phraseName, setPhraseName] = useState<string>("");
  const [phraseDesc, setPhraseDesc] = useState<string>("");

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        blockquote: false,
        codeBlock: false,
        heading: {
          levels: [1, 2]
        },
        horizontalRule: false,
        code: false,
        strike: false
      }),
      Placeholder.configure({
        placeholder: "Enter dot phrase template here."
      }),
      TaskList,
      TaskItem.configure({
        nested: true
      })
    ]
  });

  const onVariableAdd = () => {
    editor?.chain().focus().insertContent("*** ").run();
  };

  const onCancel = () => {
    dispatch(setModalIsOpen(false));
  };

  const handleSubmit = () => {
    if (phraseName === "") {
      dispatch(
        addAlertToToastTrough({
          message: "Dot Phrase name can't be blank",
          type: STATUS_KEYS.ERROR
        })
      );
      return;
    }

    const editorContent = editor?.getHTML();
    if (editorContent === "" || editorContent === "<p></p>") {
      dispatch(
        addAlertToToastTrough({
          message: "Dot Phrase content can't be blank",
          type: STATUS_KEYS.ERROR
        })
      );
      return;
    }

    const serializedData = {
      phrase: phraseName,
      description: phraseDesc,
      text: editorContent as string
    } as PhraseUpsertRequest;

    onSubmit(serializedData);
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhraseName(e.target.value);
  };

  const onDescChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhraseDesc(e.target.value);
  };

  return (
    <div className={styles.DotPhraseCreation}>
      <div className={styles.phraseFormContainer}>
        <div className={styles.phraseInfo}>
          <Input
            label="Name"
            id="phrase_name"
            name="phrase_name"
            type="text"
            value={phraseName}
            onChange={onNameChange}
          />
          <Input
            label="Description"
            id="phrase_description"
            name="phrase_description"
            type="text"
            value={phraseDesc}
            onChange={onDescChange}
          />
        </div>
        <div className={styles.phraseContent}>
          <p className={styles.editorInstructions}>
            <strong>
              Enter dot phrase below. Add *** for blank spaces where you will
              add custom text to the template.
            </strong>
          </p>
          <div className={styles.textAreaContainer}>
            <EditorContent
              editor={editor}
              className={styles.textAreaContent}
              style={{ height: "20rem" }}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.menuContainer}>
          <MenuBar editor={editor} />
          <Button onClick={onVariableAdd} style={STYLES.TERTIARY}>
            Add ***
          </Button>
        </div>
        <Button onClick={onCancel} style={STYLES.SECONDARY}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} style={STYLES.PRIMARY}>
          Save
        </Button>
      </div>
    </div>
  );
}
