// External
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";

// Globals, constants, helpers
import { PAGES, STATUS_KEYS, STYLES } from "@/globals/constants";
import { apptLocationToUpsert } from "@/globals/helpers/formatters";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { MODAL_TYPES } from "@/components/modal/dispatcher";

// Store
import { usePracticeListAppointmentTypesQuery } from "@/store/services/practice";
import {
  EncounterInfo,
  EncounterType,
  useEncounterCreateMutation,
  useEncounterSubmitMutation,
  useEncounterUpdateMutation
} from "@/store/services/encounter";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { useAppointmentUpsertMutation } from "@/store/services/scheduling";

// Components
import Icon from "@/components/icons";
import Button from "@/components/button";
import SubmittedStamp from "../standardFlow/submittedStamp";
import Delete from "../../../../public/svgs/delete_dynamic.svg";
import Certification from "@/components/certification";

// Styles
import styles from "./styles.module.scss";

interface SubmissionProps {
  encounter: EncounterInfo;
  // If you need additional onsubmit functionality
  onSubmit?: () => void;
}

export default function Submission({ encounter, onSubmit }: SubmissionProps) {
  // Store
  const dispatch = useDispatch();
  const router = useRouter();
  // appointments
  const [updateAppointment] = useAppointmentUpsertMutation();
  const { data: appointmentTypes } = usePracticeListAppointmentTypesQuery();

  // encounters
  const [createEncounter, createEncounterRequest] =
    useEncounterCreateMutation();
  const [submitEncounter, submitEncounterRequest] =
    useEncounterSubmitMutation();
  const [updateEncounter, updateEncounterRequest] =
    useEncounterUpdateMutation();

  // Local state
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [certified, setCertified] = useState<boolean>(
    encounter.status !== "IN_PROGRESS"
  ); // if encounter has been previously submitted, default checkbox to checked
  const [isEditable, setIsEditable] = useState(
    encounter.status === "IN_PROGRESS"
  );

  // Form
  const {
    control,
    formState: { errors },
    watch
  } = useForm();

  // Watches
  const visitType = watch(
    "select_appt_type",
    encounter.encounter_type || appointmentTypes?.[0]
  );
  const visitLocation = watch("select_appt_location", encounter.location);

  // Effects
  useEffect(() => {
    setIsEditable(encounter.status === "IN_PROGRESS");
  }, [encounter.status]);

  useEffect(() => {
    if (certified && visitType && visitLocation) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [certified, visitLocation, visitType]);

  // Methods
  const launchToast = (message: string, type: keyof typeof STATUS_KEYS) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };

  // Handlers
  const handleReopenEncounter = () => {
    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload: {
        provider_id: encounter.provider.user_id,
        patient_id: encounter.patient.user_id,
        encounter_type: encounter.encounter_type as EncounterType,
        status: "IN_PROGRESS"
      }
    })
      .unwrap()
      .then(() => {
        launchToast(
          "Encounter reopened",
          STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        );
        setCertified(false);
      })
      .catch(() =>
        launchToast(
          "Oops! Encounter could not be reopened",
          STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        )
      );
  };

  const handleCompleteAppointment = async () => {
    try {
      // update appointment status
      await updateAppointment({
        appointmentUpsertRequest: {
          id: encounter.appointment?.appointment_id,
          appointment_type_id: encounter.appointment?.appointment_type.appointment_type_id as string,
          patient_id: encounter.patient.user_id,
          provider_id: encounter.provider.user_id,
          appointment_location: apptLocationToUpsert(
            encounter.appointment?.appointment_location
          ),
          starts: encounter.appointment?.starts as number,
          ends: encounter.appointment?.ends as number,
          status: "CHECKED_OUT"
        }
      });

      // user alert
      launchToast(
        `Appointment completed successfully.`,
        STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
      );

      router.back();
    } catch (error) {
      launchToast(
        `An error occurred. Appointment was not able to be marked completed.`,
        STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
      );
    }
  };

  const handleSubmit = () => {
    // encounter will always be defined when this is invoked
    submitEncounter({
      encounterId: encounter?.encounter_id as number,
      version: encounter?.version as number
    })
      .unwrap()
      .then(() => {
        launchToast(
          "Encounter submitted and superbill generated",
          STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        );
        updateAppointment({
          appointmentUpsertRequest: {
            id: encounter.appointment?.appointment_id,
            appointment_type_id: encounter.appointment?.appointment_type.appointment_type_id as string,
            patient_id: encounter.patient.user_id,
            provider_id: encounter.provider.user_id,
            appointment_location: apptLocationToUpsert(
              encounter.appointment?.appointment_location
            ),
            starts: encounter.appointment?.starts as number,
            ends: encounter.appointment?.ends as number,
            status: "COMPLETED"
          }
        });
      })
      .catch(() => {
        launchToast(
          "Oops! Encounter could not be submitted", // TODO: detailed user facing errors when backend supports it
          STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        );
      });

    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <>
      <div id="certification_wrapper" className={styles.certification}>
        <Certification
          onCertify={(certified: boolean) => {
            setCertified(certified);
          }}
          isChecked={certified}
          encounterId={encounter.encounter_id}
          isReadOnly={!isEditable}
        />
      </div>
      {submitEnabled ? (
        <div className="note">
          <Icon svg="lightbulb" width={12} /> Visit type, location, and
          certification are required for submission
        </div>
      ) : (
        <div className="note warning">
          <div className="flexCol">
            {!visitLocation && (
              <div className="flex">
                <Icon svg="warning" width={20} />
                <a className="tMd" href="#visit_location_wrapper">
                  Visit location
                </a>
                is required for submission
              </div>
            )}
            {!visitType && (
              <div className="flex">
                <Icon svg="warning" width={20} />
                <a className="tMd" href="#visit_location_wrapper">
                  Visit type
                </a>
                is required for submission
              </div>
            )}
            {!certified && (
              <div className="flex">
                <Icon svg="warning" width={20} />
                <a className="tMd" href="#certification_wrapper">
                  Authentication
                </a>
                is required for submission
              </div>
            )}
          </div>
        </div>
      )}

      <div className={styles.actionWrapper}>
        <div className={styles.submittedStamp}>
          <Button
            style={STYLES.DELETE}
            onClick={() => {
              dispatch(setModalIsOpen(true));
              dispatch(
                setModalContent({
                  type: MODAL_TYPES.CONFIRM_DELETE_ENCOUNTER,
                  props: {
                    encounter,
                    message:
                      "Are you sure you want to delete this encounter and its contents?",
                    title: "Confirm Delete Encounter"
                  }
                })
              );
            }}
          >
            <Delete stroke={styles.errorText} width={15} height={17} />
            Delete Encounter
          </Button>
          <Button
            onClick={handleReopenEncounter}
            style={STYLES.SECONDARY}
            nativeButtonProps={{ disabled: isEditable }}
          >
            Reopen Encounter
          </Button>
          {encounter.status == "SUBMITTED" &&
            encounter.submitted &&
            encounter.submitted_by && (
              <SubmittedStamp
                user={encounter.submitted_by}
                timestamp={encounter.submitted}
              />
            )}
        </div>
        {encounter.appointment && encounter.status !== "SUBMITTED" && (
          <Button style={STYLES.SECONDARY} onClick={handleCompleteAppointment}>
            Complete Appointment
          </Button>
        )}

        <Button
          style={STYLES.SECONDARY}
          onClick={() =>
            router.push(
              `${PAGES.ENCOUNTERS}/${encounter.encounter_id}${PAGES.SUPERBILL}`
            )
          }
          nativeButtonProps={{ disabled: !encounter.submitted }}
        >
          View Superbill
          <Icon svg="file_highlighted" width={20} height={20} />
        </Button>
        <Button
          style={STYLES.PRIMARY}
          onClick={handleSubmit}
          nativeButtonProps={{
            disabled: !submitEnabled || !isEditable
          }}
        >
          {encounter.status === "SUBMITTED" && encounter.submitted
            ? "Sign & Resubmit"
            : "Sign & Submit"}
        </Button>
      </div>
    </>
  );
}
