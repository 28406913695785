import { Address } from "@/store/services/location";
import { timeFormat } from "d3";
import { convertUtcIntToLocalDate } from "@/components/scheduling/calendars/utils";
import { PatientInfo } from "@/store/services/patient";
import { AppointmentLocation } from "@/store/services/encounter";
import { AppointmentLocationUpsert } from "@/store/services/scheduling";
import { PatientListItem } from "@/store/services/practice";
import { capitalize } from "lodash";
import libphonenumber from "google-libphonenumber";

const TIME_FORMAT = {
  dateTime: timeFormat("%m/%d/%Y, %-I:%M%p"),
  date: timeFormat("%m/%d/%Y"),
  monthDay: timeFormat(`%B %d`),
  monthDate: timeFormat(`%m/%d`),
  // lowercase am pm
  time: (date: Date) =>
    timeFormat(`%-I:%M%p`)(date).replace(/AM|PM/g, match =>
      match.toLowerCase()
    ),
  // if date is today, display "Today", otherwise display Month Day (e.g. May 18)
  relativeTime: (date: Date) =>
    `${isToday(date) ? "today at" : TIME_FORMAT.monthDay(date)} ${TIME_FORMAT.time(date)}`
};

// check if date is today's date
const isToday = (date: Date) =>
  TIME_FORMAT.date(date) === TIME_FORMAT.date(new Date());

type UserName = {
  prefix?: string;
  first_name?: string;
  last_name?: string;
  suffix?: string | null;
};

function apptLocationToUpsert(
  location: AppointmentLocation | undefined
): AppointmentLocationUpsert | undefined {
  return typeof location === "object" ? location.location_id : location;
}

function apptLocationName(
  location: AppointmentLocation | undefined
): string | undefined {
  return typeof location === "object" ? location.name : location;
}

function constructAddressString(addressData: Address) {
  // TODO: make location info & address uniform shape
  const { line1, line2, line3, city, state, zip } = addressData;

  // Create an array of address parts
  const addressParts = [line1, line2, line3, city, state?.toUpperCase(), zip];

  // Filter out empty parts and join them with a comma and space
  const formattedAddress = addressParts
    .filter(part => part && part != "")
    .join(", ");

  return formattedAddress;
}

const FORMAT = {
  date: TIME_FORMAT,
  string: (d: string) => d,
  capitalize: (d: string) => capitalize(d),
  name: ({ first_name = "", last_name = "", suffix = "" }: UserName) =>
    `${first_name ? first_name : ""}${last_name ? " " + last_name : ""}${suffix ? " " + suffix : ""}`,
  address: (addressData: Address) =>
    addressData && constructAddressString(addressData),
  ssn: (ssn: string) =>
    ssn.substring(0, 3) + "-" + ssn.substring(3, 5) + "-" + ssn.substring(5, 9)
};

/**
 * Formats a time amount in milliseconds to the format of "mm:ss" to display
 * transcription time stamps
 * @param milli a time amount in milliseconds
 * @returns a string in the format of "mm:ss"
 */
const msToTime = (milli: number) => {
  const seconds = Math.floor((milli / 1000) % 60);
  const minutes = Math.floor((milli / (60 * 1000)) % 60);

  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
};

const nameAndDOB = (patient: PatientInfo | PatientListItem) => {
  if (!patient || !patient.first_name || !patient.last_name) return "";
  if (!patient.dob) return FORMAT.name(patient);
  if (!convertUtcIntToLocalDate(patient.dob)) return FORMAT.name(patient);
  return `${FORMAT.name(patient)} - ${convertUtcIntToLocalDate(
    patient.dob
  ).format("MM/DD/YYYY")}`;
};

const formatPhone = (phone: string) => {
  if (!phone) return "";
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const number = phoneUtil.parse(phone, "US");
  let phoneStr = phoneUtil.formatInOriginalFormat(number, "US") || "";
  return phoneStr;
};

export {
  TIME_FORMAT,
  FORMAT,
  isToday,
  msToTime,
  nameAndDOB,
  apptLocationToUpsert,
  apptLocationName,
  formatPhone
};
