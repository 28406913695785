import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getFeatureFlags: build.query<
      GetFeatureFlagsApiResponse,
      GetFeatureFlagsApiArg
    >({
      query: () => ({ url: `/feature-flags` })
    }),
    bulkUpdatePracticeFeatureFlags: build.mutation<
      BulkUpdatePracticeFeatureFlagsApiResponse,
      BulkUpdatePracticeFeatureFlagsApiArg
    >({
      query: queryArg => ({
        url: `/feature-flags`,
        method: "POST",
        body: queryArg.practiceFeatureFlags
      })
    }),
    reportFrontendError: build.mutation<
      ReportFrontendErrorApiResponse,
      ReportFrontendErrorApiArg
    >({
      query: queryArg => ({
        url: `/system/report-error`,
        method: "POST",
        body: queryArg.reportSystemError
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as system };
export type GetFeatureFlagsApiResponse =
  /** status 200 List the features enabled for the active user.
   */ FeatureFlags;
export type GetFeatureFlagsApiArg = void;
export type BulkUpdatePracticeFeatureFlagsApiResponse =
  /** status 200 List the features enabled for the active user.
   */ FeatureFlags;
export type BulkUpdatePracticeFeatureFlagsApiArg = {
  practiceFeatureFlags: PracticeFeatureFlags;
};
export type ReportFrontendErrorApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type ReportFrontendErrorApiArg = {
  /** Trace ID. */
  reportSystemError: ReportSystemError;
};
export type PracticeFeatureFlags = {
  /** Whether or not lab ordering is enabled.
   */
  lab_ordering_enabled: boolean;
  /** Whether or not claim lifecycle management is enabled.
   */
  clm_enabled: boolean;
  /** Whether or not infant charting is enabled.
   */
  infant_charting_enabled: boolean;
  /** Whether or not pronouns are enabled.
   */
  pronouns_enabled: boolean;
  /** Whether or not the rx feature / DoseSpot is enabled.
   */
  rx_enabled: boolean;
  /** Whether or not the messaging feature is enabled.
   */
  messaging_enabled: boolean;
  /** Whether or not labor charting is enabled.
   */
  labor_charting_enabled: boolean;
  /** Whether or not postpartum charting is enabled.
   */
  postpartum_charting_enabled: boolean;
  /** Whether or not prenatal charting is enabled.
   */
  prenatal_charting_enabled: boolean;
  /** Whether or not primary care patient charting is enabled.
   */
  primary_care_charting_enabled: boolean;
  /** Whether or not patient balances are enabled.
   */
  patient_balances_enabled: boolean;
  /** Whether or not male patients are enabled.
   */
  male_patients_enabled: boolean;
  /** Whether or not medical coding v2 is enabled.
   */
  medical_coding_v2_enabled: boolean;
  /** Whether or not multi-provider scheduling is enabled.
   */
  multi_provider_schedule_enabled: boolean;
  /** Whether or not patient recall is enabled.
   */
  patient_recall_enabled: boolean;
  /** Whether or not patient messaging on the facepage is enabled.
   */
  patient_messaging_enabled: boolean;
  /** Whether or not lab flowsheets are enabled.
   */
  lab_flowsheets_enabled: boolean;
  /** Whether or not the all appointments homepage view is enabled.
   */
  all_appointments_homepage_view_enabled: boolean;
  /** Whether or not patient checkout is enabled.
   */
  patient_checkout_enabled: boolean;
  /** Whether or not appointment reminders are enabled.
   */
  send_appointment_reminders_enabled: boolean;
  /** Whether or not the accessible calendar is enabled.
   */
  accessible_calendar_enabled: boolean;
  /** Whether or not auto-submit claim is enabled.
   */
  auto_submit_claim_enabled: boolean;
  /** Whether or not welcome emails are enabled.
   */
  welcome_emails_enabled: boolean;
  /** Whether or not the biller can see drafts.
   */
  biller_sees_drafts_enabled: boolean;
  /** Whether or not the new version of labor is enabled.
   */
  labor_v2_enabled: boolean;
};
export type UserFeatureFlags = {
  /** Whether the nabla feature is enabled.
   */
  nabla_enabled: boolean;
};
export type FeatureFlags = PracticeFeatureFlags & UserFeatureFlags;
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type ErrorId = number;
export type ReportSystemError = {
  trace_id?: string;
  message?: string;
  stack_trace?: string;
};
export const {
  useGetFeatureFlagsQuery,
  useLazyGetFeatureFlagsQuery,
  useBulkUpdatePracticeFeatureFlagsMutation,
  useReportFrontendErrorMutation
} = injectedRtkApi;
