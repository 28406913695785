import { STYLES } from "@/globals/constants";
import Delete from "../../../../../../../../public/svgs/delete_dynamic.svg";
import Save from "../../../../../../../../public/svgs/save-white.svg";
import Pencil from "../../../../../../../../public/svgs/edit_white.svg";
import styles from "../../../styles.module.scss";
import Button from "@/components/button";

interface ListingControlsProps {
  // The visual style for the button and whether it's a submit button
  buttonType: "SUBMIT" | "EDIT" | "UPDATE";
  onDelete: () => void;
  // Optional because the submit button doesn't do onClick
  onButtonPress?: () => void;
}

export default function ListingControls({
  buttonType,
  onDelete,
  onButtonPress
}: ListingControlsProps) {
  return (
    <div className={styles.listingControls}>
      <Button style={STYLES.DELETE} type="button" onClick={() => onDelete()}>
        <Delete stroke={styles.errorText} width={15} height={17} />
      </Button>
      {buttonType === "SUBMIT" && (
        <Button style={STYLES.PRIMARY} type="submit">
          <Save />
        </Button>
      )}
      {buttonType === "EDIT" && (
        <Button
          style={STYLES.PRIMARY}
          type="button"
          onClick={() => onButtonPress && onButtonPress()}
        >
          <Pencil width={17} height={17} stroke="white" />
        </Button>
      )}
      {buttonType === "UPDATE" && (
        <Button
          style={STYLES.PRIMARY}
          type="button"
          onClick={() => onButtonPress && onButtonPress()}
        >
          <Save />
        </Button>
      )}
    </div>
  );
}
