// components
import ControlledOptionsField from "@/components/forms/generated/options";
import ControlledTextArea from "@/components/textArea/controlledTextArea";

// constants and helpers
import { NEWBORN_FORM } from "../constants";
import { NewbornFormPartsProps } from "./newborn";

// styles
import styles from "../styles.module.scss";

export default function NewbornReflexes({
  disabled = false,
  form
}: NewbornFormPartsProps) {
  const {
    control,
  } = form;

  return (
    <>
      <div className={styles.options}>
        {NEWBORN_FORM.reflexes.map(({ id, label }) => (
          <div key={id} className={styles.row}>
            <label>{label}</label>
            <ControlledOptionsField
              label={label}
              name={id}
              key={id}
              control={control}
              options={[
                { id: "yes", label: "Yes" },
                { id: "no", label: "No" }
              ]}
              hiddenLabel
              disabled={disabled}
            />
          </div>
        ))}
      </div>

      <ControlledTextArea
        form={form}
        id="comments"
        name="comments"
        label="Comments"
        disabled={disabled}
      />
    </>
  );
}
