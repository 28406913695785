/* External Imports */
import { MouseEvent } from "react";
import { useSelector } from "react-redux";

/* Local Imports */
import {
  openOrderABN,
  openOrderResults,
  openOrderRequisition,
  openOrderDymoLabels,
  openOrderLetterLabels
} from "./utils";

// components
import Accordion from "@/components/accordions/basic";
import { setModalIsOpen, setModalContent } from "@/components/modal/modalSlice";
import { TestResults } from "./testResults";

// constants

// store
import { RootState } from "@/store/store";
import { usePracticeInfoQuery } from "@/store/services/practice";
import { useLabDeleteOrderMutation } from "@/store/services/lab";

// styles

// TEMP
import { EncounterInfo, LabOrderInfo } from "@/store/services/encounter";
import { MODAL_TYPES } from "@/components/modal/dispatcher";
import { STATUS_KEYS, STYLES, METRIC_LABELS } from "@/globals/constants";
import Button from "../button";
import { useDispatch } from "react-redux";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";

interface OrderResultsHeader {
  order: LabOrderInfo;
  encounter: EncounterInfo;
}
function OrderResultsHeader({ encounter, order }: OrderResultsHeader) {
  const dispatch = useDispatch();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { data: practice } = usePracticeInfoQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo?.practice_id }
  );
  const [deleteOrder, deleteOrderRequest] = useLabDeleteOrderMutation();

  const openLabOrderModal = () => {
    dispatch(setModalIsOpen(true));
    dispatch(
      setModalContent({
        type: MODAL_TYPES.TEST_SELECTION,
        props: { encounter, order }
      })
    );
  };

  const handleDeleteDraftOrder = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();

    deleteOrder({ orderId: order.order_id })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Order deleted",
            severity: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(error => {
        console.error("Error deleting order", error);
        dispatch(
          addAlertToToastTrough({
            message: "Error deleting order",
            severity: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const electronicOrderingSupported =
    practice?.metadata?.health_gorilla?.accounts?.[order.lab_id]?.enabled;

  return (
    <>
      <Button
        style={STYLES.SECONDARY}
        onClick={e => {
          e?.stopPropagation();
          openLabOrderModal();
        }}
      >
        Edit
      </Button>
      <Button
        style={STYLES.SECONDARY}
        onClick={async e => {
          e?.stopPropagation();
          await openOrderRequisition(order.order_id);
        }}
        nativeButtonProps={{
          disabled: order.status === "NEW" && electronicOrderingSupported
        }}
      >
        Requisition
      </Button>

      <Button
        style={STYLES.SECONDARY}
        onClick={e => {
          e?.stopPropagation();
          openOrderDymoLabels(order.order_id);
        }}
        nativeButtonProps={{
          disabled: order.status === "NEW" || !electronicOrderingSupported
        }}
      >
        Dymo Labels
      </Button>

      <Button
        style={STYLES.SECONDARY}
        onClick={e => {
          e?.stopPropagation();
          openOrderLetterLabels(order.order_id);
        }}
        nativeButtonProps={{
          disabled: order.status === "NEW" || !electronicOrderingSupported
        }}
      >
        Sheet Labels
      </Button>

      <Button
        style={STYLES.SECONDARY}
        onClick={async e => {
          e?.stopPropagation();
          await openOrderResults(order.order_id);
        }}
        nativeButtonProps={{
          disabled: order.status !== "FULFILLED"
        }}
      >
        Results
      </Button>
      <Button
        style={STYLES.SECONDARY}
        onClick={async e => {
          e?.stopPropagation();
          await openOrderABN(order.order_id);
        }}
        nativeButtonProps={{
          disabled: !order.has_abn
        }}
      >
        ABN
      </Button>

      <Button
        style={STYLES.DELETE}
        loading={deleteOrderRequest.isLoading}
        onClick={handleDeleteDraftOrder}
        nativeButtonProps={{
          disabled: order.status !== "NEW"
        }}
      >
        Delete
      </Button>
    </>
  );
}

interface OrderResultsProps {
  order: LabOrderInfo;
  encounter: EncounterInfo;
}
export function OrderResults({ encounter, order }: OrderResultsProps) {
  return (
    <Accordion
      disabled={order.tests.length === 0}
      open={order.tests.length > 0}
      title={order.label || "Unlabeled Order"}
      tag={{
        label: METRIC_LABELS[order.status] || order.status,
        type: {
          NEW: STATUS_KEYS.INFO,
          SENT: STATUS_KEYS.INFO,
          IN_PROGRESS: STATUS_KEYS.INFO,
          ERROR: STATUS_KEYS.ERROR,
          FULFILLED: STATUS_KEYS.SUCCESS,
          CORRECTED: STATUS_KEYS.WARNING,
          REVIEWED: STATUS_KEYS.SUCCESS
        }[order.status]
      }}
      count={null}
      customLeftContent={
        <OrderResultsHeader encounter={encounter} order={order} />
      }
    >
      {order.tests.map((test, i) => (
        <TestResults resultID={i} key={test.code} order={order} test={test} />
      ))}
    </Accordion>
  );
}
