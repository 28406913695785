// External
import { DefaultValues } from "react-hook-form";

// Types
import { EncounterInfo, PatientPdfSection } from "@/store/services/patient";
import {
  isPatientPdfSection,
  RecordGenerationFormValues,
} from "./recordGenerationHooks";
import { ProviderInfo } from "@/store/services/provider";
import { PracticeModel } from "@/store/services/practice";

// Utilities
import { FORMAT } from "@/globals/helpers/formatters";
import dayjs from "dayjs";

// This a helper type for the different types of forms currently, there's
// STANDARD - adult gyn or OB patient
// INFANT - infant patient
export type FormType = "STANDARD" | "INFANT" | "LOADING";

/**
 * This a helper function to get an array of file IDs that need to be attached to the PDF
 * @param data The data from the Record Generation Form
 * @returns The file ids the user has selected from the files, labs, and imaging lists
 */
const parseFileIds = (data: RecordGenerationFormValues) => {
  const fileKeys = Object.keys(data).filter(key => {
    // Object.keys() doesn't return the correct type so we cast it
    const k = key as keyof RecordGenerationFormValues;
    return (
      data[k] &&
      (/^files_/.test(key) || /^labs_/.test(key) || /^imaging_/.test(key))
    );
  });
  const fileIds = fileKeys.map(key => parseInt(key.replaceAll(/^[^_]*_/g, "")));

  return fileIds;
};

/**
 * This is a helper function to parse out all the sections the user has selected for PDF generation
 * @param data The data from the Record Generation Form
 * @returns An array of the PDF sections that need to be passed to the API for PDF generation
 */
const parsePdfSections = (data: RecordGenerationFormValues) => {
  const sections: PatientPdfSection[] = [];
  for (const [key, value] of Object.entries(data)) {
    // This typeguard function casts the key as PatientPdfSection for us
    if (isPatientPdfSection(key) && value === true) {
      sections.push(key);
    }
  }

  return sections;
};

/**
 * This is a helper function to parse out all the encounters the user has selected for PDF generation
 * @param data The data from the Record Generation Form
 * @returns An array of encounter IDs to pass in to the API for the encounter section
 */
const parseEncounterIds = (data: RecordGenerationFormValues) => {
  const encounterKeys = Object.keys(data).filter(key => {
    const k = key as keyof RecordGenerationFormValues;
    return data[k] && /^encounters_/.test(key);
  });

  const encounterList = encounterKeys.map(key =>
    parseInt(key.replaceAll(/^[^_]*_/g, ""))
  );

  return encounterList;
};

/**
 * This is a helper function to get the appropriate default values for this different form types.
 * @param practice The user's practice
 * @param provider The provider user that's currently logged in
 * @param formType The form type values are needed for
 * @param defaultData (Optional) Any default values that have been passed from somewhere else 
 * @returns The default values for the form
 */
const getRecordGenerationFormDefaultValues = (
  practice: PracticeModel | null = null,
  provider: ProviderInfo | null = null,
  formType: FormType | null = null,
  defaultData: Partial<RecordGenerationFormValues> = {}
) => {
  const values: DefaultValues<RecordGenerationFormValues> = {
    sender_phone: practice?.phone || provider?.phone || "",
    sender_name: provider ? FORMAT.name(provider) : "",
    all_patient_data: true,
    demographics: true,
    problem_list: true,
    medication_list: true,
    allergy_list: true,
    health_history: true,
    insurance: true,
    ...defaultData
  };

  if (formType == "INFANT") {
    values.mother_demographics = true;
    values.infant_growth_sheet = true;
  }

  if (formType == "STANDARD") {
    values.prenatal_flowsheet = true;
    values.past_pregnancies = true;
  }

  return values;
};

/**
 * This is a helper function to format encounter labels.
 * @param encounter The encounter you need a label for
 * @returns A label for the encounter in the form of "[ENCOUNTER TYPE] - MM/DD/YYYY"
 */
const getEncounterLabel = (encounter: EncounterInfo) => {
  const apptType = encounter.appointment_type?.appointment_type;
  let encType = encounter.encounter_type?.replaceAll("_", " ");
  if (encType && encType !== "") {
    encType = encType.slice(0, 1).concat(encType.slice(1).toLowerCase());
  }
  const dateString = dayjs(
    `${encounter.start || encounter.appointment?.starts || encounter.created}`,
    "YYYYMMDDHHmmss"
  ).format("MM/DD/YYYY");
  return apptType
    ? `${apptType} - ${dateString}`
    : `${encType} - ${dateString}`;
};

export {
  parseEncounterIds,
  parseFileIds,
  parsePdfSections,
  getRecordGenerationFormDefaultValues,
  getEncounterLabel
};
