/* AddAnnotation Name */
/* External Imports */
import clsx from "clsx";
import { useImperativeHandle, useState, forwardRef, ForwardedRef } from "react";
import { useDispatch } from "react-redux";

/* Local Imports */

// components
import Icon from "@/components/icons";
import TextArea from "@/components/textArea";
import Button from "@/components/button";

// constants
import { STYLES } from "@/globals/constants";

// store
import { AnnotationId } from "@/store/services/file";
import { LabOrderAnnotationId } from "@/store/services/lab";

// styles
import styles from "./styles.module.scss";

/* AddAnnotation Typescript Interface */
export interface AddAnnotationProps {
  annotationId?: AnnotationId | LabOrderAnnotationId;
  content?: string;
  onCancel?: () => void;
  isEditing?: boolean;
  isSubmitting?: boolean;
  onCreate: (annotation: string) => void;
  onUpdate: (
    annotationId: AnnotationId | LabOrderAnnotationId,
    annotation: string
  ) => void;
}
const AddAnnotation = forwardRef<
  { clearTextbox: () => void },
  AddAnnotationProps
>(
  (
    {
      content,
      onCancel,
      isEditing,
      annotationId,
      onCreate,
      onUpdate,
      isSubmitting
    },
    ref
  ) => {
    const [textareaValue, setTextareaValue] = useState(content || "");

    const handleAnnotationChange = (content: string) => {
      setTextareaValue(content);
    };

    const handleSubmitAnnotation = () => {
      if (isEditing && annotationId) {
        onUpdate(annotationId, textareaValue);
      } else {
        onCreate(textareaValue);
      }
    };

    useImperativeHandle(ref, () => ({
      clearTextbox: () => {
        setTextareaValue("");
      }
    }));

    return (
      <div
        className={clsx(styles.AddAnnotation, { [styles.edit]: isEditing })}
        data-cy="add-annotation-wrapper"
      >
        <TextArea
          label="Annotations"
          id="annotation_input"
          name="annotation_input"
          onChange={handleAnnotationChange}
          hiddenLabel
          useMenuBar={false}
          rows={4}
          content={textareaValue}
        />
        {
          <div className="flex">
            <Button
              style={STYLES.SECONDARY}
              onClick={() => onCancel && onCancel()}
            >
              Cancel
            </Button>
            <Button
              style={STYLES.PRIMARY}
              nativeButtonProps={{
                disabled: !textareaValue || textareaValue === "<p></p>"
              }}
              onClick={handleSubmitAnnotation}
              loading={isSubmitting}
              dataCy="submit-annotation"
            >
              <Icon svg="send" />
            </Button>
          </div>
        }
      </div>
    );
  }
);

// Adding display name for better debugging and to avoid ESLint warning
AddAnnotation.displayName = "AddAnnotation";

export default AddAnnotation;
