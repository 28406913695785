import DynamicForm from "@/components/forms/generated";
import { useState, useEffect } from "react";
import { useFormGetInfoQuery, FormId } from "@/store/services/form";
import { useDispatch } from "react-redux";
import { UserId } from "@/store/services/user";
import {
  useLazyPatientGetFormQuery,
  usePatientSubmitFormMutation
} from "@/store/services/patient";

// constants
import { STATUS_KEYS } from "@/globals/constants";

// store
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { useTaskCompleteMutation } from "@/store/services/task";
import { TaskId } from "@/store/services/task";
import { setRightPaneOpen } from "@/components/drawer/drawerSlice";

import styles from "../styles.module.scss";
import { setModalIsOpen } from "../modalSlice";

export interface GenericFormModalProps {
  formId: FormId;
  patientId: UserId;
  title: string;
  taskId?: TaskId; // optional, only if form submission associated with task
  isReadOnly?: boolean;
}

export default function GenericFormModal({
  formId,
  patientId,
  taskId,
  isReadOnly = false
}: GenericFormModalProps) {
  const dispatch = useDispatch();
  const formRequest = useFormGetInfoQuery({ formId: formId });
  const [getForm, { data: submission }] = useLazyPatientGetFormQuery();
  const [submitForm] = usePatientSubmitFormMutation();
  const [completeTask] = useTaskCompleteMutation();
  const [renderForm, setRenderForm] = useState(false);
  const onSubmit = (data: any) => {
    submitForm({
      patientId,
      formId,
      patientSubmitFormRequest: {
        data,
        form_id: formId
      }
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Form successfully submitted",
            type: STATUS_KEYS.SUCCESS
          })
        );
        // On desktop, it's right pane on mobile, it's modal - close both here
        dispatch(setRightPaneOpen(false));
        dispatch(setModalIsOpen(false));
        // if there is an associated task, mark it complete after form submission
        if (taskId) {
          completeTask({ taskId })
            .unwrap()
            .then(() => {
              dispatch(
                addAlertToToastTrough({
                  message: "Task marked complete",
                  type: STATUS_KEYS.SUCCESS
                })
              );
            })
            .catch(() => {
              dispatch(
                addAlertToToastTrough({
                  message: "Oops! Task could not be marked complete",
                  type: STATUS_KEYS.ERROR
                })
              );
            });
        }
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Oops! Form coud not be submitted",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  // fetch data for this form
  useEffect(() => {
    getForm({ patientId, formId });
  }, [formId, patientId]);

  // TEMP: explicitly tell form when to render, if its been filled before or not
  // Note this logic should probably be handled in the Dynamic Form, for a future iteration
  useEffect(() => {
    if (!submission) {
      setRenderForm(true);
    } else if (submission && !submission.form_id) {
      setRenderForm(true);
    } else if (
      submission &&
      submission.form_id &&
      submission.form_id !== formId
    ) {
      setRenderForm(false);
    } else if (
      submission &&
      submission.form_id &&
      submission.form_id === formId
    ) {
      setRenderForm(true);
    }
  }, [submission, formId]);

  return (
    <div className={styles.GenericFormModal}>
      {/* only load form once submission data for this specific form is available */}
      {formRequest.isSuccess && renderForm && (
        <DynamicForm
          form={formRequest.data}
          onSubmit={onSubmit}
          onCancel={() => {
            // On desktop, it's right pane on mobile, it's modal - close both here
            dispatch(setRightPaneOpen(false));
            dispatch(setModalIsOpen(false));
          }}
          submission={submission}
          isReadOnly={isReadOnly}
          patientId={patientId}
        />
      )}
    </div>
  );
}
