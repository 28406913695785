/* LabOrdering Name */
/* External Imports */
import clsx from "clsx";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { sanitize } from "isomorphic-dompurify";

/* Local Imports */

// components
import Icon from "@/components//icons";
import Search from "@/components/input/search";
import Input from "@/components/input";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import ControlledCombobox from "@/components/input/controlledCombobox";
import Tag from "@/components/tag";
import ContentRenderer from "@/components/textArea/contentRenderer";
import FaxContact from "@/components/input/faxContact";

// constants
import {
  METRICS,
  BILL_RECIPIENTS,
  ORDER_URGENCY,
  STATUS_KEYS,
  METRIC_LABELS
} from "@/globals/constants";

// store
import {
  useLazyLabListTestsQuery,
  useLabListQuery,
  useLabOrderCreateMutation,
  useLabOrderUpdateMutation,
  useLabSendOrderMutation,
  useLabGetLocationInfoQuery,
  useLabSearchLocationsMutation,
  useLabGetInfoQuery,
  useLazyLabGetAoeQuestionsQuery,
  LabTestInfo as LabTestType,
  LabLocationInfo,
  LabOrderCreateRequest,
  LabOrderUpdateRequest,
  LabOrderUrgency,
  HgOrgId,
  HgLocationId,
  AoeAnswer,
  AoeQuestion,
  LabSendOrderApiArg
} from "@/store/services/lab";
import {
  Icd10CodeDetails,
  useLazyCodingSearchIcd10Query
} from "@/store/services/coding";
import { LabOrgInfo } from "@/store/services/location";
import { EncounterInfo, LabOrderInfo } from "@/store/services/encounter";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setModalIsOpen } from "@/components/modal/modalSlice";
import { ErrorInfo, LabOrderBillRecipient } from "@/store/services/patient";
import { usePracticeInfoQuery } from "@/store/services/practice";
import { RootState } from "@/store/store";
import {
  setElectronicOrderingEnabled,
  setIsSubmittingOrder
} from "@/components/wizard/wizardSlice";

// styles
import styles from "./styles.module.scss";

/* LabOrdering Typescript Interface */
interface LabOrderingProps {
  selectedStep: string;
  encounter: EncounterInfo;
  order?: LabOrderInfo;
  submittingStep: string;
  customActionId?: string;
  onOrderValidation?: (valid: boolean) => void;
  shouldSubmit: boolean;
}

type OrderDraftType = {
  "order-note"?: string;
  "order-name"?: string;
  "select-urgency"?: string;
  "bill-recipient"?: LabOrderBillRecipient;
  "specimen-collection-date"?: string;
  "icd-10-codes"?: Icd10CodeDetails[];
  fasting?: boolean;
};

export default function LabOrdering({
  selectedStep,
  encounter,
  submittingStep,
  customActionId,
  onOrderValidation,
  order,
  shouldSubmit = false
}: LabOrderingProps) {
  /* Forms */
  const form = useForm();
  /* Redux */
  const dispatch = useDispatch();
  const { electronicOrderingEnabled } = useSelector(
    (state: RootState) => state.wizard
  );

  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  /* Local State */
  const [labFaxNumber, setLabFaxNumber] = useState<string | undefined>(
    undefined
  );
  const [selectedLab, setSelectedLab] = useState<HgOrgId | null>(
    order?.lab_id || null
  );
  const [selectedLabLocation, setSelectedLabLocation] =
    useState<HgLocationId | null>(null);

  const [orderDraft, setOrderDraft] = useState<OrderDraftType>({
    "order-note": "",
    "order-name": "",
    "select-urgency": METRICS.ROUTINE,
    "icd-10-codes": [],
    fasting: false
  });
  const [selectedTests, setSelectedTests] = useState<LabTestType[]>([]);
  const [testDetailOpen, setTestDetailOpen] = useState<LabTestType["code"][]>(
    []
  );
  const [aoes, setAoes] = useState<AoeQuestion[]>([]);

  // data
  const [fetchLabTests, { data: tests, isLoading: isTestsLoading }] =
    useLazyLabListTestsQuery();
  const [searchIcd10, { data: icd10Codes, isLoading: isIcd10Loading }] =
    useLazyCodingSearchIcd10Query();
  const { data: labs, isLoading: isLabsLoading } = useLabListQuery();
  const [getAoes] = useLazyLabGetAoeQuestionsQuery();
  const [createOrder] = useLabOrderCreateMutation();
  const [updateOrder] = useLabOrderUpdateMutation();
  const [sendOrder, { error: sendOrderErr }] = useLabSendOrderMutation();
  const [
    fetchLabLocations,
    { data: locations, isLoading: isLocationsLoading }
  ] = useLabSearchLocationsMutation();
  const { data: lab } = useLabGetInfoQuery(
    { labHgId: selectedLab as HgOrgId },
    { skip: !selectedLab }
  );
  const { data: location } = useLabGetLocationInfoQuery(
    {
      lablocationId: selectedLabLocation as HgLocationId
    },
    { skip: !selectedLabLocation }
  );
  const { data: practice } = usePracticeInfoQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo?.practice_id }
  );

  /* Effects */
  useEffect(() => {
    const electronicOrderingEnabled =
      practice?.metadata.health_gorilla?.accounts?.[selectedLab as string]
        ?.enabled || false;
    dispatch(setElectronicOrderingEnabled(electronicOrderingEnabled));
  }, [practice, selectedLab]);

  useEffect(() => {
    if (customActionId === "save_order_draft") {
      // Save order draft to DB
      handleUpsertOrder(false);
    }
  }, [customActionId]);

  // when user clicks "next", this effect runs
  useEffect(() => {
    // if the "next" button click was for order details, submit the form
    if (shouldSubmit) {
      handleUpsertOrder(true);
    }
  }, [shouldSubmit]);

  // fetch tests and locations only once a lab has been selected
  useEffect(() => {
    if (selectedLab) {
      fetchLabTests({ labHgId: selectedLab });
      fetchLabLocations({ labHgId: selectedLab, labSearch: {} });
    }
  }, [selectedLab]);

  // when selected tests change, default the first one to open in the details view
  useEffect(() => {
    if (selectedTests && selectedTests[0]) {
      setTestDetailOpen([selectedTests[0].code]);
    }
    getAoes({
      labHgId: selectedLab as string,
      codes: selectedTests.map(test => test.code).join(",")
    })
      .unwrap()
      .then(data => {
        setAoes(data);
      });
  }, [selectedTests]);

  // Aggregate AOEs into tests, so it should be [test_id]: []aoe_ids
  const aoesByTest = useMemo(
    () =>
      (aoes || []).reduce(
        (acc, aoe) => {
          acc[aoe.test_code] = acc[aoe.test_code] || [];
          acc[aoe.test_code].push(aoe);
          return acc;
        },
        {} as Record<string, AoeQuestion[]>
      ),
    [aoes]
  );

  // store how many selected tests have AOEs
  const testsWithAoes = useMemo(
    () => selectedTests.filter(test => aoesByTest[test.code]).length,
    [selectedTests, aoesByTest]
  );

  useEffect(() => {
    selectedTests.forEach(test => {
      (test.aoe_answers || []).forEach(aoe => {
        form.setValue(`test-${test.test_id}+aoe-${aoe.aoe_id}`, aoe.response);
      });
    });
  }, [aoesByTest]);

  // if order exists already, populate
  useEffect(() => {
    if (order) {
      const draftOrder = {
        "order-note": order.note,
        "order-name": order.label,
        "select-urgency": order.urgency,
        "bill-recipient": order.bill_recipient,
        "specimen-collection-date": order.specimen_collected,
        "icd-10-codes": order.icd10_codes,
        fasting: order.fasting
      };
      setSelectedLab(order.lab_id);
      setSelectedTests(order.tests);
      setOrderDraft(draftOrder);

      form.setValue("order-note", order.note);
      form.setValue("order-name", order.label);
      form.setValue("select-urgency", order.urgency);
      form.setValue("bill-recipient", order.bill_recipient);
      form.setValue("specimen-collection-date", order.specimen_collected);
      form.setValue("fasting", order.fasting);
      form.setValue("icd-10-codes", order.icd10_codes);
      if (order.location_id) {
        setSelectedLabLocation(order.location_id);
      }
    }
  }, [order]);

  // respond to step change
  useEffect(() => {
    if (selectedStep !== "add_order_details") {
      const orderFormData = form.getValues();
      setOrderDraft(orderFormData);
    }
  }, [selectedStep]);

  // validate order can be sent
  useEffect(() => {
    if (selectedLab && selectedTests.length > 0) {
      onOrderValidation && onOrderValidation(true);
    } else {
      onOrderValidation && onOrderValidation(false);
    }
  }, [selectedLab, selectedTests]);

  /* Event Handlers */
  const handleIcd10Select = (icd10: Icd10CodeDetails) => {
    setOrderDraft(prevDraft => {
      let currCodes = prevDraft["icd-10-codes"] || [];
      if (currCodes.some(code => code.icd_id === icd10.icd_id)) {
        currCodes = currCodes.filter(code => code.icd_id !== icd10.icd_id);
      } else {
        currCodes.push(icd10);
      }

      form.setValue("icd-10-codes", currCodes);
      return { ...prevDraft, "icd-10-codes": currCodes };
    });
  };

  const handleTestSelect = (test: LabTestType) => {
    setSelectedTests(prevSelectedTests => {
      // Check if the test with the same code is already in the array
      const isTestSelected = prevSelectedTests.some(
        selectedTest => selectedTest.code === test.code
      );

      if (isTestSelected) {
        // If the test is already in the array, remove it
        return prevSelectedTests.filter(
          selectedTest => selectedTest.code !== test.code
        );
      } else {
        // If not, add the test to the array
        return [...prevSelectedTests, test];
      }
    });
  };

  const handleTestDetailToggle = (testCode: string) => {
    setTestDetailOpen(prevTestDetailOpen => {
      // Check if the test code is already in the array
      const isTestOpen = prevTestDetailOpen.includes(testCode);

      if (isTestOpen) {
        // If the test code is already in the array, remove it
        return prevTestDetailOpen.filter(openCode => openCode !== testCode);
      } else {
        // If not, add the test code to the array
        return [...prevTestDetailOpen, testCode];
      }
    });
  };

  const handleSendOrder = (orderId: string) => {
    const payload: LabSendOrderApiArg = {
      orderId,
      labSendOrderRequest: {
        send_electronically: electronicOrderingEnabled
      }
    };

    if (!electronicOrderingEnabled) {
      payload.labSendOrderRequest.fax = labFaxNumber;
    }

    if (orderId && selectedLab) {
      dispatch(setIsSubmittingOrder(true));

      sendOrder(payload)
        .unwrap()
        .then(() => {
          dispatch(
            addAlertToToastTrough({
              message: "Successfully sent order to lab",
              type: STATUS_KEYS.SUCCESS
            })
          );
          dispatch(setModalIsOpen(false));
          dispatch(setIsSubmittingOrder(false));
        })
        .catch(({ data }: { data: ErrorInfo }) => {
          dispatch(
            addAlertToToastTrough({
              message: data?.user_facing
                ? data?.message
                : "Oops! Could not send order to lab",
              type: STATUS_KEYS.ERROR
            })
          );

          if (data?.user_facing && data?.extra_data) {
            for (const error of data.extra_data) {
              if (error.message) {
                dispatch(
                  addAlertToToastTrough({
                    message: error.message,
                    type: STATUS_KEYS.ERROR
                  })
                );
              }
            }
          }

          dispatch(setIsSubmittingOrder(false));
        });
    }
  };

  const handleUpsertOrder = (sendOrder: boolean = false) => {
    if (selectedLab) {
      if (order && order.order_id) {
        // This is an update
        const updateReq: LabOrderUpdateRequest = {
          order_id: order.order_id,
          lab_id: selectedLab,
          tests: selectedTests,
          encounter_id: encounter.encounter_id,
          patient_id: encounter.patient.user_id,
          provider_id: encounter.provider.user_id,
          label: orderDraft["order-name"] || "",
          note: orderDraft["order-note"],
          urgency: orderDraft["select-urgency"] as LabOrderUrgency,
          bill_recipient: orderDraft["bill-recipient"],
          specimen_collected: orderDraft["specimen-collection-date"],
          fasting: orderDraft["fasting"],
          icd10_ids: orderDraft["icd-10-codes"]?.map(code => code.icd_id)
        };
        updateReq.tests = selectedTests.map(test => {
          const aoes = aoesByTest[test.code] || [];
          const answers: AoeAnswer[] = aoes.map(aoe => {
            const answer = form.getValues(
              `test-${test.test_id}+aoe-${aoe.aoe_id}`
            );
            return { aoe_id: aoe.aoe_id, response: answer };
          });

          return { ...test, aoe_answers: answers };
        });
        updateOrder({
          orderId: order.order_id,
          labOrderUpdateRequest: updateReq
        })
          .unwrap()
          .then(() => {
            if (sendOrder) {
              handleSendOrder(order.order_id);
            } else {
              dispatch(
                addAlertToToastTrough({
                  message: "Successfully saved order draft",
                  type: STATUS_KEYS.SUCCESS
                })
              );
              dispatch(setModalIsOpen(false));
            }
          })
          .catch(() =>
            dispatch(
              addAlertToToastTrough({
                message: "Oops! Could not save order draft",
                type: STATUS_KEYS.ERROR
              })
            )
          );
      } else {
        // This is a create
        const createReq: LabOrderCreateRequest = {
          lab_id: selectedLab,
          tests: selectedTests,
          encounter_id: encounter.encounter_id,
          patient_id: encounter.patient.user_id,
          provider_id: encounter.provider.user_id,
          label: orderDraft["order-name"] || "",
          note: orderDraft["order-note"],
          urgency: orderDraft["select-urgency"] as LabOrderUrgency,
          bill_recipient: orderDraft["bill-recipient"],
          specimen_collected: orderDraft["specimen-collection-date"],
          fasting: orderDraft["fasting"],
          icd10_ids: orderDraft["icd-10-codes"]?.map(code => code.icd_id)
        };
        createReq.tests = selectedTests.map(test => {
          const aoes = aoesByTest[test.code] || [];
          const answers: AoeAnswer[] = aoes.map(aoe => {
            const answer = form.getValues(
              `test-${test.test_id}+aoe-${aoe.aoe_id}`
            );
            return { aoe_id: aoe.aoe_id, response: answer };
          });

          return { ...test, aoe_answers: answers };
        });
        createOrder({ labOrderCreateRequest: createReq })
          .unwrap()
          .then(orderId => {
            if (sendOrder) {
              handleSendOrder(orderId);
            } else {
              dispatch(
                addAlertToToastTrough({
                  message: "Successfully saved order draft",
                  type: STATUS_KEYS.SUCCESS
                })
              );
              dispatch(setModalIsOpen(false));
            }
          })
          .catch(() =>
            dispatch(
              addAlertToToastTrough({
                message: "Oops! Could not save order draft",
                type: STATUS_KEYS.ERROR
              })
            )
          );
      }
    }
  };

  return (
    <div className={clsx(styles.LabOrdering)}>
      {selectedStep === "select_lab" && (
        <div className={styles.wrapper}>
          <div className={styles.note}>
            <Icon svg="lightbulb" width={12} />
            Select a lab to order tests from
          </div>
          <Search
            name={`search-labs`}
            id={`search-labs`}
            placeholder={isLabsLoading ? "Fetching labs..." : "Select a lab"}
            labelAcc={lab => lab.name}
            label="Select a lab"
            hiddenLabel
            options={labs || []}
            onSelect={(lab: LabOrgInfo) => setSelectedLab(lab.id)}
            isClearOnSelect
            fullWidth
            disabled={isLabsLoading}
          />
          <div
            className={clsx(styles.itemDetail, {
              [styles.emptyState]: !selectedLab
            })}
            key={selectedLab}
          >
            <div className={styles.itemName}>
              <div className="flex">
                <Icon svg="lab" width={12} />
                {isLabsLoading ? (
                  <div className="flex">
                    <div className={styles.spinner} />
                    <p>Connecting to lab network...</p>
                  </div>
                ) : (
                  <p>{lab?.name || "No lab selected"}</p>
                )}
              </div>
              <button type={"button"} onClick={() => setSelectedLab(null)}>
                <Icon svg="close" width={12} />
              </button>
            </div>
          </div>
          {locations && selectedLab && (
            <>
              <Search
                id={`search-lab-location`}
                name={`search-lab-location`}
                placeholder={
                  isLocationsLoading
                    ? "Fetching locations..."
                    : "Select a location"
                }
                labelAcc={(loc: LabLocationInfo) => {
                  let label = loc.name || "";
                  label += loc.address.display
                    ? " - " + loc.address.display
                    : "";
                  return label;
                }}
                label="Search lab locations"
                hiddenLabel
                options={locations || []}
                onSelect={(loc: LabLocationInfo) =>
                  setSelectedLabLocation(loc.location_id || null)
                }
                isClearOnSelect
                fullWidth
                disabled={isLocationsLoading}
              />
              <div
                className={clsx(styles.itemDetail, {
                  [styles.emptyState]: !selectedLabLocation
                })}
                key={lab?.id}
              >
                <div className={styles.itemName}>
                  <div className="flex">
                    <Icon svg="marker-pin" width={14} />
                    {isLocationsLoading ? (
                      <div className="flex">
                        <div className={styles.spinner} />
                        <p>Fetching lab locations...</p>
                      </div>
                    ) : (
                      <p>{location?.name || "No location specified"}</p>
                    )}
                  </div>
                  <button
                    type={"button"}
                    onClick={() => setSelectedLabLocation(null)}
                  >
                    <Icon svg="close" width={12} />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {selectedStep === "select_tests" && (
        <div className={styles.wrapper}>
          <div className={styles.note}>
            <Icon svg="lightbulb" width={12} />
            {selectedLab
              ? "Select tests to order from " + lab?.name || ""
              : "Please select a lab before selecting tests"}
          </div>
          {selectedLab && (
            <Search
              name={`search-tests`}
              id={`search-tests`}
              label={`Search tests`}
              placeholder={
                isTestsLoading ? "Fetching tests..." : "Search tests"
              }
              hiddenLabel
              options={tests || []}
              labelAcc={test => `(${test.code}) ${test.display}`}
              onSelect={handleTestSelect}
              fullWidth
              isClearOnSelect
              disabled={isTestsLoading}
            />
          )}
          <div
            className={clsx(styles.overflowContainer, {
              [styles.hidden]: !selectedLab || selectedTests.length === 0
            })}
          >
            {selectedLab &&
              selectedTests.map(test => (
                <div className={styles.itemDetail} key={test.code}>
                  <div className={styles.itemName}>
                    <p>{`(${test.code}) ${test.display}`}</p>
                    <button
                      type={"button"}
                      onClick={() => handleTestSelect(test)}
                    >
                      <Icon svg="close" width={12} />
                    </button>
                  </div>
                </div>
              ))}
          </div>
          {selectedLab && (!selectedTests || selectedTests.length === 0) && (
            <div className={clsx(styles.itemDetail, styles.emptyState)}>
              <div className={styles.itemName}>
                <div className="flex">
                  <Icon svg="order_lab" width={12} />
                  {isTestsLoading ? (
                    <div className="flex">
                      <div className={styles.spinner} />
                      <p>Fetching tests...</p>
                    </div>
                  ) : (
                    <p>No tests selected</p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {selectedStep === "add_details" && (
        <>
          <div className={styles.wrapper}>
            <div className={styles.note}>
              <Icon svg="lightbulb" width={12} />
              {selectedLab
                ? `${testsWithAoes} selected tests require additional details`
                : "Please select tests before adding details"}
            </div>
            <div className={styles.overflowContainer}>
              {selectedTests.map(test => (
                <div className={styles.itemDetail} key={test.code}>
                  <div className={styles.itemName}>
                    <p>{`(${test.code}) ${test.display}`}</p>
                    {aoesByTest[test.code] ? (
                      <button
                        type={"button"}
                        onClick={() => handleTestDetailToggle(test.code)}
                      >
                        <Icon
                          svg={`chevron_${
                            testDetailOpen.includes(test.code) ? "up" : "down"
                          }_blue`}
                          width={12}
                        />
                      </button>
                    ) : (
                      <Tag
                        type={STATUS_KEYS.INFO}
                        label={"No additional details required"}
                      />
                    )}
                  </div>
                  {aoesByTest[test.code] && (
                    <div
                      className={clsx(styles.testOptions, {
                        [styles.open]: testDetailOpen.includes(test.code)
                      })}
                    >
                      {/* map over list of AoE for this test */}
                      {aoesByTest[test.code]?.map(aoe => {
                        switch (aoe.type) {
                          case "string":
                            return (
                              <div
                                className={styles.testOption}
                                key={`test-${test.test_id}+aoe-${aoe.aoe_id}`}
                              >
                                <Input
                                  name={`test-${test.test_id}+aoe-${aoe.aoe_id}`}
                                  id={`test-${test.test_id}+aoe-${aoe.aoe_id}`}
                                  label={aoe.text}
                                  fullWidth
                                  type={"text"}
                                  placeholder={aoe.text}
                                  register={form.register}
                                />
                              </div>
                            );

                          case "choice":
                            return (
                              <div
                                className={styles.testOption}
                                key={`test-${test.test_id}+aoe-${aoe.aoe_id}`}
                              >
                                <ControlledCombobox
                                  name={`test-${test.test_id}+aoe-${aoe.aoe_id}`}
                                  isHorizontalLayout={false}
                                  fullWidth
                                  label={aoe.text}
                                  options={aoe.options || []}
                                  control={form.control}
                                />
                              </div>
                            );

                          case "open-choice":
                            return (
                              <div
                                className={styles.testOption}
                                key={`test-${test.test_id}+aoe-${aoe.aoe_id}`}
                              >
                                <ControlledCombobox
                                  isHorizontalLayout={false}
                                  name={`test-${test.test_id}+aoe-${aoe.aoe_id}`}
                                  fullWidth
                                  label={aoe.text}
                                  options={aoe.options || []}
                                  control={form.control}
                                />
                              </div>
                            );

                          case "date":
                            return (
                              <div
                                className={styles.testOption}
                                key={`test-${test.test_id}+aoe-${aoe.aoe_id}`}
                              >
                                <Input
                                  name={`test-${test.test_id}+aoe-${aoe.aoe_id}`}
                                  id={`test-${test.test_id}+aoe-${aoe.aoe_id}`}
                                  label={aoe.text}
                                  fullWidth
                                  type={"date"}
                                  register={form.register}
                                />
                              </div>
                            );

                          default:
                            throw Error("invalid AoE type");
                        }
                      })}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {selectedStep === "add_icd10_codes" && (
        <div className={styles.wrapper}>
          <div className={styles.note}>
            <Icon svg="lightbulb" width={12} />
            Add ICD-10 codes to send with this order
          </div>
          <Search
            name={`search-icd10`}
            id={`search-icd10`}
            label={`Search ICD-10 codes`}
            placeholder={
              isIcd10Loading
                ? "Fetching ICD-10 codes..."
                : "Search ICD-10 codes"
            }
            hiddenLabel
            options={icd10Codes || []}
            labelAcc={(code: Icd10CodeDetails) =>
              `${code.name} - ${code.description}`
            }
            onSelect={handleIcd10Select}
            onChange={value => {
              searchIcd10({ term: value });
            }}
            fullWidth
            isClearOnSelect
          />

          <div
            className={clsx(styles.overflowContainer, {
              [styles.hidden]:
                !selectedLab || (orderDraft["icd-10-codes"] || []).length === 0
            })}
          >
            {selectedLab &&
              (orderDraft["icd-10-codes"] || []).map(code => (
                <div className={styles.itemDetail} key={code.icd_id}>
                  <div className={styles.itemName}>
                    <p>{`${code.name} - ${code.description}`}</p>
                    <button
                      type={"button"}
                      onClick={() => handleIcd10Select(code)}
                    >
                      <Icon svg="close" width={12} />
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {selectedStep === "add_order_details" && (
        <form className={styles.wrapper}>
          <div className={styles.note}>
            <Icon svg="lightbulb" width={12} />
            Add details to this order
          </div>
          <div className={styles.orderEntryDetail}>
            <p>Name lab order</p>
            <Input
              name={`order-name`}
              id={`order-name`}
              label={`Order Name`}
              hiddenLabel
              fullWidth
              type={"text"}
              placeholder="Name order"
              register={form.register}
            />
          </div>
          <div className={styles.orderEntryDetail}>
            <p>Add note for lab</p>
            <ControlledTextArea
              name={`order-note`}
              id={`order-note`}
              label={`Order Note`}
              hiddenLabel
              placeholder="Note here"
              onChange={(content: string) => {
                setOrderDraft(prevDraft => ({
                  ...prevDraft,
                  "order-note": content
                }));
              }}
              form={form}
              rows={3}
            />
          </div>
          <div className={styles.selections}>
            <div className={styles.orderEntryDetail}>
              <p>Fasting</p>
              <div id="fasting-checkbox-wrapper">
                <Input
                  type="checkbox"
                  label="Fasting"
                  name="fasting"
                  id="fasting"
                  hiddenLabel
                  register={form.register}
                />
              </div>
            </div>
            <div className={styles.orderEntryDetail}>
              <p>Collection Date</p>
              <Input
                type="date"
                label="Collection Date"
                name="specimen-collection-date"
                id="specimen-collection-date"
                hiddenLabel
                fullWidth
                register={form.register}
              />
            </div>
            <div className={styles.orderEntryDetail}>
              <p>Select urgency</p>
              <ControlledCombobox
                name={`select-urgency`}
                label={`Select Urgency`}
                hiddenLabel
                options={ORDER_URGENCY}
                control={form.control}
                isHorizontalLayout={false}
                fullWidth
              />
            </div>
            <div className={styles.orderEntryDetail}>
              <p>Bill to</p>
              <ControlledCombobox
                name={`bill-recipient`}
                label={`bill to`}
                hiddenLabel
                options={BILL_RECIPIENTS}
                control={form.control}
                isHorizontalLayout={false}
                fullWidth
              />
            </div>
          </div>
          {!electronicOrderingEnabled && (
            <div className={styles.selections}>
              <div className={styles.orderEntryDetail}>
                <FaxContact onChange={item => setLabFaxNumber(item?.fax)} />
              </div>
            </div>
          )}
        </form>
      )}
      {selectedStep === "review" && (
        <div className={styles.wrapper}>
          <div className={styles.note}>
            <Icon svg="lightbulb" width={12} />
            <p>Save draft order or send order to lab</p>
          </div>
          <div className={styles.orderDetail}>
            <p className={styles.label}>Recipient</p>
            <div className="flex">
              <Tag
                type={lab?.name ? STATUS_KEYS.INFO : STATUS_KEYS.ERROR}
                label={
                  lab?.name
                    ? `${lab?.name} ${labFaxNumber ? "|" + labFaxNumber : ""}`
                    : "No recipient lab selected"
                }
              />
            </div>
          </div>
          <div className={styles.orderDetail}>
            <p className={styles.label}>{orderDraft["order-name"]}</p>
            <div className={styles.selectedTests}>
              {selectedTests.map(test => (
                <Tag
                  key={test.code}
                  type={STATUS_KEYS.INFO_GREY}
                  label={test.display + " - " + test.code}
                  isStatus
                />
              ))}
              {selectedTests.length === 0 && (
                <Tag type={STATUS_KEYS.ERROR} label="No tests selected" />
              )}
            </div>
          </div>
          {/* fasting */}
          <div className={styles.orderDetail}>
            <p className={styles.label}>Fasting</p>
            <Tag
              type={STATUS_KEYS.INFO_GREY}
              label={orderDraft.fasting ? "Yes" : "No"}
            />
          </div>
          <div className={styles.orderDetail}>
            <p className={styles.label}>Urgency</p>
            <Tag
              type={STATUS_KEYS.INFO_GREY}
              label={
                METRIC_LABELS[orderDraft["select-urgency"] as string] ||
                "Not specified"
              }
            />
          </div>
          <div className={styles.orderDetail}>
            <p className={styles.label}>Bill to</p>
            <Tag
              type={STATUS_KEYS.INFO_GREY}
              label={orderDraft["bill-recipient"] || "Not specified"}
            />
          </div>
          <div className={styles.orderDetail}>
            <p className={styles.label}>Note</p>
            <ContentRenderer
              content={sanitize(orderDraft["order-note"] || "")}
              classes="t5"
            />
          </div>
        </div>
      )}
    </div>
  );
}
