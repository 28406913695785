import * as generated from "./generated/system";

const system = generated.system.enhanceEndpoints({
  endpoints: {
    getFeatureFlags: {
      providesTags: ["FeatureFlags"]
    },
    bulkUpdatePracticeFeatureFlags: {
      invalidatesTags: ["FeatureFlags"]
    }
  }
});

export * from "./generated/system";
export default system;
export const {
  useGetFeatureFlagsQuery,
  useLazyGetFeatureFlagsQuery,
  useReportFrontendErrorMutation
} = system;
