/* Share Records Modal Template */
// External
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

// components
import RecordGenerationForm from "./recordGenerationForm";
import ShareRecordsWizard from "./shareRecordsWizard";
import SendRecordsForm from "./sendRecordsForm";
import RecordGenerationConfirmation from "./recordGenerationConfirmation";

// Globals, helpers, constants
import { MODAL_TYPES } from "../../dispatcher";
import {
  RecordGenerationFormValues,
  useFormType,
  useIsContactError,
  useIsSendingFax
} from "./helpers/recordGenerationHooks";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { STATUS_KEYS } from "@/globals/constants";
import { setModalContent, setModalIsOpen } from "../../modalSlice";

// Utilities
import {
  parseEncounterIds,
  parseFileIds,
  parsePdfSections,
  getRecordGenerationFormDefaultValues
} from "./helpers/utilities";
import { FORMAT } from "@/globals/helpers/formatters";

// store
import { RootState } from "@/store/store";
import {
  usePatientGetInfoQuery,
  usePatientGeneratePdfMutation,
  PatientGeneratePdfPayload,
  PatientPdfCoverpage,
  PatientInfo
} from "@/store/services/patient";
import { usePracticeInfoQuery } from "@/store/services/practice";
import {
  useProviderGetInfoQuery
} from "@/store/services/provider";

// styles
import styles from "./styles.module.scss";

export interface ShareRecordsModalProps {
  patientId: number;
  // Optional pre-filled data
  data?: Partial<RecordGenerationFormValues>;
}

export default function ShareRecordsModal({
  patientId,
  data
}: ShareRecordsModalProps) {
  const dispatch = useDispatch();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { data: patient } = usePatientGetInfoQuery(
    { patientId },
    { skip: !patientId }
  );

  const providerId = sessionInfo?.user_id;
  const { data: provider } = useProviderGetInfoQuery(
    { providerId: providerId as number },
    { skip: !providerId }
  );

  const practiceId = sessionInfo?.practice_id;
  const { data: practice } = usePracticeInfoQuery(
    { practiceId: practiceId as number },
    { skip: !practiceId }
  );

  const [generatePdf, { isLoading }] = usePatientGeneratePdfMutation();

  const formType = useFormType(patientId);

  const form = useForm<RecordGenerationFormValues>({
    defaultValues: getRecordGenerationFormDefaultValues(practice, provider, formType, data)
  });

  // Custom hooks for form validation
  const isSendingFax = useIsSendingFax(form);
  const isContactError = useIsContactError(form);

  // Effect to autofill the provider phone and name
  useEffect(() => {
    form.reset(getRecordGenerationFormDefaultValues(practice, provider, formType, data));
  }, [provider, practice, formType]);

  const dataOptionsError = useMemo(() => {
    const values = form.getValues();
    let err = true;
    // we just need one value that exactly equals true (implicitly meaning it's a checkbox)
    Object.values(values).forEach((value: any) => {
      if (typeof value === "boolean" && value === true) {
        err = false;
      }
    });
    return err;
  }, [form.watch()]);

  const onSubmit = () => {
    // The wizard doesn't pass the form values on submit so we grab them manually
    const data: RecordGenerationFormValues = form.getValues();

    // Build the payload
    const fileIds = parseFileIds(data);
    const sections = parsePdfSections(data);

    const payload: PatientGeneratePdfPayload = {
      sections,
      extra_file_ids: fileIds
    };

    const encounterList = parseEncounterIds(data);
    if (encounterList.length > 0) {
      payload.sections.push("encounter_list");
      payload.encounter_list_params = {
        encounter_ids: encounterList
      };
      // I'm doing the !! to ensure these are booleans and not possible "undefined" or "null" to
      // keep things consistent and clean.
      payload.encounter_list_params.include_newborn_exams =
        !!data.include_newborn_exams;
      payload.encounter_list_params.include_codes = !!data.include_codes;
    }

    if (isSendingFax && !isContactError) {
      const coverpage: PatientPdfCoverpage = {
        // isContactError will already have checked if either of these are falsy so
        // casting is safe
        to_name: data.recipient_name as string,
        to_fax: data.recipient_fax as string,
        subject: FORMAT.name(patient as PatientInfo),
        comment: data.record_note
      };
      payload.coverpage = coverpage;
    }

    // Generate the PDF and open new modal if it's a success
    generatePdf({
      patientId: patient?.user_id as number,
      patientGeneratePdfPayload: payload
    })
      .unwrap()
      .then(pdfGenerationResponse => {
        dispatch(setModalIsOpen(true));
        dispatch(
          setModalContent({
            type: MODAL_TYPES.PREVIEW_PDF,
            props: {
              title: "Transfer",
              pdfBase64: pdfGenerationResponse.content,
              isSendingFax: isSendingFax,
              patientId,
              data,
              errors: pdfGenerationResponse.file_errors
            }
          })
        );
      })
      .catch(err => {
        console.error(err);
        dispatch(addAlertToToastTrough({
          message: "Failed to generate PDF record",
          type: STATUS_KEYS.ERROR,
        }))
      });
  };

  // Rendering helper functions
  const submitButtonLabel = useMemo(() => {
    if (isLoading) {
      return "Loading";
    }

    return isSendingFax ? "Send fax" : "Print PDF";
  }, [isSendingFax, isLoading]);

  const isSubmitDisabled = useMemo(() => {
    if (isLoading) {
      return true;
    }

    return (isSendingFax && isContactError) || dataOptionsError;
  }, [isContactError, isSendingFax, dataOptionsError, isLoading]);

  const isBackDisabled = useMemo(() => {
    if (isLoading) {
      return true;
    }
  }, [isLoading])

  // Escape check for patient (also helps with the type safety in the actual render return)
  if (!patient) {
    return <p>Loading...</p>;
  }

  return (
    <div className={styles.ShareRecordsModal}>
      <ShareRecordsWizard
        steps={["select_records", "contact_info", "confirmation"]}
        labels={{
          select_records: "Select records",
          contact_info: "Add contact information",
          confirmation: "Confirm and generate"
        }}
        onSubmit={onSubmit}
        submitButtonLabel={submitButtonLabel}
        isSubmitDisabled={isSubmitDisabled}
        isPreviousDisabled={isBackDisabled}
        //TODO(Landon): Add this back in, I'd like to add this button but
        // it's going to take a good amount of work and I want to focus on
        // just getting this out, then I can fast follow with improvements.
        // customButtons={{
        //   contact_info: [
        //     {
        //       label: "Skip",
        //       actionId: "skip_contact_info",
        //       disabled: false,
        //     }
        //   ]
        // }}
      >
        {selectedStep => {
          switch (selectedStep) {
            case "select_records":
              return (
                <>
                  <p>
                    Select the records from the lists below that you'd like to
                    include in the generated PDF records.
                  </p>
                  {patient && (
                    <div className={styles.fullWidth}>
                      <RecordGenerationForm
                        patientId={patient.user_id}
                        form={form}
                      />
                    </div>
                  )}
                </>
              );
            case "contact_info":
              return (
                <>
                  <p>
                    If you'd like to fax the records to someone, please fill in
                    their contact information below. If you just want to
                    generate the records without sending them, you can skip this
                    step.
                  </p>
                  <SendRecordsForm form={form} />
                </>
              );
            case "confirmation":
              return (
                <RecordGenerationConfirmation
                  form={form}
                  patientId={patient.user_id}
                />
              );
            default:
              return <h1>Not a step.</h1>;
          }
        }}
      </ShareRecordsWizard>
    </div>
  );
}
