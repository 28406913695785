/* Conclude Pregnancy Modal Template */

// External
import Skeleton from "react-loading-skeleton";
import dayjs from "dayjs";
import { useEffect } from "react";
import clsx from "clsx";
import { Inter } from "next/font/google";
import { useDispatch } from "react-redux";
import { Controller, useForm, FieldValues } from "react-hook-form";

// components
import Button from "@/components/button";
import Input from "@/components/input";
import ComboboxSelect from "@/components/input/combobox";
import Checkbox from "@/components/input/checkbox";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import { convertUtcIntToLocalDate } from "@/components/scheduling/calendars/utils";
import WeightInput, { Weight, getWeightObjectFromGrams } from "@/components/input/weightInput";

// constants
import {
  METRIC_LABELS,
  PREGNANCY_OUTCOMES,
  STATUS_KEYS
} from "@/globals/constants";

// store
import { setModalIsOpen } from "../modalSlice";
import {
  usePregnancyInfoQuery,
  usePregnancyUpsertMutation
} from "@/store/services/pregnancy";
import {
  PatientInfo,
  PregnancyOutcome,
  usePatientGetInfoQuery,
  usePatientUpsertMutation
} from "@/store/services/patient";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// styles
import styles from "../styles.module.scss";
import { useGetFeatureFlagsQuery } from "@/store/services/system";

const font = Inter({
  weight: ["400", "500", "700"],
  subsets: ["latin"]
});

type DeliverPregnancyForm = FieldValues & {
  create_chart: boolean;
  gest_days?: string;
  gest_weeks?: string;
  delivery_date: string;
  weight?: Weight;
};

export interface MarkPregnancyDeliveredProps {
  patientId: number;
  pregnancyId: number;
}

export default function MarkPregnancyDelivered({
  patientId,
  pregnancyId
}: MarkPregnancyDeliveredProps) {
  const dispatch = useDispatch();

  const { data: featureFlags } = useGetFeatureFlagsQuery();

  const { data: pregnancy, isSuccess: pregnancyLoaded } = usePregnancyInfoQuery(
    { pregnancyId: pregnancyId },
    { skip: !pregnancyId }
  );
  const { data: motherInfo } = usePatientGetInfoQuery(
    {
      patientId: patientId
    },
    { skip: !patientId }
  );

  const [
    updatePregnancy,
    { isError: isPregnancyUpdateError, isSuccess: isPregnancyUpdateSuccess }
  ] = usePregnancyUpsertMutation();
  const [
    updatePatient,
    { isError: isPatientUpdateError, isSuccess: isPatientUpdateSuccess }
  ] = usePatientUpsertMutation();
  const [
    createInfant,
    { isError: isCreateInfantError, isSuccess: isCreateInfantSuccess }
  ] = usePatientUpsertMutation();

  const gest_days = pregnancy?.gestational_age_at_delivery
    ? pregnancy?.gestational_age_at_delivery % 7
    : undefined;
  const gest_weeks = pregnancy?.gestational_age_at_delivery
    ? Math.floor(pregnancy?.gestational_age_at_delivery / 7)
    : undefined;
  const defaults: DeliverPregnancyForm = {
    gest_days: gest_days?.toString(),
    gest_weeks: gest_weeks?.toString(),
    gestational_age_at_delivery: pregnancy?.gestational_age_at_delivery,
    create_chart: false,
    pregnancy_id: pregnancyId,
    outcome: pregnancy?.outcome,
    state: pregnancy?.state,
    estimated_due_date: pregnancy?.edd?.estimated_due_date,
    flow_sheet: pregnancy?.flow_sheet,
    desired_birthplace: pregnancy?.desired_birthplace,
    length_of_labor: pregnancy?.length_of_labor_minutes,
    living: pregnancy?.living,
    weight: pregnancy?.weight
      ? getWeightObjectFromGrams(pregnancy.weight)
      : { pounds: 0, ounces: 0, grams: 0 },
    sex: pregnancy?.sex,
    name: pregnancy?.name,
    birthplace: pregnancy?.birthplace,
    completed: pregnancy?.pregnancy_completion_time,
    comment: pregnancy?.comment,
    is_past_pregnancy: false,
    // @ts-ignore
    delivery_date: pregnancy?.delivery_date
      ? convertUtcIntToLocalDate(pregnancy.delivery_date).format("YYYY-MM-DD")
      : undefined
  };
  const form = useForm<DeliverPregnancyForm>({ defaultValues: defaults });

  const { register, control, handleSubmit, watch, setValue, reset } = form;
  const chartWatch = watch("create_chart");

  useEffect(() => {
    if (!pregnancy || !pregnancy.edd?.estimated_due_date) {
      return;
    }
    setValue("gest_weeks", gest_weeks ? gest_weeks.toString() : undefined);
    setValue("gest_days", gest_days ? gest_days.toString() : undefined);

    reset({
      gest_days: gest_days?.toString(),
      gest_weeks: gest_weeks?.toString(),
      gestational_age_at_delivery: pregnancy?.gestational_age_at_delivery,
      create_chart: false,
      pregnancy_id: pregnancyId,
      outcome: pregnancy?.outcome,
      state: pregnancy?.state,
      estimated_due_date: pregnancy?.edd?.estimated_due_date,
      flow_sheet: pregnancy?.flow_sheet,
      desired_birthplace: pregnancy?.desired_birthplace,
      length_of_labor: pregnancy?.length_of_labor_minutes,
      living: pregnancy?.living,
      weight: pregnancy?.weight
        ? {
            pounds: pregnancy.weight / 453,
            ounces: (pregnancy.weight % 453) / 28,
            grams: pregnancy.weight
          }
        : { pounds: 0, ounces: 0, grams: 0 },
      sex: pregnancy?.sex,
      name: pregnancy?.name,
      birthplace: pregnancy?.birthplace,
      completed: pregnancy?.pregnancy_completion_time,
      comment: pregnancy?.comment,
      is_past_pregnancy: false,
      // @ts-ignore
      delivery_date: pregnancy?.delivery_date
        ? convertUtcIntToLocalDate(pregnancy.delivery_date).format("YYYY-MM-DD")
        : undefined
    });
  }, [pregnancy]);

  // Toast notifications for API calls
  // Successes
  useEffect(() => {
    if (isPregnancyUpdateSuccess && isPatientUpdateSuccess) {
      dispatch(
        addAlertToToastTrough({
          message: "Successfully concluded the pregnancy",
          type: STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        })
      );
    }
  }, [isPregnancyUpdateSuccess, isPatientUpdateSuccess]);

  // Failures
  useEffect(() => {
    if (isPregnancyUpdateError) {
      dispatch(
        addAlertToToastTrough({
          message: "Could not conclude pregnancy",
          type: STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        })
      );
    }

    if (isPatientUpdateError) {
      dispatch(
        addAlertToToastTrough({
          message: "Could not update patient to GYN",
          type: STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        })
      );
    }

    if (isCreateInfantError) {
      dispatch(
        addAlertToToastTrough({
          message: "Unable to create infant chart",
          type: STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        })
      );
    }
  }, [isPatientUpdateError, isPregnancyUpdateError, isCreateInfantError]);

  const onSubmit = async (data: DeliverPregnancyForm) => {
    const gest_days = data.gest_days ? parseInt(data.gest_days) : undefined;
    const gest_weeks = data.gest_weeks ? parseInt(data.gest_weeks) : undefined;
    const gest_age = (gest_weeks || 0) * 7 + (gest_days || 0);

    let infantID = pregnancy?.infant_id;
    if (data.create_chart) {
      if (infantID) {
        dispatch(
          addAlertToToastTrough({
            message: "Infant chart already exists for this pregnancy",
            type: STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
          })
        );
        return;
      }

      // Create the infant chart
      infantID = await createInfant({
        patientUpsertRequest: {
          first_name: data.name,
          last_name: motherInfo?.last_name,
          sex: data.sex || "UNKNOWN",
          parent_ids: [patientId],
          insurance: motherInfo?.insurance,
          dob: parseInt(
            dayjs(data.delivery_date, "YYYY-MM-DD")
              .utc()
              .format("YYYYMMDDHHmmss")
          ),
          address: motherInfo?.address,
          medical_history: {
            weight: data.weight ? data.weight.grams : undefined
          },
          practice_data: {
            type: "INFANT"
          }
        }
      })
        .unwrap()
        .then((p: PatientInfo) => p.user_id)
        .catch(() => {
          dispatch(
            addAlertToToastTrough({
              message: "Unable to create infant chart",
              type: STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
            })
          );
          return undefined;
        });

      if (!infantID) {
        return;
      }
    }

    // Update the pregnancy
    updatePregnancy({
      patientId: patientId,
      pregnancyUpsertRequest: {
        pregnancy_id: pregnancyId,
        outcome: (data.outcome as PregnancyOutcome) || "UNKNOWN",
        delivery_date: parseInt(
          dayjs(data.delivery_date, "YYYY-MM-DD").utc().format("YYYYMMDDHHmmss")
        ),
        living: data.living,
        sex: data.sex || "UNKNOWN",
        name: data.name,
        birthplace: data.birthplace,
        gestational_age_at_delivery: gest_age,
        comment: data.comment,
        state: "DELIVERED",
        weight: data.weight && data.weight.grams ? data.weight.grams : undefined,
        infant_id: infantID
      }
    })
      .unwrap()
      .then(() => {
        updatePatient({
          patientUpsertRequest: {
            user_id: patientId,
            practice_data: {
              type: "POSTPARTUM"
            }
          }
        }).catch(() => {
          dispatch(
            addAlertToToastTrough({
              message: "Unable to update patient to POSTPARTUM",
              type: STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
            })
          );
          return;
        });

        // Close the modal
        dispatch(setModalIsOpen(false));
      });
  };

  if (!pregnancyLoaded) {
    return <Skeleton />;
  } else {
    return (
      <div className={clsx(styles.ConcludePregnancy, font.className)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputRow}>
            <Input
              value={
                pregnancy?.delivery_date
                  ? convertUtcIntToLocalDate(pregnancy?.delivery_date).format(
                      "YYYY-MM-DD"
                    )
                  : undefined
              }
              type="date"
              name="delivery_date"
              id="delivery_date"
              label="Delivery/Termination Date*"
              register={register}
              rules={{ required: true }}
              error={form.formState.errors.delivery_date && "Please enter date"}
            />
            <Input
              type="text"
              name="birthplace"
              id="delivery_location"
              label="Delivery Location"
              register={register}
            />
          </div>
          <div className={styles.inputRow}>
            <Controller
              name="outcome"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ComboboxSelect
                  initialValue={defaults.outcome}
                  value={value}
                  label="Outcome"
                  name={"outcome"}
                  onChange={v => onChange(v)}
                  options={PREGNANCY_OUTCOMES}
                />
              )}
            />
          </div>
          <p className={styles.sectionHeader}>Gestational Age at Delivery</p>
          <div className={styles.inputRow}>
            <Input
              type="number"
              name="gest_weeks"
              id="gest_weeks"
              value={gest_weeks}
              label="Weeks"
              register={register}
            />
            <Input
              type="number"
              name="gest_days"
              value={gest_days}
              id="gest_days"
              label="Days"
              register={register}
            />
          </div>
          <div className={styles.inputRow}>
            {pregnancy && !pregnancy.infant_id && featureFlags?.infant_charting_enabled && (
              <Checkbox
                name="create_chart"
                id="create_chart"
                label="Create infant chart?"
                register={register}
              />
            )}
            <Checkbox
              name="living"
              id="living"
              label="Living"
              register={register}
            />
          </div>
          {chartWatch && (
            <>
              <p className={styles.sectionHeader}>Infant Demographics</p>
              <div className={styles.inputRow}>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  label="Name"
                  register={register}
                />
                <Controller
                  name="sex"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <ComboboxSelect
                      label="Sex"
                      name="sex"
                      onChange={v => onChange(v)}
                      labelAcc={item => METRIC_LABELS[item]}
                      options={["MALE", "FEMALE", "UNKNOWN"]}
                    />
                  )}
                />
              </div>
              <div className={styles.inputRow}>
                <Controller
                  name="weight"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <WeightInput
                      value={value as Weight}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </>
          )}
          <ControlledTextArea
            label="Complications and notes"
            name="comment"
            id="notes"
            form={form}
            rows={4}
          />
          <div className={styles.buttonContainer}>
            <Button type="submit">Submit</Button>
          </div>
        </form>
      </div>
    );
  }
}
