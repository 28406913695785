import { usePatientGetInfoQuery, UserId } from "@/store/services/patient";
import { ReactElement, useMemo } from "react";
import StandardGenerationForm from "./standardGenerationForm";
import InfantGenerationForm from "./infantGenerationForm";
import {
  RecordGenerationFormValues,
  useFormType
} from "../helpers/recordGenerationHooks";
import { FormType } from "../helpers/utilities";

interface RecordGenerationFormProps {
  patientId: UserId;
  form: any;
}

export default function RecordGenerationForm({
  patientId,
  form
}: RecordGenerationFormProps) {
  const formType = useFormType(patientId);

  const componentMap: Record<FormType, ReactElement> = {
    INFANT: <InfantGenerationForm patientId={patientId} form={form} />,
    STANDARD: <StandardGenerationForm patientId={patientId} form={form} />,
    LOADING: <div></div>
  };

  return componentMap[formType];
}
