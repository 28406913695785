// External
import { UseFormReturn } from "react-hook-form";
import { useMemo } from "react";

// Store
import { UserId } from "@/store/services/patient";

// Helpers
import {
  ListOption,
  RecordGenerationFormValues,
  useEncounterDataList,
  useFileDataList,
  useFormType,
  useImagingDataList,
  useIsContactError,
  useIsSendingFax,
  useLabDataList,
  usePatientDataList
} from "./helpers/recordGenerationHooks";
import { STATUS_KEYS } from "@/globals/constants";

// Components
import KeyValueGrid from "@/components/birthSummary/keyValueGrid";
import KeyValueItem from "@/components/birthSummary/keyValueItem";
import ContentRenderer from "@/components/textArea/contentRenderer";
import Alert from "@/components/alert";

// Styles
import styles from "./styles.module.scss";

interface RecordGenerationConfirmationProps {
  form: UseFormReturn<RecordGenerationFormValues>;
  patientId: UserId;
}

export default function RecordGenerationConfirmation({
  form,
  patientId
}: RecordGenerationConfirmationProps) {
  /**
   * A helper function that returns the labels for the options that HAVE been selected in the form
   * @param dataOptionList The return value from the hook for this particular option list
   * @param optionPrefix The option lists generally have a prefix for their inputs (i.e. additional_data_demographics or encounters_2)
   * @returns A string array of the labels for the options
   */
  const serializeDataOptions = (dataOptionList: ListOption[]) => {
    const dataSelected: string[] = [];
    dataOptionList.forEach(option => {
      const value = form.getValues(option.id);
      if (value) {
        dataSelected.push(option.label);
      }
    });

    return dataSelected;
  };

  // What kind of data list are we using
  const formType = useFormType(patientId);
  // Default to STANDARD is we're loading (unlikely)
  const dataList = formType != "LOADING" ? formType : "STANDARD";

  // Patient data section
  const patientDataOptions = usePatientDataList(dataList);
  const patientDataSelected: string[] = useMemo(() => {
    const serializedOptions = serializeDataOptions(patientDataOptions);
    if (serializedOptions.length === 0) {
      serializedOptions.push("No patient data selected for PDF generation");
    }
    return serializedOptions;
  }, [patientDataOptions, form]);

  // Encounters section
  const encounterDataOptions = useEncounterDataList(patientId);
  const encounterDataSelected: string[] = useMemo(() => {
    const serializedOptions = serializeDataOptions(encounterDataOptions);
    if (serializedOptions.length === 0) {
      serializedOptions.push("No encounters selected for PDF generation");
    }
    return serializedOptions;
  }, [encounterDataOptions]);

  // Labs section
  const labDataOptions = useLabDataList(patientId);
  const labDataSelected: string[] = useMemo(() => {
    const serializedOptions = serializeDataOptions(labDataOptions);
    if (serializedOptions.length === 0) {
      serializedOptions.push("No labs selected for PDF generation");
    }
    return serializedOptions;
  }, [labDataOptions]);

  // Images section
  const imageDataOptions = useImagingDataList(patientId);
  const imageDataSelected: string[] = useMemo(() => {
    const serializedOptions = serializeDataOptions(imageDataOptions);
    if (serializedOptions.length === 0) {
      serializedOptions.push("No images selected for PDF generation");
    }
    return serializedOptions;
  }, [imageDataOptions]);

  // Files section
  const fileDataOptions = useFileDataList(patientId);
  const fileDataSelected: string[] = useMemo(() => {
    const serializedOptions = serializeDataOptions(fileDataOptions);
    if (serializedOptions.length === 0) {
      serializedOptions.push("No files selected for PDF generation");
    }
    return serializedOptions;
  }, [fileDataOptions]);

  // Contact info - If the user filled either of these forms, we're assuming they're attempting to
  // send the fax and not just print it
  const showContactInfo = useIsSendingFax(form);

  const isContactError = useIsContactError(form);

  const dataOptionsError = useMemo(() => {
    const values = form.getValues();
    let err = true;
    // we just need one value that exactly equals true (implicitly meaning it's a checkbox)
    Object.values(values).forEach((value: any) => {
      if (typeof value === "boolean" && value === true) {
        err = false;
      }
    });
    return err;
  }, [form.watch()]);

  return (
    <>
      {dataOptionsError && (
        <Alert
          message="Please select at least one record to generate."
          type={STATUS_KEYS.WARNING}
        />
      )}
      <div className={styles.dataLists}>
        <div className={styles.list}>
          <p>Patient data</p>
          <ul>
            {patientDataSelected.map((option, index) => (
              <li key={index}>{option}</li>
            ))}
          </ul>
        </div>
        <div className={styles.list}>
          <p>Encounters</p>
          <ul>
            {encounterDataSelected.map((option, index) => (
              <li key={index}>{option}</li>
            ))}
          </ul>
        </div>
        <div className={styles.list}>
          <p>Labs</p>
          <ul>
            {labDataSelected.map((option, index) => (
              <li key={index}>{option}</li>
            ))}
          </ul>
        </div>
        <div className={styles.list}>
          <p>Images</p>
          <ul>
            {imageDataSelected.map((option, index) => (
              <li key={index}>{option}</li>
            ))}
          </ul>
        </div>
        <div className={styles.list}>
          <p>Files</p>
          <ul>
            {fileDataSelected.map((option, index) => (
              <li key={index}>{option}</li>
            ))}
          </ul>
        </div>
      </div>
      {showContactInfo && (
        <>
          <h4>Contact information</h4>
          {isContactError && (
            <Alert
              message="The recipient is invalid, this PDF won't be sent."
              type={STATUS_KEYS.WARNING}
            />
          )}
          <KeyValueGrid twoColumn>
            <KeyValueItem keyLabel="Recipient Name">
              <p>{form.getValues("recipient_name") || ""}</p>
            </KeyValueItem>
            <KeyValueItem keyLabel="Recipient Fax Number">
              <p>{form.getValues("recipient_fax")}</p>
            </KeyValueItem>
            <KeyValueItem keyLabel="Sender Name">
              <p>{form.getValues("sender_name")}</p>
            </KeyValueItem>
            <KeyValueItem keyLabel="Sender Number">
              <p>{form.getValues("sender_phone")}</p>
            </KeyValueItem>
          </KeyValueGrid>
          <KeyValueGrid>
            <KeyValueItem keyLabel="Note" longData textLeft>
              <ContentRenderer content={form.getValues("record_note") || ""} />
            </KeyValueItem>
          </KeyValueGrid>
        </>
      )}
    </>
  );
}
