// External
import { useState } from "react";

// Store
import { PatientInfo, UserEmergencyContact } from "@/store/services/patient";

// Components
import ContactForm from "./contactForm";
import ContactReadOnly from "./contactReadOnly";

// Styles
import styles from "../../../styles.module.scss";

interface ContactListItemProps {
  contact: UserEmergencyContact;
  contactIndex: number;
  patientInfo: PatientInfo;
}

export default function ContactListItem({
  contact,
  contactIndex,
  patientInfo
}: ContactListItemProps) {
  const [editing, setEditing] = useState<boolean>(false);

  return (
    <div className={styles.contactListItem}>
      {editing ? (
        <ContactForm
          patientInfo={patientInfo}
          contact={contact}
          onUpdate={() => setEditing(false)}
          contactIndex={contactIndex}
        />
      ) : (
        <ContactReadOnly
          patientInfo={patientInfo}
          contactIndex={contactIndex}
          contact={contact}
          onEdit={() => setEditing(true)}
        />
      )}
    </div>
  );
}
