// External
import { Controller } from "react-hook-form";
import { ForwardedRef, Key, forwardRef, useImperativeHandle } from "react";
import dayjs from "dayjs";

// Store and constants
import { NEWBORN_FORM } from "../constants";
import { METRICS } from "@/globals/constants";
import {
  useEncounterInfoQuery,
  useEncounterUpdateMutation,
  EncounterType
} from "@/store/services/encounter";

// Components
import GeneratedInput from "@/components/input/generated";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";
import { NewbornFormPartsProps } from "./newborn";
import WeightInput from "@/components/input/weightInput";

interface NewbornDemographicsProps extends NewbornFormPartsProps {
  encounterId: number;
  newbornId: number;
}

const NewbornGrowth = forwardRef(function NewbornGrowth(
  { disabled = false, encounterId, newbornId, form }: NewbornDemographicsProps,
  ref: ForwardedRef<any>
) {
  const {
    control,
    register,
    formState: { errors }
  } = form;

  const { data: encounterInfo } = useEncounterInfoQuery(
    { encounterId },
    { skip: !encounterId }
  );

  const [updateEncounter] = useEncounterUpdateMutation();

  useImperativeHandle(ref, () => {
    return {
      examPartSubmit: (data: any) => {
        if (!encounterInfo) {
          return;
        }
        const newEntry = {
          [METRICS.DATE_OF_ENTRY]: dayjs(
            `${data.start_time}`,
            "YYYYMMDDHHmmss"
          ).format("YYYY-MM-DD"), // backend expects "YYYY-MM-DD" date format
          [METRICS.WEIGHT_GRAMS]: data.birth_weight.grams || 0,
          [METRICS.STANDING_HEIGHT_CM]: parseInt(data.standing_height || 0),
          [METRICS.HEAD_CIRCUMFERENCE_CM]: parseInt(
            data.head_circumference || 0
          ),
          [METRICS.RECUMBENT_LENGTH_CM]: parseInt(data.recumbent_length || 0)
        };
        updateEncounter({
          encounterId: encounterId,
          encounterCreateUpdatePayload: {
            patient_id: newbornId,
            provider_id: encounterInfo.provider.user_id,
            infant_growth_measurements: newEntry,
            encounter_id: encounterId,
            encounter_type: encounterInfo.encounter_type as EncounterType
          }
        });
      }
    };
  });

  return (
    <>
      {NEWBORN_FORM.infantGrowth.map(
        ({
          id,
          label,
          type = "text"
        }: {
          id: string;
          label: string;
          type?: string;
        }) => {
          if (type == "dateTime") {
            return (
              <Controller
                name="start_time"
                control={control}
                key={id as Key}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <DateTimeStamp
                      name={name}
                      onChange={onChange}
                      initialValue={value}
                      value={value}
                    />
                  );
                }}
              />
            );
          } else if (id === "birth_weight") {
            return (
              <Controller
                name="birth_weight"
                control={control}
                key={id as Key}
                render={({ field: { onChange, value } }) => (
                  <WeightInput
                    value={value}
                    onChange={onChange}
                    horizontalLayout={true}
                    showGramsConversion={true}
                  />
                )}
              />
            );
          } else {
            return (
              <GeneratedInput
                key={id as Key}
                name={id}
                id={id}
                label={label}
                type={type}
                register={register}
                errors={errors}
                placeholder={label}
                disabled={disabled}
              />
            );
          }
        }
      )}
    </>
  );
});

NewbornGrowth.displayName = "NewbornGrowth";

export default NewbornGrowth;
