/* Component Name */
/* External Imports */
import Select, { MultiValue } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

/* Local Imports */

// components
import Button from "@/components/button";
import Input from "@/components/input";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import {
  CreateTaskBundleApiArg,
  UpdateTaskBundleApiArg,
  TaskBundle,
  TaskBundleItem,
  TaskType,
  useCreateTaskBundleMutation,
  usePracticeGetFormsQuery,
  useUpdateTaskBundleMutation,
  useDeleteTaskBundleMutation
} from "@/store/services/practice";
import { useFileListQuery } from "@/store/services/file";
import { RootState } from "@/store/store";
import { setModalIsOpen } from "../modalSlice";

// styles
import styles from "../styles.module.scss";
import { SelectDefaultTheme } from "@/styles/themes/selectDefaultTheme";
import Delete from "../../../../public/svgs/delete_dynamic.svg";

/* Component Typescript Interface */
export interface TaskBundleUpsertProps {
  bundle?: TaskBundle;
}

export default function TaskBundleUpsert({ bundle }: TaskBundleUpsertProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  // get a list of assignable tasks
  const { data: formFiles } = useFileListQuery(
    {
      scope: "practice",
      id: sessionInfo?.practice_id as number,
      tagName: "form",
      pagesz: 1000
    },
    {
      skip: sessionInfo?.practice_id === undefined
    }
  );

  const { data: forms } = usePracticeGetFormsQuery(
    {
      practiceId: sessionInfo?.practice_id as number
    },
    {
      skip: sessionInfo?.practice_id === undefined
    }
  );

  const items: TaskBundleItem[] = useMemo(() => {
    return (forms || [])
      .map(form => ({
        task_type: "FORM" as TaskType,
        resource_id: form.form_id,
        display: form.title
      }))
      .concat(
        (formFiles || []).map(file => ({
          task_type: "FILE" as TaskType,
          resource_id: file.file_id,
          display: file.name
        }))
      )
      .sort((a, b) => a.display.localeCompare(b.display));
  }, [forms, formFiles]);

  type SelectableType = {
    label: string;
    value: TaskBundleItem;
  };
  const selectableItems: SelectableType[] = useMemo(() => {
    return items.map((item, index) => ({
      label: item.display,
      value: item
    }));
  }, [items]);

  const { register, handleSubmit, control } = useForm<TaskBundle>({
    defaultValues: bundle
  });

  const [createTaskBundle] = useCreateTaskBundleMutation();
  const [updateTaskBundle] = useUpdateTaskBundleMutation();
  const [deleteTaskBundle] = useDeleteTaskBundleMutation();

  const handleCreate = (data: TaskBundle) => {
    const request: CreateTaskBundleApiArg = { taskBundle: { ...data } };
    createTaskBundle(request)
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Task Bundle Created",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Task Bundle Creation Failed",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleUpdate = (data: TaskBundle) => {
    const request: UpdateTaskBundleApiArg = {
      taskBundleId: data.task_bundle_id,
      taskBundle: { ...data }
    };

    updateTaskBundle(request)
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Task Bundle Updated",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Task Bundle Update Failed",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const dispatch = useDispatch();
  const onSubmit = (data: TaskBundle) => {
    if (bundle) {
      handleUpdate(data);
    } else {
      handleCreate(data);
    }
  };

  const onDelete = (id: string) => {
    const request = { taskBundleId: id };
    deleteTaskBundle(request)
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Deleted Task Bundle",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to delete task bundle",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.TaskBundleUpsertModal}
    >
      <div className={styles.fieldWrapper}>
        <Input
          label="Name"
          id="name"
          name="name"
          required={true}
          type="text"
          register={register}
        />
      </div>

      <div className={styles.fieldWrapper}>
        <Input
          label="Description"
          id="description"
          name="description"
          type="text"
          register={register}
        />
      </div>

      <div className={styles.fieldWrapper}>
        <label htmlFor="items">Items</label>
        <Controller
          name="items"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              theme={SelectDefaultTheme}
              aria-label="Encounter Type"
              options={selectableItems}
              isMulti={true}
              value={value?.map((v: TaskBundleItem) => ({
                label: v.display,
                value: v
              }))}
              onChange={(selectedOption: MultiValue<SelectableType>) => {
                onChange(selectedOption.map(o => o.value));
              }}
            />
          )}
        />
      </div>

      <div className={styles.buttons}>
        {
          // Allow deletion on update
          bundle && (
            <Button
              type="button"
              style={STYLES.DELETE}
              onClick={() => onDelete(bundle.task_bundle_id)}
            >
              <Delete stroke={styles.gray400} width={12} height={12} />
              Delete
            </Button>
          )
        }
        <Button type="submit">Save</Button>
      </div>
    </form>
  );
}
