/* External Imports */
import clsx from "clsx";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useMemo } from "react";
import React from "react";

/* Local Imports */

// components
import Button from "@/components/button";
import Alert from "@/components/alert";

// constants and helpers
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { getBlobFromBase64String } from "@/globals/helpers/fileHelpers";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { MODAL_TYPES } from "@/components/modal/dispatcher";

// store
import { useFaxSendMutation } from "@/store/services/fax";
import { useFileUploadMutation } from "@/store/services/file";
import { ErrorInfo, ExtraErrorItem, UserId } from "@/store/services/patient";
import { RecordGenerationFormValues } from "./helpers/recordGenerationHooks";

// styles
import styles from "./styles.module.scss";

/* Preview Transfer Typescript Interface */
interface PreviewTransferProps {
  pdfBase64: string;
  isSendingFax: boolean;
  patientId: UserId;
  data: RecordGenerationFormValues;
  errors: ExtraErrorItem[];
}

export default function PreviewPDF({
  pdfBase64,
  isSendingFax,
  patientId,
  data,
  errors
}: PreviewTransferProps) {
  const [sendFax, sendFaxResponse] = useFaxSendMutation();
  const [uploadFile, uploadFileResponse] = useFileUploadMutation();

  const dispatch = useDispatch();

  const url = useMemo(() => {
    if (!pdfBase64) return "";
    try {
      return URL.createObjectURL(getBlobFromBase64String(pdfBase64));
    } catch (err) {
      console.error(err);
      return "";
    }
  }, [pdfBase64]);

  const onSend = () => {
    const name =
      "Record Transfer " + dayjs().format("YYYY-MM-DD HH.mm.ss") + ".pdf";
    const content = getBlobFromBase64String(pdfBase64);
    const file = new File([content], name);
    const formData = new FormData();
    formData.append(file.name, file);
    uploadFile({
      patientId: patientId,
      body: formData
    })
      .unwrap()
      .then(created => {
        dispatch(
          addAlertToToastTrough({
            message: "PDF saved to patient files",
            type: STATUS_KEYS.SUCCESS
          })
        );

        const file_id = Object.values(created)[0];

        if (!data.recipient_fax) {
          throw new Error("There's an issue with the recipient's fax number");
        }

        sendFax({
          faxSendRequest: {
            recipients: [data.recipient_fax],
            files: [file_id]
          }
        })
          .then(() => {
            dispatch(
              addAlertToToastTrough({
                message: "Fax sent",
                type: STATUS_KEYS.SUCCESS
              })
            );

            dispatch(setModalIsOpen(false));
          })
          .catch((err: ErrorInfo) => {
            console.error(err);
            dispatch(
              addAlertToToastTrough({
                message: "Failed to send fax",
                type: STATUS_KEYS.ERROR
              })
            );
          });
      })
      .catch((err: ErrorInfo) => {
        console.error(err);
        dispatch(
          addAlertToToastTrough({
            message: "Failed to save PDF to patient files",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const onPrint = () => {
    // Window will never be null but the open function will return null
    // if the window fails to open like in the case of a popup blocker
    const newWindow = window?.open(url, "_blank");
    if (newWindow === null) {
      // Don't close the modal if the pop up gets blocked
      return;
    }
    newWindow.focus();
    dispatch(setModalIsOpen(false));
  };

  const onBack = () => {
    dispatch(
      setModalContent({
        type: MODAL_TYPES.SHARE_RECORDS,
        props: { patientId, title: "Share records", data }
      })
    );
  };

  return (
    <div className={clsx(styles.ViewFile)}>
      {errors &&
        errors.map(error => (
          <React.Fragment key={error.field}>
            <Alert message={error.message} type={STATUS_KEYS.WARNING} />
            <br />
          </React.Fragment>
        ))}
      <embed
        src={url}
        type="application/pdf"
        width="100%"
        height="100%"
        style={{ minHeight: "70vh" }}
      />
      <div className={styles.buttonContainer}>
        {isSendingFax ? (
          <>
            <Button
              type="button"
              style={STYLES.SECONDARY}
              onClick={() => onBack()}
            >
              Back
            </Button>
            <Button
              type="button"
              style={STYLES.PRIMARY}
              onClick={() => onSend()}
              nativeButtonProps={{
                disabled: sendFaxResponse.isLoading || uploadFileResponse.isLoading
              }}
              loading={sendFaxResponse.isLoading || uploadFileResponse.isLoading}
            >
              Send
            </Button>
          </>
        ) : (
          <>
            <Button
              type="button"
              style={STYLES.SECONDARY}
              onClick={() => onBack()}
            >
              Back
            </Button>
            <Button
              type="button"
              style={STYLES.PRIMARY}
              onClick={() => onPrint()}
            >
              Print
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
