/* Annotation Name */
/* External Imports */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { sanitize } from "dompurify";
import dayjs from "dayjs";

/* Local Imports */

// components
import Button from "@/components/button";
import Icon from "@/components/icons";
import AvatarPlaceholder from "@/components/avatarPlaceholder";
import AddAnnotation from "@/components/annotations/addAnnotation";
import ContentRenderer from "@/components/textArea/contentRenderer";

// constants
import { STYLES } from "@/globals/constants";
import { FORMAT } from "@/globals/helpers/formatters";

// store
import { AnnotationId } from "@/store/services/file";
import { useUserGetInfoQuery, UserId } from "@/store/services/user";
import { LabOrderAnnotationId } from "@/store/services/lab";
import { RootState } from "@/store/store";

// styles
import styles from "./styles.module.scss";

/* Annotation Typescript Interface */
export interface AnnotationProps {
  author: UserId;
  annotationId: AnnotationId | LabOrderAnnotationId;
  created: string; // RFC3339 timestamp
  updated?: string; // RFC3339 timestamp
  annotation: string; // content of annotation
  isEditing?: boolean;
  isSubmitting?: boolean;
  onDelete: (id: AnnotationId | LabOrderAnnotationId) => void;
  onAnnotationUpdate: (
    annotationId: AnnotationId | LabOrderAnnotationId,
    annotation: string
  ) => void;
  onAnnotationCreate: (annotation: string) => void;
}

export default function Annotation({
  author,
  annotationId,
  created,
  updated,
  annotation,
  isEditing,
  isSubmitting,
  onDelete,
  onAnnotationUpdate,
  onAnnotationCreate
}: AnnotationProps) {
  /* Redux */
  const { data: userInfo } = useUserGetInfoQuery(
    { userId: author },
    { skip: !author }
  );
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  /* Local State */
  // annotation open
  const [annotationOpen, setAnnotationOpen] = useState(isEditing);

  /* Effects */

  /* Event Handlers */
  // handle edit annotation
  const handleEditAnnotation = () => {
    setAnnotationOpen(true);
  };

  const handleDeleteAnnotion = () => {
    // delete the annotation
    onDelete(annotationId);
  };

  useEffect(() => {
    setAnnotationOpen(isEditing);
  }, [isEditing]);

  return (
    <div
      key={annotationId}
      className={clsx(styles.Annotation)}
      data-cy="annotation"
    >
      <div className={styles.metaWrapper} data-cy="annotation-meta">
        <AvatarPlaceholder
          character={
            userInfo ? userInfo?.first_name?.[0] + userInfo?.last_name?.[0] : ""
          }
          userId={userInfo?.user_id}
        />
        <div>
          <p className="t4 dark" data-cy="annotation-author">
            {userInfo ? FORMAT.name(userInfo) : "-"}
          </p>
          <p className="xLight t4" data-cy="annotation-date">
            {dayjs(updated || created).format("hh:mm A")} {""}
            {dayjs(updated || created).format("MM/DD/YYYY")}
          </p>
        </div>
        {sessionInfo?.user_id === author && (
          <div className={styles.buttons} data-cy="annotation-buttons">
            <Button
              style={STYLES.SECONDARY}
              onClick={handleEditAnnotation}
              dataCy="edit-annotation-btn"
            >
              <Icon svg="edit" />
            </Button>
            <Button
              style={STYLES.SECONDARY}
              onClick={handleDeleteAnnotion}
              dataCy="delete-annotation-btn"
            >
              <Icon svg="delete" />
            </Button>
          </div>
        )}
      </div>

      {annotationOpen ? (
        <AddAnnotation
          content={annotation}
          onCancel={() => setAnnotationOpen(false)}
          onUpdate={onAnnotationUpdate}
          onCreate={onAnnotationCreate}
          annotationId={annotationId}
          isEditing
          isSubmitting={isSubmitting}
        />
      ) : (
        <div className={styles.content} data-cy="annotation-content">
          <ContentRenderer content={sanitize(annotation)} />
        </div>
      )}
    </div>
  );
}
