/* Copy Fax to Paitent Modal Template */

// External
import { useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

// components
import Button from "@/components/button";
import Skeleton from "react-loading-skeleton";
import FaxContact from "@/components/input/faxContact";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import { setModalIsOpen } from "../modalSlice";
import { FaxId, useForwardInboundFaxMutation } from "@/store/services/fax";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// styles
import styles from "../styles.module.scss";

export interface ForwardFaxesProps {
  selectedFaxIds: Array<FaxId>;
}

export default function ForwardFaxes({ selectedFaxIds }: ForwardFaxesProps) {
  const dispatch = useDispatch();

  const [faxNumber, setFaxNumber] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [forwardFaxes, { isLoading }] = useForwardInboundFaxMutation();

  // Event handlers
  const handleCancel = () => {
    // close modal
    dispatch(setModalIsOpen(false));
  };

  const handleConfirm = () => {
    if (!selectedFaxIds) {
      return;
    }

    if (!faxNumber) {
      setErrorMessage("Fax number is required");
      return;
    }

    forwardFaxes({
      faxId: selectedFaxIds[0],
      faxForwardRequest: {
        faxes: selectedFaxIds,
        recipients: [faxNumber]
      }
    })
      .unwrap()
      .then(data => {
        dispatch(
          addAlertToToastTrough({
            message: "Fax successfully forwarded",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })
      .catch(err => {
        setErrorMessage("Something went wrong forwarding the faxes");
      });
  };

  return (
    <div className={clsx(styles.FaxFiles)}>
      {isLoading ? (
        <div className={clsx([styles.FaxFiles, styles.loading])}>
          Forwarding faxes...
          <Skeleton count={10} />
        </div>
      ) : (
        <>
          <div className={styles.message}>
            <FaxContact onChange={v => setFaxNumber(v?.fax)} />
          </div>
          <div className={styles.buttons}>
            <Button style={STYLES.SECONDARY} onClick={handleCancel}>
              Cancel
            </Button>
            <Button style={STYLES.PRIMARY} onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
