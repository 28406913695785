/* Combobox Select Component */

/* External */
import { useState, useEffect, useRef, useMemo } from "react";
import clsx from "clsx";

// downshift dropdown state management library
import { useCombobox } from "downshift";
import { ErrorMessage } from "@hookform/error-message";
/* Local */

// components
import Icon from "../icons";

// constants

// styles
import styles from "./styles.module.scss";

interface ComboboxProps {
  options: any[];
  label: string; // required for accessibility
  name?: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (item: any) => void;
  onSearch?: (item: any) => void;
  hiddenLabel?: boolean;
  labelAcc?: (item: any) => string;
  initialValue?: any; // default value if form field pre-populated
  error?: string;
  noFilter?: boolean;
  selectOnInput?: boolean;
  fullWidth?: boolean;
  noMenu?: boolean;
  isHorizontalLayout?: boolean;
  clearOnSelect?: boolean;
  isDisabled?: boolean;
  onEnter?: (d: any) => void;
  value?: any;
  valueKey?: any;
  setNullValues?: boolean;
  hasClearButton?: boolean;
  debounce?: boolean;
}

export default function ComboboxSelect({
  placeholder = "Select",
  label,
  name,
  options = [],
  onChange,
  onSearch,
  hiddenLabel = false,
  labelAcc = item => item,
  initialValue = null,
  error,
  noFilter = false,
  selectOnInput = false,
  fullWidth = false,
  noMenu = false,
  isHorizontalLayout = false,
  clearOnSelect,
  isDisabled = false,
  onEnter,
  value,
  valueKey, // use this if the value is complex e.g. an array or object to determine when the value should be reset and prevent infinite rerenders
  setNullValues = false, // if true, the value will be set to null when a null value is passed
  hasClearButton = false, // if true, a clear button will be displayed in input
  debounce = false // if true, the search will be debounced
}: ComboboxProps) {
  const [selectedItem, setSelectedItem] = useState<any | null>(initialValue);
  const [doNotFilter, setDoNotFilter] = useState(noFilter);
  const [items, setItems] = useState(options);
  const [debounceInputValue, setDebounceInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setItems(options);
  }, [options]);

  useEffect(() => {
    if (selectedItem && onChange) onChange(selectedItem);
  }, [selectedItem, onChange]);

  useEffect(() => {
    // if the value is complex, do not reset when react detects a change
    if (valueKey) return;
    // if the value is a string, empty string or if nulls are supported reset the value
    if (value || value === "" || (setNullValues && value === null)) {
      setSelectedItem(value);
      onChange && onChange(value);
    }
  }, [value]);

  useEffect(() => {
    // if the value is complex, only reset when the value key explicitly changes
    if (value || value === "") {
      setSelectedItem(value);
      onChange && onChange(value);
    }
  }, [valueKey]);

  useEffect(() => {
    if (!debounce || !onSearch) return;
    const debouncedSearch = setTimeout(() => {
      onSearch(debounceInputValue);
    }, 500);

    return () => clearTimeout(debouncedSearch);
  }, [debounceInputValue]);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    reset
  } = useCombobox({
    items,
    selectedItem,
    itemToString(item) {
      return item ? labelAcc(item) : "";
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (onChange) {
        onChange(selectedItem);
      }
      if (clearOnSelect) {
        reset();
        // @ts-ignore
        if (inputRef.current) inputRef?.current?.blur();
      }
    },
    onStateChange: changes => {
      if (changes.type === "__input_keydown_enter__") {
        if (inputRef && inputRef.current && inputRef.current.value && onEnter) {
          onEnter(inputRef.current.value);
        }
      }
    },
    onInputValueChange: ({ inputValue = "" }) => {
      if (onSearch) {
        if (debounce) {
          setDebounceInputValue(inputValue);
        } else {
          onSearch(inputValue);
        }
      }

      const filteredOptions = doNotFilter
        ? options
        : options.filter(item => {
            const inputValueLowerCase = inputValue?.toLowerCase().trim();
            const itemLowerCase = labelAcc && labelAcc(item).toLowerCase();

            if (inputValueLowerCase === "") {
              return true; // Show all options when input value is empty
            }

            return (
              itemLowerCase.includes(inputValueLowerCase) ||
              itemLowerCase.includes(inputValueLowerCase.replace(/\s/g, ""))
            );
          });
      setDoNotFilter(false);
      setItems(filteredOptions);
      if (selectOnInput && !(filteredOptions.length > 0)) {
        if (onChange) {
          onChange(null);
        }
      }

      if (inputValue === "") {
        setSelectedItem(null);
        onChange && onChange(null);
      }
    }
  });

  const handleInputClick = () => {
    setDoNotFilter(true);
    if (inputRef && inputRef.current) {
      inputRef.current.select();
    }
  };

  return (
    <div
      className={clsx(styles.Input, styles.Select, {
        [styles.fullWidth]: fullWidth,
        [styles.horizontal]: isHorizontalLayout
      })}
      data-cy="input-combobox"
      data-cy-name={name}
    >
      <label
        {...getLabelProps()}
        className={clsx({ [styles.hidden]: hiddenLabel })}
      >
        {label}
      </label>
      <div className={styles.inputWrapper}>
        <input
          {...getInputProps({
            // @ts-ignore
            ref: inputRef && inputRef,
            placeholder,
            onClick: handleInputClick,
            disabled: isDisabled
          })}
          data-cy={`${name}-input`}
          name={name}
          className={clsx({
            [styles.hasError]: error
          })}
        />
        {hasClearButton && (
          <button
            type="button"
            className={styles.clear}
            onClick={() => {
              reset();
            }}
            aria-label="Clear input field"
          >
            <Icon svg="close_grey" width={7} />
          </button>
        )}

        {!noMenu && (
          <button
            type="button"
            className={styles.close}
            {...getToggleButtonProps()}
          >
            <Icon svg="caret_down" width={10} />
          </button>
        )}
        <ul
          {...getMenuProps()}
          className={clsx({
            [styles.open]: items.length > 0 && isOpen && !noMenu
          })}
        >
          {isOpen &&
            !noMenu &&
            items.map((item, index) => (
              <li
                style={{
                  backgroundColor: highlightedIndex === index ? "#F9FAFB" : null
                }}
                key={`${item}${index}`}
                {...getItemProps({
                  item,
                  index
                })}
                data-cy="combobox-option"
              >
                {item ? labelAcc(item) : ""}
              </li>
            ))}
        </ul>
      </div>
      {error && <div className={styles.validationError}>{error}</div>}
    </div>
  );
}
