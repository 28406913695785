/* Appointment Type Upsert Modal */
/* External Imports */
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Select, { SingleValue } from "react-select";
import { useMemo } from "react";

/* Local Imports */
// components
import Input from "@/components/input";
import Button from "@/components/button";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// Globals, helpers, constants
import { setModalIsOpen } from "../modalSlice";
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import {
  TaskBundle,
  AppointmentTypeDetails,
  PracticeCreateAppointmentTypeApiArg,
  PracticeUpdateAppointmentTypeApiArg,
  PracticeDeleteAppointmentTypeApiArg,
  usePracticeCreateAppointmentTypeMutation,
  usePracticeUpdateAppointmentTypeMutation,
  usePracticeDeleteAppointmentTypeMutation,
  useListTaskBundlesQuery
} from "@/store/services/practice";
import { EncounterType } from "@/store/services/encounter";
import { task } from "@/store/services/task";

// styles
import { SelectDefaultTheme } from "@/styles/themes/selectDefaultTheme";
import Delete from "../../../../public/svgs/delete_dynamic.svg";
import styles from "../styles.module.scss";

export interface AppointmentTypeUpsertProps {
  appt?: AppointmentTypeDetails;
}

export default function AppointmentTypeUpsert({
  appt
}: AppointmentTypeUpsertProps) {
  const [updateAppointmentType] = usePracticeUpdateAppointmentTypeMutation();
  const [createAppointmentType] = usePracticeCreateAppointmentTypeMutation();
  const [deleteAppointmentType] = usePracticeDeleteAppointmentTypeMutation();

  const onUpdate = (updated: AppointmentTypeDetails) => {
    const request: PracticeUpdateAppointmentTypeApiArg = {
      appointmentTypeId: updated.appointment_type_id,
      appointmentTypeUpdateRequest: {
        ...updated,
        task_bundle_id: updated.task_bundle?.task_bundle_id,
        code_bundle_id: updated.code_bundle?.code_bundle_id
      }
    };
    return updateAppointmentType(request);
  };

  const onCreate = (created: AppointmentTypeDetails) => {
    const request: PracticeCreateAppointmentTypeApiArg = {
      appointmentTypeCreateRequest: {
        ...created,
        task_bundle_id: created.task_bundle?.task_bundle_id,
        code_bundle_id: created.code_bundle?.code_bundle_id
      }
    };
    return createAppointmentType(request);
  };

  const onDelete = (id: string) => {
    const request: PracticeDeleteAppointmentTypeApiArg = {
      appointmentTypeId: id
    };
    deleteAppointmentType(request)
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Deleted Appointment Type",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to delete appointment type",
            type: STATUS_KEYS.ERROR
          })
        );
      });
    dispatch(setModalIsOpen(false));
  };

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<AppointmentTypeDetails> = (
    data: AppointmentTypeDetails
  ) => {
    // For some reason the default_duration is being passed as a string
    data.default_duration = Number(data.default_duration);
    const func = appt ? onUpdate : onCreate;
    func(data)
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: appt
              ? "Appointment Type Updated"
              : "Appointment Type Created",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: appt
              ? "Appointment Type Update Failed"
              : "Appointment Type Creation Failed",
            type: STATUS_KEYS.ERROR
          })
        );
      });
    dispatch(setModalIsOpen(false));
  };

  // Options for form selects
  type selectableType = {
    label: string;
    value: string;
  };

  const encounterTypeOptions: selectableType[] = (
    [
      "WELL_WOMAN",
      "PRENATAL",
      "LABOR",
      "POSTPARTUM",
      "INFANT"
    ] as EncounterType[]
  ).map(e => {
    return { label: e, value: e };
  });

  const { register, handleSubmit, control } = useForm<AppointmentTypeDetails>({
    defaultValues: appt
  });

  const { data: taskBundles } = useListTaskBundlesQuery();
  const taskBundleOptions = useMemo(() => {
    return taskBundles?.map(taskBundle => {
      return {
        label: taskBundle.name,
        value: taskBundle.task_bundle_id
      };
    });
  }, [taskBundles]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.AppointmentTypeUpsertModal}
    >
      <div className={styles.fieldWrapper}>
        <Input
          label="Appointment Type"
          id="appointment_type"
          name="appointment_type"
          required={true}
          type="text"
          register={register}
        />
      </div>

      <div className={styles.fieldWrapper}>
        <label htmlFor="encounter_type">Encounter Type*</label>
        <Controller
          name="encounter_type"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Select
              theme={SelectDefaultTheme}
              aria-label="Encounter Type"
              options={encounterTypeOptions}
              value={encounterTypeOptions.find(o => o.value === value)}
              onChange={(selectedOption: SingleValue<selectableType>) => {
                if (selectedOption) {
                  onChange(selectedOption.value);
                }
              }}
            />
          )}
        />
      </div>

      <div className={styles.fieldWrapper}>
        <Input
          label="Default Duration"
          id="default_duration"
          name="default_duration"
          required={true}
          type="number"
          register={register}
        />
      </div>

      <div className={styles.fieldWrapper}>
        <label htmlFor="task_bundle">Task Bundle</label>
        <Controller
          name="task_bundle"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              theme={SelectDefaultTheme}
              aria-label="Task Bundle"
              options={taskBundleOptions}
              isClearable={true}
              value={taskBundleOptions?.find(
                o => o.value === value?.task_bundle_id
              )}
              onChange={(selectedOption: SingleValue<selectableType>) => {
                onChange({
                  task_bundle_id: selectedOption?.value || null
                } as TaskBundle);
              }}
            />
          )}
        />
      </div>

      <div className={styles.buttons}>
        {
          // Allow deletion if updating
          appt && (
            <Button
              type="button"
              style={STYLES.DELETE}
              onClick={() => {
                onDelete(appt.appointment_type_id);
              }}
            >
              <Delete stroke={styles.errorText} width={15} height={17} />
              Delete Appointment Type
            </Button>
          )
        }
        <Button type="submit" style={STYLES.FULL_WIDTH}>
          {appt ? "Update Appointment Type" : "Create Appointment Type"}
        </Button>
      </div>
    </form>
  );
}
