import * as generated from "./generated/scheduling";

const scheduling = generated.scheduling.enhanceEndpoints({
  endpoints: {
    appointmentUpsert: {
      invalidatesTags: ["Appointments", "Tasks", "Encounter"]
    },
    appointmentStart: {
      invalidatesTags: ["Appointments"]
    },
    appointmentsGetBriefList: {
      providesTags: ["Appointments"]
    },
    appointmentsGetDetailedList: {
      providesTags: ["Appointments"]
    }
  }
});

export * from "./generated/scheduling";
export default scheduling;
export const {
  useAppointmentsGetBriefListQuery,
  useAppointmentsGetDetailedListQuery,
  useAppointmentUpsertMutation,
  useAppointmentStartMutation,
  useAppointmentInfoQuery,
  useLazyAppointmentInfoQuery
} = scheduling;
