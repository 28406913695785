// third-party
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import AsyncCreatableSelect from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import posthog from "posthog-js";

// components
import Button from "@/components/button";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import Input from "@/components/input";
import LoadingIcon from "../../../../public/svgs/loading-icon.svg";

// store
import {
  useLazyMedicationSearchQuery,
  useMedicationDosageSearchQuery,
  useMedicationCreateForPatientMutation,
  useMedicationUpdateForPatientMutation,
  useMedicationDeleteFromPatientMutation,
  MedicationCreate,
  UserId
} from "@/store/services/medication";
import {
  Medication,
  MedicationStatus,
  MedicationUpdate
} from "@/store/services/patient";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";

// constants and helpers
import { STATUS_KEYS, STYLES, METRICS } from "@/globals/constants";
import { parseParioDate } from "@/utils/api/time";
import { MODAL_TYPES } from "../dispatcher";
import { FORMAT } from "@/globals/helpers/formatters";

// styles
import styles from "../styles.module.scss";
import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";

interface MedicationFormProps {
  userId: UserId;
  medication?: Medication;
  uuid: string;
}

type MedicationOption = {
  label: string;
  value: string;
};

type StatusOption = {
  label: string;
  value: MedicationStatus;
};

type MedicationFormValues = {
  name: MedicationOption;
  dosage: MedicationOption;
  start: string;
  end: string;
  status: StatusOption;
  comments: string;
};

function MedicationForm({ userId, medication, uuid }: MedicationFormProps) {
  const dispatch = useDispatch();
  const defaultValues: MedicationFormValues = useMemo(
    () => ({
      name: { label: medication?.name || "", value: medication?.name || "" },
      dosage: {
        label: medication?.dosage || "",
        value: medication?.dosage || ""
      },
      status: {
        label: FORMAT.capitalize(medication?.status || "ACTIVE"),
        value: medication?.status || "ACTIVE"
      },
      start: medication?.start
        ? dayjs(`${medication?.start}`, "YYYYMMDD").format("YYYY-MM-DD")
        : "",
      end: medication?.end
        ? dayjs(`${medication?.end}`, "YYYYMMDD").format("YYYY-MM-DD")
        : "",
      comments: medication?.comment || ""
    }),
    [medication]
  );

  const form = useForm<MedicationFormValues>({ defaultValues });
  const {
    formState: { errors },
    reset,
    register,
    control,
    handleSubmit,
    watch
  } = form;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const medicationWatch = watch("name");

  const [searchMeds] = useLazyMedicationSearchQuery();
  const { data: availableDosages } = useMedicationDosageSearchQuery(
    {
      name: medicationWatch.value
    },
    { skip: !medicationWatch.value || medicationWatch.value?.length < 3 }
  );
  const [addUserMedication] = useMedicationCreateForPatientMutation();
  const [updateUserMedication] = useMedicationUpdateForPatientMutation();
  const [removeMedication] = useMedicationDeleteFromPatientMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const statusOptions: StatusOption[] = [
    {
      label: FORMAT.capitalize(METRICS.ACTIVE_STATUS),
      value: METRICS.ACTIVE_STATUS as MedicationStatus
    },
    {
      label: FORMAT.capitalize(METRICS.INACTIVE),
      value: METRICS.INACTIVE as MedicationStatus
    },
    {
      label: FORMAT.capitalize(METRICS.NONCOMPLIANT),
      value: METRICS.NONCOMPLIANT as MedicationStatus
    }
  ];

  const dosageOptions: MedicationOption[] = useMemo(() => {
    return (availableDosages || [])?.map(
      dosage =>
        ({
          label: dosage,
          value: dosage
        }) as MedicationOption
    );
  }, [availableDosages]);

  const handleRemove = async () => {
    try {
      await removeMedication({ userId, uuid }).unwrap();
      dispatch(
        addAlertToToastTrough({
          message: "Medication removed",
          type: STATUS_KEYS.SUCCESS
        })
      );
      dispatch(
        setModalContent({
          type: MODAL_TYPES.ADD_MEDICATION,
          props: {
            userId: userId,
            medication: null,
            uuid: null,
            title: "Add Medication"
          }
        })
      );
      setIsLoading(false);
      dispatch(setModalIsOpen(false));
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      dispatch(
        addAlertToToastTrough({
          message: "Failed to remove medication",
          type: STATUS_KEYS.ERROR
        })
      );
    }
  };

  const onSubmit = async (data: MedicationFormValues) => {
    try {
      if (!medication) {
        await sendCreateRequest(data);

        dispatch(
          addAlertToToastTrough({
            message: "Medication successfully added!",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      } else {
        await sendUpdateRequest(data);

        dispatch(
          addAlertToToastTrough({
            message: "Medication successfully updated!",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      }
    } catch (err: any) {
      posthog.capture("user_medication_error", {
        userId,
        error: err
      });
      dispatch(
        addAlertToToastTrough({
          message: "Whoops! Failed to add medication",
          type: STATUS_KEYS.ERROR
        })
      );
    }
  };

  /**
   * A helper function to get the parsed start and end dates for a medication
   * @param data The Medication Form data from the submit function
   * @returns [parsedStartDate, parseEndDate]
   */
  const getParsedStartEndDates = (
    data: MedicationFormValues
  ): Array<number | undefined> => {
    const parsedStart: number | undefined = data.start
      ? parseInt(dayjs(data.start).format("YYYYMMDD"))
      : undefined;
    const parsedEnd: number | undefined = data.end
      ? parseInt(dayjs(data.end).format("YYYYMMDD"))
      : undefined;
    return [parsedStart, parsedEnd];
  };

  /**
   * Helper async function that sends a create request from the submitted data
   * @param data The Medication Form data from the submit function
   */
  const sendCreateRequest = async (data: MedicationFormValues) => {
    const [parsedStart, parsedEnd] = getParsedStartEndDates(data);
    const medicationCreatePayload: MedicationCreate = {
      name: data.name.value,
      dosage: data.dosage.value,
      comment: data.comments,
      status: data.status.value,
      start: parsedStart,
      end: parsedEnd
    };
    await addUserMedication({
      userId,
      medicationCreate: medicationCreatePayload
    }).unwrap();
  };

  /**
   * Helper async function that sends an update request from the submitted data
   * @param data The Medication Form data from the submit function
   */
  const sendUpdateRequest = async (data: MedicationFormValues) => {
    const [parsedStart, parsedEnd] = getParsedStartEndDates(data);
    const medicationUpdatePayload: MedicationUpdate = {
      uuid,
      dosespot_id: medication?.dosespot_id,
      name: data.name.value,
      dosage: data.dosage.value,
      comment: data.comments,
      status: data.status.value,
      start: parsedStart,
      end: parsedEnd
    };

    await updateUserMedication({
      userId,
      medicationUpdate: medicationUpdatePayload
    }).unwrap();
  };

  /**
   * The helper function for asynchronously loading the medication Select Options
   * @param inputValue The input value passed by the Select component
   * @returns A promise that resolves with the options
   */
  const loadMedicationOptions = (inputValue: string) => {
    return new Promise<MedicationOption[]>(resolve => {
      searchMeds({ term: inputValue })
        .unwrap()
        .then(res => {
          const meds: MedicationOption[] = res.map(med => ({
            label: med,
            value: med
          }));
          resolve(meds);
        });
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={clsx(styles.AddMedication)}
    >
      <div className={clsx(styles.FieldGrid)}>
        {
          /* Medication dropdown: Prescriptions are read only */
          !medication?.is_prescription ? (
            <Controller
              name={"name"}
              control={control}
              rules={{
                required: true,
                validate: value => value.value !== ""
              }}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => (
                <div>
                  <label className="standardLabel">Medication</label>
                  <AsyncCreatableSelect
                    loadOptions={loadMedicationOptions}
                    cacheOptions
                    createOptionPosition="first"
                    allowCreateWhileLoading
                    formatCreateLabel={(inputValue: string) => inputValue}
                    theme={SelectDefaultTheme}
                    styles={SelectDefaultStyles}
                    onChange={onChange}
                    value={value}
                  />
                  {errors.name && <span className="errorText">Required</span>}
                </div>
              )}
            />
          ) : (
            <div>
              <p className={clsx(styles.FieldTitle)}>Name</p>
              <p>{medication?.name}</p>
            </div>
          )
        }

        {
          /* Dosage dropdown: Prescriptions are read only */
          !medication?.is_prescription ? (
            <Controller
              name={"dosage"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <div>
                  <label className="standardLabel">Dosage</label>
                  <CreatableSelect
                    createOptionPosition="first"
                    formatCreateLabel={(inputValue: string) => inputValue}
                    onChange={onChange}
                    value={value}
                    options={dosageOptions}
                    theme={SelectDefaultTheme}
                    styles={SelectDefaultStyles}
                  />
                </div>
              )}
            />
          ) : (
            <div>
              <p>Dosage</p>
              <p>{medication?.dosage}</p>
            </div>
          )
        }

        {
          /* Medication start date: Prescriptions are read only */
          !medication?.is_prescription ? (
            <Input
              id="start"
              name="start"
              type="date"
              label="Start"
              fullWidth
              register={register}
            />
          ) : (
            <div>
              <p>Start</p>
              <p>
                {medication?.start
                  ? dayjs(parseParioDate(medication?.start)).format(
                      "MM/DD/YYYY"
                    )
                  : "-"}
              </p>
            </div>
          )
        }

        {
          /* Medication end date: Prescriptions are read only */
          !medication?.is_prescription ? (
            <Input
              id="end"
              name="end"
              type="date"
              label="End"
              fullWidth
              register={register}
            />
          ) : (
            <div>
              <p>End</p>
              <p>
                {medication?.end
                  ? dayjs(parseParioDate(medication?.end)).format("MM/DD/YYYY")
                  : "-"}
              </p>
            </div>
          )
        }

        {/* Status dropdown */}
        <Controller
          name={"status"}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <div>
              <label className="standardLabel">Status</label>
              <Select
                options={statusOptions}
                onChange={onChange}
                value={value}
                theme={SelectDefaultTheme}
                styles={SelectDefaultStyles}
              />
            </div>
          )}
        />
      </div>

      {/* Comments */}
      <div className={styles.Comments}>
        <ControlledTextArea
          id="comments"
          name="comments"
          label="Comments"
          form={form}
          placeholder="Add any comments here"
        />
      </div>

      <div
        className={styles.Buttons}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {
          /* Can not remove prescriptions since they are managed by dosespot */
          medication && !medication?.is_prescription ? (
            <Button
              type="button"
              style={STYLES.SECONDARY}
              onClick={handleRemove}
              nativeButtonProps={{ disabled: isLoading }}
            >
              {isLoading ? (
                <LoadingIcon class={styles.loadingIcon} />
              ) : (
                "Remove"
              )}
            </Button>
          ) : null
        }

        <Button
          type="submit"
          style={!medication ? STYLES.FULL_WIDTH : STYLES.PRIMARY}
          nativeButtonProps={{ disabled: isLoading }}
        >
          {isLoading ? (
            <LoadingIcon class={styles.loadingIcon} />
          ) : medication ? (
            "Update"
          ) : (
            "Add"
          )}
        </Button>
      </div>
    </form>
  );
}

export default MedicationForm;
