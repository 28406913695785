import { EVENTS, STAGES } from "@/components/flows/labor/constants";
import { LaborModel, PatientInfo } from "@/store/services/patient";
import SectionCol from "../sectionCol";
import KeyValueGrid from "../keyValueGrid";
import KeyValueItem from "../keyValueItem";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";
import { FORMAT } from "@/globals/helpers/formatters";
import SectionRow from "../sectionRow";
import TableItem from "../tableItem";
import { useEffect, useState } from "react";
import ReadOnlySection from "../readOnlySection";
import dayjs from "dayjs";
import { getWeightObjectFromGrams } from "@/components/input/weightInput";

interface SummaryNewbornInfoProps {
  patient: PatientInfo;
  infant: PatientInfo;
  labor: LaborModel;
}

export default function SummaryNewbornInfo({
  labor,
  patient,
  infant
}: SummaryNewbornInfoProps) {
  const [newbornExam, setNewbornExam] = useState<Record<string, any>>({});
  const [weightString, setWeightString] = useState<string>("");

  useEffect(() => {
    if (
      labor &&
      labor.stages[STAGES.POSTPARTUM] &&
      labor.stages[STAGES.POSTPARTUM].events.newborn_exam
    ) {
      setNewbornExam(
        labor.stages[STAGES.POSTPARTUM].events.newborn_exam.forms as Record<
          string,
          any
        >
      );
    }
  }, [labor]);

  // Get formatted weight string
  useEffect(() => {
    const grams =
      infant.medical_history.weight ||
      labor?.stages[STAGES.POSTPARTUM]?.events[
        EVENTS.NEWBORN_EXAM
        //@ts-ignore
      ]?.forms?.birth_weight.grams;
    if (grams) {
      const weightObject = getWeightObjectFromGrams(grams);
      setWeightString(`${weightObject.pounds} lbs ${weightObject.ounces} oz`);
    } else {
      setWeightString("");
    }
  }, [labor, infant]);

  const getApgars = () => {
    const apgar1 =
      labor.stages[STAGES.POSTPARTUM]?.events[EVENTS.APGAR_1]?.forms ||
      ({} as Record<string, number>);
    const apgar5 =
      labor.stages[STAGES.POSTPARTUM]?.events[EVENTS.APGAR_5]?.forms ||
      ({} as Record<string, number>);
    const apgar10 =
      labor.stages[STAGES.POSTPARTUM]?.events[EVENTS.APGAR_10]?.forms ||
      ({} as Record<string, number>);

    let retArr: Array<number | string> = [0, 0, 0];
    Object.entries(apgar1).forEach(([key, value]) => {
      if (key != "start_time") {
        retArr[0] += value;
      }
    });
    Object.entries(apgar5).forEach(([key, value]) => {
      if (key != "start_time") {
        retArr[1] += value;
      }
    });
    Object.entries(apgar10).forEach(([key, value]) => {
      if (key != "start_time") {
        retArr[2] += value;
      }
    });
    if (Object.entries(apgar10).length == 0) {
      retArr[2] = "N/A";
    }
    return retArr.map(item => item.toString());
  };

  const getGestationalAge = () => {
    if (!patient?.pregnancy?.edd) {
      return "EDD not set, unable to calculate EGA";
    }

    const edd = dayjs(patient?.pregnancy?.edd?.estimated_due_date);
    if (!edd) {
      return "EDD not set, unable to calculate EGA";
    }
    
    const diffWeeks = edd.diff(dayjs(`${infant.dob}`, "YYYYMMDD"), "weeks");
    const ega = 40 - diffWeeks;
    return ega + " weeks";
  };

  return (
    <ReadOnlySection header="Newborn information">
      <SectionRow>
        {infant && (
          <SectionCol>
            <KeyValueGrid>
              <KeyValueItem keyLabel="Name:">
                <p>{FORMAT.name(infant as PatientInfo)}</p>
              </KeyValueItem>
              <KeyValueItem keyLabel="Date Born:">
                {infant.dob > 0 ? (
                  <p>
                    {dayjs(`${infant.dob}`, "YYYYMMDD").format("MM/DD/YYYY")}
                  </p>
                ) : (
                  <p>
                    <em>Infant DOB not set in infant chart</em>
                  </p>
                )}
              </KeyValueItem>
              <KeyValueItem keyLabel="Weight:">
                {weightString !== "" ? (
                  <p>{weightString}</p>
                ) : (
                  <p>
                    <em>Infant weight not set in infant chart</em>
                  </p>
                )}
              </KeyValueItem>
              <KeyValueItem keyLabel="EGA by EDD:">
                {/* TODO: What if the baby comes after estimated due date? Negative EGA? */}
                {patient?.pregnancy?.edd ? (
                  <p>{getGestationalAge()}</p>
                ) : (
                  <p>
                    <em>EDD not set, unabled to calculate EGA</em>
                  </p>
                )}
              </KeyValueItem>

              <KeyValueItem keyLabel="APGARS:">
                <TableItem
                  headers={["1 min", "5 min", "10 min"]}
                  values={[getApgars()]}
                  tightDisplay
                />
              </KeyValueItem>
            </KeyValueGrid>
          </SectionCol>
        )}
        {newbornExam && (
          <SectionCol>
            {/* From newborn exam */}
            <KeyValueGrid>
              <KeyValueItem keyLabel="Sex:">
                {infant.sex && infant.sex !== "UNKNOWN" ? (
                  <p>{infant.sex}</p>
                ) : (
                  <p>
                    <em>Infant sex not set in infant chart</em>
                  </p>
                )}
              </KeyValueItem>
              <KeyValueItem keyLabel="Time Born:">
                {newbornExam.birth_time ? (
                  <p>{newbornExam.birth_time}</p>
                ) : (
                  // TODO(Landon): Would be really cool to link to the Newborn Exam
                  // but that would mean routing for labor. Or maybe just a tooltip
                  // on hover so the user knows where to set this
                  <p>
                    <em>Birth time not set in the Newborn Exam</em>
                  </p>
                )}
              </KeyValueItem>
              <KeyValueItem keyLabel="Length:">
                {newbornExam.recumbent_length ? (
                  <p>{newbornExam.recumbent_length}</p>
                ) : (
                  <p>
                    <em>Infant length not set in the Newborn Exam</em>
                  </p>
                )}
              </KeyValueItem>
              <KeyValueItem keyLabel="EGA by Exam:">
                {newbornExam.EGA ? (
                  <p>{newbornExam.EGA}</p>
                ) : (
                  <p>
                    <em>Infant EGA not set in the Newborn Exam</em>
                  </p>
                )}
              </KeyValueItem>
            </KeyValueGrid>
          </SectionCol>
        )}
      </SectionRow>
    </ReadOnlySection>
  );
}
