// External
import { UseFormReturn } from "react-hook-form";

// Components
import Input from "@/components/input";
import FaxContact from "@/components/input/faxContact";
import ControlledTextArea from "@/components/textArea/controlledTextArea";

// Helpers
import { RecordGenerationFormValues } from "./helpers/recordGenerationHooks";

// styles
import styles from "./styles.module.scss";

interface SendRecordsFormProps {
  form: UseFormReturn<RecordGenerationFormValues>;
}

export default function SendRecordsForm({
  form
}: SendRecordsFormProps) {
  const {
    register,
    formState: { errors },
    setValue
  } = form;

  return (
    <div className={styles.SendRecordsForm}>
      <Input
        type="text"
        label="Recipient Name*"
        name="recipient_name"
        id="recipient_name"
        register={register}
        fullWidth
        rules={{
          required: true
        }}
        error={errors.recipient_name && "Recipient can't be blank"}
      />
      <input
        type="hidden"
        {...register("recipient_fax", {
          required: "Recipient fax can't be blank"
        })}
      />
      <FaxContact
        label="Recipient Fax*"
        required
        onChange={item => {
          if (item) {
            setValue("recipient_fax", item.fax, {
              shouldValidate: true
            });
          }
        }}
        error={errors.recipient_fax && "Recipient fax can't be blank"}
      />
      <Input
        label="Sender Name*"
        name="sender_name"
        id="sender_name"
        type="text"
        fullWidth
        placeholder="Provider's name"
        register={register}
        rules={{ required: true }}
        error={errors.sender_name && "Please enter a name for the provider"}
      />
      <Input
        label="Sender Phone*"
        name="sender_phone"
        id="sender_phone"
        type="text"
        fullWidth
        placeholder="(888) 555 4444"
        register={register}
        rules={{
          required: true,
          minLength: 10
        }}
        error={
          errors.sender_phone &&
          "Please check the provider phone and try again."
        }
      />
      <div className={styles.fullWidth}>
        <ControlledTextArea
          name="record_note"
          form={form}
          label="Note"
          id="record_note"
          rows={4}
        />
      </div>
    </div>
  );
}
