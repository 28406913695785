// External
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

// Components
import Button from "@/components/button";

// Store
import { TaskType } from "@/store/services/task";

// Styles
import styles from "../../styles.module.scss";
import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";

interface TaskTypeFormProps {
  onSubmit: (taskType: TaskType) => void;
}

export default function TaskTypeForm({
  onSubmit
}: TaskTypeFormProps) {
  const { control, handleSubmit } = useForm();
  const [taskTypeError, setTaskTypeError] = useState<boolean>(false);

  type TaskTypeOption = {
    value: TaskType;
    label: string;
  }

  const taskTypeOptions: Array<TaskTypeOption> = [
    {
      value: "FORM",
      label: "Fill out a form"
    },
    {
      value: "UPLOAD",
      label: "Upload a file"
    },
    {
      value: "GENERIC",
      label: "General task"
    },
    {
      value: "DEMOGRAPHICS",
      label: "Demographics & Insurance"
    }
  ];

  const onOurSubmit = (data: any) => {
    const taskType: TaskType = data.task_type as TaskType;
    if (!taskType) {
      setTaskTypeError(true);
      return;
    }

    onSubmit(taskType);
  };

  return (
    <div className={styles.TaskCreation} data-cy="task-creation-modal">
      <form onSubmit={handleSubmit(onOurSubmit)}>
        <div className={styles.inputContainer}>
          <Controller
            name="task_type"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                theme={SelectDefaultTheme}
                styles={SelectDefaultStyles}
                onChange={(item: any) => onChange(item?.value)}
                value={taskTypeOptions.find(item => item.value === value)}
                options={taskTypeOptions}
              />
            )}
            rules={{ required: true }}
          />
        </div>
        {taskTypeError && <p className="error">Please select a task type.</p>}
        <div className={styles.buttonContainer}>
          <Button type="submit">Next</Button>
        </div>
      </form>
    </div>
  );
}
