// External
import { useForm } from "react-hook-form";

// Store
import { PatientInfo, usePatientUpsertMutation, UserEmergencyContact } from "@/store/services/patient";

// Components
import FormElement from "../components/formElement";
import ListingControls from "../components/listingControls";

// Styles
import styles from "../../../styles.module.scss";

interface ContactFormProps {
  contact?: UserEmergencyContact;
  onUpdate: () => void;
  contactIndex: number;
  patientInfo: PatientInfo;
}

export default function ContactForm({ contact, onUpdate, contactIndex, patientInfo }: ContactFormProps) {
  const [updatePatient] = usePatientUpsertMutation(); 
  
    const form = useForm<UserEmergencyContact>({
    defaultValues: {
      ...contact
    }
  });

  const onSubmit = (data: UserEmergencyContact) => {
    const allContacts = [...(patientInfo.emergency_contacts || [])];
    if (!allContacts || allContacts.length < contactIndex) {
        throw new Error("Trying to update contact that doesn't exist")
    }

    allContacts[contactIndex] = {
        ...data
    };

    updatePatient({
        patientUpsertRequest: {
            ...patientInfo,
            emergency_contacts: allContacts,
        }
    })
  }

  const handleDelete = () => {
    const allContacts = [...(patientInfo.emergency_contacts || [])];
    if (!allContacts || allContacts.length < contactIndex) {
        throw new Error("Trying to update contact that doesn't exist")
    }

    allContacts.splice(contactIndex, 1);

    updatePatient({
        patientUpsertRequest: {
            ...patientInfo,
            emergency_contacts: allContacts,
        }
    });

    onUpdate();
  }

  return (
    <div className={styles.contactForm}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormElement form={form} submitOnBlur={onSubmit} />
        <ListingControls
          buttonType="UPDATE"
          onButtonPress={() => onUpdate()}
          onDelete={() => handleDelete()}
        />
      </form>
    </div>
  );
}
