// External
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";

// Store
import {
  PatientInfo,
  PatientUpsertApiArg,
  usePatientUpsertMutation,
  UserEmergencyContact
} from "@/store/services/patient";
import { normalizePhoneNumber } from "@/globals/helpers";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// Components
import ContactListItem from "./contactListing";
import NewContactForm from "./newContactForm";
import Button from "@/components/button";

// Styles
import styles from "../../styles.module.scss";

interface EmergencyContactListingProps {
  patientInfo: PatientInfo;
}

export default function EmergencyContactListing({
  patientInfo
}: EmergencyContactListingProps) {
  const dispatch = useDispatch();

  const [upsertPatient, { isLoading }] = usePatientUpsertMutation();

  const [addingContact, setAddingContact] = useState<boolean>(false);

  const emergencyContacts = useMemo(() => {
    return patientInfo.emergency_contacts || [];
  }, [patientInfo]);

  const onAddContact = (data: UserEmergencyContact) => {
    const phone =
      data.phone && normalizePhoneNumber(data.phone)?.normalizedPhoneNumber;

    const request: PatientUpsertApiArg = {
      patientUpsertRequest: {
        ...patientInfo,
        emergency_contacts: [
          ...(patientInfo.emergency_contacts || []),
          { ...data, phone }
        ]
      }
    };

    upsertPatient(request)
      .unwrap()
      .then(_ => {
        dispatch(
          addAlertToToastTrough({
            message: "Successfully added emergency contact",
            type: STATUS_KEYS.SUCCESS
          })
        );
        setAddingContact(false);
      })
      .catch(err => {
        console.error(err);
        addAlertToToastTrough({
          message: "Unable to add emergency contact",
          type: STATUS_KEYS.ERROR
        });
      });
  };

  return (
    <div className={styles.EmergencyContactListing}>
      {emergencyContacts.map((contact, index) => (
        <ContactListItem
          contact={contact}
          patientInfo={patientInfo}
          contactIndex={index}
          key={index}
        />
      ))}
      <div className={styles.contactListItem}>
        {addingContact ? (
          isLoading ? (
            <Skeleton height={36} />
          ) : (
            <NewContactForm
              onSubmit={onAddContact}
              onCancel={() => setAddingContact(false)}
            />
          )
        ) : (
          <div className={styles.buttonContainer}>
            <Button
              type="button"
              style={STYLES.FULL_WIDTH}
              onClick={() => setAddingContact(true)}
            >
              Add new contact
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
