// External
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import Select from "react-select";

// Store
import {
  Pregnancy,
  usePatientGetInfoQuery,
  usePatientUpsertMutation
} from "@/store/services/patient";
import { usePregnancyInfoQuery } from "@/store/services/pregnancy";

// Constants, helpers, globals
import { METRIC_LABELS, STATUS_KEYS, STYLES } from "@/globals/constants";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setModalIsOpen } from "../modalSlice";

// Components
import Input from "@/components/input";
import Button from "@/components/button";
import ComboboxSelect from "@/components/input/combobox";

// Styles
import styles from "../styles.module.scss";
import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";
import { useGetFeatureFlagsQuery } from "@/store/services/system";

interface CreateInfantProps {
  patientId: number;
  // If a pregnancyId is passed in, that means this chart should
  // automatically be associate with that pregnancy. Otherwise,
  // the user must pick a pregnancy from the past pregnancies.
  pregnancyId?: number;
  defaultValues?: any;
}

export default function CreateInfant({
  patientId,
  pregnancyId,
  defaultValues
}: CreateInfantProps) {
  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: defaultValues || {
      birth_weight: {
        pounds: 0,
        ounces: 0,
        grams: 0
      },
      infant_name: "",
      past_pregnancy: "",
      infant_dob: dayjs().format("YYYY-MM-DD")
    }
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    reset
  } = form;

  const { data: motherInfo } = usePatientGetInfoQuery(
    {
      patientId: patientId
    },
    { skip: !patientId }
  );

  const { data: pregnancyData } = usePregnancyInfoQuery(
    {
      pregnancyId: pregnancyId as number
    },
    { skip: !pregnancyId }
  );

  type PregnancyOption = {
    value: Pregnancy;
    label: string;
  };

  const pastPregnancyOptions: PregnancyOption[] = useMemo(() => {
    if (!motherInfo) {
      return [];
    }
    const pregnancies = (motherInfo.medical_history.pregnancies || []).filter(
      preg => preg.state != "DELETED" && preg.delivery_date
    );

    return pregnancies.map(preg => ({
      value: preg,
      label: `Delivered on ${dayjs(`${preg.delivery_date}`, "YYYYMMDD").format('MM/DD/YYYY')}`
    }));
  }, [motherInfo]);

  const [createInfant, { isError: isCreateInfantError }] =
    usePatientUpsertMutation();

  useEffect(() => {
    if (isCreateInfantError) {
      dispatch(
        addAlertToToastTrough({
          message: "Could not create infant chart",
          type: STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        })
      );
    }
  }, [isCreateInfantError]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  // Here we will update the infant we've already automatically
  // created when the form was first rendered with the form data.
  const ourOnSubmit = (data: any) => {
    if (!motherInfo) {
      dispatch(
        addAlertToToastTrough({
          message: "Something went wrong",
          type: STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        })
      );
      console.error("Could not get mother's info.");
      return;
    }

    let infantUpsertRequest = {
      first_name: data.infant_name || "Baby",
      last_name: motherInfo?.last_name,
      parent_ids: [patientId],
      sex: data.infant_sex || "UNKNOWN",
      insurance: motherInfo?.insurance,
      dob:
        parseInt(dayjs(data.infant_dob).utc().format("YYYYMMDD")) ||
        parseInt(dayjs.utc().format("YYYYMMDD")),
      address: motherInfo?.address,
      practice_data: {
        type: "INFANT"
      }
    } as Record<string, any>;

    if (pregnancyId && pregnancyData) {
      // If there's already an infant id, we want to update that chart
      const infantId = pregnancyData.infant_id;
      if (infantId) {
        infantUpsertRequest = {
          ...infantUpsertRequest,
          user_id: infantId
        };
      }
    }

    // Add either the passed in pregnancy id to automatically associate
    // or pass the selected pregnancy to associate
    if (
      pregnancyId ||
      (data.past_pregnancy && data.past_pregnancy.pregnancy_id)
    ) {
      infantUpsertRequest = {
        ...infantUpsertRequest,
        pregnancy_id: pregnancyId || data.past_pregnancy.pregnancy_id
      };
    }

    createInfant({
      patientUpsertRequest: infantUpsertRequest
    });

    dispatch(setModalIsOpen(false));
  };

  return (
    <div className={styles.CreateInfantChart}>
      <form onSubmit={handleSubmit(ourOnSubmit)}>
        {pastPregnancyOptions.length > 0 && !pregnancyId && (
          <Controller
            name="past_pregnancy"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <label className="standardLabel">
                  <span>Pregnancy infant is associated with</span>
                  <Select
                    theme={SelectDefaultTheme}
                    styles={SelectDefaultStyles}
                    id="associated_pregnancy"
                    placeholder="Select an associated pregnancy"
                    options={pastPregnancyOptions}
                    onChange={(newValue: unknown) => {
                      if (newValue) {
                        onChange((newValue as PregnancyOption).value);
                      }
                    }}
                    value={pastPregnancyOptions.find(v => v.value === value)}
                  />
                </label>
              </>
            )}
          />
        )}
        <Input
          label="Infant name"
          type="text"
          name="infant_name"
          id="infant_id"
          register={register}
        />
        <Input
          label="Infant DOB"
          type="date"
          name="infant_dob"
          id="infant_dob"
          value={defaultValues && defaultValues.infant_dob}
          register={register}
        />
        <Controller
          name="infant_sex"
          control={control}
          render={({ field: { onChange } }) => (
            <ComboboxSelect
              label="Sex"
              name="infant_sex"
              onChange={v => onChange(v)}
              labelAcc={item => METRIC_LABELS[item]}
              options={["MALE", "FEMALE", "UNKNOWN"]}
            />
          )}
        />
        <div className={styles.fullWidth}>
          <Button style={STYLES.FULL_WIDTH} type="submit">
            Create infant chart
          </Button>
        </div>
      </form>
    </div>
  );
}
