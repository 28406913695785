import { useDispatch } from "react-redux";

import { setModalIsOpen } from "@/components/modal/modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

import { PregnancyId } from "@/store/services/pregnancy";
import {
  usePatientGetInfoQuery,
  usePatientUpsertMutation,
  PatientGetInfoApiArg,
  PatientUpsertApiArg,
  UserId
} from "@/store/services/patient";
import {
  useEncounterInfoQuery,
  useEncounterUpdateMutation,
  EncounterUpdateApiArg,
  EncounterInfoApiArg,
  EncounterId,
  EncounterType
} from "@/store/services/encounter";

import {
  AreYouPrenatalChartingDisplayProps as DisplayProps,
  default as Display
} from "./modal";
import { STATUS_KEYS } from "@/globals/constants";

export interface AreYouPrenatalChartingProps {
  encounterId: EncounterId;
}

export default function AreYouPrenatalCharting({
  encounterId
}: AreYouPrenatalChartingProps) {
  const dispatch = useDispatch();

  // API call for encounter info
  const encounterReq: EncounterInfoApiArg = { encounterId };
  const { data: encounter, isLoading: encounterLoading } =
    useEncounterInfoQuery(encounterReq);

  // API call for patient info
  const patientReq: PatientGetInfoApiArg = {
    patientId: encounter?.patient?.user_id as UserId
  };
  const patientReqOptions = {
    skip: !encounter?.patient?.user_id
  };
  const { data: patient, isLoading: patientLoading } = usePatientGetInfoQuery(
    patientReq,
    patientReqOptions
  );

  // API calls to update encounter and patient
  const [updateEncounter, updateEncounterResp] = useEncounterUpdateMutation();
  const [upsertPatient, upsertPatientResp] = usePatientUpsertMutation();

  const handleClose = () => {
    dispatch(setModalIsOpen(false));
  };

  const handleLinkPregnancy = async (pregnancyId: PregnancyId) => {
    const req: EncounterUpdateApiArg = {
      encounterId,
      encounterCreateUpdatePayload: {
        patient_id: encounter?.patient?.user_id as UserId,
        provider_id: encounter?.provider?.user_id as UserId,
        encounter_type: encounter?.encounter_type as EncounterType,
        pregnancy_id: pregnancyId
      }
    };
    await updateEncounter(req)
      .unwrap()
      .then(() => {
        handleClose();
        dispatch(
          addAlertToToastTrough({
            message: "Pregnancy linked successfully",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(error => {
        console.error(error);
        dispatch(
          addAlertToToastTrough({
            message: "Failed to link pregnancy",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleCreatePregnancy = async () => {
    const req: PatientUpsertApiArg = {
      patientUpsertRequest: {
        user_id: encounter?.patient?.user_id as UserId,
        practice_data: {
          type: "OB"
        }
      }
    };

    await upsertPatient(req)
      .unwrap()
      .then(async p => {
        dispatch(
          addAlertToToastTrough({
            message: "Pregnancy created successfully",
            type: STATUS_KEYS.SUCCESS
          })
        );

        if (p.pregnancy?.pregnancy_id) {
          await handleLinkPregnancy(p.pregnancy?.pregnancy_id);
          handleClose();
        } else throw Error("Failed to refresh patient");
      })
      .catch(error => {
        console.error(error);
        dispatch(
          addAlertToToastTrough({
            message: "Failed to create pregnancy",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const props: DisplayProps = {
    type: encounter?.encounter_type as "PRENATAL" | "POSTPARTUM",
    activePregnancyId: patient?.pregnancy?.pregnancy_id,
    onClose: handleClose,
    onCreatePregnancy: handleCreatePregnancy,
    onLinkPregnancy: handleLinkPregnancy,
    submitting: updateEncounterResp.isLoading || upsertPatientResp.isLoading,
    loading: encounterLoading || patientLoading
  };

  return <Display {...props} />;
}
