/* CreateProblem */
/* External Imports */
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { useState } from "react";
/* Local Imports */

// components
import ControlledTextArea from "../textArea/controlledTextArea";
import ComboboxSelect from "@/components/input/combobox";
import Button from "@/components/button";
import Input from "@/components/input";

// constants
import { STYLES } from "@/globals/constants";

// store
import {
  Icd10CodeDetails,
  useLazyCodingSearchIcd10Query
} from "@/store/services/coding";
import { ProblemType, ProblemUpsertRequest } from "@/store/services/problem";

// styles
import styles from "./styles.module.scss";

/* CreateProblem Typescript Interface */
interface CreateProblemProps {
  onSubmit: (data: ProblemUpsertRequest) => void;
  onCancel: () => void;
}

export default function CreateProblem({
  onSubmit,
  onCancel
}: CreateProblemProps) {
  type CreateProblemFormFields = {
    icd_code?: Icd10CodeDetails;
    date_of_onset?: string;
    title?: string;
    note?: string;
  };
  const form = useForm<CreateProblemFormFields>();
  const {
    control,
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    getValues
  } = form;

  const [titleCodeError, setTitleCodeError] = useState<boolean>(false);

  /* Redux */
  const [searchICD10, { data: icd10codes }] = useLazyCodingSearchIcd10Query();
  /* Local State */

  /* Effects */

  /* Event Handlers */
  const onOurSubmit = (data: CreateProblemFormFields) => {
    // TODO: validation there's not both icd10 and title and at least one
    if (data.icd_code && data.title) {
      setTitleCodeError(true);
      return;
    } else {
      setTitleCodeError(false);
    }

    let type: ProblemType;
    if (data.icd_code) {
      type = "DIAGNOSIS";
    } else {
      type = "NOTE";
    }

    const date_of_onset = data.date_of_onset !== "" ? data.date_of_onset : undefined;

    const problemUpsertPayload: ProblemUpsertRequest = {
      type,
      date_of_onset,
      note: data.note
    };

    if (data.icd_code) {
      problemUpsertPayload.icd_id = data.icd_code.icd_id;
    }

    if (data.title) {
      problemUpsertPayload.title = data.title;
    }

    onSubmit(problemUpsertPayload);
  };

  return (
    <motion.div
      className={clsx(styles.CreateProblem)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <form onSubmit={handleSubmit(onOurSubmit)}>
        <div className={styles.filterWrapper}>
          <Controller
            name="icd_code"
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState
            }) => (
              <ComboboxSelect
                label="Select diagnosis"
                placeholder="Search for ICD10 code"
                options={icd10codes || []}
                labelAcc={item => `${item?.name} - ${item?.description}`}
                onChange={v => onChange(v)}
                onSearch={term => {
                  // get ICD10 codes from API
                  if (term.length >= 3) searchICD10({ term });
                }}
                error={
                  errors?.icd_code && (errors?.icd_code?.message as string)
                }
                fullWidth
              />
            )}
            rules={{ required: false }}
          />
          <span className="tMd t5">or</span>
          <Input
            label="Create problem title"
            id="new_problem_title"
            name="title"
            type="text"
            required={!getValues("icd_code")}
            register={register}
            fullWidth
          />
          {titleCodeError && (
            <p className="error">Please only enter ICD 10 OR title.</p>
          )}
        </div>
        <div className="grid2">
          <Input
            label="Date of onset"
            id="new_date_of_onset"
            name="date_of_onset"
            type="date"
            register={register}
            fullWidth
          />
          <div />
        </div>
        <ControlledTextArea
          hiddenLabel
          label="problem commentary"
          id={`problem_new_note`}
          name={`note`}
          placeholder="add a note about this problem"
          setValue={setValue}
          rules={{
            required: true
          }}
          form={form}
        />
        <div className={styles.buttons}>
          <Button style={STYLES.SECONDARY} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Save Problem</Button>
        </div>
      </form>
    </motion.div>
  );
}
