// External
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

// Store
import { usePracticeListContactsQuery } from "@/store/services/practice";
import { RootState } from "@/store/store";

// Components
import ComboboxSelect from "./combobox";
import Fuse from "fuse.js";

interface FaxContactProps {
  label?: string;
  required?: boolean;
  onChange: (faxContact: Record<string, string> | null) => void;
  error?: string;
}

export default function FaxContact({ onChange, label, required, error }: FaxContactProps) {
  // Store
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const { data: contactsData } = usePracticeListContactsQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo || !sessionInfo.practice_id }
  );

  // Local state

  // The entered fax number from the input
  const [enteredNumber, setEnteredNumber] = useState<Record<string, any>>({});
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Create the faxContacts list from the contactsData
  const faxContacts = useMemo(() => {
    if (!contactsData) {
      return [];
    }

    const contacts = contactsData.filter(contact => !!contact.fax).map(contact => {
        return {
            name: contact.name,
            fax: contact.fax
        }
    });

    if (searchTerm !== "") {
      const fuse = new Fuse(contacts, {
        keys: ["name", "fax"]
      });
      return fuse.search(searchTerm).map(r => r.item);
    }

    return contacts;
  }, [contactsData, searchTerm]);

  return (
    <ComboboxSelect
      label={ label || "Search contacts or enter a fax number" }
      initialValue={""}
      onChange={item => {
        onChange(item);
      }}
      onSearch={(item: any) => {
        setSearchTerm(item);
        setEnteredNumber({ name: `New fax number`, fax: `${item}` });
      }}
      options={[...faxContacts, enteredNumber]}
      labelAcc={item =>
        item && item.name && item.fax ? `${item.name} - ${item.fax}` : ""
      }
      fullWidth
      required={required}
      error={error}
    />
  );
}
