import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    locationGetList: build.query<
      LocationGetListApiResponse,
      LocationGetListApiArg
    >({
      query: queryArg => ({
        url: `/location/${queryArg.practiceId}/list`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    locationGetInfo: build.query<
      LocationGetInfoApiResponse,
      LocationGetInfoApiArg
    >({
      query: queryArg => ({ url: `/location/${queryArg.locationId}/info` })
    }),
    locationGetUserList: build.query<
      LocationGetUserListApiResponse,
      LocationGetUserListApiArg
    >({
      query: queryArg => ({
        url: `/location/${queryArg.locationId}/users`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          pagesz: queryArg.pagesz
        }
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as location };
export type LocationGetListApiResponse =
  /** status 200 Abbreviated list of locations suitable for dropdown list population.
   */ LocationList;
export type LocationGetListApiArg = {
  practiceId: PracticeId;
  page?: Page;
  pagesz?: PageSize;
};
export type LocationGetInfoApiResponse =
  /** status 200 Sent to describe a single location in detail.
   */ LocationInfo;
export type LocationGetInfoApiArg = {
  locationId: LocationId;
};
export type LocationGetUserListApiResponse =
  /** status 200 Abbreviated list of users of a given type in a given location.
   */ LocationUsersList;
export type LocationGetUserListApiArg = {
  locationId: LocationId;
  filters?: LocationUserRoleFilters;
  page?: Page;
  pagesz?: PageSize;
};
export type LocationType =
  | "MIDWIFE"
  | "BIRTHING_CENTER"
  | "MIDWIFERY_CLINIC"
  | "OBGYN_CLINIC"
  | "HOSPITAL"
  | "INTERNAL";
export type LocationListItem = {
  /** System-assigned unique location identifier.
   */
  location_id?: number;
  /** User ID of the user who owns the practice or location. An owner has extra priviliges. Every practice
    or location has one and only one owner, and by definition this user can see, modify or create anything
    within that practice / location. However, just because a user is an owner of one practice does not
    infer any rights or priviliges too other practices. Likewise a location owner has no extra powers
    outside of their location.
     */
  owner_id?: number;
  /** The name of the location. There is no requirement that this be unique as locations are referenced
    throughout the system by their ID, not their name.
     */
  name?: string;
  type?: LocationType;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** A timezone in the form of a string. This is a standard timezone string, such as 'America/New_York'.
   */
  timezone?: string;
};
export type LocationList = LocationListItem[];
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type PracticeId = number;
export type Page = number;
export type PageSize = number;
export type Statecode =
  | "AK"
  | "AL"
  | "AR"
  | "AZ"
  | "CA"
  | "CO"
  | "CT"
  | "DC"
  | "DE"
  | "FL"
  | "GA"
  | "HI"
  | "IA"
  | "ID"
  | "IL"
  | "IN"
  | "KS"
  | "KY"
  | "LA"
  | "MA"
  | "MD"
  | "ME"
  | "MI"
  | "MN"
  | "MO"
  | "MS"
  | "MT"
  | "NC"
  | "ND"
  | "NE"
  | "NH"
  | "NJ"
  | "NM"
  | "NV"
  | "NY"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "PR"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VA"
  | "VI"
  | "VT"
  | "WA"
  | "WI"
  | "WV"
  | "WY";
export type Address = {
  /** Address line 1. Mandatory. This can include the street number.
   */
  line1?: string;
  /** Optional line 2 of 3 for an address.
   */
  line2?: string;
  /** Optional line 3 of 3 for an address.
   */
  line3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  /** This is the optional, human-readable representation of the address. This is used
    when communicating addresses that come from sources that don't parse their addresses
    down into its constituent parts, like Health Gorilla.
     */
  display?: string;
};
export type PhraseModel = {
  /** A unique phrase defined by a practice as one of its "dot-phrases". This must be alphanumeric, lower case, start with
    an alphabetic character, and between 1 and 16 characters in length. It must be unique across a practice.
     */
  phrase: string;
  /** Description of a dot-phrase, used in selection dropdown.
   */
  description?: string;
  /** Expansion for a dot-phrase.
   */
  text: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  last_editor: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_edited: number;
};
export type PhrasePreferences = {
  [key: string]: PhraseModel;
};
export type LabOrgInfo = {
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  id: string;
  name: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax: string;
};
export type LabLocationInfo = {
  /** Health Gorilla ID for Location FHIR/R4 resource
   */
  location_id: string;
  name: string;
  address: Address;
  lab?: LabOrgInfo;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax: string;
};
export type LabObservationStatus =
  | "registered"
  | "preliminary"
  | "final"
  | "amended"
  | "cancelled"
  | "corrected";
export type ObservationSource = "LAB" | "MANUAL";
export type ValueQuantity = {
  value: string;
  unit?: string;
};
export type ReferenceRange = {
  low?: ValueQuantity;
  high?: ValueQuantity;
  text?: string;
};
export type PerformingSite = {
  name?: string;
  address?: Address;
  contact?: string;
};
export type LabObservationInfo = {
  /** System-assigned unique ID of an observation stored in the Pario system.
    Not assigned to observations stored in Health Gorilla.
     */
  observation_id: string;
  /** An ID for a lab test.
   */
  test_id: string;
  /** Health Gorilla ID for an observation from lab results.
   */
  hg_id: string;
  status: LabObservationStatus;
  /** Unique file ID to reference a file stored in S3.
   */
  file_id?: number;
  source: ObservationSource;
  display: string;
  code: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  issued: string;
  value?: string;
  unit?: string;
  reference_range?: ReferenceRange;
  interpretation?: string;
  note?: string;
  performing_site?: PerformingSite;
};
export type LabOrderStatus =
  | "NEW"
  | "SENT"
  | "IN_PROGRESS"
  | "ERROR"
  | "FULFILLED"
  | "CORRECTED"
  | "REVIEWED";
export type AoeAnswer = {
  aoe_id: string;
  response: string;
};
export type LabTestInfo = {
  /** An ID for a lab test.
   */
  test_id: string;
  /** An ID for a lab order.
   */
  order_id?: string;
  /** Code used by the lab to identify a test.
   */
  code: string;
  /** Code used by the lab to identify a test.
   */
  display: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note_to_lab?: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note_from_lab?: string;
  observations: LabObservationInfo[];
  status?: LabOrderStatus;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated: string;
  aoe_answers?: AoeAnswer[];
};
export type LabTests = LabTestInfo[];
export type LabTestBundle = {
  /** System-assigned ID for a bundle. Formatted as a UUID.
   */
  bundle_uuid?: string;
  /** User-defined name for a bundle.
   */
  name: string;
  tests: LabTests;
};
export type LocationBookmarkedLab = {
  locations: {
    [key: string]: LabLocationInfo;
  };
  bundles: {
    [key: string]: LabTestBundle;
  };
};
export type LocationBookmarkedLabs = {
  [key: string]: LocationBookmarkedLab;
};
export type UserId = number;
export type UserIdList = UserId[];
export type LocationGroupListItem = {
  /** System-assigned unique ID for a group of users.
   */
  group_id: number;
  /** The name of a location group. This is a collection of users (most likely staff but patients are allowed too)
    within the location. For example, all front desk staff.
     */
  name: string;
  /** System level and defined flags. Very little should ever see let alone be able to set these.
   */
  flags: number;
  members: UserIdList;
};
export type LocationGroups = {
  [key: string]: LocationGroupListItem;
};
export type LocationPreferences = {
  phrases: PhrasePreferences;
  labs?: LocationBookmarkedLabs;
  prenatal_test_sequence?: object;
  groups?: LocationGroups;
};
export type LocationDosespotMeta = {
  clinic_id?: number;
  clinic_key?: string;
};
export type LocationMetadata = {
  dosespot?: LocationDosespotMeta;
};
export type LocationInfo = {
  /** System-assigned unique location identifier.
   */
  location_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** User ID of the user who owns the practice or location. An owner has extra priviliges. Every practice
    or location has one and only one owner, and by definition this user can see, modify or create anything
    within that practice / location. However, just because a user is an owner of one practice does not
    infer any rights or priviliges too other practices. Likewise a location owner has no extra powers
    outside of their location.
     */
  owner_id?: number;
  /** The name of the location. There is no requirement that this be unique as locations are referenced
    throughout the system by their ID, not their name.
     */
  name: string;
  location_type: LocationType;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  contact?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  contact_email?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  public_email?: string;
  address: Address;
  fields?: object;
  preferences: LocationPreferences;
  metadata: LocationMetadata;
  /** Tax identification number.
   */
  tin?: string;
  /** Clinical Laboratory Improvement Amendments ID. CLIA is a federal regulation that applies to labs in the
    United States.
     */
  clia?: string;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
  /** A timezone in the form of a string. This is a standard timezone string, such as 'America/New_York'.
   */
  timezone?: string;
};
export type LocationId = number;
export type RoleId = number;
export type UserPracticeRoles = RoleId[];
export type LocationIds = LocationId[];
export type UserMetaPractice = {
  roles: UserPracticeRoles;
  locations: LocationIds;
  /** A yes/no or true/false value.
   */
  is_ma: boolean;
  /** A yes/no or true/false value.
   */
  is_provider: boolean;
  /** A yes/no or true/false value.
   */
  is_admin: boolean;
  /** A yes/no or true/false value.
   */
  is_superadmin: boolean;
  /** A yes/no or true/false value.
   */
  is_patient: boolean;
  /** A yes/no or true/false value.
   */
  is_biller: boolean;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_email?: number;
};
export type UserMetaPractices = {
  [key: string]: UserMetaPractice;
};
export type UserMetaLast = {
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** A role which can be assigned to a user at the practice. The role ID is defined at the practice level but
    a given user can have a different role at each location. This is used to determine what the user can do at the
    location level. For example, a user may be a patient at one location and a front desk person at another. Each
    user in a practice has a practice role too, which is used to determine what they can do at the practice level.
    So a user will have a practice role and a location role (or multiple location roles).
     */
  role_id: number;
};
export type UserDosespotMeta = {
  patient_id?: number;
  proxy_clinician_id?: number;
  prescribing_clinician_id?: number;
};
export type UserMetadata = {
  practices: UserMetaPractices;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
  /** Drug Enforcement Administration (DEA) number.
   */
  dea_id?: string;
  last?: UserMetaLast;
  dosespot: UserDosespotMeta;
};
export type LocationUsersListItem = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  /** A role which can be assigned to a user at the practice. The role ID is defined at the practice level but
    a given user can have a different role at each location. This is used to determine what the user can do at the
    location level. For example, a user may be a patient at one location and a front desk person at another. Each
    user in a practice has a practice role too, which is used to determine what they can do at the practice level.
    So a user will have a practice role and a location role (or multiple location roles).
     */
  location_role: number;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  address?: Address;
  metadata: UserMetadata;
};
export type LocationUsersList = LocationUsersListItem[];
export type LocationUserRoleFilter =
  | "patient"
  | "provider"
  | "medical-assistant"
  | "admin"
  | "biller";
export type LocationUserRoleFilters = LocationUserRoleFilter[];
export const {
  useLocationGetListQuery,
  useLazyLocationGetListQuery,
  useLocationGetInfoQuery,
  useLazyLocationGetInfoQuery,
  useLocationGetUserListQuery,
  useLazyLocationGetUserListQuery
} = injectedRtkApi;
